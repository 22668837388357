// index.ts
import { feature, featureCollection, point } from "@turf/helpers";

// lib/sweepline-intersections-export.ts
import lib from "sweepline-intersections";
var sweeplineIntersections = lib;

// index.ts
function lineIntersect(line1, line2, options = {}) {
  const {
    removeDuplicates = true,
    ignoreSelfIntersections = false
  } = options;
  let features = [];
  if (line1.type === "FeatureCollection") features = features.concat(line1.features);else if (line1.type === "Feature") features.push(line1);else if (line1.type === "LineString" || line1.type === "Polygon" || line1.type === "MultiLineString" || line1.type === "MultiPolygon") {
    features.push(feature(line1));
  }
  if (line2.type === "FeatureCollection") features = features.concat(line2.features);else if (line2.type === "Feature") features.push(line2);else if (line2.type === "LineString" || line2.type === "Polygon" || line2.type === "MultiLineString" || line2.type === "MultiPolygon") {
    features.push(feature(line2));
  }
  const intersections = sweeplineIntersections(featureCollection(features), ignoreSelfIntersections);
  let results = [];
  if (removeDuplicates) {
    const unique = {};
    intersections.forEach(intersection => {
      const key = intersection.join(",");
      if (!unique[key]) {
        unique[key] = true;
        results.push(intersection);
      }
    });
  } else {
    results = intersections;
  }
  return featureCollection(results.map(r => point(r)));
}
var turf_line_intersect_default = lineIntersect;
export { turf_line_intersect_default as default, lineIntersect };
