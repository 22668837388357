<div class="flex flex-col h-full">
  @if (busySaving()) {
    <div class="w-full absolute">
      <mat-progress-bar class="mds-x-small" [mode]="'indeterminate'"></mat-progress-bar>
    </div>
  }

  <div class="flex flex-row p-4 pr-6 border-b border-gray-1">
    <div class="h-8 flex flex-1 flex-wrap content-center font-semibold">
      {{ (classificationScheme$ | async)?.isNew ? 'Add Classification' : 'Edit Classification' }}
    </div>
    <button
      id="options-panel-classification-edit-close"
      class="text-gray-8"
      modus-icon-button
      modus-tooltip="Close"
      (click)="close()"
      [disabled]="busySaving()"
    >
      <md-icon>close</md-icon>
    </button>
  </div>

  @if (classificationScheme$ | async; as classificationScheme) {
    <div class="flex-1 overflow-y-scroll p-4">
      <div class="text-small italic mb-4">
        Note: This action applies to all classifications for the project.
      </div>
      <!-- id -->
      <sd-label-value label="Class #" value="{{ classificationScheme.id }}"></sd-label-value>
      <!-- name -->
      <modus-form-field class="mb-2">
        <modus-label>Description</modus-label>
        <input
          modus-input
          [formControl]="formGroup.controls.name"
          (blur)="trimInputValue(formGroup.controls.name)"
        />
      </modus-form-field>
      <!-- color -->
      <modus-label class="block text-small text-gray-8">Point Style</modus-label>
      <div class="h-8 block mb-2">
        <div class="fixed">
          <input
            [formControl]="formGroup.controls.rgba"
            colorPicker="#{{ formGroup.value.rgba }}"
            [cpOutputFormat]="'hex'"
            [cpAlphaChannel]="'disabled'"
            [cpPosition]="'top-right'"
            [cpCancelButton]="true"
            [cpCancelButtonClass]="'modus-button modus-button-medium modus-basic-button'"
            [cpOKButton]="true"
            [cpOKButtonClass]="'modus-button modus-button-medium modus-flat-button-primary'"
            (colorPickerSelect)="setColor($event)"
            style="background-color: #{{ formGroup.value.rgba }}"
            class="w-10 pl-10 border border-gray-0 rounded-2xl"
            [ngClass]="{ 'cursor-pointer': formGroup.enabled }"
            readonly
          />
        </div>
      </div>
      <!-- visible -->
      <modus-label class="block text-small text-gray-8">Default 3D Visbility</modus-label>
      <modus-switch
        [label]="visibleLabel()"
        [formControl]="formGroup.controls.visible"
      ></modus-switch>
      <!-- error alert -->
      @if (classificationSaveError$ | async) {
        <div class="w-full mt-4">
          <modus-alert
            class="w-full"
            message="There was an error saving the classification template."
            type="error"
          ></modus-alert>
        </div>
      }
    </div>
  }

  <div class="flex px-4 p-4 justify-end">
    <button
      id="options-panel-classification-edit-close"
      modus-button
      class="mr-4"
      color="secondary"
      (click)="close()"
      [disabled]="busySaving()"
    >
      Cancel
    </button>

    <button
      id="options-panel-classification-edit-save"
      modus-flat-button
      color="secondary"
      (click)="save()"
      [disabled]="saveDisabled()"
    >
      Save
    </button>
  </div>
</div>
