import { Directive, ElementRef, Input } from '@angular/core';

export type ButtonSize = 'small' | 'medium' | 'large';

@Directive()
export class ButtonBase {
  protected element: HTMLButtonElement;

  private _size: ButtonSize = 'medium';

  @Input() set size(value: ButtonSize) {
    const oldSize = this.sizeClasses[this._size];
    const newSize = this.sizeClasses[value];

    if (oldSize) this.element.classList.remove(oldSize);
    this.element.classList.add(newSize);

    this._size = value;
  }

  private sizeClasses = {
    small: 'modus-button-small',
    medium: 'modus-button-medium',
    large: 'modus-button-large',
  };

  constructor(protected elementRef: ElementRef) {
    this.element = elementRef.nativeElement as HTMLButtonElement;
    this.element.classList.add('modus-button', 'modus-button-medium');
  }
}
