import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { FeatureCollection } from 'geojson';
import { SetFeatureCollection, SetIsLoading } from './feature-layer.actions';

export interface FeatureLayerStateModel {
  featureCollection: FeatureCollection | null;
  isLoading: boolean;
}

const defaultState: FeatureLayerStateModel = {
  featureCollection: null,
  isLoading: false,
};

@State<FeatureLayerStateModel>({
  name: 'featureLayerState',
  defaults: defaultState,
})
@Injectable()
export class FeatureLayerState {
  @Selector()
  static featureCollection(state: FeatureLayerStateModel) {
    return state.featureCollection;
  }

  @Selector() static isLoading(state: FeatureLayerStateModel): boolean {
    return state.isLoading;
  }

  @Action(SetFeatureCollection)
  setFeatureCollection(
    ctx: StateContext<FeatureLayerStateModel>,
    { featureCollection }: SetFeatureCollection,
  ) {
    ctx.patchState({ featureCollection: featureCollection });
  }

  @Action(SetIsLoading) setIsLoading(
    ctx: StateContext<FeatureLayerStateModel>,
    { isLoading }: SetIsLoading,
  ) {
    ctx.patchState({ isLoading: isLoading });
  }
}
