<div class="flex p-1">
  <mat-select
    id="map-toolbar-layers"
    class="modus-dropdown !w-[140px] !px-2 hover:bg-gray-0"
    panelClass="modus-dropdown-panel"
    [formControl]="layerOptionControl"
    disableRipple
  >
    <mat-select-trigger class="flex items-center h-8 min-w-[100px]">
      <md-icon class="mr-1 text-gray-8">layers</md-icon>
      {{ activeBaseLayer()?.name }}
    </mat-select-trigger>
    @for (item of baseLayers(); track $index) {
      <mat-option [value]="item.name">{{ item.name }}</mat-option>
    }
  </mat-select>
</div>

<!-- add icon buttons in a seperate div so flex wrap will break here -->
<div class="flex p-1">
  <button
    id="map-toolbar-pan"
    class="text-gray-8 ml-1"
    modus-icon-button
    color="secondary"
    aria-label="pan"
    (click)="panSelectClick()"
    [ngClass]="{ ' !bg-blue-pale !text-trimble-blue': panSelectActive() }"
  >
    <md-icon>hand</md-icon>
  </button>

  <button
    id="map-toolbar-select-rectangle"
    class="text-gray-8 ml-1"
    modus-icon-button
    color="secondary"
    aria-label="rectangle select"
    (click)="rectangleSelectClick()"
    [ngClass]="{ ' !bg-blue-pale !text-trimble-blue': rectangleSelectActive() }"
  >
    <md-icon>polygon_drag_rectangle</md-icon>
  </button>

  <button
    id="map-toolbar-select-polygon"
    class="text-gray-8 ml-1"
    modus-icon-button
    color="secondary"
    aria-label="polygon select"
    (click)="polygonSelectClick()"
    [ngClass]="{ '!bg-blue-pale !text-trimble-blue': polygonSelectActive() }"
  >
    <md-icon>polygon_cone</md-icon>
  </button>

  <button
    id="map-toolbar-zoom-in"
    class="text-gray-8 ml-1"
    modus-icon-button
    color="secondary"
    aria-label="pan"
    (click)="zoomInClick.emit()"
  >
    <md-icon>zoom_in</md-icon>
  </button>

  <button
    id="map-toolbar-zoom-out"
    class="text-gray-8 ml-1"
    modus-icon-button
    color="secondary"
    aria-label="pan"
    (click)="zoomOutClick.emit()"
  >
    <md-icon>zoom_out</md-icon>
  </button>

  <button
    id="map-toolbar-fit-to-view"
    class="text-gray-8 ml-1"
    modus-icon-button
    color="secondary"
    aria-label="fit to view"
    (click)="fitToViewClick.emit()"
  >
    <md-icon>expand</md-icon>
  </button>
</div>
