import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isNil } from '@trimble-gcs/common';
import { toIsoDateString } from '@trimble-gcs/modus';

/**
 * @param maxDate - ISO8601 formatted string or function in the format 'YYYY-MM-DD'
 */
export function MaxDateValidator(maxDate: string | (() => string)): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (isNil(control.value) || control.value.length === 0) {
      return null;
    }

    const dateValue = toIsoDateString(control.value);
    const maxDateValue = typeof maxDate === 'function' ? maxDate() : maxDate;

    const valid = new Date(dateValue) <= new Date(maxDateValue);
    if (!valid) return { maxDateExceeded: true };

    return null;
  };
}
