import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  effect,
  input,
  output,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { isNil } from '@trimble-gcs/common';
import {
  ModusButtonModule,
  ModusCheckboxModule,
  ModusFormFieldModule,
  ModusIconModule,
  ModusSelectModule,
  ModusTooltipModule,
} from '@trimble-gcs/modus';
import {
  ScandataEmptyComponent,
  ScandataEmptyReason,
} from '../../scandata-list/scandata-empty/scandata-empty.component';
import { PointcloudStatus, ScandataModel } from '../../scandata/scandata.models';
import { MapFilterOption, MapScandataModel } from '../map.models';

interface ScanListItem {
  scan: MapScandataModel;
  notReady: boolean;
  hasError: boolean;
  message: string | null;
}

@Component({
  selector: 'sd-map-list',
  standalone: true,
  imports: [
    CommonModule,
    ScandataEmptyComponent,
    MatProgressBarModule,
    ModusFormFieldModule,
    ModusIconModule,
    ModusButtonModule,
    ModusSelectModule,
    ModusCheckboxModule,
    ModusTooltipModule,
    ScrollingModule,
    MatSelectModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './map-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapListComponent {
  scanLoadError = input<boolean>(false);
  isLoading = input<boolean>(false);

  filterOption = input.required<MapFilterOption>();
  showScandataEmpty = input.required<boolean>();
  scandataEmptyReason = input.required<ScandataEmptyReason>();

  mapScandata = input.required<ScanListItem[], MapScandataModel[]>({
    transform: (scans) => {
      return scans.map((scan) => ({
        scan,
        notReady: this.scanNotReady(scan),
        hasError: this.scanHasError(scan),
        message: this.getScanMessage(scan),
      }));
    },
  });

  changeItemVisibilityClicked = output<ScandataModel>();
  itemClicked = output<ScandataModel>();
  itemChecked = output<ScandataModel>();
  zoomToItemClicked = output<ScandataModel>();
  filterOptionChanged = output<MapFilterOption>();

  mapFilterOption = MapFilterOption;
  filterOptionControl = new FormControl<MapFilterOption>(MapFilterOption.All);

  private filterOptionControlValue = toSignal(this.filterOptionControl.valueChanges, {
    initialValue: null,
  });

  constructor() {
    effect(() => {
      this.filterOptionControl.setValue(this.filterOption());
    });

    effect(() => {
      const filterOptionControlValue = this.filterOptionControlValue();
      if (isNil(filterOptionControlValue)) return;
      this.filterOptionChanged.emit(filterOptionControlValue);
    });
  }

  private scanHasError(scan: ScandataModel) {
    return scan.pointcloudStatus === PointcloudStatus.Failed;
  }

  private scanNotReady(scan: ScandataModel) {
    return (
      scan.pointcloudStatus === PointcloudStatus.Uploading ||
      scan.pointcloudStatus === PointcloudStatus.Processing
    );
  }

  private getScanMessage(scan: ScandataModel) {
    if (this.scanNotReady(scan)) return 'Not ready for viewing';
    if (this.scanHasError(scan)) return 'Failed to ingest';
    return null;
  }
}
