<div #chipContainer class="flex flex-wrap justify-start h-fit my-auto">
  @for (chip of chips; track chip) {
    <modus-chip
      id="tag"
      #chip
      class="mr-2 my-1"
      [value]="chip"
      (click)="chipClick.emit($event)"
    ></modus-chip>
  }

  <!-- summary chip -->
  <modus-chip
    id="summary-tag"
    #summaryChip
    [modus-tooltip]="summaryTooltip()"
    (click)="chipClick.emit($event)"
    class="mr-2 my-1"
    value="..."
  ></modus-chip>
</div>
