<button id="3d-sort" class="text-gray-8" modus-icon-button>
  <md-icon [modusMenuTriggerFor]="menu">sort</md-icon>
</button>

<md-menu #menu>
  @for (item of menuItems(); track item.name) {
    <md-menu-item (itemClick)="onSortClick(item)" class="w-16"
      ><span class="w-12">{{ item.name }}</span>
      @if (item.active) {
        <md-icon class="!text-sm">{{ getIcon(item) }}</md-icon>
      }
    </md-menu-item>
  }
</md-menu>
