import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ModusButtonModule, ModusIconModule } from '@trimble-gcs/modus';
import { User } from '../../user/user.models';
import { ProjectSelectComponent } from './project-select/project-select.component';

@Component({
  selector: 'sd-app-bar',
  standalone: true,
  imports: [
    CommonModule,
    ModusButtonModule,
    ModusIconModule,
    MatToolbarModule,
    MatMenuModule,
    ProjectSelectComponent,
  ],
  templateUrl: './app-bar.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBarComponent {
  @Input() user?: User;
  @Input() loggedIn = false;

  @Output() menuClick = new EventEmitter<MouseEvent>();
  @Output() profileClick = new EventEmitter<void>();
  @Output() licenceClick = new EventEmitter<void>();
  @Output() logoutClick = new EventEmitter<void>();
}
