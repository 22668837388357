<div class="max-w-[320px]">
  <!-- header -->
  <div mat-dialog-title class="flex flex-row p-4 items-center">
    <div class="flex flex-1 flex-wrap items-center font-bold">Out Of Space</div>
    <button modus-icon-button (click)="closeClick()">
      <md-icon>close</md-icon>
    </button>
  </div>

  <!-- content -->
  <div mat-dialog-content class="flex-auto p-4 text-sm">
    <div class="flex flex-auto flex-col items-center justify-center">
      <img src="../../../assets/out-of-quota.png" class="w-[260px]" />
      <div class="text-lg font-semibold mt-6">Quota Exceeded</div>
      <div class="text-center text-sm mt-2">
        Looks like you have run out of space. Clean up your data by deleting or archiving,
        alternatively, contact your license administrator to upgrade.
      </div>
    </div>
  </div>

  <!-- actions -->
  <div mat-dialog-actions class="flex pt-4 pb-8 px-8 justify-end items-center">
    <button modus-flat-button (click)="closeClick()">Ok</button>
  </div>
</div>
