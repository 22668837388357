import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, Component } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { Observable, combineLatest, combineLatestWith, filter, map } from 'rxjs';
import { ErrorState } from '../error-handling/error.state';
import { SetInfoView } from '../options-panel/options-panel.actions';
import { OptionsPanelInfoView } from '../options-panel/options-panel.state';
import { Sorting } from '../scandata/scandata-query.models';
import { ScandataSelectedState } from '../scandata/scandata-selected.state';
import {
  PatchScandataModel,
  SetSorting,
  UnselectAllScandataModels,
} from '../scandata/scandata.actions';
import { ScandataModel } from '../scandata/scandata.models';
import { ScandataState } from '../scandata/scandata.state';
import {
  ScandataEmptyComponent,
  ScandataEmptyReason,
} from './scandata-empty/scandata-empty.component';
import { ScandataTableComponent } from './scandata-table/scandata-table.component';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    ScandataTableComponent,
    ScandataEmptyComponent,
    MatProgressBarModule,
    ScrollingModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './scandata-list.component.html',
  styleUrls: ['./scandata-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScandataListComponent {
  @Select(ScandataState.textFilteredScandata) filteredScandata$!: Observable<ScandataModel[]>;
  @Select(ScandataState.sorting) sorting$!: Observable<Sorting>;
  @Select(ScandataSelectedState.ids) selectedIds$!: Observable<string[]>;
  @Select(ScandataState.isLoading) isLoading$!: Observable<boolean>;
  @Select(ErrorState.hasError('scanLoadError')) scanLoadError$!: Observable<boolean>;

  @Select(ScandataState.scandata) private scandata$!: Observable<ScandataModel[]>;
  @Select(ScandataState.filterCount) private filterCount$!: Observable<number>;
  @Select(ScandataState.textFilter) private textFilter$!: Observable<string | undefined>;

  showScandataEmpty$!: Observable<boolean>;
  scandataEmptyReason$!: Observable<ScandataEmptyReason>;

  constructor(private store: Store) {
    this.setupScandataEmpty();
  }

  selectionChange(model: ScandataModel) {
    this.store.dispatch(new PatchScandataModel(model));
  }

  chipClick(model: ScandataModel) {
    this.store.dispatch([
      new PatchScandataModel(model),
      new SetInfoView(OptionsPanelInfoView.Tagging),
    ]);
  }

  sortingChange(sorting: Sorting) {
    this.store.dispatch([new SetSorting(sorting), new UnselectAllScandataModels()]);
  }

  private setupScandataEmpty() {
    this.showScandataEmpty$ = combineLatest([
      this.isLoading$,
      this.scanLoadError$,
      this.filteredScandata$,
    ]).pipe(
      map(([loading, error, scandata]) => {
        if (loading || error) return false;
        return scandata.length === 0;
      }),
    );

    this.scandataEmptyReason$ = this.showScandataEmpty$.pipe(
      filter((empty) => empty),
      combineLatestWith(this.scandata$, this.textFilter$, this.filterCount$),
      map(([, scandata, textFilter, filterCount]) => {
        if (filterCount === 0 && scandata.length === 0) {
          return ScandataEmptyReason.NoUploads;
        }

        if (filterCount > 0 || isDefined(textFilter)) {
          return ScandataEmptyReason.NoFilterResults;
        }

        return ScandataEmptyReason.NoUploads;
      }),
    );
  }
}
