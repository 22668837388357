import { ErrorType } from './error-type';

export class SetError {
  static readonly type = '[Error] SetError';
  constructor(public readonly errorType: ErrorType) {}
}

export class ClearError {
  static readonly type = '[Error] ClearError';
  constructor(public readonly errorType: ErrorType) {}
}
