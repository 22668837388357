import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModusTooltipModule } from '@trimble-gcs/modus';
import { PropertiesComponent } from '../../options-panel/selected-details/single-selected/properties/properties.component';
import { ScandataModel } from '../../scandata/scandata.models';
import { Scan3dStyle } from '../models/scan-3d-style';
import { Scan3dStylingComponent } from '../scan-3d-styling/scan-3d-styling.component';
import { Scan3dClassificationComponent } from './scan-3d-classification/scan-3d-classification.component';

@UntilDestroy()
@Component({
  selector: 'sd-scan-3d-detail',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    PropertiesComponent,
    Scan3dClassificationComponent,
    Scan3dStylingComponent,
    MatProgressBarModule,
    ModusTooltipModule,
  ],
  templateUrl: './scan-3d-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Scan3dDetailComponent {
  currentModel = input.required<ScandataModel>();
  scan3dStyle = input.required<Scan3dStyle>();

  exitDetailClick = output();
  styleChange = output<Scan3dStyle>();

  currentModelName = computed(() => this.currentModel().name);

  onExitDetailClick() {
    this.exitDetailClick.emit();
  }

  onStyleChange(style: Scan3dStyle) {
    this.styleChange.emit(style);
  }
}
