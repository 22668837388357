// https://support.gainsight.com/PX/Install_PX/Install_PX_Web/Install_Gainsight_PX_in_IFrames

/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

// Global function to initialize Gainsight PX
export function initializeAptrinsic(window: Window, tag: string) {
  if (window.aptrinsic?.init) return;

  // Gainsight PX Tag
  (function (n, t, a, e, x) {
    const i = 'aptrinsic';
    (n[i] =
      n[i] ||
      function () {
        (n[i].q = n[i].q || []).push(arguments);
      }),
      (n[i].p = e),
      (n[i].c = x);

    const r = t.createElement('script');
    r.async = !0;
    r.src = a + '?a=' + e;

    const c = t.getElementsByTagName('script')[0];
    c.parentNode.insertBefore(r, c);
  })(window, document, 'https://web-sdk.aptrinsic.com/api/aptrinsic.js', tag, {
    iframeModeEnabled: false,
    // Gainsight support session with Nick Wolfe <nwolfe@gainsight.com>
    // https://gainsight.zendesk.com/hc/en-us/requests/298373
    localStorageCookie: isLocalhost(), //
  });
  // End Gainsight PX Tag
}

function isLocalhost() {
  return ['localhost', '127.0.0.1', '[::1]', ''].includes(location.hostname);
}
