import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  Signal,
  computed,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import {
  ModusButtonModule,
  ModusFormFieldModule,
  ModusIconModule,
  ModusInputModule,
  ModusSwitchModule,
} from '@trimble-gcs/modus';
import { ColorPickerModule } from 'ngx-color-picker';
import { Observable, map, startWith, switchMap, take } from 'rxjs';
import { ClassificationScheme } from '../../classification/classification-scheme.model';
import { ClassificationSchemeState } from '../../classification/classification-scheme.state';
import { ClassificationService } from '../../classification/classification.service';
import { ErrorState } from '../../error-handling/error.state';
import { LabelValueComponent } from '../../label-value/label-value.component';
import { NotWhitespaceStringValidator } from '../../utils/not-whitespace-string-validator';
import { OptionsPanelService } from '../options-panel.service';
import { OptionsPanelView } from '../options-panel.state';

@UntilDestroy()
@Component({
  selector: 'sd-classification-edit',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LabelValueComponent,
    ColorPickerModule,
    ModusFormFieldModule,
    ModusInputModule,
    ModusSwitchModule,
    ModusButtonModule,
    ModusIconModule,
    MatProgressBarModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './classification-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClassificationEditComponent {
  @Select(ClassificationSchemeState.classificationForEdit)
  classificationScheme$!: Observable<ClassificationScheme>;

  @Select(ErrorState.hasError('classificationSaveError'))
  classificationSaveError$!: Observable<boolean>;

  formGroup = new FormGroup({
    name: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, NotWhitespaceStringValidator],
    }),
    rgba: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    visible: new FormControl<boolean>(true, {
      nonNullable: true,
    }),
  });

  busySaving = signal(false);
  visibleLabel!: Signal<string>;
  saveDisabled!: Signal<boolean>;

  constructor(
    private classificationService: ClassificationService,
    private optionsPanelService: OptionsPanelService,
  ) {
    this.setupVisibleLabel();
    this.setupSaveButton();
    this.setFormValues();
  }

  close() {
    this.optionsPanelService.setView(OptionsPanelView.None);
  }

  setColor(color: string) {
    const rgbaNew = color.slice(1).toUpperCase();
    const rgbaCurrent = this.formGroup.value.rgba;

    const valueChanged = rgbaNew !== rgbaCurrent;
    if (!valueChanged) return;

    this.formGroup.markAsDirty();
    this.formGroup.controls.rgba.setValue(rgbaNew);
  }

  trimInputValue(formControl: FormControl<string>) {
    formControl.setValue(formControl.value.trim());
  }

  save() {
    this.setFormSaving(true);

    this.classificationScheme$
      .pipe(
        take(1),
        map((classificationScheme) => {
          return { ...classificationScheme, ...this.formGroup.value };
        }),
        switchMap((classificationScheme) => {
          return this.getClassificationSchemesForSave(classificationScheme);
        }),
        switchMap((classificationSchemes) => {
          return this.classificationService.saveClassificationSchemes(classificationSchemes);
        }),
      )
      .subscribe({
        next: () => {
          this.close();
        },
        error: () => {
          this.setFormSaving(false);
        },
      });
  }

  private setupVisibleLabel() {
    const visibleSwitch = toSignal(
      this.formGroup.controls.visible.valueChanges.pipe(
        startWith(this.formGroup.controls.visible.value),
      ),
      { initialValue: true },
    );

    this.visibleLabel = computed(() => (visibleSwitch() ? 'Visible' : 'Hidden'));
  }

  private setupSaveButton() {
    const pristineOrInvalid = toSignal(
      this.formGroup.valueChanges.pipe(
        startWith(this.formGroup.value),
        map(() => this.formGroup.pristine || this.formGroup.invalid),
      ),
      { initialValue: true },
    );

    this.saveDisabled = computed(() => {
      return this.busySaving() || pristineOrInvalid();
    });
  }

  private setFormValues() {
    this.classificationScheme$.pipe(untilDestroyed(this)).subscribe({
      next: (classificationScheme) => {
        this.formGroup.setValue({
          name: classificationScheme.name,
          rgba: classificationScheme.rgba,
          visible: classificationScheme.visible,
        });
      },
    });
  }

  private setFormSaving(saving: boolean) {
    if (saving) this.formGroup.disable();
    else this.formGroup.enable();

    this.busySaving.set(saving);
  }

  private getClassificationSchemesForSave(updatedClassificationScheme: ClassificationScheme) {
    return this.classificationService.getClassificationSchemes().pipe(
      map((classificationSchemes) => {
        const schemes = classificationSchemes.filter(
          (item) => item.id !== updatedClassificationScheme.id,
        );
        schemes.push(updatedClassificationScheme);

        return schemes;
      }),
    );
  }
}
