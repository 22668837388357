import { isDefined } from '@trimble-gcs/common';

export function checkValidSasToken(url: string) {
  const expiryDate = getSASTokenExpiryDate(url);
  const currentDate = new Date();

  if (isDefined(expiryDate) && expiryDate > currentDate) {
    return true;
  }

  return false;
}

export function getSASTokenExpiryDate(url: string) {
  const urlParams = new URLSearchParams(url);
  const signedExpiry = urlParams.get('se');
  return signedExpiry ? new Date(signedExpiry) : null;
}
