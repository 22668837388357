import { ChangeDetectionStrategy, Component, HostBinding, input, output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModusButtonModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { ScandataModel } from '../../../../scandata/scandata.models';

@UntilDestroy()
@Component({
  selector: 'sd-options',
  standalone: true,
  imports: [ModusTooltipModule, ModusButtonModule, ModusIconModule],
  templateUrl: './options.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsComponent {
  scandataModels = input.required<ScandataModel[]>();
  disabled = input<boolean>(false);
  readonly = input<boolean>(false);

  viewIn3dClicked = output();
  editTagsClicked = output();
  downloadClicked = output();
  deleteClicked = output();
  removeAllClicked = output();
  removeModelClicked = output<ScandataModel>();

  @HostBinding('class') class = 'flex flex-col h-full';
}
