<!--single selection-->
@if (showSingleSelected()) {
  <sd-single-selected
    [scandataModel]="singleSelected()!"
    [readonly]="readonly()"
    (downloadClicked)="showDownload.set(true)"
  ></sd-single-selected>
}

<!--multi selection-->
@if (showMultiSelected()) {
  <sd-multi-selected
    [scandataModels]="selected()"
    [readonly]="readonly()"
    (downloadClicked)="showDownload.set(true)"
    (unselectClicked)="unselectScan($event)"
    (unselectAllClicked)="unselectAllScans()"
  ></sd-multi-selected>
}

<!--download-->
@if (showDownload()) {
  <sd-download-select (closeClicked)="showDownload.set(false)"></sd-download-select>
}
