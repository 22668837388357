<!-- modus-label -->
<label class="modus-form-field-label">
  <ng-content select="modus-label"></ng-content>
  @if (!hideRequiredMarker && (required$ | async)) {
    <span class="modus-form-field-required-marker"></span>
  }
</label>

<!-- flex container for the prefix, control and suffix -->
<div
  class="modus-form-field-control"
  [ngClass]="{
    'modus-disabled': disabled$ | async,
    'modus-readonly': readonly$ | async,
    'modus-focused': focused$ | async,

    'modus-untouched': untouched$ | async,
    'modus-touched': touched$ | async,

    'modus-pristine': pristine$ | async,
    'modus-dirty': dirty$ | async,

    'modus-valid': valid$ | async,
    'modus-invalid': invalid$ | async
  }"
>
  <!-- modus-icon-prefix -->
  @if (hasIconPrefix$ | async) {
    <div class="modus-form-field-icon-prefix">
      <ng-content select="[modus-prefix], [modus-icon-prefix]"></ng-content>
    </div>
  }

  <!-- modus-text-prefix -->
  @if (hasTextPrefix$ | async) {
    <div class="modus-form-field-text-prefix">
      <ng-content select="[modus-text-prefix]"></ng-content>
    </div>
  }

  <!-- FormFieldControl -->
  <ng-content></ng-content>

  <!-- modus-modus-text-suffix -->
  @if (hasTextSuffix$ | async) {
    <div class="modus-form-field-text-suffix">
      <ng-content select="[modus-text-suffix]"></ng-content>
    </div>
  }

  <!-- modus-icon-suffix -->
  @if (hasIconSuffix$ | async) {
    <div class="modus-form-field-icon-suffix">
      <ng-content select="[modus-suffix], [modus-icon-suffix]"></ng-content>
    </div>
  }
</div>

<!-- container for hint, feedback and error  -->
<div class="modus-form-field-subscript">
  @if (showHint$ | async) {
    <ng-content select="modus-hint"></ng-content>
  }
  @if (showValid$ | async) {
    <ng-content select="modus-valid"></ng-content>
  }
  @if (showError$ | async) {
    <ng-content select="modus-error"></ng-content>
  }
</div>
