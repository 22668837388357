import { Directive, Input } from '@angular/core';

/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @angular-eslint/directive-class-suffix */

/** Suffix to be placed at the end of a modus-form-field. */
@Directive({
  selector: '[modus-suffix], [modus-icon-suffix], [modus-text-suffix]',
})
export class ModusSuffix {
  isText = false;

  get isIcon(): boolean {
    return !this.isText;
  }

  @Input('modus-text-suffix')
  set textSuffix(value: '') {
    this.isText = true;
  }
}
