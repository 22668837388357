import { Injectable } from '@angular/core';
import {
  RxCollectionChangeType,
  RxCollectionPush,
  RxCollectionSet,
  RxCollectionSplice,
} from '@trimble-gcs/reactive';
import { Observable, Subscription } from 'rxjs';
import { FeatureFlag, getRegexForKeyDescendants, isFeatureFlag } from './feature-flag';
import { FeatureFlagStore } from './feature-flag-store';
import { FeatureStateStream } from './feature-state-stream';

@Injectable({ providedIn: 'root' })
export class FeatureFlagService<TKey extends string = string> {
  private _store = new FeatureFlagStore<TKey>({} as Record<TKey, boolean>);
  private _streams = new Map<TKey, FeatureStateStream<TKey>>();
  private _subscription = Subscription.EMPTY;

  get features(): ReadonlyArray<FeatureFlag<TKey>> {
    return [...this._store.features];
  }

  constructor() {
    this.subscribeToStoreChanges();
  }

  load(config: Record<TKey, boolean>) {
    this._store = new FeatureFlagStore(config);
    this.syncStreamsToStore();
    this.subscribeToStoreChanges();
  }

  clear() {
    this._store = new FeatureFlagStore({});
    this.syncStreamsToStore();
    this.subscribeToStoreChanges();
  }

  add(feature: FeatureFlag<TKey>): FeatureFlag<TKey>;
  add(key: TKey, enabled?: boolean): FeatureFlag<TKey>;
  add(keyOrFeature: TKey | FeatureFlag<TKey>, enabled = true): FeatureFlag<TKey> {
    return isFeatureFlag(keyOrFeature)
      ? this._store.add(keyOrFeature)
      : this._store.add(keyOrFeature, enabled);
  }

  remove(keyOrFeature: TKey | FeatureFlag<TKey>) {
    this._store.remove(keyOrFeature);
  }

  enable(...keys: TKey[]) {
    keys.forEach((key: TKey) => this._store.find(key)?.enable());
  }

  disable(...keys: TKey[]) {
    keys.forEach((key: TKey) => this._store.find(key)?.disable());
  }

  hasFeature(key: TKey): boolean {
    const feature = this._store.find(key);
    return feature?.active ?? false;
  }

  hasFeature$(key: TKey): Observable<boolean> {
    const existing = this._streams.get(key);
    if (existing) return existing.active$;

    const feature = this._store.find(key);
    const stream = new FeatureStateStream(feature || key);
    this._streams.set(key, stream);

    return stream.active$;
  }

  private subscribeToStoreChanges() {
    this._subscription.unsubscribe();

    this._subscription = this._store.changes$.subscribe((change) => {
      switch (change.type) {
        case RxCollectionChangeType.push: {
          return this.handleAdditions(change as RxCollectionPush<FeatureFlag<TKey>>);
        }

        case RxCollectionChangeType.splice: {
          return this.handleRemovals(change as RxCollectionSplice<FeatureFlag<TKey>>);
        }

        case RxCollectionChangeType.set: {
          return this.handleReplacements(change as RxCollectionSet<FeatureFlag<TKey>>);
        }

        default:
          throw new Error(`${change.type} not implemented`);
      }
    });
  }

  private handleAdditions(change: RxCollectionPush<FeatureFlag<TKey>>) {
    change.items.forEach((addedFeature) => {
      this.handleFeatureAddition(addedFeature);
    });
  }

  private handleFeatureAddition(added: FeatureFlag<TKey>) {
    const existing = this._streams.get(added.key);
    if (existing) existing.switch(added);

    const entries = this._streams.entries();
    const regex = getRegexForKeyDescendants(added.key);

    for (const [key, stream] of entries) {
      if (regex.test(key)) {
        const descendant = added.find(stream.key);
        if (descendant) stream.switch(descendant);
      }
    }
  }

  private handleRemovals(change: RxCollectionSplice<FeatureFlag<TKey>>) {
    change.deletedItems.forEach((removedFeature) => {
      this.handleFeatureRemoval(removedFeature);
    });
  }

  private handleFeatureRemoval(removed: FeatureFlag<TKey>) {
    const existing = this._streams.get(removed.key);
    if (existing) existing.switch(undefined);

    const entries = this._streams.entries();
    const regex = getRegexForKeyDescendants(removed.key);

    for (const [key, stream] of entries) {
      if (regex.test(key)) stream.switch(undefined);
    }
  }

  private handleReplacements(change: RxCollectionSet<FeatureFlag<TKey>>) {
    this.handleFeatureRemoval(change.oldValue);
    this.handleFeatureAddition(change.newValue);
  }

  private syncStreamsToStore() {
    const entries = this._streams.entries();
    for (const [key, stream] of entries) {
      const feature = this._store.find(key);
      stream.switch(feature);
    }
  }
}
