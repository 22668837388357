import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { isNil } from '@trimble-gcs/common';
import { checkValidSasToken } from '../utils/sas-token-validator';
import { CacheStations, ClearCurrentStation, SetCurrentStation } from './station.actions';
import { CurrentStation, Station, StationStatus } from './station.models';

export interface StationStateModel {
  cache: {
    [key: string]: Station[];
  };
  currentStation?: CurrentStation;
}

@State<StationStateModel>({
  name: 'StationState',
  defaults: {
    cache: {},
  },
})
@Injectable()
export class StationState {
  @Selector() static stations(state: StationStateModel): Station[] {
    return Object.values(state.cache).flat();
  }

  @Selector() static currentStation(state: StationStateModel): CurrentStation | undefined {
    return state.currentStation;
  }

  static getStations(pointcloudId: string): (state: StationStateModel) => Station[] {
    return createSelector([StationState], (state: StationStateModel) => {
      const stations = state.cache[pointcloudId];

      // ALL the stations must be in a Ready state, location only or
      // with a valid sasToken for the tileset, to qualify for a cache hit.
      const allStationsValid = stations?.every(
        (s) =>
          s.status === StationStatus.Ready &&
          (isNil(s.tilesetInfo) || checkValidSasToken(s.tilesetInfo.tilesetUrl)),
      );

      // A cache miss returns an empty [].
      return allStationsValid ? stations : [];
    });
  }

  @Action(CacheStations)
  cacheStations(ctx: StateContext<StationStateModel>, { pointcloudId, stations }: CacheStations) {
    ctx.setState(patch({ cache: patch({ [pointcloudId]: stations }) }));
  }

  @Action(SetCurrentStation) setCurrentStation(
    ctx: StateContext<StationStateModel>,
    { currentStation }: SetCurrentStation,
  ) {
    ctx.patchState({ currentStation });
  }

  @Action(ClearCurrentStation) clearCurrentStation(ctx: StateContext<StationStateModel>) {
    ctx.patchState({ currentStation: undefined });
  }
}
