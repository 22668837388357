export function isDefined<T>(value: T | null | undefined): value is T {
  return value !== undefined && value !== null;
}

export function isNil(value: unknown): value is undefined | null {
  return value === undefined || value === null;
}

export function isDate(value: unknown): value is Date {
  return typeof value === 'object' && getObjTag(value) === '[object Date]';
}

export function isString(value: unknown): value is string {
  const type = typeof value;
  if (type === 'string') return true;

  return (
    type === 'object' &&
    isDefined(value) &&
    !Array.isArray(value) &&
    getObjTag(value) === '[object String]'
  );
}

export function getObjTag(obj: unknown): string {
  if (obj === null) {
    return obj === undefined ? '[object Undefined]' : '[object Null]';
  }
  return toString.call(obj);
}

export function toIsoDateString(date: Date): string {
  if (!isDate(date)) return '';

  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split('T')[0];
}

export function isNilOrWhitespace(value: null | undefined | string): boolean {
  return isString(value) ? value.trim() === '' : isNil(value);
}

export function last<T>(array: Array<T>): T | null {
  const len = array?.length ?? 0;
  return len > 0 ? array[len - 1] : null;
}
