<div class="flex flex-row p-4 pr-6 border-b border-gray-1">
  <div class="h-8 flex flex-1 flex-wrap content-center uppercase text-xs font-bold">
    {{ scandataModels().length }} Selected Items
  </div>
  <button
    id="options-panel-multi_close"
    class="text-gray-8"
    modus-icon-button
    modus-tooltip="Close"
    (click)="removeAllClicked.emit()"
    [disabled]="disabled()"
  >
    <md-icon>close</md-icon>
  </button>
</div>

<div class="flex-1 overflow-y-auto p-4 text-sm">
  <div class="flex flex-col mb-4 -ml-2 leading-8 items-start gap-2">
    <button
      id="options-panel-multi_view_in_3d"
      modus-button
      modus-tooltip="View in 3D"
      (click)="viewIn3dClicked.emit()"
      [disabled]="disabled()"
    >
      <md-icon class="mr-1">cube</md-icon>
      View in 3D
    </button>

    <button
      id="options-panel-multi_edit_tags"
      modus-button
      modus-tooltip="Edit Tags"
      (click)="editTagsClicked.emit()"
      [disabled]="disabled()"
    >
      <md-icon class="mr-1">ticket</md-icon>
      Edit Tags
    </button>

    <button
      id="options-panel-multi_download"
      modus-button
      modus-tooltip="Download"
      (click)="downloadClicked.emit()"
      [disabled]="disabled()"
    >
      <md-icon class="mr-1">download</md-icon>
      Download
    </button>

    @if (!readonly()) {
      <button
        id="options-panel-multi_delete"
        modus-button
        modus-tooltip="Delete"
        (click)="deleteClicked.emit()"
        [disabled]="disabled()"
      >
        <md-icon class="mr-1">delete</md-icon>
        Delete
      </button>
    }
  </div>

  <div class="flex flex-col">
    <div class="-ml-4 -mr-4 mt-1 mb-2 border-b border-gray-1"></div>
    <div class="font-medium mt-2 mb-4">Selected Items</div>
    @for (model of scandataModels(); track model.id) {
      <div class="flex flex-row mb-4">
        <md-icon class="text-gray-8">file</md-icon>
        <div
          modus-tooltip="{{ model.name }}"
          class="w-full overflow-hidden text-ellipsis whitespace-nowrap leading-8 ml-2"
        >
          {{ model.name }}
        </div>
        <button
          id="options-panel-multi_remove"
          class="text-gray-8"
          modus-icon-button
          modus-tooltip="Remove"
          (click)="removeModelClicked.emit(model)"
          [disabled]="disabled()"
        >
          <md-icon>close</md-icon>
        </button>
      </div>
    }
  </div>
</div>
