import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconRegistry } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { ModusIconModule } from '@trimble-gcs/modus';
import { Observable, filter, map } from 'rxjs';
import { AppRoute } from '../../app.routes';
import { AuthService } from '../../auth/auth.service';
import { AuthState } from '../../auth/auth.state';
import { easterEggs } from '../../easter-egg/easter-eggs';
import { User } from '../../user/user.models';
import { UserState } from '../../user/user.state';
import { AppBarComponent } from '../app-bar/app-bar.component';
import { TabHostComponent } from '../tab-host/tab-host.component';
import { lightIcons } from './app-icons';

@Component({
  selector: 'sd-app-host',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ModusIconModule,
    AppBarComponent,
    TabHostComponent,
    MatSidenavModule,
    MatListModule,
  ],
  templateUrl: './app-host.component.html',
  styleUrls: ['./app-host.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHostComponent {
  @Select(AuthState.loggedIn) loggedIn$!: Observable<boolean>;
  @Select(UserState.user) user$!: Observable<User>;

  private sidenavToggle = signal(false);
  private loggedIn = toSignal(this.loggedIn$);

  sidenavOpen = computed(() => this.loggedIn() && this.sidenavToggle());

  readonly activeLink$: Observable<string>;

  easterEggs = easterEggs;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private store: Store,
    private authService: AuthService,
  ) {
    this.registerIcons();
    this.authService.registerTokenExpiryWatch().subscribe();

    this.activeLink$ = this.router.events.pipe(
      filter((evt) => evt instanceof NavigationEnd),
      map((evt) => {
        const url = (evt as NavigationEnd).url;
        return url.startsWith('/') ? url.slice(1) : url;
      }),
    );
  }

  toggleSidenav(evt: MouseEvent) {
    if (evt.ctrlKey && evt.shiftKey) {
      this.sidenavToggle.update((value) => !value);
    }
  }

  navigate(path: string) {
    this.router.navigate([path]);
  }

  easterEgg(path: string) {
    this.router.navigate(['easter-egg', path]);
  }

  logout() {
    this.router.navigate([AppRoute.Logout]);
  }

  private registerIcons() {
    lightIcons.forEach((icon) => {
      this.matIconRegistry.addSvgIcon(
        icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.resourceUrl),
      );
    });
  }
}
