import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  inject,
} from '@angular/core';
import { Observable, Subject, fromEvent, takeUntil } from 'rxjs';

/* eslint-disable @typescript-eslint/no-explicit-any */

@Component({
  templateUrl: './tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent implements OnDestroy {
  private readonly _element = inject(ElementRef).nativeElement;
  private readonly _changeDetectorRef = inject(ChangeDetectorRef);
  private readonly _destroy$ = new Subject<void>();
  private readonly _hidden$: Subject<void> = new Subject();

  private readonly _showAnimation = 'modus-tooltip-show';
  private readonly _hideAnimation = 'modus-tooltip-hide';

  private _animationsDisabled!: boolean;

  @Input() message?: string;
  @Input() @HostBinding('class') tooltipClass:
    | string
    | string[]
    | Set<string>
    | { [key: string]: any } = '';

  @HostBinding('class.modus-tooltip') className = true;

  get hidden$(): Observable<void> {
    return this._hidden$.asObservable();
  }

  ngOnDestroy(): void {
    this._hidden$.complete();
    this._destroy$.next();
    this._destroy$.complete();
  }

  show() {
    this._element.classList.remove(this._hideAnimation);
    this._element.classList.add(this._showAnimation);

    this._animationsDisabled = this.checkAnimationsDisabled();
    if (!this._animationsDisabled) this.subscribeToAnimationEnd();
  }

  hide() {
    this._element.classList.remove(this._showAnimation);
    this._element.classList.add(this._hideAnimation);

    if (this._animationsDisabled) {
      this._hidden$.next();
    }
  }

  markForCheck() {
    this._changeDetectorRef.markForCheck();
  }

  private checkAnimationsDisabled() {
    if (typeof getComputedStyle !== 'function') return false;

    const styles = getComputedStyle(this._element);
    const duration = styles.getPropertyValue('animation-duration');
    const animationName = styles.getPropertyValue('animation-name');

    return duration === '0s' || animationName === 'none';
  }

  private subscribeToAnimationEnd() {
    fromEvent<AnimationEvent>(this._element, 'animationend')
      .pipe(takeUntil(this._destroy$))
      .subscribe(({ animationName }) => {
        if (animationName === this._hideAnimation) {
          this._hidden$.next();
        }
      });
  }
}
