import { v4 as uuidv4 } from 'uuid';
import { ScandataModel } from '../scandata/scandata.models';

export const ALLOWED_EXTENSIONS = ['.las', '.laz', '.e57'];

export interface ImportScan {
  name: string;
  files: ImportFile[];

  scan?: ScandataModel;
}

export enum ImportFileSource {
  Upload = 'Upload',
  // connect, google drive, dropbox
}

export enum ImportFileStatus {
  Pending = 'Pending',
  Busy = 'Busy',
  Completed = 'Completed',
  Error = 'Error',
  Skipped = 'Skipped',
}

export interface ImportFile {
  id: string;
  name: string;
  size: number;
  source: ImportFileSource;

  errorMessage?: string;
  status?: ImportFileStatus;
  fileUpload?: { file: File; uploadUrl?: string; progress?: number };
}

export function createImportFile(file: File): ImportFile {
  return {
    id: uuidv4(),
    name: file.name,
    size: file.size,
    source: ImportFileSource.Upload,
    fileUpload: { file },
  };
}
