import { ChangeDetectionStrategy, Component, input, output, viewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofActionDispatched } from '@ngxs/store';
import { ModusIconModule, ModusMenu, ModusMenuModule, ModusMenuTrigger } from '@trimble-gcs/modus';
import { filter } from 'rxjs';
import { CloseTreeMenu } from '../../scan-3d-panel/scan-3d.actions';
import { ScandataModel } from '../../scandata/scandata.models';

@UntilDestroy()
@Component({
  selector: 'sd-scandata-tree-menu',
  standalone: true,
  imports: [ModusIconModule, ModusMenuModule],
  templateUrl: './scandata-tree-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScandataTreeMenuComponent {
  menu = viewChild.required<ModusMenu>(ModusMenu);
  menuTrigger = viewChild.required<ModusMenuTrigger>(ModusMenuTrigger);

  scan = input.required<ScandataModel>();
  fitToViewDisabled = input<boolean>(false);

  modelDetailClick = output<ScandataModel>();
  modelFitToViewClick = output<ScandataModel>();

  constructor(private actions$: Actions) {
    this.subscribeToCloseTreeMenu();
  }

  onModelDetailClick() {
    this.modelDetailClick.emit(this.scan());
  }

  onModelFitToViewClick() {
    this.modelFitToViewClick.emit(this.scan());
  }

  private subscribeToCloseTreeMenu() {
    this.actions$
      .pipe(
        ofActionDispatched(CloseTreeMenu),
        filter(() => this.menu().isOpen),
        untilDestroyed(this),
      )
      .subscribe(() => this.menuTrigger().closeMenu());
  }
}
