import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/component-class-suffix */

@Component({
  selector: 'md-icon',
  template: `<ng-content></ng-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModusIcon {
  @HostBinding('[attr.aria-hidden]') 'aria-hidden' = true;
  @HostBinding('class') 'class' = 'modus-icons';
}
