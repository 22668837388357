import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { Observable, combineLatest, filter, map, switchMap } from 'rxjs';
import { AppState } from '../app-state/app.state';
import { GET_CONNECT_REGION_URL } from '../connect/get-connect-region-url';
import { ScandataState } from '../scandata/scandata.state';
import { SetTags } from './tag.actions';
import { TagState } from './tag.state';

export const MAX_TAG_LENGTH = 40;

export interface Tag {
  id: string;
  label: string;
}

@Injectable({
  providedIn: 'root',
})
export class TagService {
  private readonly getConnectRegionUrl$ = inject(GET_CONNECT_REGION_URL);

  constructor(
    private store: Store,
    private http: HttpClient,
  ) {}

  getTags(): Observable<string[]> {
    return combineLatest([this.getScandataTags(), this.getConnectTags()]).pipe(
      map(([scandataTags, connectTags]) => {
        const storeTags = this.store.selectSnapshot(TagState.tags);
        return [...scandataTags, ...connectTags, ...storeTags];
      }),
      map((tags) => {
        const uniqueTags = [...new Set(tags)];
        return uniqueTags.sort((a, b) => a.localeCompare(b));
      }),
      switchMap((tags) => {
        return this.store.dispatch(new SetTags(tags)).pipe(map(() => tags));
      }),
    );
  }

  private getScandataTags(): Observable<string[]> {
    return this.store.select(ScandataState.scandata).pipe(
      filter((scandata) => scandata.length > 0),
      map((scandata) => scandata.map((item) => item.tags ?? []).flat()),
    );
  }

  private getConnectTags(): Observable<string[]> {
    const project$ = this.store.selectOnce(AppState.project).pipe(filter(isDefined));

    return project$.pipe(
      switchMap((project) => this.getConnectRegionUrl$(`tags?projectId=${project.id}`)),
      switchMap((url) => {
        return this.http.get<Tag[]>(url);
      }),
      map((tags) => {
        return tags.map((tag) => tag.label);
      }),
    );
  }
}
