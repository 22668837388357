import { ChangeDetectionStrategy, Component, input } from '@angular/core';

export enum ScandataEmptyReason {
  NoUploads,
  NoFilterResults,
  NoGeolocated,
  NoSelected,
}

@Component({
  selector: 'sd-scandata-empty',
  standalone: true,
  templateUrl: './scandata-empty.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScandataEmptyComponent {
  reason = input.required<string, ScandataEmptyReason>({
    transform: (reason) => {
      switch (reason) {
        case ScandataEmptyReason.NoUploads:
          return "Looks like you haven't added any data yet.";

        case ScandataEmptyReason.NoFilterResults:
          return 'No results found with the applied filter.';

        case ScandataEmptyReason.NoGeolocated:
          return 'No geolocated data or CRS missing.';

        case ScandataEmptyReason.NoSelected:
          return 'No data was selected.';
      }
    },
  });
}
