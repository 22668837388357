import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Signal, computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import { ModusButtonModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { Observable, filter, take } from 'rxjs';
import { CANCEL_BUTTON, DialogData } from '../../../dialog/dialog.model';
import { DialogService } from '../../../dialog/dialog.service';
import { SetView } from '../../options-panel.actions';
import { OptionsPanelView } from '../../options-panel.state';
import { DownloadProgressComponent } from '../download-progress/download-progress.component';
import { ClearCompletedDownloads } from '../download.actions';
import { DownloadFile, DownloadProgress, DownloadScan, DownloadStatus } from '../download.model';
import { DownloadService } from '../download.service';
import { DownloadState } from '../download.state';

@UntilDestroy()
@Component({
  selector: 'sd-download-status',
  standalone: true,
  imports: [
    CommonModule,
    ModusTooltipModule,
    ModusButtonModule,
    ModusIconModule,
    DownloadProgressComponent,
  ],
  templateUrl: './download-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadStatusComponent {
  @Select(DownloadState.downloads) private currentDownloads$!: Observable<DownloadScan[]>;

  downloadScans = toSignal(this.currentDownloads$, { initialValue: [] });
  hasDownloads = computed(() => this.downloadScans().length > 0);

  clearDisabled!: Signal<boolean>;
  cancelDisabled!: Signal<boolean>;
  overallProgress!: Signal<DownloadProgress | null>;

  private filesDownloading!: Signal<DownloadFile[]>;

  constructor(
    private downloadService: DownloadService,
    private store: Store,
    private dialogService: DialogService,
  ) {
    this.filesDownloading = computed(() =>
      this.downloadScans().flatMap((scan) => {
        return scan.files.filter((file) => isDefined(file.downloadProgress));
      }),
    );

    this.overallProgress = computed(() => {
      const files = this.filesDownloading();
      if (isNil(files) || files.length === 0) return null;

      return this.store.selectSnapshot(DownloadState.getDownloadsProgress(files));
    });

    this.clearDisabled = computed(() => {
      const files = this.filesDownloading();
      return (
        files.length === 0 ||
        !files.some((file) => {
          const status = file.downloadProgress?.status;
          return status === DownloadStatus.Complete || status === DownloadStatus.Failed;
        })
      );
    });

    this.cancelDisabled = computed(() => {
      const files = this.filesDownloading();
      return (
        files.length === 0 ||
        files.every((file) => {
          const status = file.downloadProgress?.status;
          return status === DownloadStatus.Complete || status === DownloadStatus.Failed;
        })
      );
    });
  }

  close() {
    this.store.dispatch(new SetView(OptionsPanelView.None, false));
  }

  toggleExpanded(item: DownloadScan) {
    item.expanded = !item.expanded;
  }

  clearCompleted() {
    this.store.dispatch(new ClearCompletedDownloads());
  }

  cancelDownloads() {
    this.showCancelConfirmation()
      .pipe(take(1))
      .subscribe(() => this.downloadService.cancelAllDownloads());
  }

  private showCancelConfirmation() {
    const dialogData = new DialogData(
      'Cancel Downloads',
      `Are you sure you want to cancel all downloads?`,
      { text: 'Yes', color: 'danger' },
      CANCEL_BUTTON,
    );

    return this.dialogService.show(dialogData).pipe(filter((confirmed) => confirmed));
  }
}
