/**
 * Max block size is 4,000 MiB x 50,000 blocks (total: ~190.7 tebibytes), see below:
 * https://learn.microsoft.com/en-us/rest/api/storageservices/put-block?tabs=microsoft-entra-id#remarks
 */
export const BLOCK_SIZE = 4000 * 1024 * 1024;

export interface UploadFile {
  id: string;
  name: string;
  createdUtc: Date;
  size: number;
  uploadLink: string;
}

export interface UploadBlock {
  index: number;
  id: string;
  blob: Blob;
  size: number;
  uploadedSize: number;
  completed: boolean;
}
