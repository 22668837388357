import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isDefined } from '@trimble-gcs/common';
import { catchError, concatMap, from, map, Observable, of, switchMap } from 'rxjs';
import { injectLogger, Logger } from '../logging/logger';
import { ImportFile, ImportFileStatus } from './import.models';
import { Md5WorkerService } from './md5/md5.service';
import { BLOCK_SIZE, UploadBlock } from './upload.models';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  private logger = injectLogger(Logger, 'UploadService');

  constructor(
    private http: HttpClient,
    private md5WorkerService: Md5WorkerService,
  ) {}

  uploadFiles(uploadFiles: ImportFile[]): Observable<ImportFile[]> {
    return from(uploadFiles).pipe(
      // TODO: test parallel uploads
      concatMap((uploadFile) =>
        this.uploadFile(uploadFile).pipe(
          // TODO: remove this test code, to force a fail
          // tap((uploadFile) => {
          //   const forceFail = uploadFiles.findIndex((file) => file.id === uploadFile.id) === 1;
          //   if (forceFail && uploadFile.status === ImportFileStatus.Completed) {
          //     throw new Error('FORCE FAIL');
          //   }
          // }),
          catchError((err) => {
            this.logger.error(`Upload error`, {}, err);

            uploadFile.status = ImportFileStatus.Error;
            uploadFile.errorMessage = 'Error during upload';

            throw err;
          }),
        ),
      ),
      map(() => [...uploadFiles]),
    );
  }

  private uploadFile(uploadFile: ImportFile): Observable<ImportFile> {
    const fileUpload = uploadFile.fileUpload!;
    const file = fileUpload.file;
    const uploadUrl = fileUpload.uploadUrl!;
    const uploadBlocks = this.getUploadBlocks(file);

    return from(uploadBlocks).pipe(
      concatMap((block) => {
        return this.md5WorkerService.getMd5Hash(block.blob).pipe(
          map((md5Hash) => {
            return { block, md5Hash };
          }),
        );
      }),
      concatMap(({ block, md5Hash }) => {
        const blockUrl = `${uploadUrl}&comp=block&blockid=${btoa(block.id)}`;

        return this.http
          .put(blockUrl, block.blob, {
            headers: { 'Content-MD5': btoa(md5Hash) },
            reportProgress: true,
            observe: 'events',
          })
          .pipe(
            map((event) => {
              if (event.type === HttpEventType.UploadProgress && isDefined(event.total)) {
                block.uploadedSize = event.loaded;
              }

              if (event.type === HttpEventType.Response) {
                block.completed = true;
                block.uploadedSize = block.size;
              }

              const allCompleted = uploadBlocks.every((block) => block.completed);
              if (allCompleted) {
                uploadFile.fileUpload!.progress = 100;
                uploadFile.status = ImportFileStatus.Completed;
              } else {
                const totalSize = file.size;
                const totalUploaded = uploadBlocks.reduce((acc, cur) => acc + cur.uploadedSize, 0);
                const progress = +((100 * totalUploaded) / totalSize).toFixed(1);
                uploadFile.fileUpload!.progress = progress;
                uploadFile.status = ImportFileStatus.Busy;
              }

              return uploadFile;
            }),
          );
      }),
      switchMap((uploadFile) => {
        if (uploadFile.status === ImportFileStatus.Completed) {
          const blockUrl = `${uploadUrl}&comp=blocklist`;
          const commitBlocks = uploadBlocks
            .map((block) => `<Latest>${btoa(block.id)}</Latest>`)
            .join('');
          const body = `<?xml version="1.0" encoding="utf-8"?><BlockList>${commitBlocks}</BlockList>`;

          return this.http.put(blockUrl, body).pipe(map(() => uploadFile));
        }

        return of(uploadFile);
      }),
    );
  }

  private getUploadBlocks(file: File) {
    const blocks: UploadBlock[] = [];
    const blockSize = BLOCK_SIZE;
    const blockCount = Math.ceil(file.size / blockSize);

    for (let blockIndex = 0; blockIndex < blockCount; blockIndex++) {
      // NOTE: https://learn.microsoft.com/en-us/rest/api/storageservices/put-block?tabs=microsoft-entra-id#uri-parameters
      // For a specified blob, the length of the value for the blockid parameter must be the same size for each block.
      const blockIndexString = blockIndex.toString().padStart(5, '0');
      const blockId = `block-${blockIndexString}`;

      const blockStart = blockSize * blockIndex;
      const blob = file.slice(blockStart, blockStart + blockSize);

      blocks.push({
        index: blockIndex,
        id: blockId,
        blob,
        size: blob.size,
        uploadedSize: 0,
        completed: false,
      });
    }

    return blocks;
  }
}
