import { Injectable } from '@angular/core';
import { Select, Selector, State, StateContext } from '@ngxs/store';
import { isNil } from '@trimble-gcs/common';
import { Observable } from 'rxjs';
import { ConnectProject } from 'trimble-connect-workspace-api';
import { AppState } from '../../../app-state/app.state';
import { ConnectRegion } from '../../../connect/connect.models';

export interface ProjectSelectStateModel {
  cachedConnectRegion?: ConnectRegion;
  cachedProject?: ConnectProject;
}

@State<ProjectSelectStateModel>({
  name: 'projectSelectState',
})
@Injectable()
export class ProjectSelectState {
  @Select(AppState.connectRegion) private connectRegion$!: Observable<ConnectRegion>;
  @Select(AppState.project) private project$!: Observable<ConnectProject>;

  @Selector() static cachedConnectRegion(
    state: ProjectSelectStateModel,
  ): ConnectRegion | undefined {
    return state.cachedConnectRegion;
  }

  @Selector() static cachedProject(state: ProjectSelectStateModel): ConnectProject | undefined {
    return state.cachedProject;
  }

  ngxsAfterBootstrap(ctx: StateContext<ProjectSelectStateModel>): void {
    this.connectRegion$.subscribe((connectRegion) => {
      if (isNil(connectRegion)) return;

      return ctx.patchState({ cachedConnectRegion: connectRegion });
    });

    this.project$.subscribe((project) => {
      if (isNil(project)) return;

      return ctx.patchState({ cachedProject: project });
    });
  }
}
