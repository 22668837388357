import { ChangeDetectionStrategy, Component, EventEmitter, Output, signal } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModusButtonModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { DownloadSelectListComponent } from './download-select-list/download-select-list.component';

@UntilDestroy()
@Component({
  selector: 'sd-download-select',
  standalone: true,
  imports: [
    ModusTooltipModule,
    ModusButtonModule,
    ModusIconModule,
    DownloadSelectListComponent,
    MatProgressBarModule,
  ],
  templateUrl: './download-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadSelectComponent {
  @Output() closeClicked = new EventEmitter();

  showLoading = signal(false);

  setLoading(showLoading: boolean) {
    this.showLoading.set(showLoading);
  }
}
