import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * @param isUniqueCallback A function that takes the formControl as a parameter and must true if the value is unique otherwise false.
 */
export function UniqueValueValidator(
  isUniqueCallback: (control: AbstractControl) => boolean,
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const valid = isUniqueCallback(control);
    if (!valid) return { notUnique: true };

    return null;
  };
}
