import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { FeatureFlag, isFeatureFlag } from './feature-flag';

/**
 * An internal helper class for `FeatureFlagService.hasFeature$(key: string)`.
 * It allows switching the source of emissions while presenting the same instance
 * of an observable to subscribers.
 */
export class FeatureStateStream<TKey extends string = string> {
  private readonly _key: TKey;
  private readonly _active$: Observable<boolean>;
  private readonly _subscriber$: BehaviorSubject<boolean>;
  private readonly _default$ = new BehaviorSubject(false);
  private _subscription: Subscription = Subscription.EMPTY;

  get key(): TKey {
    return this._key;
  }

  get active$(): Observable<boolean> {
    return this._active$;
  }

  constructor(keyOrFeature: TKey | FeatureFlag<TKey>) {
    if (isFeatureFlag(keyOrFeature)) {
      // subscribe to feature.active$
      const feature = keyOrFeature;
      this._key = feature.key;
      this._subscriber$ = new BehaviorSubject(feature.active);
      this._subscription = feature.active$.subscribe(this._subscriber$);
    } else {
      // subscribe to this._default$
      this._key = keyOrFeature;
      this._subscriber$ = new BehaviorSubject(false);
      this._subscription = this._default$.subscribe(this._subscriber$);
    }

    this._active$ = this._subscriber$.asObservable();
  }

  switch(feature: FeatureFlag<TKey> | undefined) {
    if (feature && feature.key !== this._key) {
      throw new Error('arg key does not match instance key');
    }

    const source$ = feature?.active$ || this._default$;
    this._subscription.unsubscribe();
    this._subscription = source$.subscribe(this._subscriber$);
  }
}
