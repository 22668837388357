import { AppSettings } from '../app-state/app.models';

export function GetClientIdentificationHeaders(settings: AppSettings): {
  [header: string]: string;
} {
  return {
    'Trimble-FieldSystems-ClientName': settings.title,
    'Trimble-FieldSystems-ClientVersion': 'latest',
  };
}
