<div class="flex h-full">
  <sd-map-list
    [scanLoadError]="(scanLoadError$ | async)!"
    [isLoading]="(isLoading$ | async)!"
    [mapScandata]="(mapScandata$ | async)!"
    [filterOption]="(filterOption$ | async)!"
    [showScandataEmpty]="(showScandataEmpty$ | async)!"
    [scandataEmptyReason]="(scandataEmptyReason$ | async)!"
    (changeItemVisibilityClicked)="changeItemVisibilityClicked($event)"
    (itemClicked)="itemClicked($event)"
    (itemChecked)="itemChecked($event)"
    (zoomToItemClicked)="zoomToItemClicked($event)"
    (filterOptionChanged)="filterOptionChanged($event)"
    class="w-[296px] max-[672px]:w-[240px] min-[1200px]:w-[312px]"
  ></sd-map-list>

  <sd-map-viewer
    #mapViewer
    [baseLayer]="activeBaseLayer$ | async"
    [featureLayer]="featureLayer$ | async"
    [bounds]="(bounds$ | async)!"
    [activeMapTool]="(activeMapTool$ | async)!"
    (mapToolSelected)="mapToolSelected($event)"
    (featureClicked)="featureClicked($event)"
    (featureDblClicked)="featureDblClicked($event)"
    (featuresSelected)="featuresSelected($event)"
    (boundsChanged)="boundsChanged($event)"
    class="flex-1 z-10"
  ></sd-map-viewer>

  <!-- toolbar -->
  <div
    class="pointer-events-none absolute block justify-center top-0 left-0 right-0 z-50 ml-[296px] max-[672px]:ml-[240px] min-[1200px]:ml-[312px]"
  >
    <div class="pointer-events-auto flex flex-wrap w-fit m-auto bg-white rounded-b-sm">
      <sd-map-toolbar
        [activeBaseLayer]="activeBaseLayer$ | async"
        [baseLayers]="baseLayers$ | async"
        [activeMapTool]="(activeMapTool$ | async)!"
        (baseLayerChange)="setActiveBaseLayer($event)"
        (mapToolSelected)="mapToolSelected($event)"
        (zoomInClick)="zoomIn()"
        (zoomOutClick)="zoomOut()"
        (fitToViewClick)="fitToView()"
        class="flex"
      ></sd-map-toolbar>
    </div>
  </div>
</div>
