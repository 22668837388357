import { InjectionToken } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

export interface LoadingSpinnerOptions {
  color: ThemePalette;
  diameter: number;
  strokeWidth: number;
}

export const LOADING_SPINNER_DEFAULT_OPTIONS: LoadingSpinnerOptions = {
  color: 'primary',
  diameter: 100,
  strokeWidth: 10,
};

export const LOADING_SPINNER_OPTIONS = new InjectionToken<LoadingSpinnerOptions>(
  'LoadingSpinnerOptions',
);
