export interface StationFov {
  minElevation: number;
  maxElevation: number;
  startAzimuth: number;
  endAzimuth: number;
}

export interface StationPosition {
  x: number;
  y: number;
  z: number;
}

export interface StationOrientation {
  yaw: number;
  pitch: number;
  roll: number;
}

export interface StationImageInfo {
  rawFileSize: number;
  originalWidth: number;
  originalHeight: number;
}

export interface StationTilesetInfo {
  tilesetUrl: string;
  tileWidth: number;
  tileHeight: number;
  resizedWidth: number;
  resizedHeight: number;
  levels: number;
  tileTemplate: string;
}

export enum StationStatus {
  NotReady = 'NotReady',
  Ready = 'Ready',
  Error = 'Error',
}

export interface Station {
  id: string;
  name: string;

  status: StationStatus;
  fov?: StationFov;
  position: StationPosition;
  orientation?: StationOrientation;
  imageInfo?: StationImageInfo;
  tilesetInfo?: StationTilesetInfo;

  /** Web3d model id */
  web3dId: string;

  /** Station icon id to use in web3d.  Only numbers allowed. */
  iconId: number;

  /** Parent point cloud id */
  pointcloudId: string;
}

export interface CurrentStation {
  station: Station;
  displayStatus: StationDisplayStatus;
}

export enum StationLoadStatus {
  NotLoaded = 'NotLoaded',
  Loading = 'Loading',
  LoadingError = 'LoadingError',
  Loaded = 'Loaded',
}

export enum StationDisplayStatus {
  AwaitingDisplay = 'AwaitingDisplay',
  Displayed = 'Displayed',
  DisplayError = 'DisplayError',
}
