import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  effect,
  input,
  output,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModusSwitchModule } from '@trimble-gcs/modus';
import { LabelValueComponent } from '../../label-value/label-value.component';
import { Scan3dStyle } from '../models/scan-3d-style';

@UntilDestroy()
@Component({
  selector: 'sd-scan-3d-styling',
  standalone: true,
  imports: [ReactiveFormsModule, ModusSwitchModule, MatSliderModule, LabelValueComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './scan-3d-styling.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Scan3dStylingComponent {
  scan3dStyle = input.required<Scan3dStyle>();

  styleChange = output<Scan3dStyle>();

  showExperimental = false;

  formGroup = new FormGroup({
    showClassification: new FormControl<boolean>(false, { nonNullable: true }),
    showIntensity: new FormControl<boolean>(false, { nonNullable: true }),
    showEyeDomeLighting: new FormControl<boolean>(false, { nonNullable: true }),
    pointBudget: new FormControl<number>(10000000, { nonNullable: true }),
    pointDensity: new FormControl<number>(1, { nonNullable: true }),
    pointSize: new FormControl<number>(1, { nonNullable: true }),
  });

  constructor() {
    this.createFormGroupSetValueEffect();
    this.subscribeToFormChanges();
  }

  showExperimentalClick(event: MouseEvent) {
    if (event.shiftKey && event.altKey) {
      this.showExperimental = !this.showExperimental;
    }
  }

  private createFormGroupSetValueEffect() {
    // Using allowSignalWrites: true here because the MatSlider component
    // propagates the FormControl.setValue()
    // by setting a signal internally.
    effect(
      () => {
        const style = this.scan3dStyle();
        this.formGroup.setValue(
          {
            showClassification: style.showClassification,
            showIntensity: style.showIntensity,
            showEyeDomeLighting: style.showEyeDomeLighting,
            pointBudget: style.pointBudget,
            pointDensity: style.pointDensity,
            pointSize: style.pointSize,
          },
          { emitEvent: false },
        );
      },
      { allowSignalWrites: true },
    );
  }

  private subscribeToFormChanges() {
    this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      const mergedStyle: Scan3dStyle = {
        ...this.scan3dStyle(),
        ...this.formGroup.getRawValue(),
      };

      this.styleChange.emit(mergedStyle);
    });
  }
}
