<div
  #map
  leaflet
  class="flex flex-col h-full outline-0"
  (leafletMapReady)="setMapInstance($event)"
  (leafletMapZoomEnd)="zoomed($event)"
  (leafletMapMoveEnd)="moved()"
  [leafletOptions]="options"
>
  @let tile = tileLayer();
  @if (tile !== undefined) {
    <div [leafletLayer]="tile"></div>
  }
  <div [leafletLayer]="scanLayer()"></div>
</div>
