import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store';
import {
  ExistingState,
  StateOperator,
  append,
  compose,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import { isDefined, isNil } from '@trimble-gcs/common';
import { ConnectView } from '../connect-3d-ext/host-3d.model';
import {
  DEFAULT_FILTER_TAGS_MATCH_ALL_VALUE,
  Filters,
  Paging,
  ScandataQuery,
  Sorting,
} from './scandata-query.models';
import {
  AddScan,
  ClearFilters,
  ClearScandata,
  PatchScandataModel,
  PatchScandataModels,
  RemoveScandataModel,
  SelectOnly,
  SelectScandataModel,
  SetFilters,
  SetIsLoading,
  SetPaging,
  SetScandata,
  SetSorting,
  SetTextFilter,
  UnselectAllScandataModels,
  UnselectScandataModel,
  UpdateScandata,
  UpdateScandataModels,
} from './scandata.actions';
import { ScandataModel } from './scandata.models';

export interface ScandataStateModel {
  scandata: ScandataModel[];
  isLoading: boolean;
  textFilter?: string;
  scandataQuery: ScandataQuery;
}

const defaultFilters: Filters = { tagsMatchAll: DEFAULT_FILTER_TAGS_MATCH_ALL_VALUE };

const defaultState: ScandataStateModel = {
  scandata: [],
  isLoading: false,
  scandataQuery: {
    sorting: { sortBy: <keyof ScandataModel>'uploadedDate', sortDirection: 'desc' },
    paging: { pageIndex: 0, pageSize: 1000 },
    filters: defaultFilters,
  },
};

@State<ScandataStateModel>({
  name: 'scandataState',
  defaults: defaultState,
})
@Injectable()
export class ScandataState {
  @Selector() static scandata(state: ScandataStateModel): ScandataModel[] {
    return state.scandata;
  }

  @Selector() static textFilteredScandata(state: ScandataStateModel): ScandataModel[] {
    const textFilter = state.textFilter?.trim();
    if (isNil(textFilter) || textFilter.length === 0) return state.scandata;

    const filter = textFilter.toLowerCase();
    return state.scandata.filter((scan) => {
      return (
        scan.name.toLowerCase().includes(filter) ||
        scan.uploadedBy?.toLowerCase().includes(filter) ||
        scan.notes?.toLowerCase().includes(filter) ||
        scan.scannerType?.toLowerCase().includes(filter) ||
        scan.tags?.some((tag) => tag.toLowerCase().includes(filter)) ||
        scan.files?.some((file) => file.name.toLowerCase().includes(filter)) ||
        scan.location?.city.toLowerCase().includes(filter) ||
        scan.location?.country.toLowerCase().includes(filter) ||
        scan.location?.state.toLowerCase().includes(filter) ||
        scan.location?.stateName.toLowerCase().includes(filter) ||
        scan.location?.streetAddress.toLowerCase().includes(filter) ||
        scan.location?.zip.toLowerCase().includes(filter)
      );
    });
  }

  @Selector() static selected(state: ScandataStateModel): ScandataModel[] {
    return state.scandata.filter((model) => model.selected);
  }

  @Selector() static isLoading(state: ScandataStateModel): boolean {
    return state.isLoading;
  }

  @Selector() static textFilter(state: ScandataStateModel): string | undefined {
    return state.textFilter;
  }

  @Selector() static query(state: ScandataStateModel): ScandataQuery {
    return state.scandataQuery;
  }

  @Selector() static sorting(state: ScandataStateModel): Sorting {
    return state.scandataQuery.sorting;
  }

  @Selector() static paging(state: ScandataStateModel): Paging {
    return state.scandataQuery.paging;
  }

  @Selector() static filters(state: ScandataStateModel): Filters {
    return state.scandataQuery.filters;
  }

  @Selector() static filterCount(state: ScandataStateModel): number {
    const defaultFilterCount = Object.values(defaultFilters).filter(
      (value) => isDefined(value) && value.toString().length > 0,
    ).length;

    const selectedFilters = state.scandataQuery.filters;
    const selectedFilterCount = Object.values(selectedFilters).filter(
      (value) => isDefined(value) && value.toString().length > 0,
    ).length;

    return selectedFilterCount - defaultFilterCount;
  }

  static getScansForConnectView(connectView: ConnectView) {
    return createSelector([ScandataState], (state: ScandataStateModel) => {
      return state.scandata.filter((scan) => connectView.scans.find((x) => x.id === scan.id));
    });
  }

  static getScan(id: string) {
    return createSelector([ScandataState], (state: ScandataStateModel) => {
      return state.scandata.find((scan) => id === scan.id);
    });
  }

  static getScanForExternalFile(externalFileId: string) {
    return createSelector([ScandataState], (state: ScandataStateModel) => {
      return state.scandata.find((scan) => externalFileId === scan.externalFileId);
    });
  }

  @Action(UpdateScandata) updateScandata(
    ctx: StateContext<ScandataStateModel>,
    { scandata }: UpdateScandata,
  ) {
    ctx.setState(patch<ScandataStateModel>({ scandata: updateScandata(scandata) }));
  }

  @Action(SetScandata) setScandata(
    ctx: StateContext<ScandataStateModel>,
    { scandata }: SetScandata,
  ) {
    ctx.setState(patch<ScandataStateModel>({ scandata: scandata }));
  }

  @Action(ClearScandata) clearScandata(ctx: StateContext<ScandataStateModel>) {
    ctx.setState(patch<ScandataStateModel>({ scandata: [] }));
  }

  @Action(SelectScandataModel) selectScandataModel(
    ctx: StateContext<ScandataStateModel>,
    { scandataModelId }: SelectScandataModel,
  ) {
    ctx.setState(
      patch({
        scandata: updateItem(
          (model) => model.id === scandataModelId,
          (model) => {
            return { ...model, ...{ selected: true } };
          },
        ),
      }),
    );
  }

  @Action(UnselectScandataModel) unselectScandataModel(
    ctx: StateContext<ScandataStateModel>,
    { scandataModelId }: UnselectScandataModel,
  ) {
    ctx.setState(
      patch({
        scandata: updateItem(
          (model) => model.id === scandataModelId,
          (model) => {
            return { ...model, ...{ selected: false } };
          },
        ),
      }),
    );
  }

  @Action(SelectOnly) selectOnly(
    ctx: StateContext<ScandataStateModel>,
    { scandataModelIds }: SelectOnly,
  ) {
    const data = ctx.getState().scandata.slice();
    data.forEach((model) => (model.selected = scandataModelIds.includes(model.id)));
    ctx.patchState({ scandata: data });
  }

  @Action(UnselectAllScandataModels) unselectAllScandataModels(
    ctx: StateContext<ScandataStateModel>,
  ) {
    const data = ctx.getState().scandata.slice();
    data.forEach((model) => (model.selected = false));
    ctx.patchState({ scandata: data });
  }

  @Action(PatchScandataModel) patchScandataModel(
    ctx: StateContext<ScandataStateModel>,
    { scandataModel }: PatchScandataModel,
  ) {
    this.patchScandataModels(ctx, new PatchScandataModels([scandataModel]));
  }

  @Action(PatchScandataModels) patchScandataModels(
    ctx: StateContext<ScandataStateModel>,
    { scandataModels }: PatchScandataModels,
  ) {
    const updateItems = scandataModels.map((model) =>
      updateItem<ScandataModel>(
        (item) => item.id === model.id,
        (item) => ({ ...item, ...model }),
      ),
    );

    ctx.setState(
      patch({
        scandata: compose(...updateItems),
      }),
    );
  }

  @Action(UpdateScandataModels) updateScandataModels(
    ctx: StateContext<ScandataStateModel>,
    { scandataModels }: UpdateScandataModels,
  ) {
    const updateItems = scandataModels.map((model) =>
      updateItem<ScandataModel>((x) => x.id === model.id, { ...model }),
    );

    ctx.setState(
      patch({
        scandata: compose(...updateItems),
      }),
    );
  }

  @Action(AddScan) addScan(ctx: StateContext<ScandataStateModel>, { scan }: AddScan) {
    ctx.setState(
      patch({
        scandata: append([scan]),
      }),
    );
  }

  @Action(RemoveScandataModel) removeScandataModel(
    ctx: StateContext<ScandataStateModel>,
    { scandataModelId }: RemoveScandataModel,
  ) {
    ctx.setState(
      patch({
        scandata: removeItem<ScandataModel>((item) => item.id === scandataModelId),
      }),
    );
  }

  @Action(SetTextFilter) setTextFilter(
    ctx: StateContext<ScandataStateModel>,
    { textFilter }: SetTextFilter,
  ) {
    ctx.patchState({ textFilter });
  }

  @Action(SetSorting) setSorting(ctx: StateContext<ScandataStateModel>, { sorting }: SetSorting) {
    ctx.setState(
      patch({
        scandataQuery: patch({ sorting }),
      }),
    );
  }

  @Action(SetPaging) setPaging(ctx: StateContext<ScandataStateModel>, { paging }: SetPaging) {
    ctx.setState(
      patch({
        scandataQuery: patch({ paging }),
      }),
    );
  }

  @Action(SetFilters) setFilters(ctx: StateContext<ScandataStateModel>, { filters }: SetFilters) {
    ctx.setState(
      patch({
        scandataQuery: patch({ filters }),
      }),
    );
  }

  @Action(ClearFilters) clearFilters(ctx: StateContext<ScandataStateModel>) {
    ctx.setState(
      patch({
        scandataQuery: patch({ filters: defaultFilters }),
      }),
    );
  }

  @Action(SetIsLoading) setIsLoading(
    ctx: StateContext<ScandataStateModel>,
    { isLoading }: SetIsLoading,
  ) {
    ctx.patchState({ isLoading: isLoading });
  }
}

function updateScandata(data: ScandataModel[]): StateOperator<ScandataModel[]> {
  return (existing: ExistingState<ScandataModel[]>) => {
    const updated = data.map((item) => {
      const current = existing.find((ex) => ex.id === item.id);

      // Keeping web3dId the same as current item is important
      // when going to 3d viewer from selection.
      // The scans in host-3d is set first and getScandata updates
      // the scans later.
      const web3dId = current?.web3dId ?? item.web3dId;

      const merged = isNil(current) ? item : { ...current, ...item, web3dId };
      return merged;
    });
    return updated;
  };
}
