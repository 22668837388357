import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable, map } from 'rxjs';
import { DialogComponent } from './dialog.component';
import { DialogData } from './dialog.model';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private readonly minDialogWidth = 220;

  constructor(private dialog: MatDialog) {}

  show(data: DialogData): Observable<boolean> {
    const dialogRef = this.dialog.open<DialogComponent, DialogData, boolean>(DialogComponent, {
      minWidth: this.minDialogWidth,
      disableClose: data.disableClose,
      autoFocus: false,
      data,
    });

    return dialogRef.afterClosed().pipe(map((confirmed) => confirmed ?? false));
  }

  showComponent<T, D = any, R = any>(
    component: ComponentType<T>,
    config?: MatDialogConfig<D>,
  ): Observable<R | undefined> {
    const dialogRef = this.dialog.open<T, D, R>(component, {
      minWidth: this.minDialogWidth,
      autoFocus: false,
      ...config,
    });

    return dialogRef.afterClosed();
  }
}
