// index.ts
import { booleanDisjoint } from "@turf/boolean-disjoint";
import { flattenEach } from "@turf/meta";
function booleanIntersects(feature1, feature2, options = {}) {
  var _a;
  const ignoreSelfIntersections = (_a = options.ignoreSelfIntersections) != null ? _a : false;
  let bool = false;
  flattenEach(feature1, flatten1 => {
    flattenEach(feature2, flatten2 => {
      if (bool === true) {
        return true;
      }
      bool = !booleanDisjoint(flatten1.geometry, flatten2.geometry, {
        ignoreSelfIntersections
      });
    });
  });
  return bool;
}
var turf_boolean_intersects_default = booleanIntersects;
export { booleanIntersects, turf_boolean_intersects_default as default };
