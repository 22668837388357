import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { SimpleChangesTyped, isDefined } from '@trimble-gcs/common';
import { ModusButtonModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { BehaviorSubject, Observable, combineLatest, filter, map, switchMap } from 'rxjs';
import { ConnectService } from '../../../connect/connect.service';
import { CANCEL_BUTTON, DialogData } from '../../../dialog/dialog.model';
import { DialogService } from '../../../dialog/dialog.service';
import { ClearError } from '../../../error-handling/error.actions';
import { LoadingService } from '../../../loading/loading.service';
import { UnselectScandataModel } from '../../../scandata/scandata.actions';
import { ScandataModel } from '../../../scandata/scandata.models';
import { ScandataService } from '../../../scandata/scandata.service';
import { SetInfoView, TogglePreviewOpen } from '../../options-panel.actions';
import { OptionsPanelInfoView, OptionsPanelState } from '../../options-panel.state';
import { ClassificationComponent } from './classification/classification.component';
import { PropertiesComponent } from './properties/properties.component';
import { TaggingComponent } from './tagging/tagging.component';
import { ViewerGlbComponent } from './viewer-glb/viewer-glb.component';

@UntilDestroy()
@Component({
  selector: 'sd-single-selected',
  standalone: true,
  imports: [
    CommonModule,
    ModusButtonModule,
    ModusIconModule,
    ModusTooltipModule,
    ViewerGlbComponent,
    ClassificationComponent,
    PropertiesComponent,
    TaggingComponent,
    ScrollingModule,
    MatTabsModule,
    MatProgressBarModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './single-selected.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleSelectedComponent implements OnInit, OnChanges {
  @Select(OptionsPanelState.previewOpen) previewOpen$!: Observable<boolean>;
  @Select(OptionsPanelState.infoView) private infoView$!: Observable<OptionsPanelInfoView>;

  @Input() scandataModel!: ScandataModel;
  @Input() readonly = false;
  @Output() downloadClicked = new EventEmitter();

  disablePreview$ = new BehaviorSubject<boolean>(false);

  previewTooltip$!: Observable<string>;
  showPreview$!: Observable<boolean>;
  showProgressBar$!: Observable<boolean>;

  selectedInfoTabIndex$!: Observable<number>;

  private infoTabs = {
    [OptionsPanelInfoView.Properties]: 0,
    [OptionsPanelInfoView.Tagging]: 1,
    [OptionsPanelInfoView.Classification]: 2,
  };

  private isSaving$ = new BehaviorSubject(false);

  constructor(
    private store: Store,
    private loadingService: LoadingService,
    private scandataService: ScandataService,
    private connectService: ConnectService,
    private dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this.showPreview$ = combineLatest([this.disablePreview$, this.previewOpen$]).pipe(
      map(([disablePreview, previewOpen]) => {
        if (disablePreview) return false;
        return previewOpen;
      }),
    );

    this.previewTooltip$ = combineLatest([this.disablePreview$, this.previewOpen$]).pipe(
      map(([disablePreview, previewOpen]) => {
        if (disablePreview) return 'No preview available';
        return previewOpen ? 'Hide preview' : 'Show preview';
      }),
    );

    this.selectedInfoTabIndex$ = this.infoView$.pipe(map((infoView) => this.infoTabs[infoView]));

    this.showProgressBar$ = combineLatest([
      this.loadingService.isLoading$(this),
      this.isSaving$,
    ]).pipe(map(([loading, saving]) => loading || saving));
  }

  ngOnChanges(changes: SimpleChanges & SimpleChangesTyped<SingleSelectedComponent>): void {
    const change = changes.scandataModel;
    if (change && change.currentValue) {
      const disablePreview = !this.scanHasPreview();
      this.disablePreview$.next(disablePreview);
    }
  }

  togglePreview() {
    this.store.dispatch(new TogglePreviewOpen());
  }

  infoTabChange(event: MatTabChangeEvent) {
    const tab = Object.keys(this.infoTabs)[event.index] as OptionsPanelInfoView;
    this.store.dispatch([new SetInfoView(tab), new ClearError('scanDetailsSaveError')]);
  }

  viewIn3d() {
    this.connectService.goTo3dExtension();
  }

  unselect() {
    this.store.dispatch(new UnselectScandataModel(this.scandataModel.id));
  }

  handleSave(saving: boolean) {
    this.isSaving$.next(saving);
  }

  deleteScandata() {
    const dialogData = new DialogData(
      'Delete Reality Capture Data',
      'Are you sure you want to delete this data?',
      { text: 'Delete', color: 'danger' },
      CANCEL_BUTTON,
    );

    this.dialogService
      .show(dialogData)
      .pipe(
        filter((confirmed) => confirmed),
        switchMap(() => {
          return this.loadingService.loadFrom(
            this.scandataService.deleteScandataModel(this.scandataModel.id),
            this,
          );
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private scanHasPreview() {
    const previewUrl = this.scandataModel.previewUrl;
    const hasPreview = isDefined(previewUrl) && previewUrl.length > 0;

    return hasPreview;
  }
}
