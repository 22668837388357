import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export type LabelValueOrientation = 'col' | 'row';
export type ValueOverflow = 'clip' | 'multiline';

/**
 * A simple component to display label-value pairs with consistent styling.
 */
@Component({
  selector: 'sd-label-value',
  standalone: true,
  imports: [CommonModule],
  template: `
    <label class="text-xs text-gray-8" [ngClass]="{ 'mr-2': orientation === 'row' }">{{
      label
    }}</label>
    <div class="text-sm" [ngClass]="valueOverflowClass">{{ value }}</div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelValueComponent {
  @Input() orientation: LabelValueOrientation = 'col';
  @Input() valueOverflow: ValueOverflow = 'clip';

  @Input() label!: string;
  @Input() value!: string;

  get valueOverflowClass(): string {
    if (this.valueOverflow === 'multiline') {
      return '';
    }

    return 'h-5 overflow-hidden text-ellipsis whitespace-nowrap';
  }

  @HostBinding('class') get className() {
    const colClasses = `flex flex-col mb-2`;
    const rowClasses = 'flex flex-row items-baseline mb-2';

    return this.orientation === 'col' ? colClasses : rowClasses;
  }
}
