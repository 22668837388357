import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LOADING_BAR_OPTIONS, LoadingBarOptions } from './loading-bar-options';

@Component({
  standalone: true,
  imports: [MatProgressBarModule],
  template: `
    <div class="w-full max-w-full">
      <mat-progress-bar
        class="mds-x-small"
        [mode]="'indeterminate'"
        [color]="color"
      ></mat-progress-bar>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex: 1 0 auto;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingBarComponent {
  color: ThemePalette;

  constructor(@Inject(LOADING_BAR_OPTIONS) options: LoadingBarOptions) {
    this.color = options.color;
  }
}
