import { CurrentStation, Station } from './station.models';

export class CacheStations {
  static readonly type = '[Stations] CacheStations';
  constructor(
    public pointcloudId: string,
    public stations: Station[],
  ) {}
}

export class SetCurrentStation {
  static readonly type = '[Station] SetCurrent';
  constructor(public readonly currentStation: CurrentStation) {}
}

export class ClearCurrentStation {
  static readonly type = '[Station] ClearCurrent';
}
