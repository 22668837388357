import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, first, fromEvent, map, takeUntil } from 'rxjs';

/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */

@Directive({
  selector: 'modus-checkbox',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ModusCheckboxDirective,
      multi: true,
    },
  ],
})
export class ModusCheckboxDirective implements ControlValueAccessor, OnInit, OnDestroy {
  private readonly element = this.elementRef.nativeElement;
  private readonly onDestroy = new Subject<void>();

  @Input() formControl?: FormControl;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.subscribeToStatusChanges();
    this.subscribeToValueChanges();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  onChange = (_: unknown) => {};
  onTouched = () => {};

  writeValue(value: boolean): void {
    this.element.checked = value;
  }

  registerOnChange(fn: never): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: never): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.elementRef.nativeElement.disabled = isDisabled;
  }

  private subscribeToStatusChanges() {
    if (!this.formControl) return;

    this.formControl.statusChanges
      .pipe(
        first(() => this.formControl?.touched ?? false),
        takeUntil(this.onDestroy),
      )
      .subscribe(() => this.onTouched());
  }

  private subscribeToValueChanges() {
    if (!this.formControl) return;

    fromEvent<CustomEvent>(this.element, 'checkboxClick')
      .pipe(
        map((event: CustomEvent) => event.detail),
        takeUntil(this.onDestroy),
      )
      .subscribe((value: boolean) => {
        this.onChange(value);
      });
  }
}
