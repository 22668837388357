import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModusFormFieldModule } from '../modus-form-field';
import { ModusIconModule } from '../modus-icon';
import { ModusInputModule } from '../modus-input';
import { ModusAutocomplete } from './modus-autocomplete.component';
import { ModusOptionList } from './modus-option-list/modus-option-list.component';
import { ModusOption } from './modus-option/modus-option.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModusFormFieldModule,
    ModusIconModule,
    ModusInputModule,
  ],
  declarations: [ModusAutocomplete, ModusOptionList, ModusOption],
  exports: [ModusAutocomplete, ModusOptionList, ModusOption],
})
export class ModusAutocompleteModule {}
