<sd-app-bar
  [loggedIn]="(loggedIn$ | async) ?? false"
  [user]="(user$ | async) ?? undefined"
  (menuClick)="toggleSidenav($event)"
  (logoutClick)="logout()"
></sd-app-bar>

<mat-sidenav-container class="flex-grow border-t border-gray-1">
  <mat-sidenav
    #sidenav
    mode="side"
    [opened]="sidenavOpen()"
    fixedInViewport
    fixedTopGap="56"
    class="w-[256px] max-w-[256px]"
  >
    <mat-action-list>
      <mat-list-item
        [ngClass]="{ '!bg-blue-light': (activeLink$ | async) === 'map-view' }"
        (click)="navigate('map-view')"
      >
        <div class="flex grow justify-start items-center h-8">
          <md-icon class="mr-4">map_poi</md-icon>
          <button class="">Map View</button>
        </div>
      </mat-list-item>

      <mat-list-item
        [ngClass]="{ '!bg-blue-light': (activeLink$ | async) === 'list-view' }"
        (click)="navigate('list-view')"
      >
        <div class="flex grow justify-start items-center h-8">
          <md-icon class="mr-4">list_bulleted</md-icon>
          <button class="bg-transparent">List View</button>
        </div>
      </mat-list-item>

      <mat-list-item
        [ngClass]="{ '!bg-blue-light': (activeLink$ | async) === 'config' }"
        (click)="navigate('config')"
      >
        <div class="flex grow justify-start items-center h-8">
          <md-icon class="mr-4">settings</md-icon>
          <button class="bg-transparent">Config</button>
        </div>
      </mat-list-item>

      <mat-list-item
        [ngClass]="{ '!bg-blue-light': (activeLink$ | async) === 'loading-demo' }"
        (click)="navigate('loading-demo')"
      >
        <div class="flex grow justify-start items-center h-8">
          <md-icon class="mr-4">sync</md-icon>
          <button class="bg-transparent">Loading Demo</button>
        </div>
      </mat-list-item>

      <mat-list-item
        [ngClass]="{ '!bg-blue-light': (activeLink$ | async) === 'dialog-demo' }"
        (click)="navigate('dialog-demo')"
      >
        <div class="flex grow justify-start items-center h-8">
          <md-icon class="mr-4">launch</md-icon>
          <button class="bg-transparent">Dialog Demo</button>
        </div>
      </mat-list-item>

      @for (egg of easterEggs; track egg.title) {
        <mat-list-item
          [ngClass]="{ '!bg-blue-light': (activeLink$ | async) === 'easter-egg/' + egg.path }"
          (click)="easterEgg(egg.path)"
        >
          <div class="flex grow justify-start items-center h-8">
            <md-icon class="mr-4">image</md-icon>
            <button class="bg-transparent">{{ egg.title }}</button>
          </div>
        </mat-list-item>
      }
    </mat-action-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="flex flex-col h-full">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
