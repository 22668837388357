import { ScandataFile } from '../../scandata/scandata-file.model';
import { ScandataModel } from '../../scandata/scandata.models';

export interface DownloadScan {
  scandataModel: ScandataModel;
  files: DownloadFile[];
  expanded: boolean;
}

export interface DownloadFile {
  scandataModelId: string;
  scandataFile: ScandataFile;
  downloadProgress?: DownloadProgress;
  icon: string;
}

export interface DownloadProgress {
  status: DownloadStatus;
  percentageComplete: number;
  content: Blob | null;
}

export enum DownloadStatus {
  Pending = 'Pending',
  Busy = 'Busy',
  Complete = 'Complete',
  Failed = 'Failed',
}
