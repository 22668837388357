import { Injectable } from '@angular/core';
import { Action, State, StateContext, createSelector } from '@ngxs/store';
import { checkValidSasToken } from '../utils/sas-token-validator';
import { Tileset, TilesetStatus } from './tileset.models';

export interface TilesetStateModel {
  [key: string]: Tileset[];
}

export class CacheTileSets {
  static readonly type = '[Tilesets] AddToCache';
  constructor(
    public pointcloudId: string,
    public tilesets: Tileset[],
  ) {}
}

@State<TilesetStateModel>({
  name: 'tilesetState',
  defaults: {},
})
@Injectable()
export class TilesetState {
  static getTilesets(pointcloudId: string): (state: TilesetStateModel) => Tileset[] {
    return createSelector([TilesetState], (state: TilesetStateModel) => {
      const tilesets = state[pointcloudId];
      const tileset = tilesets?.find((t) => t.status === TilesetStatus.Ready);
      return tileset?.rootUrl && checkValidSasToken(tileset.rootUrl) ? tilesets : [];
    });
  }

  @Action(CacheTileSets)
  cacheTileSets(ctx: StateContext<TilesetStateModel>, { pointcloudId, tilesets }: CacheTileSets) {
    ctx.patchState({ [pointcloudId]: tilesets });
  }
}
