import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  signal,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModusTooltipModule } from '@trimble-gcs/modus';

@UntilDestroy()
@Component({
  selector: 'sd-chip-container',
  standalone: true,
  imports: [CommonModule, ModusTooltipModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './chip-container.component.html',
  styles: [
    `
      :host {
        display: flex;
        overflow: hidden;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipContainerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('chipContainer') private chipContainerElementRef!: ElementRef;
  @ViewChild('summaryChip') private summaryChipElementRef!: ElementRef<HTMLModusChipElement>;
  @ViewChildren('chip') chipElementRefs!: QueryList<ElementRef<HTMLModusChipElement>>;

  @Input() chips?: string[];
  @Output() chipClick = new EventEmitter<Event>();

  private readonly componentContainer: HTMLElement = this.elementRef.nativeElement;
  private resizeObserver!: ResizeObserver;

  summaryTooltip = signal('');

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    this.subscribeChipsObserver();
    this.subscribeResizeObserver();
  }

  ngOnDestroy() {
    this.resizeObserver.disconnect();
  }

  private subscribeChipsObserver() {
    this.chipElementRefs.changes.pipe(untilDestroyed(this)).subscribe(() => {
      this.updateChipsVisibility();
    });
  }

  private subscribeResizeObserver() {
    this.resizeObserver = new ResizeObserver(() => {
      window.requestAnimationFrame(() => {
        this.updateChipsVisibility();
      });
    });

    const chipContainer = this.chipContainerElementRef.nativeElement;
    this.resizeObserver.observe(chipContainer);
  }

  private updateChipsVisibility() {
    const componentRect: DOMRect = this.componentContainer.getBoundingClientRect();
    const chips = this.chipElementRefs.map((item) => item.nativeElement);
    const summaryChip = this.summaryChipElementRef.nativeElement;

    // Make all chips visible so you can get their DOMRect
    [...chips, summaryChip].forEach((child) => {
      child.classList.remove('hidden');
    });

    // If last chip is not visible, reverse through the chips and hide until the summary is visible
    [...chips].reverse().reduce((prevChipVisible, chip, index) => {
      if (prevChipVisible) return true;

      // If last chip is visible we can exit early
      if (index === 0 && this.isElementInView(componentRect, chip)) {
        return true;
      }

      // Hide chip if summary is not visible
      const isSummaryInViewPort = this.isElementInView(componentRect, summaryChip);
      if (!isSummaryInViewPort) {
        chip.classList.add('hidden');
      }

      return isSummaryInViewPort;
    }, false);

    this.updateSummaryChip(summaryChip, chips);
  }

  private updateSummaryChip(summaryChip: HTMLModusChipElement, chips: HTMLModusChipElement[]) {
    const hiddenChips = chips
      .filter((item) => item.classList.contains('hidden'))
      .map((item) => item.value);

    if (hiddenChips.length === 0) {
      summaryChip.classList.add('hidden');
      return;
    }

    this.summaryTooltip.set(hiddenChips.join('\n'));
    summaryChip.value = `+${hiddenChips.length}`;
    summaryChip.classList.remove('hidden');
  }

  private isElementInView(containerRect: DOMRect, element: HTMLElement) {
    const rect = element.getBoundingClientRect();
    return rect.bottom <= containerRect.bottom && rect.right <= containerRect.right;
  }
}
