<div class="w-full relative">
  @if (isLoading()) {
    <div class="w-full absolute">
      <mat-progress-bar class="mds-x-small" [mode]="'indeterminate'"></mat-progress-bar>
    </div>
  }
</div>

<div class="w-full p-4 bg-white border border-gray-0 rounded">
  @if (projectQuotaView(); as quota) {
    <div class="flex flex-row items-center">
      <!-- title -->
      <div class="flex flex-1 flex-wrap">
        <span class="font-semibold text-lg mr-4">Reality Capture Data Usage</span>
        <div class="flex items-center px-2 bg-green-light font-semibold text-white rounded-md">
          {{ quota.quotaUsedByAccountPercent | percentage: 0 }}
        </div>
      </div>

      <!-- usage -->
      <div class="flex flex-1 flex-wrap justify-end text-sm">
        <div class="flex whitespace-nowrap">
          <span class="font-semibold mr-1">Total Storage Used:</span>
          {{ quota.quotaUsedByAccount | fileSize }}
        </div>
        <div class="flex whitespace-nowrap ml-6">
          <span class="font-semibold mr-1">Storage Plan:</span>
          {{ quota.quota | fileSize }}
        </div>
      </div>
    </div>

    <!-- usage graph -->
    <div class="w-full pt-4">
      <sd-usage-bar
        [usageValueBackground]="{
          percentage: quota.quotaUsedByAccountPercent,
          label: 'All Projects',
        }"
        [usageValueForeground]="{
          percentage: quota.quotaUsedByProjectPercent,
          label: 'This Project',
        }"
        class="border-1"
      ></sd-usage-bar>
    </div>
  }
</div>

@if (recycleBinFeatureActive()) {
  <div class="w-full mt-4 p-4 bg-white border border-gray-0 rounded">
    <div class="flex flex-1 flex-row items-center mb-2">
      <span class="font-bold">Project Recycle Bin</span>

      <!-- archive options -->
      <div class="flex flex-1 justify-end">
        <button modus-icon-button modus-tooltip="Restore Data">
          <md-icon>refresh</md-icon>
        </button>
        <button modus-icon-button modus-tooltip="Delete Data" class="ml-2">
          <md-icon>delete</md-icon>
        </button>
      </div>
    </div>

    <!-- data table -->
  </div>
}
