<div class="h-full">
  <div class="w-full relative">
    @if (isLoading()) {
      <div class="w-full absolute">
        <mat-progress-bar class="mds-x-small" [mode]="'indeterminate'"></mat-progress-bar>
      </div>
    }
  </div>

  <div class="h-full flex flex-1 flex-col p-4 bg-white border border-gray-0 rounded">
    <!-- error alert -->
    @if (hasError$ | async) {
      <div class="w-full mb-4">
        <modus-alert
          class="w-full"
          message="{{ errorMessage$ | async }}"
          type="error"
          dismissible
          (dismissClick)="dismissAlert()"
        ></modus-alert>
      </div>
    }

    <div class="flex flex-1 flex-row justify-end mb-2">
      <!-- save options -->
      @if (state.dirty()) {
        <span>
          <button modus-button (click)="cancel()" [disabled]="isLoading()">Cancel</button>
          <button
            modus-flat-button
            color="primary"
            class="ml-1"
            (click)="save()"
            [disabled]="disableSave()"
          >
            Save
          </button>
        </span>
      } @else {
        <!-- reset, export & import options -->
        <button modus-button (click)="reset()" [disabled]="isLoading()">Revert to Default</button>
        <button
          modus-flat-button
          color="secondary"
          class="mx-4"
          (click)="export()"
          [disabled]="isLoading()"
        >
          Export
        </button>
        <!-- file import -->
        <input
          #fileImportInput
          type="file"
          accept="application/json"
          (change)="onFileSelected($event)"
          style="display: none"
        />
        <button
          modus-flat-button
          color="primary"
          (click)="fileImportInput.click()"
          [disabled]="isLoading()"
        >
          Import
        </button>
      }
    </div>

    <div class="h-full overflow-y-scroll">
      <sd-config-classification-table
        #classificationTable
        [classifications]="(classifications$ | async)!"
        [disabled]="isLoading()"
      ></sd-config-classification-table>
    </div>
  </div>
</div>
