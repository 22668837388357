import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SimpleChangesTyped, isNil } from '@trimble-gcs/common';
import Chart from 'chart.js/auto';
import { ActiveElement } from 'chart.js/dist/plugins/plugin.tooltip';
import { Classification } from '../../../../../classification/classification.model';
import { PercentagePipe } from '../../../../../pipes/percentage.pipe';

@UntilDestroy()
@Component({
  selector: 'sd-classification-graph',
  standalone: true,
  imports: [],
  templateUrl: './classification-graph.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClassificationGraphComponent implements AfterViewInit, OnChanges {
  @ViewChild('chart') private chartRef!: ElementRef;

  @Input() classifications!: Classification[];
  @Input() activeClassification!: Classification | null;
  @Output() activeClassificationChanged = new EventEmitter<Classification | null>();

  private chart!: Chart;

  constructor(private percentagePipe: PercentagePipe) {}

  ngAfterViewInit() {
    this.initChart();
    this.setChartData();
  }

  ngOnChanges(changes: SimpleChanges & SimpleChangesTyped<ClassificationGraphComponent>) {
    if (changes && changes.classifications) this.setChartData();
    if (changes && changes.activeClassification) this.setActiveClassification();
  }

  private setActiveClassification() {
    if (isNil(this.chart)) return;

    if (isNil(this.activeClassification)) {
      this.chart.setActiveElements([]);
      this.chart.tooltip?.setActiveElements([], { x: 0, y: 0 });
      this.chart.update();
      return;
    }

    const activeIndex = this.classifications.indexOf(this.activeClassification);
    if (this.chart.tooltip) {
      this.chart.tooltip.setActiveElements(
        [
          {
            datasetIndex: 0,
            index: activeIndex,
          },
        ],
        {
          x: 0,
          y: 0,
        },
      );
    }

    this.chart.setActiveElements([
      {
        datasetIndex: 0,
        index: activeIndex,
      },
    ]);
    this.chart.update();
  }

  private chartActiveElementChanged(activeElement: ActiveElement) {
    if (isNil(activeElement)) {
      this.activeClassificationChanged.emit(null);
      return;
    }

    const classification = this.classifications[activeElement.index];
    this.activeClassificationChanged.emit(classification);
  }

  private setChartData() {
    if (isNil(this.chart)) return;
    if (isNil(this.classifications)) return;

    const chartLabels = this.classifications.map(
      (item) => `${item.classificationScheme.id} - ${item.classificationScheme.name}`,
    );
    const chartData = this.classifications.map((item) => item.percentage / 100);
    const chartColorsRGBA = this.classifications.map(
      (item) => `#${item.classificationScheme.rgba}`,
    );

    this.chart.data = {
      labels: chartLabels,
      datasets: [
        {
          data: chartData,
          maxBarThickness: 20,
          backgroundColor: chartColorsRGBA,
        },
      ],
    };
    this.chart.update();
  }

  private initChart() {
    const percentagePipe = this.percentagePipe;

    this.chart = new Chart(this.chartRef.nativeElement, {
      type: 'bar',
      data: { datasets: [] },
      options: {
        plugins: {
          legend: { display: false },
          tooltip: {
            callbacks: {
              title(tooltipItems) {
                //format display value with 2 decimals
                const value = (tooltipItems[0].raw as number) * 100;
                tooltipItems[0].formattedValue = percentagePipe.transform(value, 2);

                //break tooltip title into multi line if length exceeds n
                const maxLineChars = 40;
                const title = tooltipItems[0].label;
                const words = title.split(' ');
                const paragraph = [''];

                words.map((word) => {
                  const lastLine = paragraph.slice(-1);
                  const combined = [lastLine, word].join(' ').trim();
                  if (combined.length < maxLineChars) {
                    paragraph.pop();
                    paragraph.push(combined);
                  } else {
                    paragraph.push(word);
                  }
                });

                return paragraph;
              },
            },
          },
        },
        scales: {
          y: {
            ticks: {
              callback(tickValue) {
                //add percentage symbol to zero scale and drop decimal from scale
                const value = typeof tickValue === 'string' ? parseInt(tickValue) : tickValue;
                return `${Math.round(value * 100)}%`;
              },
              format: {
                style: 'percent',
              },
            },
            border: { display: false },
          },
          x: {
            ticks: {
              callback(tickValue) {
                // truncate the labels on x axis
                // open issue on github: https://github.com/chartjs/Chart.js/issues/10466
                const maxLabelLength = 15;
                const label = this.getLabelForValue(tickValue as number);
                if (typeof label === 'string' && label.length > maxLabelLength) {
                  return `${label.substring(0, maxLabelLength)}...`;
                }
                return label;
              },
              minRotation: 90,
            },
            grid: { lineWidth: 0 },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 0,
        },
        onHover: (_, activeElements) => {
          this.chartActiveElementChanged(activeElements[0]);
        },
      },
    });
  }
}
