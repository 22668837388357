import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { ConnectProject } from 'trimble-connect-workspace-api';
import { ConnectRegion } from '../connect/connect.models';
import { ProjectRegion } from '../project-region/project-region.models';
import {
  SetConnectRegion,
  SetConnectRegions,
  SetContext,
  SetProject,
  SetProjectRegion,
  SetSettings,
  SetUrlQuery,
} from './app.actions';
import { AppContext, AppSettings } from './app.models';

export interface AppStateModel {
  settings: AppSettings;
  context: AppContext;
  project: ConnectProject;
  projectRegion: ProjectRegion;
  connectRegion: ConnectRegion;
  connectRegions: ConnectRegion[];
  urlQuery: string;
}

@State<AppStateModel>({
  name: 'appState',
})
@Injectable()
export class AppState {
  @Selector() static settings(state: AppStateModel) {
    return state.settings;
  }

  @Selector() static context(state: AppStateModel) {
    return state.context;
  }

  @Selector() static project(state: AppStateModel) {
    return state.project;
  }

  @Selector() static projectRegion(state: AppStateModel) {
    return state.projectRegion;
  }

  @Selector() static connectRegion(state: AppStateModel) {
    return state.connectRegion;
  }

  @Selector() static connectRegions(state: AppStateModel) {
    return state.connectRegions;
  }

  @Selector() static endpoints(state: AppStateModel) {
    const projectRegionEndpoints = isDefined(state.projectRegion)
      ? { scandata: state.projectRegion.scandata, features: state.projectRegion.features }
      : undefined;

    const connectRegionEndpoint = isDefined(state.connectRegion)
      ? { connectRegion: state.connectRegion.endpoint }
      : undefined;

    return {
      ...state.settings.endpoints,
      ...projectRegionEndpoints,
      ...connectRegionEndpoint,
    };
  }

  @Selector() static urlQueryScandataIds(state: AppStateModel) {
    const httpParams = new HttpParams({ fromString: state.urlQuery });
    const scandataIdString = httpParams.get('scandataIds') ?? '';
    return scandataIdString.length > 0 ? scandataIdString.split(',') : [];
  }

  @Selector() static urlQueryScandataQuery(state: AppStateModel) {
    const httpParams = new HttpParams({ fromString: state.urlQuery });
    return httpParams.get('scandataQuery');
  }

  @Selector() static hasUrlQueryParams(state: AppStateModel) {
    const httpParams = new HttpParams({ fromString: state.urlQuery });
    return isDefined(httpParams.get('scandataIds')) || isDefined(httpParams.get('scandataQuery'));
  }

  @Action(SetSettings) setSettings(ctx: StateContext<AppStateModel>, action: SetSettings) {
    ctx.patchState({ settings: action.appSettings });
  }

  @Action(SetContext) setContext(ctx: StateContext<AppStateModel>, action: SetContext) {
    ctx.patchState({ context: action.context });
  }

  @Action(SetProject) setProject(ctx: StateContext<AppStateModel>, action: SetProject) {
    ctx.patchState({ project: action.project });
  }

  @Action(SetProjectRegion) setProjectRegion(
    ctx: StateContext<AppStateModel>,
    action: SetProjectRegion,
  ) {
    ctx.patchState({ projectRegion: action.projectRegion });
  }

  @Action(SetConnectRegion) setConnectRegion(
    ctx: StateContext<AppStateModel>,
    action: SetConnectRegion,
  ) {
    ctx.patchState({ connectRegion: action.connectRegion });
  }

  @Action(SetConnectRegions) setConnectRegions(
    ctx: StateContext<AppStateModel>,
    action: SetConnectRegions,
  ) {
    ctx.patchState({ connectRegions: action.connectRegions });
  }

  @Action(SetUrlQuery) setUrlQuery(ctx: StateContext<AppStateModel>, action: SetUrlQuery) {
    ctx.patchState({ urlQuery: action.urlQuery });
  }
}
