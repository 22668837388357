import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { SimpleChangesTyped, isDefined, isNil } from '@trimble-gcs/common';
import { ModusAutocomplete, ModusAutocompleteModule, ModusIconModule } from '@trimble-gcs/modus';
import {
  BehaviorSubject,
  Observable,
  combineLatestWith,
  filter,
  finalize,
  from,
  map,
  of,
  startWith,
  switchMap,
  take,
} from 'rxjs';
import { ConnectCommand } from '../../../../connect/connect.models';
import { ConnectService } from '../../../../connect/connect.service';
import { ErrorState } from '../../../../error-handling/error.state';
import { ScandataModel, UpdateScandataModel } from '../../../../scandata/scandata.models';
import { ScandataService } from '../../../../scandata/scandata.service';
import { MAX_TAG_LENGTH, TagService } from '../../../../tag/tag.service';
import { NotWhitespaceStringValidator } from '../../../../utils/not-whitespace-string-validator';

@UntilDestroy()
@Component({
  selector: 'sd-tagging',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ModusAutocompleteModule, ModusIconModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './tagging.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaggingComponent implements OnInit, OnChanges, AfterViewInit {
  @Select(ErrorState.hasError('scanDetailsSaveError')) scanDetailsSaveError$!: Observable<boolean>;

  @Input() scandataModel!: ScandataModel;
  @Output() saving = new EventEmitter<boolean>();

  @ViewChild('autocomplete') autocomplete!: ModusAutocomplete<string>;

  filteredTags$!: Observable<string[]>;
  selectedTags$ = new BehaviorSubject<string[]>([]);
  isSaving$ = new BehaviorSubject(false);
  errorText$!: Observable<string>;

  tagSelector = new FormControl<string | null>(null, {
    validators: [NotWhitespaceStringValidator, Validators.maxLength(MAX_TAG_LENGTH)],
    updateOn: 'change',
  });

  private tags$!: Observable<string[]>;

  constructor(
    private tagService: TagService,
    private scandataService: ScandataService,
    private connectService: ConnectService,
  ) {}

  ngOnInit() {
    this.tags$ = this.getProjectTags();
    this.filteredTags$ = this.getFilteredTags();

    this.errorText$ = this.tagSelector.statusChanges.pipe(
      map(() => {
        return this.tagSelector.hasError('maxlength')
          ? `Maximum ${MAX_TAG_LENGTH} characters allowed.`
          : '';
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges & SimpleChangesTyped<TaggingComponent>): void {
    const change = changes.scandataModel;
    if (change && change.currentValue) {
      this.setSelectedTags();
      this.setFormSaving(false);
    }
  }

  ngAfterViewInit(): void {
    this.registerAutoCompleteHandler();
  }

  registerAutoCompleteHandler() {
    if (isNil(this.autocomplete)) return;

    this.autocomplete.inputKeydown$
      .pipe(
        filter((event) => event.key === 'Enter'),
        filter(() => {
          const listSelection = isDefined(this.autocomplete.activeOption);
          if (listSelection) return false;

          const inputInvalid = this.tagSelector.pristine || this.tagSelector.invalid;
          if (inputInvalid) return false;

          const value = this.tagSelector.getRawValue() as string;
          const duplicateInput = this.selectedTags$.value.find(
            (tag) => tag.toLowerCase() === value.toLowerCase(),
          )
            ? true
            : false;
          if (duplicateInput) return false;

          return true;
        }),
        switchMap(() => this.tags$.pipe(take(1))),
        map((tags) => {
          const value = this.tagSelector.getRawValue() as string;
          const tag = tags.find((tag) => tag.toLowerCase() === value.toLowerCase()) ?? value;

          return tag;
        }),
        untilDestroyed(this),
      )
      .subscribe((tag) => {
        this.selected(tag);
        this.autocomplete.closePanel();
      });
  }

  remove(tag: string): void {
    const selectedTags = [...this.selectedTags$.value];
    const index = selectedTags.indexOf(tag);

    if (index >= 0) {
      selectedTags.splice(index, 1);
      this.selectedTags$.next(selectedTags);

      this.tagSelector.setValue(null);
      this.saveScandataModel();
    }
  }

  selected(tag: string): void {
    if (this.tagSelector.invalid || isNil(tag)) return;

    const selectedTags = [...this.selectedTags$.value];
    selectedTags.push(tag);
    this.selectedTags$.next(selectedTags);

    this.tagSelector.reset();
    this.saveScandataModel();
  }

  private setFormSaving(isSaving: boolean) {
    if (isSaving) {
      this.tagSelector.disable({ emitEvent: false });
    } else {
      this.tagSelector.enable({ emitEvent: false });
    }

    this.isSaving$.next(isSaving);
    this.saving.emit(isSaving);
  }

  private saveScandataModel() {
    this.selectedTags$
      .pipe(
        take(1),
        switchMap((selectedTags) => {
          this.setFormSaving(true);

          const pointcloudId = this.scandataModel.id;
          const updateScandataModel: UpdateScandataModel = {
            tags: selectedTags,
          };

          return this.scandataService.updateScandataModel(pointcloudId, updateScandataModel);
        }),
        finalize(() => this.setFormSaving(false)),
      )
      .subscribe({
        error: () => {
          this.setSelectedTags();
        },
      });
  }

  private setSelectedTags() {
    const modelTags = this.scandataModel?.tags ? [...this.scandataModel.tags] : [];
    this.selectedTags$.next(modelTags);
  }

  private getProjectTags() {
    const nothing = '';

    return from(this.connectService.getWorkspace()).pipe(
      switchMap((ws) => {
        return isDefined(ws)
          ? ws.command$.pipe(
              filter((event) => event.data === ConnectCommand.ScandataBrowser),
              map(() => nothing),
              startWith(nothing),
            )
          : of(nothing);
      }),
      switchMap(() => this.tagService.getTags()),
    );
  }

  private getFilteredTags() {
    return this.tagSelector.valueChanges.pipe(
      startWith(null),
      combineLatestWith(this.tags$, this.selectedTags$),
      map(([filter, tags, selectedTags]) => {
        const availableTags = tags.filter((tag) => !selectedTags.includes(tag));
        if (isNil(filter)) return availableTags;

        const filterValue = filter.toLowerCase();
        return availableTags.filter((tag) => tag.toLowerCase().includes(filterValue));
      }),
    );
  }
}
