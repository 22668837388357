import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetActiveMapTool, SetBounds, SetMapFilterOption } from './map.actions';
import { DEFAULT_BBOX, DEFAULT_BOUNDS_PADDING, MapBounds, MapFilterOption } from './map.models';

export enum MapTool {
  Default = 'Default',
  RectangleSelect = 'RectangleSelect',
  PolygonSelect = 'PolygonSelect',
}

export interface MapStateModel {
  filterOption: MapFilterOption;
  activeMapTool: MapTool;
  bounds: MapBounds;
}

const defaultState: MapStateModel = {
  filterOption: MapFilterOption.LocatedOnly,
  activeMapTool: MapTool.Default,
  bounds: { bbox: DEFAULT_BBOX, padding: DEFAULT_BOUNDS_PADDING },
};

@State<MapStateModel>({
  name: 'MapState',
  defaults: defaultState,
})
@Injectable()
export class MapState {
  @Selector()
  static filterOption(state: MapStateModel) {
    return state.filterOption;
  }

  @Selector()
  static activeMapTool(state: MapStateModel) {
    return state.activeMapTool;
  }

  @Selector()
  static bounds(state: MapStateModel) {
    return state.bounds;
  }

  @Action(SetMapFilterOption)
  setMapFilterOption(ctx: StateContext<MapStateModel>, { filterOption }: SetMapFilterOption) {
    ctx.patchState({ filterOption: filterOption });
  }

  @Action(SetActiveMapTool)
  setActiveMapTool(ctx: StateContext<MapStateModel>, { mapTool }: SetActiveMapTool) {
    ctx.patchState({ activeMapTool: mapTool });
  }

  @Action(SetBounds)
  setBounds(ctx: StateContext<MapStateModel>, { bounds }: SetBounds) {
    ctx.patchState({ bounds });
  }
}
