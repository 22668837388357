import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Self,
  ViewChild,
  computed,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { ModusButtonModule } from '@trimble-gcs/modus';
import { Observable, combineLatest, filter, map, merge, of, switchMap, take } from 'rxjs';
import { ClassificationScheme } from '../../classification/classification-scheme.model';
import { ClassificationSchemeState } from '../../classification/classification-scheme.state';
import { ClassificationService } from '../../classification/classification.service';
import { CANCEL_BUTTON, DialogData } from '../../dialog/dialog.model';
import { DialogService } from '../../dialog/dialog.service';
import { ClearError } from '../../error-handling/error.actions';
import { ErrorState } from '../../error-handling/error.state';
import { LoadingService } from '../../loading/loading.service';
import { Logger, injectLogger } from '../../logging/logger';
import { downloadBlob } from '../../utils/download-blob';
import { ClassificationFormState } from './classification-form-state';
import { ClassificationTableComponent } from './classification-table/classification-table.component';

@UntilDestroy()
@Component({
  selector: 'sd-config-classification',
  standalone: true,
  imports: [CommonModule, ClassificationTableComponent, ModusButtonModule, MatProgressBarModule],
  providers: [ClassificationFormState],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './classification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClassificationComponent implements OnInit {
  @Select(ErrorState.hasError('classificationLoadError'))
  private classificationLoadError$!: Observable<boolean>;

  @Select(ErrorState.hasError('classificationSaveError'))
  private classificationSaveError$!: Observable<boolean>;

  @Select(ErrorState.hasError('classificationResetError'))
  private classificationResetError$!: Observable<boolean>;

  @Select(ErrorState.hasError('classificationImportError'))
  private classificationImportError$!: Observable<boolean>;

  hasError$ = merge(
    this.classificationLoadError$,
    this.classificationSaveError$,
    this.classificationResetError$,
    this.classificationImportError$,
  );

  errorMessage$ = combineLatest([
    this.classificationLoadError$,
    this.classificationSaveError$,
    this.classificationResetError$,
    this.classificationImportError$,
  ]).pipe(
    map(([loadError, saveError, resetError, importError]) => {
      if (loadError) return 'Error loading classifications.';
      if (saveError) return 'Error saving classifications.';
      if (resetError) return 'Error resetting classifications.';
      if (importError) return 'Error importing classifications.';
      return '';
    }),
  );

  @ViewChild('classificationTable') private classificationTable!: ClassificationTableComponent;

  isLoading = toSignal(this.loadingService.isLoading$(this), { initialValue: false });
  disableSave = computed(() => this.isLoading() || !this.state.valid());

  classifications$!: Observable<ClassificationScheme[]>;

  private logger = injectLogger(Logger, 'ClassificationComponent');

  constructor(
    private loadingService: LoadingService,
    private classificationService: ClassificationService,
    private store: Store,
    private dialogService: DialogService,
    @Self() public state: ClassificationFormState,
  ) {}

  ngOnInit(): void {
    this.classifications$ = this.getClassificationSchemes();
  }

  reset() {
    const dialogData = new DialogData(
      'Reset Classifications',
      'Are you sure you want to reset classifications to the default?',
      { text: 'Reset', color: 'danger' },
      CANCEL_BUTTON,
    );

    this.dialogService
      .show(dialogData)
      .pipe(
        filter((confirmed) => confirmed),
        switchMap(() => {
          return this.loadingService.loadFrom<ClassificationScheme[]>(
            this.classificationService.resetClassificationSchemes(),
            this,
          );
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  export() {
    this.classifications$.pipe(take(1), untilDestroyed(this)).subscribe((classifications) => {
      const content = JSON.stringify(classifications, null, 2);
      const blob = new Blob([content], { type: 'application/json' });

      downloadBlob(blob, 'classifications.json');
    });
  }

  cancel() {
    this.classificationTable.resetUserInput();
  }

  save() {
    this.loadingService
      .loadFrom<ClassificationScheme[]>(
        this.classificationService.saveClassificationSchemes(this.state.value()),
        this,
      )
      .pipe(untilDestroyed(this))
      .subscribe({
        error: (err) => {
          this.logger.error('Save Error', {}, err);
        },
      });
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.item(0);
    input.value = '';
    if (file) this.importFile(file);
  }

  dismissAlert() {
    this.store.dispatch([
      new ClearError('classificationLoadError'),
      new ClearError('classificationSaveError'),
      new ClearError('classificationResetError'),
      new ClearError('classificationImportError'),
    ]);
  }

  private getClassificationSchemes(): Observable<ClassificationScheme[]> {
    return this.store.select(ClassificationSchemeState.classifications).pipe(
      switchMap((classifications) => {
        if (isDefined(classifications)) return of(classifications);

        return this.loadingService.loadFrom<ClassificationScheme[]>(
          this.classificationService.getClassificationSchemes(),
          this,
        );
      }),
      untilDestroyed(this),
    );
  }

  private importFile(file: File) {
    this.classificationService
      .readAndParseFile(file)
      .pipe(
        switchMap((classifications) => this.confirmImport(classifications)),
        switchMap((classifications) => {
          return this.loadingService.loadFrom<ClassificationScheme[]>(
            this.classificationService.saveClassificationSchemes(classifications),
            this,
          );
        }),
        untilDestroyed(this),
      )
      .subscribe({
        error: (err) => {
          this.logger.error('Import File Error', {}, err);
        },
      });
  }

  private confirmImport(classifications: ClassificationScheme[]) {
    const dialogData = new DialogData(
      'Import Classifications',
      `Are you sure you want to import ${classifications.length} classification${
        classifications.length > 1 ? 's' : ''
      }?`,
      { text: 'Import', color: 'primary' },
      CANCEL_BUTTON,
    );

    return this.dialogService.show(dialogData).pipe(
      filter((confirmed) => confirmed),
      map(() => classifications),
    );
  }
}
