import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModusMenu } from './modus-menu-component/modus-menu.component';
import { ModusMenuItem } from './modus-menu-item/modus-menu-item.component';
import { ModusMenuTrigger } from './modus-menu-trigger';

@NgModule({
  imports: [CommonModule],
  declarations: [ModusMenuTrigger, ModusMenu, ModusMenuItem],
  exports: [CommonModule, ModusMenuTrigger, ModusMenu, ModusMenuItem],
})
export class ModusMenuModule {}
