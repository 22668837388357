@if (isLoading()) {
  <div class="sticky top-0 left-0 right-0 z-[200]">
    <div class="w-full absolute">
      <mat-progress-bar class="mds-x-small" [mode]="'indeterminate'"></mat-progress-bar>
    </div>
  </div>
}

<div class="h-full flex flex-col items-center justify-center">
  @switch (currentView()) {
    @case (view.LoadLicense) {
      <span class="mt-4 text-gray-8">Loading license...</span>
    }
    @case (view.NoLicense) {
      <img src="../../../assets/no-license.png" class="w-[300px]" />
      <div class="text-center text-sm h-36">
        <div class="text-lg font-semibold mt-8 mb-2">No Active License Found</div>
        <div>There is currently no active license for this project.</div>
      </div>
    }
    @case (view.AccountNotAllowed) {
      <img src="../../../assets/no-license.png" class="w-[300px]" />
      <div class="text-center text-sm h-36">
        <div class="text-lg font-semibold mt-8 mb-2">Account Not Allowed</div>
        <div>The project uses an account type that is not allowed.</div>
      </div>
    }
    @case (view.UnknownError) {
      <img src="../../../assets/no-license.png" class="w-[300px]" />
      <div class="text-center text-sm h-36">
        <div class="text-lg font-semibold mt-8 mb-2">Error Loading License</div>
        <div>There was an error loading your license.</div>

        <button
          id="license-error-retry"
          class="mt-4"
          modus-flat-button
          [disabled]="isLoading()"
          (click)="reloadLicense()"
        >
          Retry
        </button>
      </div>
    }
  }
</div>
