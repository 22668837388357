import { HttpErrorResponse } from '@angular/common/http';

export type ApiErrorType =
  | 'ACCOUNT_NOT_ALLOWED'
  | 'INSUFFICIENT_PRIVILEGE'
  | 'MISSING_LICENSE'
  | 'QUOTA_EXCEEDED'
  | 'RESERVED_FOR_ADMIN'
  | 'RESERVED_FOR_SUPER_USER';

export function isApiErrorType(err: HttpErrorResponse, errorReason: ApiErrorType) {
  return err.error?.reasonCode === errorReason;
}
