import { LoggerBase, compareLevel } from './logger';
import { LogLevel, LoggerType } from './logger-types';

type LogFn = (message: string, context?: object, error?: Error) => void;

export class ConsoleLogger extends LoggerBase {
  override get type(): LoggerType {
    return 'consoleLogger';
  }

  constructor(name = 'ConsoleLogger') {
    super(name);
  }

  createLogger(name: string) {
    return new ConsoleLogger(name);
  }

  log(message: string, context?: object, level?: LogLevel, error?: Error): void {
    if (level === LogLevel.None) return;

    switch (level) {
      case 'info':
        return this.info(message, context, error);

      case 'warn':
        return this.warn(message, context, error);

      case 'error':
        return this.error(message, context, error);

      default:
        return this.debug(message, context, error);
    }
  }

  debug(message: string, context?: object, error?: Error): void {
    if (compareLevel(this.level, LogLevel.Debug) >= 0) {
      this.executeLogFn(console.debug, message, context, error);
    }
  }

  info(message: string, context?: object, error?: Error): void {
    if (compareLevel(this.level, LogLevel.Info) >= 0) {
      this.executeLogFn(console.info, message, context, error);
    }
  }

  warn(message: string, context?: object, error?: Error): void {
    if (compareLevel(this.level, LogLevel.Warn) >= 0) {
      this.executeLogFn(console.warn, message, context, error);
    }
  }

  error(message: string, context?: object, error?: Error): void {
    if (compareLevel(this.level, LogLevel.Error) >= 0) {
      this.executeLogFn(console.error, message, context, error);
    }
  }

  private executeLogFn(logFn: LogFn, message: string, context?: object, error?: Error) {
    if (this.level === LogLevel.None) return;

    if (context && error) return logFn(message, context, error);
    if (context) return logFn(message, context);
    if (error) return logFn(message, error);
    logFn(message);
  }
}
