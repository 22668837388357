import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, map, of, switchMap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { GET_SCAN_PROJECT_URL } from '../utils/get-scan-project-url';
import { CacheStations } from './station.actions';
import { Station } from './station.models';
import { StationState } from './station.state';

@Injectable({
  providedIn: 'root',
})
export class StationService {
  private readonly getScanProjectUrl$ = inject(GET_SCAN_PROJECT_URL);

  constructor(
    private store: Store,
    private http: HttpClient,
  ) {}

  getStations(pointcloudId: string): Observable<Station[]> {
    return this.store
      .selectOnce(StationState.getStations(pointcloudId))
      .pipe(
        switchMap((stations) =>
          stations.length > 0 ? of(stations) : this.fetchAndCacheStations(pointcloudId),
        ),
      );
  }

  private fetchAndCacheStations(pointcloudId: string) {
    return this.getScanProjectUrl$(`/pointclouds/${pointcloudId}/stations`).pipe(
      switchMap((url) => {
        return this.http.get<Station[]>(url);
      }),
      map((stations) => stations.map((station) => this.updateStationForWeb3d(station))),
      map((stations) =>
        stations.map((station) => this.updateStationPointcloudId(pointcloudId, station)),
      ),
      switchMap((stations) => {
        return this.store
          .dispatch(new CacheStations(pointcloudId, stations))
          .pipe(map(() => stations));
      }),
    );
  }

  private updateStationForWeb3d(station: Station) {
    station.iconId = crypto.getRandomValues(new Uint32Array(1))[0];
    station.web3dId = uuidv4();
    return station;
  }

  private updateStationPointcloudId(pointcloudId: string, station: Station) {
    station.pointcloudId = pointcloudId;
    return station;
  }
}
