<!-- error alert -->
@if (scanDetailsSaveError$ | async) {
  <div class="w-full mb-4">
    <modus-alert
      class="w-full"
      message="Error saving changes. Please try again later."
      type="error"
    ></modus-alert>
  </div>
}

<div [formGroup]="formGroup" class="flex flex-col">
  <sd-label-value label="Status" value="{{ scandataModel.pointcloudStatus }}"></sd-label-value>

  @if (readonly) {
    <sd-label-value
      label="Capture Date"
      value="{{ scandataModel.captureDatetimeUtc | date: 'mediumDate' | dashedIfEmpty }}"
    ></sd-label-value>
  } @else {
    <modus-date-picker label="Capture Date" class="-mt-1 mb-4">
      <modus-date-input
        type="single"
        format="yyyy-mm-dd"
        allowed-chars-regex="[\d-]"
        show-calendar-icon="true"
        [max]="maxCaptureDate"
        [formControl]="formGroup.controls.captureDate"
        (dateInputBlur)="saveChange(formGroup.controls.captureDate)"
      ></modus-date-input>
    </modus-date-picker>
  }

  <sd-label-value label="Uploaded By" value="{{ scandataModel.uploadedBy }}"></sd-label-value>

  <sd-label-value
    label="Upload Date"
    value="{{ scandataModel.uploadedDate | date: 'medium' }}"
  ></sd-label-value>

  @if (readonly) {
    <sd-label-value
      label="Scanner Type"
      value="{{ scandataModel.scannerType | dashedIfEmpty }}"
    ></sd-label-value>
  } @else {
    <modus-form-field class="mb-2">
      <modus-label>Scanner Type</modus-label>
      <input
        modus-input
        [formControl]="formGroup.controls.scannerType"
        (blur)="saveChange(formGroup.controls.scannerType)"
        (keydown.enter)="saveChange(formGroup.controls.scannerType)"
      />
    </modus-form-field>
  }

  <sd-label-value
    label="# of Stations"
    value="{{ scandataModel.numberOfStations }}"
  ></sd-label-value>

  @if (showName) {
    @if (readonly) {
      <sd-label-value label="Point Cloud Name" value="{{ scandataModel.name }}"></sd-label-value>
    } @else {
      <modus-form-field class="mb-2">
        <modus-label>Point Cloud Name</modus-label>
        <input
          modus-input
          [formControl]="formGroup.controls.name"
          (blur)="saveChange(formGroup.controls.name)"
          (keydown.enter)="saveChange(formGroup.controls.name)"
        />
      </modus-form-field>
    }
  }

  <sd-label-value
    label="Point Count"
    value="{{ scandataModel.pointCount | pointCount: 3 | dashedIfEmpty }}"
  ></sd-label-value>

  <sd-label-value
    label="Classified"
    value="{{ scandataModel.isClassified ? 'Yes' : 'No' }}"
  ></sd-label-value>

  <sd-label-value
    label="Point Cloud Area"
    value="{{ scandataModel.area ?? 0 | area | dashedIfEmpty }}"
  ></sd-label-value>

  <sd-label-value
    label="Point Cloud Location"
    value="{{ getLocationSummary(scandataModel) | dashedIfEmpty }}"
  ></sd-label-value>

  <sd-label-value
    label="Size"
    value="{{ scandataModel.fileSize | fileSize: 1 | dashedIfEmpty }}"
  ></sd-label-value>

  @if (readonly) {
    <sd-label-value
      label="Notes"
      value="{{ scandataModel.notes | dashedIfEmpty }}"
    ></sd-label-value>
  } @else {
    <modus-form-field class="mb-2">
      <modus-label>Notes</modus-label>
      <textarea
        modus-input
        [formControl]="formGroup.controls.notes"
        (blur)="saveChange(formGroup.controls.notes)"
      ></textarea>
    </modus-form-field>
  }

  @if (showTags) {
    <div>
      <modus-label class="text-xs">Tags</modus-label>
      <div class="flex justify-start flex-wrap">
        @for (tag of scandataModel.tags; track tag) {
          <modus-chip class="m-1" [value]="tag"></modus-chip>
        }
      </div>
    </div>
  }
</div>
