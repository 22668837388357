export interface FeatureLayer {
  featureCollection: GeoJSON.FeatureCollection;
}

export interface FeatureStyle {
  color: string;
  fillOpacity: number;
}

export const NORMAL_STYLE: FeatureStyle = { color: '#0063a3', fillOpacity: 0.3 };
export const SELECTED_STYLE: FeatureStyle = { color: '#fbad26', fillOpacity: 0.7 };

export const DISABLED_COLOR = '#d0d0d0';
