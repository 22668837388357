import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModusButtonModule } from '@trimble-gcs/modus';
import { Observable, Subscription, delay, of } from 'rxjs';
import { LoadingBarDirective } from '../loading-bar/loading-bar.directive';
import { LoadingSpinnerDirective } from '../loading-spinner/loading-spinner.directive';
import { LoadingService } from '../loading.service';

@Component({
  standalone: true,
  imports: [CommonModule, LoadingBarDirective, LoadingSpinnerDirective, ModusButtonModule],
  templateUrl: './loading-demo.component.html',
  styleUrls: ['./loading-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingDemoComponent {
  loadingPrimary$: Observable<boolean>;
  loadingAccent$: Observable<boolean>;
  loadingWarn$: Observable<boolean>;

  private accentSubscription: Subscription = Subscription.EMPTY;
  private warnSubscription: Subscription = Subscription.EMPTY;

  constructor(private loadingService: LoadingService) {
    this.loadingPrimary$ = loadingService.isLoading$(this);
    this.loadingAccent$ = loadingService.isLoading$(this, 'accent');
    this.loadingWarn$ = loadingService.isLoading$(this, 'warn');
  }

  loadingPrimary = () => this.loadingService.isLoading(this);

  toggleAccent() {
    if (this.accentSubscription.closed) {
      this.accentSubscription = this.loadingService
        .loadFrom(of(true).pipe(delay(1 * 60 * 1000)), this, 'accent')
        .subscribe();
    } else {
      this.accentSubscription.unsubscribe();
    }
  }

  toggleWarn() {
    if (this.warnSubscription.closed) {
      this.warnSubscription = this.loadingService
        .loadFrom(of(true).pipe(delay(1 * 60 * 1000)), this, 'warn')
        .subscribe();
    } else {
      this.warnSubscription.unsubscribe();
    }
  }
}
