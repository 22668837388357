import { Observable, Subscriber } from 'rxjs';
import { isDefined } from '../utils';
import {
  RxCollectionChange,
  RxCollectionCopyWithin,
  RxCollectionFill,
  RxCollectionPop,
  RxCollectionPull,
  RxCollectionPush,
  RxCollectionSet,
  RxCollectionShift,
  RxCollectionSplice,
  RxCollectionUnshift,
} from './rx-collection-models';

interface ConcatRxCollection<T> {
  readonly length: number;
  join(separator?: string): string;
  slice(start?: number, end?: number): RxCollection<T>;
}

/**
 * An array-like collection that is reactive.
 * It emits changes every time the collection is mutated.
 *
 * It implements all the properties and methods of `array`, up to the ES2023 spec.
 */
export class RxCollection<T> extends Observable<RxCollectionChange> {
  private data: Array<T>;
  private subscriber!: Subscriber<RxCollectionChange>;

  constructor(...args: [length?: number] | [length: number] | [...items: T[]]) {
    super((subscriber: Subscriber<RxCollectionChange>) => {
      this.subscriber = subscriber;
    });

    if (args.length === 0) {
      this.data = new Array<T>();
    } else if (args.length === 1) {
      const arg = args[0];
      this.data = typeof arg === 'number' ? new Array<T>(arg as number) : new Array<T>(arg as T);
    } else {
      const items: T[] = args as T[];
      this.data = new Array<T>(...items);
    }
  }

  // #region ES5

  /**
   * Gets the length of the collection.
   */
  get length(): number {
    return this.data.length;
  }

  /**
   * Set the length of the collection.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/length
   */
  set length(value: number) {
    this.data.length = value;
  }

  /**
   * Returns a string representation of a collection.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/toString
   */
  override toString(): string {
    return this.data.toString();
  }

  /**
   * Returns a string representation of a collection.
   * The elements are converted to string using their toLocaleString methods.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/toLocaleString
   */
  override toLocaleString(): string {
    return this.data.toLocaleString();
  }

  /**
   * Removes the last element from a collection and returns it.
   * If the collection is empty, undefined is returned and the collection is not modified.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/pop
   */
  pop(): T | undefined {
    const item = this.data.pop();
    if (isDefined(item)) {
      this.subscriber?.next(new RxCollectionPop(item));
    }
    return item;
  }

  /**
   * Appends new elements to the end of a collection,
   * and returns the new length of the collection.
   * @param items New elements to add to the collection.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/push
   */
  push(...items: T[]): number {
    const length = this.data.push(...items);
    this.subscriber?.next(new RxCollectionPush(length, items));
    return length;
  }

  /**
   * Combines two or more collections.
   * This method returns a new collection without modifying any existing collections.
   * @param items Additional collections and/or items to add to the end of the collection.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/concat
   */
  concat(...items: ConcatRxCollection<T>[]): RxCollection<T>;
  concat(...items: (T | ConcatRxCollection<T>)[]): RxCollection<T>;
  concat(...items: ConcatRxCollection<T>[] | (T | ConcatRxCollection<T>)[]): RxCollection<T> {
    const combined: T[] = [...this.data];

    for (let i = 0; i < items.length; i++) {
      const element = items[i];
      if (RxCollection.isRxCollection(element)) {
        combined.push(...element.data);
      } else {
        combined.push(element as T);
      }
    }
    return RxCollection.compose(...combined);
  }

  /**
   * Adds all the elements of a collection into a string,
   * separated by the specified separator string.
   * @param separator A string used to separate one element of the collection from the next
   * in the resulting string. If omitted, the collection elements are separated with a comma.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/join
   */
  join(separator?: string): string {
    return this.data.join(separator);
  }

  /**
   * Reverses the elements in a collection in place.
   * This method mutates the collection and returns a reference to the same collection.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/reverse
   */
  reverse(): RxCollection<T> {
    this.data.reverse();
    this.subscriber?.next(RxCollectionChange.reverse());
    return this;
  }

  /**
   * Removes the first element from a collection and returns it.
   * If the collection is empty, undefined is returned and the collection is not modified.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/shift
   */
  shift(): T | undefined {
    const item = this.data.shift();
    if (isDefined(item)) {
      this.subscriber?.next(new RxCollectionShift(item));
    }
    return item;
  }

  /**
   * Returns a copy of a section of a collection.
   * For both start and end, a negative index can be used to indicate an offset
   * from the end of the collection.
   * For example, -2 refers to the second to last element of the collection.
   *
   * @param start The beginning index of the specified portion of the collection.
   * If start is undefined, then the slice begins at index 0.
   *
   * @param end The end index of the specified portion of the collection.
   * This is exclusive of the element at the index 'end'.
   * If end is undefined, then the slice extends to the end of the collection.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/slice
   */
  slice(start?: number, end?: number): RxCollection<T> {
    const items = this.data.slice(start, end);
    return RxCollection.compose(...items);
  }

  /**
   * Sorts a collection in place.
   * This method mutates the collection and returns a reference to the same collection.
   *
   * @param compareFn Function used to determine the order of the elements.
   * It is expected to return a negative value if the first argument is less than the second argument,
   * zero if they're equal, and a positive value otherwise.
   * If omitted, the elements are sorted in ascending, ASCII character order.
   *
   * ```ts
   * RxCollection.sort((a, b) => a - b)
   * ```
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
   */
  sort(compareFn?: (a: T, b: T) => number): this {
    this.data.sort(compareFn);
    this.subscriber?.next(RxCollectionChange.sort());
    return this;
  }

  /**
   * Removes elements from a collection and, if necessary, inserts new elements in their place,
   * returning the deleted elements.
   * @param start The zero-based location in the collection from which to start removing elements.
   * @param deleteCount The number of elements to remove.
   * @param items Elements to insert into the collection in place of the deleted elements.
   * @returns A collection containing the elements that were deleted.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/splice
   */
  splice(start: number, deleteCount?: number): RxCollection<T>;
  splice(start: number, deleteCount: number, ...items: T[]): RxCollection<T>;
  splice(start: number, deleteCount?: number, ...items: T[]): RxCollection<T> {
    const deleted =
      deleteCount === undefined
        ? items.length === 0
          ? this.data.splice(start)
          : this.data.splice(start, 0, ...items)
        : this.data.splice(start, deleteCount, ...items);

    this.subscriber?.next(new RxCollectionSplice(start, deleteCount, items, deleted));
    return RxCollection.compose(...deleted);
  }

  /**
   * Inserts new elements at the start of a collection, and returns the new length of the collection.
   * @param items Elements to insert at the start of the collection.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/unshift
   */
  unshift(...items: T[]): number {
    const length = this.data.unshift(...items);
    this.subscriber?.next(new RxCollectionUnshift(length, items));
    return length;
  }

  /**
   * Returns the index of the first occurrence of a value in a collection,
   * or -1 if it is not present.
   * @param searchElement The value to locate in the collection.
   * @param fromIndex The collection index at which to begin the search.
   * If fromIndex is omitted, the search starts at index 0.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/indexOf
   */
  indexOf(searchElement: T, fromIndex?: number): number {
    return this.data.indexOf(searchElement, fromIndex);
  }

  /**
   * Returns the index of the last occurrence of a specified value in a collection,
   * or -1 if it is not present.
   * @param searchElement The value to locate in the collection.
   * @param fromIndex The collection index at which to begin searching backward.
   * If fromIndex is omitted, the search starts at the last index in the collection.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/lastIndexOf
   */
  lastIndexOf(searchElement: T, fromIndex?: number): number {
    const start = fromIndex ?? this.data.length - 1;
    return this.data.lastIndexOf(searchElement, start);
  }

  /**
   * Determines whether all the members of a collection satisfy the specified test.
   * @param predicate A function that accepts up to three arguments. The every method calls
   * the predicate function for each element in the collection until the predicate returns a value
   * which is coercible to the Boolean value false, or until the end of the collection.
   * @param thisArg An object to which the this keyword can refer in the predicate function.
   * If thisArg is omitted, undefined is used as the this value.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/every
   */
  // prettier-ignore
  every<S extends T>(predicate: (value: T, index: number, array: T[]) => value is S, thisArg?: unknown): this is RxCollection<T>;
  every(predicate: (value: T, index: number, array: T[]) => unknown, thisArg?: unknown): boolean;
  every(predicate: (value: T, index: number, array: T[]) => unknown, thisArg?: unknown): boolean {
    return this.data.every(predicate, thisArg);
  }

  /**
   * Determines whether the specified callback function returns true for any element of a collection.
   * @param predicate A function that accepts up to three arguments. The some method calls
   * the predicate function for each element in the collection until the predicate returns a value
   * which is coercible to the Boolean value true, or until the end of the collection.
   * @param thisArg An object to which the this keyword can refer in the predicate function.
   * If thisArg is omitted, undefined is used as the this value.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/some
   */
  some(predicate: (value: T, index: number, array: T[]) => unknown, thisArg?: unknown): boolean {
    return this.data.some(predicate, thisArg);
  }

  /**
   * Performs the specified action for each element in a collection.
   * @param callbackfn  A function that accepts up to three arguments.
   * forEach calls the callbackfn function one time for each element in the collection.
   * @param thisArg  An object to which the this keyword can refer in the callbackfn function.
   * If thisArg is omitted, undefined is used as the this value.
   *
   * @description
   * This implementation of `array.forEach` is named `forEachItem`,
   * as `forEach` is inherited from `Observable`.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/forEach
   */
  forEachItem(callbackfn: (value: T, index: number, array: T[]) => void, thisArg?: unknown): void {
    this.data.forEach((value: T, index: number, array: T[]) => {
      callbackfn(value, index, array);
    }, thisArg);
  }

  /**
   * Calls a defined callback function on each element of a collection,
   * and returns a collection that contains the results.
   * @param callbackfn A function that accepts up to three arguments.
   * The map method calls the callbackfn function one time for each element in the collection.
   * @param thisArg An object to which the this keyword can refer in the callbackfn function.
   * If thisArg is omitted, undefined is used as the this value.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/map
   */
  // prettier-ignore
  map<U>(callbackfn: (value: T, index: number, array: T[]) => U, thisArg?: unknown): RxCollection<U> {
    const items = this.data.map(callbackfn, thisArg);
    return RxCollection.compose(...items);
  }

  /**
   * Returns the elements of a collection that meet the condition specified in a callback function.
   * @param predicate A function that accepts up to three arguments.
   * The filter method calls the predicate function one time for each element in the collection.
   * @param thisArg An object to which the this keyword can refer in the predicate function.
   * If thisArg is omitted, undefined is used as the this value.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/filter
   */
  // prettier-ignore
  filter<S extends T>(predicate: (value: T, index: number, array: T[]) => value is S, thisArg?: unknown): RxCollection<S>;
  // prettier-ignore
  filter(predicate: (value: T, index: number, array: T[]) => unknown, thisArg?: unknown): RxCollection<T>;
  // prettier-ignore
  filter(predicate: (value: T, index: number, array: T[]) => unknown, thisArg?: unknown): RxCollection<T> {
    const filtered = this.data.filter(predicate, thisArg);
    return RxCollection.compose(...filtered);
  }

  /**
   * Calls the specified callback function for all the elements in a collection.
   * The return value of the callback function is the accumulated result,
   * and is provided as an argument in the next call to the callback function.
   * @param callbackfn A function that accepts up to four arguments.
   * The reduce method calls the callbackfn function one time for each element in the collection.
   * @param initialValue If initialValue is specified, it is used as the initial value to start the accumulation.
   * The first call to the callbackfn function provides this value as an argument instead of a collection value.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/reduce
   */
  // prettier-ignore
  reduce(callbackfn: (previousValue: T, currentValue: T, currentIndex: number, array: T[]) => T): T;
  // prettier-ignore
  reduce(callbackfn: (previousValue: T, currentValue: T, currentIndex: number, array: T[]) => T, initialValue: T): T;
  // prettier-ignore
  reduce<U>(callbackfn: (previousValue: U, currentValue: T, currentIndex: number, array: T[]) => U, initialValue: U): U;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reduce<U>(callbackfn: never, initialValue?: any): T | U {
    return this.data.reduce(callbackfn, initialValue);
  }

  /**
   * Calls the specified callback function for all the elements in a collection, in descending order.
   * The return value of the callback function is the accumulated result,
   * and is provided as an argument in the next call to the callback function.
   * @param callbackfn A function that accepts up to four arguments.
   * The reduceRight method calls the callbackfn function one time for each element in the collection.
   * @param initialValue If initialValue is specified, it is used as the initial value to start the accumulation.
   * The first call to the callbackfn function provides this value as an argument instead of a collection value.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/reduceRight
   */
  // prettier-ignore
  reduceRight(callbackfn: (previousValue: T, currentValue: T, currentIndex: number, array: T[]) => T): T;
  // prettier-ignore
  reduceRight(callbackfn: (previousValue: T, currentValue: T, currentIndex: number, array: T[]) => T, initialValue: T): T;
  // prettier-ignore
  reduceRight<U>(callbackfn: (previousValue: U, currentValue: T, currentIndex: number, array: T[]) => U, initialValue: U): U;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reduceRight<U>(callbackfn: never, initialValue?: any): T | U {
    return this.data.reduceRight(callbackfn, initialValue);
  }

  static isRxCollection(arg: unknown): arg is RxCollection<never> {
    const value = arg as RxCollection<never>;
    return value instanceof RxCollection && value.data && Array.isArray(value.data);
  }

  // #endregion ES5

  // #region ES2015 core

  /**
   * Returns the value of the first element in the collection where predicate is true,
   * and undefined otherwise.
   * @param predicate find calls predicate once for each element of the collection,
   * in ascending order, until it finds one where predicate returns true.
   * If such an element is found, find immediately returns that element value.
   * Otherwise, find returns undefined.
   * @param thisArg If provided, it will be used as the this value for each invocation of predicate.
   * If it is not provided, undefined is used instead.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/find
   */
  // prettier-ignore
  find<S extends T>(predicate: (this: void, value: T, index: number, obj: T[]) => value is S, thisArg?: unknown): S | undefined;
  find(predicate: (value: T, index: number, obj: T[]) => unknown, thisArg?: unknown): T | undefined;
  // prettier-ignore
  find(predicate: (value: T, index: number, obj: T[]) => unknown, thisArg?: unknown): T | undefined {
    return this.data.find(predicate, thisArg);
  }

  /**
   * Returns the index of the first element in the collection where predicate is true,
   * and -1 otherwise.
   * @param predicate find calls predicate once for each element of the collection,
   * in ascending order, until it finds one where predicate returns true.
   * If such an element is found, findIndex immediately returns that element index.
   * Otherwise, findIndex returns -1.
   * @param thisArg If provided, it will be used as the this value for each invocation of predicate.
   * If it is not provided, undefined is used instead.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/findIndex
   */
  findIndex(predicate: (value: T, index: number, obj: T[]) => unknown, thisArg?: unknown): number {
    return this.data.findIndex(predicate, thisArg);
  }

  /**
   * Changes all collection elements from `start` to `end` index to a static `value`
   * and returns the modified collection.
   * @param value value to fill collection section with
   * @param start index to start filling the collection at.
   * If start is negative, it is treated as length+start where length is the length of the collection.
   * @param end index to stop filling the collection at.
   * If end is negative, it is treated as length+end.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/fill
   */
  fill(value: T, start?: number, end?: number): this {
    this.data.fill(value, start, end);
    this.subscriber?.next(new RxCollectionFill(value, start, end));
    return this;
  }

  /**
   * Returns the this object after copying a section of the collection
   * identified by start and end to the same collection starting at position target.
   * @param target If target is negative, it is treated as length+target where length is the
   * length of the collection.
   * @param start If start is negative, it is treated as length+start. If end is negative, it
   * is treated as length+end.
   * @param end If not specified, length of the this object is used as its default value.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/copyWithin
   */
  copyWithin(target: number, start: number, end?: number): this {
    this.data.copyWithin(target, start, end);
    this.subscriber?.next(new RxCollectionCopyWithin(target, start, end));
    return this;
  }

  /**
   * Creates a reactive collection from array-like or iterable object.
   * @param arrayLike An array-like object to convert to an RxCollection.
   * @param mapfn A mapping function to call on every element of the array-like.
   * @param thisArg Value of 'this' used to invoke the mapfn.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/from
   */
  // prettier-ignore
  static from<T, U = T>(arrayLike: ArrayLike<T>, mapfn?: (v: T, k: number) => U, thisArg?: unknown): RxCollection<T | U> {
    const items = typeof mapfn !== 'function'
    ? Array.from(arrayLike)
    : Array.from(arrayLike, mapfn, thisArg)
    return RxCollection.compose(...items);
  }

  /**
   * Returns a new reactive collection from a set of elements.
   * @param items A set of elements to include in the new collection object.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/of
   */
  static of<T>(...items: T[]): RxCollection<T> {
    return RxCollection.compose(...items);
  }

  // #endregion ES2015 core

  // #region ES2015 iterable

  /**
   * Iterator
   * @returns An iterator object that yields the value of each index in the collection.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/@@iterator
   */
  *[Symbol.iterator](): IterableIterator<T> {
    for (const iterator of this.data) {
      yield iterator as Readonly<T>;
    }
  }

  /**
   * Returns an iterator of key / value pairs for every entry in the collection.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/entries
   */
  entries(): IterableIterator<[number, T]> {
    return this.data.entries();
  }

  /**
   * Returns an iterator of the keys in the collection.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/keys
   */
  keys(): IterableIterator<number> {
    return this.data.keys();
  }

  /**
   * Returns an iterator of the values in the collection.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/values
   */
  values(): IterableIterator<T> {
    return this.data.values();
  }

  // #endregion ES2015 iterable

  // #region ES2016 includes()

  /**
   * Determines whether a collection includes a certain element, returning true or false as appropriate.
   * @param searchElement The element to search for.
   * @param fromIndex The position in this collection at which to begin searching for searchElement.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/includes
   */
  includes(searchElement: T, fromIndex?: number): boolean {
    return this.data.includes(searchElement, fromIndex);
  }

  // #endregion ES2016

  // #region ES2019 flatMap() & flat()

  /**
   * Calls a defined callback function on each element of a collection. Then, flattens the result into
   * a new collection.
   * This is identical to a map followed by flat with depth 1.
   *
   * @param callback A function that accepts up to three arguments. The flatMap method calls the
   * callback function one time for each element in the collection.
   * @param thisArg An object to which the this keyword can refer in the callback function. If
   * thisArg is omitted, undefined is used as the this value.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flatMap
   */
  // prettier-ignore
  flatMap<U, This = undefined>(callback: (this: This, value: T, index: number, array: T[]) => U | ReadonlyArray<U>, thisArg?: This): RxCollection<U> {
    const items = this.data.flatMap(callback, thisArg);
    return RxCollection.compose(...items);
  }

  /**
   * Returns a new collection with all sub-array elements concatenated into it recursively up to the
   * specified depth.
   *
   * @param depth The maximum recursion depth
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flat
   */
  flat<T>(depth = 1): RxCollection<T> {
    const items = <T[]>this.data.flat(depth);
    return RxCollection.compose(...items);
  }

  // #endregion ES2019

  // #region ES2022 at()

  /**
   * Returns the item located at the specified index.
   * @param index The zero-based index of the desired code unit.
   * A negative index will count back from the last item.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/at
   */
  at(index: number): T | undefined {
    return this.data.at(index);
  }

  // #endregion ES2022

  // #region ES2023

  /**
   * Returns the value of the last element in the collection where predicate is true, and undefined
   * otherwise.
   * @param predicate findLast calls predicate once for each element of the collection, in descending
   * order, until it finds one where predicate returns true. If such an element is found, findLast
   * immediately returns that element value. Otherwise, findLast returns undefined.
   * @param thisArg If provided, it will be used as the this value for each invocation of
   * predicate. If it is not provided, undefined is used instead.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/findLast
   */
  // prettier-ignore
  findLast<S extends T>(predicate: (value: T, index: number, array: T[]) => value is S, thisArg?: unknown): S | undefined;
  // prettier-ignore
  findLast(predicate: (value: T, index: number, array: T[]) => unknown, thisArg?: unknown): T | undefined;
  findLast<S extends T>(predicate: never, thisArg?: unknown): T | S | undefined {
    return this.data.findLast(predicate, thisArg);
  }

  /**
   * Returns the index of the last element in the collection where predicate is true, and -1
   * otherwise.
   * @param predicate findLastIndex calls predicate once for each element of the collection, in descending
   * order, until it finds one where predicate returns true. If such an element is found,
   * findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
   * @param thisArg If provided, it will be used as the this value for each invocation of
   * predicate. If it is not provided, undefined is used instead.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/findLastIndex
   */
  // prettier-ignore
  findLastIndex(predicate: (value: T, index: number, array: T[]) => unknown, thisArg?: unknown): number {
    return this.data.findLastIndex(predicate, thisArg);
  }

  /**
   * Returns a copy of a collection with its elements reversed.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/toReversed
   */
  toReversed(): RxCollection<T> {
    const reversed = this.data.toReversed();
    return RxCollection.compose(...reversed);
  }

  /**
   * Returns a copy of a collection with its elements sorted.
   * @param compareFn Function used to determine the order of the elements. It is expected to return
   * a negative value if the first argument is less than the second argument, zero if they're equal, and a positive
   * value otherwise. If omitted, the elements are sorted in ascending, ASCII character order.
   * ```ts
   * [11, 2, 22, 1].toSorted((a, b) => a - b) // [1, 2, 11, 22]
   * ```
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/toSorted
   */
  toSorted(compareFn?: (a: T, b: T) => number): RxCollection<T> {
    const sorted = this.data.toSorted(compareFn);
    return RxCollection.compose(...sorted);
  }

  /**
   * Copies a collection and removes elements and, if necessary, inserts new elements in their place. Returns the copied collection.
   * @param start The zero-based location in the collection from which to start removing elements.
   * @param deleteCount The number of elements to remove.
   * @param items Elements to insert into the copied collection in place of the deleted elements.
   * @returns The copied collection.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/toSpliced
   */
  toSpliced(start: number, deleteCount: number, ...items: T[]): RxCollection<T>;

  /**
   * Copies a collection and removes elements while returning the remaining elements.
   * @param start The zero-based location in the collection from which to start removing elements.
   * @param deleteCount The number of elements to remove.
   * @returns A copy of the original collection with the remaining elements.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/toSpliced
   */
  toSpliced(start: number, deleteCount?: number): RxCollection<T>;
  toSpliced(start: number, deleteCount = Infinity, ...items: T[]): RxCollection<T> {
    const spliced = this.data.toSpliced(start, deleteCount, ...items);
    return RxCollection.compose(...spliced);
  }

  /**
   * Copies a collection, then overwrites the value at the provided index with the
   * given value. If the index is negative, then it replaces from the end
   * of the collection.
   * @param index The index of the value to overwrite. If the index is
   * negative, then it replaces from the end of the collection.
   * @param value The value to write into the copied collection.
   * @returns The copied collection with the updated value.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/with
   */
  with(index: number, value: T): RxCollection<T> {
    const withValue = this.data.with(index, value);
    return RxCollection.compose(...withValue);
  }

  // #endregion ES2023

  /**
   * Gets the element at the given index in the collection.
   * @param index Index of the element to get.
   * @returns The element at the given index, or undefined.
   */
  get(index: number): T | undefined {
    return this.data[index];
  }

  /**
   * Sets the element at the given index in the collection.
   * @param index Index of the element to set.
   * @param item The item to set at the given index.
   */
  set(index: number, item: T) {
    const change = new RxCollectionSet(index, this.data[index], item);
    this.data[index] = item;
    this.subscriber?.next(change);
  }

  /**
   * Removes the element at the given index in the collection.
   * @param index Index of the element to get.
   * @returns The element at the given index, or undefined.
   */
  pull(index: number): T | undefined {
    const pulled = this.data.splice(index, 1);
    const item = pulled && pulled.length ? pulled[0] : undefined;

    if (isDefined(item)) {
      this.subscriber?.next(new RxCollectionPull(index, item));
    }

    return item;
  }

  /**
   * Composes a new reactive collection from a set of elements.
   * @param items A set of elements to include in the new collection object.
   */
  private static compose<T>(...items: T[]): RxCollection<T> {
    const collection = new RxCollection<T>();
    collection.data.push(...items);
    return collection;
  }
}
