import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  input,
  output,
  signal,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ScandataModel } from '../../../scandata/scandata.models';

import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { isDefined, isNil } from '@trimble-gcs/common';
import {
  ModusButtonModule,
  ModusIconModule,
  ModusSwitchModule,
  ModusTooltipModule,
} from '@trimble-gcs/modus';
import { ColorPickerModule } from 'ngx-color-picker';
import { ClassificationScheme } from '../../../classification/classification-scheme.model';
import { Classification } from '../../../classification/classification.model';
import { ClassificationService } from '../../../classification/classification.service';
import { ClassificationGraphComponent } from '../../../options-panel/selected-details/single-selected/classification/classification-graph/classification-graph.component';
import { PercentagePipe } from '../../../pipes/percentage.pipe';
import { colorHexStripAlpha } from '../../../utils/color-converter';
import { Scan3dStyle } from '../../models/scan-3d-style';

@UntilDestroy()
@Component({
  selector: 'sd-scan-3d-classification',
  standalone: true,
  imports: [
    CommonModule,
    ClassificationGraphComponent,
    ModusButtonModule,
    ModusIconModule,
    ModusTooltipModule,
    PercentagePipe,
    ReactiveFormsModule,
    ModusSwitchModule,
    ColorPickerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './scan-3d-classification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Scan3dClassificationComponent {
  scandataModel = input<ScandataModel>();
  scan3dStyle = input.required<Scan3dStyle>();

  styleChange = output<Scan3dStyle>();

  activeClassification = signal<Classification | null>(null);

  private projectClassificationSchemes = toSignal(
    this.classificationService.getClassificationSchemes(),
    { initialValue: [] },
  );

  classifications = computed(() => {
    const scandataModel = this.scandataModel();
    if (isNil(scandataModel)) return [];

    const scan3dStyle = this.scan3dStyle();
    const projectSchemes = this.projectClassificationSchemes();

    return this.getScanClassifications(scandataModel, scan3dStyle, projectSchemes);
  });

  hasClassifications = computed(() => this.classifications().length > 0);

  unallocatedClassificationCount = computed(() => {
    const style = this.scan3dStyle();
    return isDefined(style)
      ? style.classificationSchemes.length - this.classifications().length
      : 0;
  });

  showClassificationControl = new FormControl<boolean>(false, { nonNullable: true });
  private colorPickerOpen = false;

  constructor(private classificationService: ClassificationService) {
    this.createShowClassificationControlEffect();
    this.subscribeToFormChange();
  }

  changeColor(color: string, classification: Classification) {
    const scheme = classification.classificationScheme;
    scheme.rgba = color.replace('#', '').toUpperCase();

    const schemeChanges = this.getClassificationSchemes(scheme);
    this.updateModelStyle({ classificationSchemes: schemeChanges });
  }

  changeVisibility(classification: Classification) {
    const scheme = classification.classificationScheme;
    scheme.visible = !scheme.visible;

    const schemeChanges = this.getClassificationSchemes(scheme);
    this.updateModelStyle({ classificationSchemes: schemeChanges });
  }

  colorPickerToggle(open: boolean) {
    this.activeClassification.set(null);
    this.colorPickerOpen = open;
  }

  isActiveClassification(classification: Classification) {
    return classification === this.activeClassification();
  }

  setActiveClassification(classification: Classification | null = null) {
    if (this.colorPickerOpen) return;

    this.activeClassification.set(classification);
  }

  stripAlpha(rgba: string) {
    return colorHexStripAlpha(rgba);
  }

  private createShowClassificationControlEffect() {
    effect(() => {
      const scan3dStyle = this.scan3dStyle();
      this.showClassificationControl.setValue(scan3dStyle.showClassification, {
        emitEvent: false,
      });
    });
  }

  private getClassificationSchemes(classificationScheme: ClassificationScheme) {
    return this.classifications().map((classification) =>
      classification.classificationScheme.id === classificationScheme.id
        ? classificationScheme
        : classification.classificationScheme,
    );
  }

  private getScanClassifications(
    scandataModel: ScandataModel,
    scan3dStyle: Scan3dStyle,
    projectSchemes: ClassificationScheme[],
  ): Classification[] {
    const classifications = this.classificationService.getScanClassifications(
      scandataModel,
      projectSchemes,
    );

    const schemes = scan3dStyle.classificationSchemes;

    return classifications.map((classification) => {
      const classificationScheme =
        schemes.find((scheme) => scheme.id === classification.classificationScheme.id) ??
        classification.classificationScheme;

      return {
        ...classification,
        classificationScheme,
      };
    });
  }

  private subscribeToFormChange() {
    this.showClassificationControl.valueChanges.pipe(untilDestroyed(this)).subscribe((show) => {
      this.updateModelStyle({ showClassification: show });
    });
  }

  private updateModelStyle(style: Partial<Scan3dStyle>) {
    const mergedStyle: Scan3dStyle = {
      ...this.scan3dStyle(),
      ...style,
    };
    this.styleChange.emit(mergedStyle);
  }
}
