import { isNil } from '@trimble-gcs/common';

/** Default classification color if none is provided by scheme */
export const DEFAULT_CLASSIFICATION_COLOR = 'EBEBEBFF';

export interface ClassificationScheme {
  id: number;
  name: string;
  rgba: string;
  visible: boolean;
  predefined: boolean;
  isNew?: boolean;
}

export function createNewClassificationScheme(id: number): ClassificationScheme {
  return {
    id,
    name: 'not defined',
    rgba: DEFAULT_CLASSIFICATION_COLOR,
    visible: true,
    predefined: false,
    isNew: true,
  };
}

export function isClassificationSchemeArray(value: unknown): value is ClassificationScheme[] {
  return Array.isArray(value) && value.every((item) => isClassificationScheme(item));
}

export function isClassificationScheme(value: unknown): value is ClassificationScheme {
  if (isNil(value)) return false;
  const valid = ['id', 'name', 'rgba', 'visible'].every((property) =>
    Object.hasOwnProperty.call(value, property),
  );
  return valid;
}
