import { MapBounds, MapFilterOption } from './map.models';
import { MapTool } from './map.state';

export class SetMapFilterOption {
  static readonly type = '[Map] SetMapFilterOption';
  constructor(public filterOption: MapFilterOption) {}
}

export class SetActiveMapTool {
  static readonly type = '[Map] SetActiveMapTool';
  constructor(public mapTool: MapTool) {}
}

export class SetBounds {
  static readonly type = '[FeatureLayer] SetBounds';
  constructor(public bounds: MapBounds) {}
}
