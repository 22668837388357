import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { Feature } from 'geojson';
import { Observable, combineLatest, combineLatestWith, filter, map } from 'rxjs';
import { ErrorState } from '../error-handling/error.state';
import { Logger, injectLogger } from '../logging/logger';
import { ScandataEmptyReason } from '../scandata-list/scandata-empty/scandata-empty.component';
import {
  PatchScandataModel,
  SelectOnly,
  SelectScandataModel,
  UnselectAllScandataModels,
  UnselectScandataModel,
} from '../scandata/scandata.actions';
import { ScandataModel } from '../scandata/scandata.models';
import { ScandataState } from '../scandata/scandata.state';
import { SetActiveBaseLayer } from './base-layer/base-layer.actions';
import { BaseLayer } from './base-layer/base-layer.models';
import { BaseLayerService } from './base-layer/base-layer.service';
import { BaseLayerState } from './base-layer/base-layer.state';
import { FeatureLayer } from './feature-layer/feature-layer.models';
import { FeatureLayerService } from './feature-layer/feature-layer.service';
import { FeatureLayerState } from './feature-layer/feature-layer.state';
import { MapListComponent } from './map-list/map-list.component';
import { MapToolbarComponent } from './map-toolbar/map-toolbar.component';
import { FeatureClickedEvent, MapViewerComponent } from './map-viewer/map-viewer.component';
import { SetActiveMapTool, SetBounds, SetMapFilterOption } from './map.actions';
import { DEFAULT_BOUNDS_PADDING, MapBounds, MapFilterOption, MapScandataModel } from './map.models';
import { MapService } from './map.service';
import { MapState, MapTool } from './map.state';
import { calculateBounds } from './map.util';

@UntilDestroy()
@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MapViewerComponent, MapListComponent, MapToolbarComponent],
  templateUrl: './map.component.html',
  styles: [],
})
export class MapComponent {
  @ViewChild('mapViewer') mapViewer!: MapViewerComponent;

  @Select(ErrorState.hasError('scanLoadError')) scanLoadError$!: Observable<boolean>;
  @Select(MapState.bounds) bounds$!: Observable<MapBounds>;
  @Select(MapState.activeMapTool) activeMapTool$!: Observable<MapTool>;
  @Select(MapState.filterOption) filterOption$!: Observable<MapFilterOption>;
  @Select(BaseLayerState.activeBaseLayer) activeBaseLayer$!: Observable<BaseLayer | null>;

  @Select(ScandataState.isLoading) private scandataLoading$!: Observable<boolean>;
  @Select(FeatureLayerState.isLoading) private featuresLoading$!: Observable<boolean>;
  @Select(ScandataState.filterCount) private filterCount$!: Observable<number>;
  @Select(ScandataState.scandata) private scandata$!: Observable<ScandataModel[]>;
  @Select(ScandataState.textFilter) private textFilter$!: Observable<string | undefined>;

  mapScandata$: Observable<MapScandataModel[]>;
  featureLayer$: Observable<FeatureLayer>;
  baseLayers$: Observable<BaseLayer[]>;
  isLoading$: Observable<boolean>;

  showScandataEmpty$!: Observable<boolean>;
  scandataEmptyReason$!: Observable<ScandataEmptyReason | null>;

  private logger = injectLogger(Logger, 'MapComponent');

  constructor(
    private mapService: MapService,
    private featureLayerService: FeatureLayerService,
    private baseLayerService: BaseLayerService,
    private store: Store,
  ) {
    this.isLoading$ = combineLatest([this.scandataLoading$, this.featuresLoading$]).pipe(
      map(([scandataLoading, featureLoading]) => scandataLoading || featureLoading),
    );

    this.mapScandata$ = this.mapService.getMapScandata();
    this.featureLayer$ = this.featureLayerService.getFeatureLayer();
    this.baseLayers$ = this.baseLayerService.getBaseLayers();

    this.store.dispatch(new SetActiveMapTool(MapTool.Default));

    this.setupScandataEmpty();
  }

  featureClicked(event: FeatureClickedEvent) {
    const id = event.feature.id as string | undefined; //all our features have string ids;

    if (!id) {
      this.logger.error('Feature has no id', event.feature);
      return;
    }

    if (event.hasModifierKey) {
      if (event.feature.properties?.['selected']) {
        this.store.dispatch(new UnselectScandataModel(id));
      } else {
        this.store.dispatch(new SelectScandataModel(id));
      }
    } else {
      const selected = this.store.selectSnapshot(ScandataState.selected);
      if (selected.length === 1 && selected[0].id === id) {
        this.store.dispatch(new UnselectScandataModel(id));
      } else {
        this.store.dispatch(new SelectOnly([id]));
      }
    }
  }

  featureDblClicked(event: FeatureClickedEvent) {
    const feature = event.feature;
    const bounds: MapBounds = {
      bbox: calculateBounds(feature),
      padding: DEFAULT_BOUNDS_PADDING,
    };
    this.store.dispatch(new SetBounds(bounds));
  }

  featuresSelected(features: Feature[]) {
    const selectIds = features.map((feature) => feature.id?.toString()).filter(isDefined);
    this.store.dispatch(new SelectOnly(selectIds));
  }

  changeItemVisibilityClicked(model: ScandataModel) {
    model.hiddenOnMap = !model.hiddenOnMap;
    this.store.dispatch(new PatchScandataModel(model));
  }

  itemClicked(model: ScandataModel) {
    const noOfSelected = this.store.selectSnapshot(ScandataState.selected).length;

    if (model.selected && noOfSelected === 1) {
      this.store.dispatch(new UnselectAllScandataModels());
    } else {
      this.store.dispatch(new SelectOnly([model.id]));
    }
  }

  itemChecked(model: ScandataModel) {
    if (model.selected) {
      this.store.dispatch(new UnselectScandataModel(model.id));
    } else {
      this.store.dispatch(new SelectScandataModel(model.id));
    }
  }

  zoomToItemClicked(model: ScandataModel) {
    const featureCollection = this.store.selectSnapshot(FeatureLayerState.featureCollection);
    const feature = featureCollection?.features.find((ftr) => ftr.id === model.id);
    if (feature) {
      const bounds: MapBounds = {
        bbox: calculateBounds(feature),
        padding: DEFAULT_BOUNDS_PADDING,
      };
      this.store.dispatch(new SetBounds(bounds));
    }
  }

  mapToolSelected(mapTool: MapTool) {
    this.store.dispatch(new SetActiveMapTool(mapTool));
  }

  zoomIn() {
    this.mapViewer.zoomIn();
  }

  zoomOut() {
    this.mapViewer.zoomOut();
  }

  fitToView() {
    const featureCollection = this.store.selectSnapshot(FeatureLayerState.featureCollection);
    const bounds: MapBounds = {
      bbox: this.featureLayerService.getFeatureCollectionOrDefaultBBox(featureCollection),
      padding: DEFAULT_BOUNDS_PADDING,
    };

    this.store.dispatch(new SetBounds(bounds));
  }

  filterOptionChanged(option: MapFilterOption) {
    this.store.dispatch(new SetMapFilterOption(option));
  }

  setActiveBaseLayer(layer: BaseLayer) {
    this.store.dispatch(new SetActiveBaseLayer(layer));
  }

  boundsChanged(mapBounds: MapBounds) {
    this.store.dispatch(new SetBounds(mapBounds));
  }

  private setupScandataEmpty() {
    this.showScandataEmpty$ = combineLatest([
      this.isLoading$,
      this.scanLoadError$,
      this.mapScandata$,
    ]).pipe(
      map(([loading, error, scandata]) => {
        if (loading || error) return false;
        return scandata.length === 0;
      }),
    );

    this.scandataEmptyReason$ = this.showScandataEmpty$.pipe(
      filter((empty) => empty),
      combineLatestWith(this.scandata$, this.textFilter$, this.filterCount$, this.filterOption$),
      map(([, scandata, textFilter, filterCount, filterOption]) => {
        if (filterCount === 0 && scandata.length === 0) {
          return ScandataEmptyReason.NoUploads;
        }

        if (filterCount > 0 || isDefined(textFilter)) {
          return ScandataEmptyReason.NoFilterResults;
        }

        if (filterOption === MapFilterOption.LocatedOnly) {
          return ScandataEmptyReason.NoGeolocated;
        }

        return ScandataEmptyReason.NoUploads;
      }),
    );
  }
}
