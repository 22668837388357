import { ScandataModel } from '../scandata/scandata.models';

export class SetHost3dScandata {
  static readonly type = '[Host 3d] SetAction';
  constructor(public readonly scandata: ScandataModel[]) {}
}

export class ClearHost3dScandata {
  static readonly type = '[Host 3d] ClearAction';
}

export class FitToView {
  static readonly type = '[Scandata] FitToView';
  constructor(public readonly models: ScandataModel[]) {}
}
