import { Injectable, InjectionToken } from '@angular/core';

export abstract class FormFieldIdProvider {
  private _nextId = 0;
  readonly controlType!: string;
  readonly nextId = () => `${this.controlType}-${this._nextId++}`;
}

@Injectable({ providedIn: 'root' })
export class InputIdProvider extends FormFieldIdProvider {
  override readonly controlType = 'modus-input';
}

@Injectable({ providedIn: 'root' })
export class SelectIdProvider extends FormFieldIdProvider {
  override readonly controlType = 'modus-select';
}

export const FORM_FIELD_ID_PROVIDER = new InjectionToken<FormFieldIdProvider>(
  'FORM_FIELD_ID_PROVIDER',
);
