@if (showProgressBar()) {
  <div class="w-full absolute">
    <mat-progress-bar class="mds-x-small" [mode]="'indeterminate'"></mat-progress-bar>
  </div>
}

@if (showOptions()) {
  <sd-options
    [scandataModels]="scandataModels()"
    [disabled]="disabled()"
    [readonly]="readonly()"
    (viewIn3dClicked)="viewIn3d()"
    (editTagsClicked)="showTagging.set(true)"
    (downloadClicked)="downloadClicked.emit()"
    (deleteClicked)="deleteScandata()"
    (removeAllClicked)="unselectAllClicked.emit()"
    (removeModelClicked)="unselectClicked.emit($event)"
  ></sd-options>
}

@if (showTagging()) {
  <sd-tagging
    [scandataModels]="scandataModels()"
    (closeClicked)="showTagging.set(false)"
  ></sd-tagging>
}
