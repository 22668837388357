import { hasModifierKey } from '@angular/cdk/keycodes';
import {
  ConnectionPositionPair,
  Overlay,
  OverlayConfig,
  OverlayRef,
  PositionStrategy,
  ScrollDispatcher,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { DefaultValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  merge,
  share,
  shareReplay,
  startWith,
  takeUntil,
} from 'rxjs';
import { ControlSize, ModusControlSize } from '../directives';
import {
  FORM_FIELD_ID_PROVIDER,
  FormFieldControl,
  InputIdProvider,
  ModusError,
} from '../modus-form-field';
import { createIntersectionObserver } from '../utils/intersection-observer';
import { isDefined, isNilOrWhitespace, isString, last } from '../utils/utils';
import { ModusOptionList } from './modus-option-list/modus-option-list.component';

/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable @angular-eslint/no-input-rename */

@Component({
  selector: 'modus-autocomplete-input',
  templateUrl: './modus-autocomplete.component.html',
  hostDirectives: [
    { directive: ModusControlSize, inputs: ['size'] },
    { directive: FormFieldControl, inputs: ['id', 'required', 'disabled', 'readonly'] },
  ],
  providers: [
    {
      provide: FORM_FIELD_ID_PROVIDER,
      useExisting: InputIdProvider,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useClass: DefaultValueAccessor,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ModusAutocomplete<T> implements AfterViewInit, OnDestroy {
  private readonly _element = inject(ElementRef).nativeElement;
  private readonly _overlay = inject(Overlay);
  private readonly _document = inject(DOCUMENT);
  private readonly _viewContainerRef = inject(ViewContainerRef);
  private readonly _scrollDispatcher = inject(ScrollDispatcher);

  private readonly _destroy$ = new Subject<void>();
  private readonly _panelClass = 'modus-autocomplete-panel';
  private readonly _topIntersectionThreshold = 0.5;
  private readonly _bottomIntersectionThreshold = 0.9;
  private readonly _viewportMargin = 8;

  private _overlayRef?: OverlayRef;
  private _intersectionObserver$!: Observable<boolean>;

  inputKeydown$!: Observable<KeyboardEvent>;
  readonly formFieldControl = inject(FormFieldControl);

  @Input() label?: string;
  @Input() includeSearchIcon = true;
  @Input() placeholder?: string;
  @Input() clearable = false;
  @Input() errorText?: string;
  @Input() size: ControlSize = 'default';

  @Input('options') optionList!: ModusOptionList<T>;
  @Input() formControl: FormControl = new FormControl();

  @Output() optionSelected = new EventEmitter<T>();
  @Output() clearClick = new EventEmitter();

  @ViewChild('autocompleteInput') inputRef!: ElementRef<HTMLInputElement>;
  @ViewChild('autocompleteClearButton') clearButtonRef!: ElementRef<HTMLInputElement>;
  @ContentChildren(ModusError, { descendants: true }) _errorChildren!: QueryList<ModusError>;

  private get input(): HTMLInputElement {
    return this.inputRef.nativeElement;
  }

  get hasLabel(): boolean {
    return isString(this.label) && this.label.length > 0;
  }

  get hasErrorText(): boolean {
    return isString(this.errorText) && this.errorText.length > 0;
  }

  get listIsOpen(): boolean {
    return this.optionList?.isOpen ?? false;
  }

  get activeOption() {
    return this.optionList.options.find((opt) => opt.active);
  }

  constructor() {
    this._element.classList.add('modus-autocomplete');
  }

  ngAfterViewInit(): void {
    this.inputKeydown$ = fromEvent<KeyboardEvent>(this.input, 'keydown').pipe(share());

    const options: IntersectionObserverInit = {
      rootMargin: '0px 0px -7px 0px', // modus-form-field label & subscript adds 8px padding
      threshold: [this._topIntersectionThreshold, this._bottomIntersectionThreshold],
    };

    this._intersectionObserver$ = createIntersectionObserver(this._element, options).pipe(
      map(last),
      filter(isDefined),
      map((entry) => {
        return entry.intersection.top
          ? entry.intersectionRatio >= this._topIntersectionThreshold
          : entry.intersection.bottom
            ? entry.intersectionRatio >= this._bottomIntersectionThreshold
            : true;
      }),
      startWith(true),
    );

    this.subscribeToOpeningEvents();
    this.subscribeToNavigationKeys();
    this.subscribeToSelectionEvents();
    this.subscribeToClosingEvents();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  clear(event: MouseEvent) {
    event.stopPropagation();
    this.setFormControlValue(null);
    this.optionList.clearSelection();
    if (this.listIsOpen) this.input.focus();
    this.clearClick.emit();
  }

  openPanel() {
    this.optionList.open();
    this._overlayRef = this.createOverlayRef();
    this._overlayRef.attach(new TemplatePortal(this.optionList.template, this._viewContainerRef));
  }

  closePanel() {
    this.optionList.close();
    this._overlayRef?.dispose();
    this._overlayRef = undefined;
  }

  private setFormControlValue(value: T | null) {
    this.formControl.setValue(value);
    this.formControl.markAsDirty();
    this.formControl.updateValueAndValidity();
  }

  private subscribeToOpeningEvents() {
    // these events could open the panel
    const event$ = merge(
      fromEvent<FocusEvent>(this.input, 'focus'),
      fromEvent<MouseEvent>(this.input, 'click'),
      this.inputKeydown$,
    );

    // the formControl must be valid to open the panel
    const valid$ = this.formControl.statusChanges.pipe(
      map((status) => status === 'VALID'),
      startWith(true),
      distinctUntilChanged(),
    );

    // the input must be visible, and not clipped by more than intersectionThreshold
    const intersect$ = this._intersectionObserver$.pipe(
      shareReplay({ refCount: true, bufferSize: 1 }),
    );

    combineLatest([event$, valid$, intersect$])
      .pipe(
        filter(([, valid, withinThreshold]) => {
          return valid && withinThreshold && this.canOpenPanel();
        }),
        takeUntil(this._destroy$),
      )
      .subscribe(() => this.openPanel());
  }

  private canOpenPanel() {
    const canOpen =
      this._document.activeElement === this.input &&
      !this.formFieldControl.readonly &&
      !this.listIsOpen;
    return canOpen;
  }

  private subscribeToNavigationKeys() {
    this.inputKeydown$
      .pipe(
        filter(() => this.listIsOpen),
        filter((event) => event.key === 'ArrowUp'),
        takeUntil(this._destroy$),
      )
      .subscribe(() => {
        this.optionList.activatePrevious();
      });

    this.inputKeydown$
      .pipe(
        filter(() => this.listIsOpen),
        filter((event) => event.key === 'ArrowDown'),
        takeUntil(this._destroy$),
      )
      .subscribe(() => {
        this.optionList.activateNext();
      });
  }

  private subscribeToSelectionEvents() {
    const initialValue = this.formControl.value;
    const candidate = this.optionList.options.length === 1 ? this.optionList.options.first : null;
    if (candidate && candidate.value === initialValue) candidate.select();

    this.inputKeydown$
      .pipe(
        filter(() => this.listIsOpen),
        filter((event) => event.key === 'Enter'),
        takeUntil(this._destroy$),
      )
      .subscribe(() => {
        this.optionList.selectActiveOption();
      });

    this.optionList.selectionChange.pipe(takeUntil(this._destroy$)).subscribe((value) => {
      this.setFormControlValue(value);
      this.optionSelected.emit(value);
      this.closePanel();
    });

    this.formControl.valueChanges
      .pipe(filter(isNilOrWhitespace), takeUntil(this._destroy$))
      .subscribe(() => this.optionList.clearSelection());
  }

  private subscribeToClosingEvents() {
    const invalid$ = this.formControl.statusChanges.pipe(
      filter(() => this.formControl.touched),
      map((status) => status === 'INVALID'),
      startWith(false),
    );

    combineLatest([invalid$, this._intersectionObserver$])
      .pipe(
        filter(([invalid, withinThreshold]) => invalid || !withinThreshold),
        takeUntil(this._destroy$),
      )
      .subscribe(() => this.closePanel());
  }

  private createOverlayRef(): OverlayRef {
    const overlayRef = this._overlay.create(this.getOverlayConfig());

    overlayRef
      .outsidePointerEvents()
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.closePanel());

    overlayRef
      .keydownEvents()
      .pipe(
        filter(({ key }) => /Escape|Tab/.test(key)),
        filter((event) => !hasModifierKey(event)),
        takeUntil(this._destroy$),
      )
      .subscribe(() => {
        this.closePanel();
      });

    return overlayRef;
  }

  private getOverlayConfig(): OverlayConfig {
    const overlayConfig = new OverlayConfig({
      panelClass: this._panelClass,
      positionStrategy: this.getPositionStrategy(),
      scrollStrategy: this._overlay.scrollStrategies.reposition(),
      width: this._element.getBoundingClientRect().width,
    });
    return overlayConfig;
  }

  private getPositionStrategy(): PositionStrategy {
    const scrollableAncestors = this._scrollDispatcher.getAncestorScrollContainers(this._element);

    /**
     * There was a -9px offset in the SCSS to position the overlay,
     * but it is better handled here, because it has become conditional.
     *
     * There is a timing issue, where the errorLabel is still present in the DOM
     * when the formControl status changes from 'INVALID' to 'VALID and the panel re-opens.
     * It looks like the errorLabel is removed during the next tick.
     *
     * The errorLabel has a height of 24px, so when it is removed from the DOM,
     * it leaves a gap of 24px between the bottom of the input and the top of
     * the overlayRef. We shift the overlay upwards to close the gap, and add
     * one extra pixel to compensate for border thickness.
     *
     *    yOffset = -25;
     *
     * When the errorLabel is not rendered, there is some whitespace remaining
     * due to padding on the errorLabel container, which is just kak styling.
     *
     * The padding is 8px, and we compensate for the border of 1px again.
     *
     *    yOffset = -9;
     *
     * EDIT: 2023-11-21
     * Changes elsewhere in the code has affected the offset again, and the compensation
     * for the errorText is not currently needed, only the 9px for padding and border.
     *
     */
    const yOffset = this.hasErrorText ? -9 : -9;

    const positionStrategy = this._overlay
      .position()
      .flexibleConnectedTo(this._element)
      .withPositions(this.getPositions())
      .withFlexibleDimensions(false)
      .withViewportMargin(this._viewportMargin)
      .withDefaultOffsetY(yOffset)
      .withScrollableContainers(scrollableAncestors)
      .withPush(false);

    return positionStrategy;
  }

  private getPositions(): ConnectionPositionPair[] {
    return [
      { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
      { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
    ];
  }
}
