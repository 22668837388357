<div class="flex flex-1 flex-col bg-gray-light p-4 pt-0 text-sm text-trimble-gray">
  <div class="flex flex-row items-center h-12 p-4 font-semibold">
    Dialogs Demo
    <div class="flex flex-1 justify-end">
      <!-- toggle dark mode -->
      <button modus-icon-button modus-tooltip="Toggle dark mode" (click)="toggleDarkMode()">
        <md-icon>{{ darkThemeOn() ? 'sun' : 'moon' }}</md-icon>
      </button>
    </div>
  </div>
  <div
    class="flex-1 flex-col px-4 border border-gray-2 rounded dark:bg-trimble-gray dark:text-white"
  >
    <!-- dialog -->
    <div class="flex flex-1 items-center py-3 border-b border-gray-2">
      <div class="flex w-[100px]">Dialog:</div>
      <button modus-flat-button class="" (click)="showDialog()">Open</button>
    </div>

    <!-- confirm -->
    <div class="flex flex-1 items-center py-3 border-b border-gray-2">
      <div class="flex w-[100px]">Confirm:</div>
      <button modus-flat-button class="" (click)="showConfirm()">Open</button>
      <div class="pl-4">Confirmed: {{ confirmResult$ | async }}</div>
    </div>

    <!-- danger confirm -->
    <div class="flex flex-1 items-center py-3 border-b border-gray-2">
      <div class="flex w-[150px]">Confirm Alternative:</div>
      <button modus-flat-button class="" (click)="showConfirmAlt()">Open</button>
      <div class="pl-4">Confirmed: {{ confirmAltResult$ | async }}</div>
    </div>
  </div>
</div>
