<div class="flex flex-col h-full">
  <div class="flex flex-row p-4 pr-6 border-b border-gray-1">
    <div class="h-8 flex flex-1 flex-wrap content-center font-semibold">Download Status</div>
    <button
      id="options-panel-download-status-close"
      class="text-gray-8"
      modus-icon-button
      modus-tooltip="Close"
      (click)="close()"
    >
      <md-icon>close</md-icon>
    </button>
  </div>

  <div class="flex-1 overflow-y-scroll p-4 text-sm">
    @if (hasDownloads()) {
      <div class="h-7 flex flex-row items-center pb-1">
        <div class="w-full text-sm font-bold">Overall Progress</div>
        <!-- overall progress -->
        <sd-download-progress
          class="min-w-[26px] max-w-[26px] ml-2 flex justify-center"
          [downloadProgress]="overallProgress()"
          [progressTooltipPrefix]="'Overall Progress'"
        ></sd-download-progress>
      </div>
      @for (scan of downloadScans(); track $index) {
        <div>
          <!-- list scandata model -->
          <div class="flex flex-row items-center">
            <button modus-icon-button (click)="toggleExpanded(scan)">
              @if (scan.expanded) {
                <md-icon class="text-lg/5">caret_down</md-icon>
              } @else {
                <md-icon class="text-lg/5">caret_right</md-icon>
              }
            </button>
            <div
              modus-tooltip="{{ scan.scandataModel.name }}"
              class="w-full overflow-hidden text-ellipsis whitespace-nowrap leading-8 ml-1"
            >
              {{ scan.scandataModel.name }}
            </div>
          </div>
          <!-- list scandata model files -->
          @if (scan.expanded) {
            <div class="pl-12 mb-2">
              @for (file of scan.files; track $index) {
                <div class="flex flex-row items-center">
                  <md-icon>{{ file.icon }}</md-icon>
                  <div
                    modus-tooltip="{{ file.scandataFile.name }}"
                    class="w-full overflow-hidden text-ellipsis whitespace-nowrap leading-8 ml-1"
                  >
                    {{ file.scandataFile.name }}
                  </div>
                  <!-- progress -->
                  <sd-download-progress
                    class="min-w-[26px] max-w-[26px] flex justify-center ml-1"
                    [downloadProgress]="file.downloadProgress"
                    [progressTooltipPrefix]="'File Progress'"
                  ></sd-download-progress>
                </div>
              }
            </div>
          }
        </div>
      }
    } @else {
      No downloads
    }

    <!-- no downloads -->
  </div>

  <!-- button group -->
  <div class="flex p-4 justify-end border-t border-gray-1">
    <button
      id="options-panel-download-clear"
      modus-button
      class="mr-4"
      color="secondary"
      modus-tooltip="Clear Completed Downloads"
      (click)="clearCompleted()"
      [disabled]="clearDisabled()"
    >
      Clear
    </button>
    <button
      id="options-panel-download-cancel"
      modus-flat-button
      color="secondary"
      modus-tooltip="Cancel All Downloads"
      (click)="cancelDownloads()"
      [disabled]="cancelDisabled()"
    >
      Cancel
    </button>
  </div>
</div>
