import { from, Subject } from 'rxjs';
import { concatMap, filter, map, share, switchMap, takeUntil } from 'rxjs/operators';
import {
  WorkerErrorMessage,
  WorkerInitMessage,
  WorkerMessage,
  WorkerMessageType,
  WorkerResultMessage,
} from './worker-messages';
import { TaskResult, WorkerDefinition, WorkerTask } from './worker-models';
import {
  AsyncWorker,
  AsyncWorkerFunction,
  AsyncWorkerLiteral,
  UnknownWorker,
  WorkerFunction,
} from './worker-types';

export class WebWorker {
  readonly name: string;
  readonly ready: Promise<AsyncWorker>;

  private worker: Worker;
  private current!: WorkerTask;

  private workerTask$ = new Subject<WorkerTask>();
  private taskResult$ = new Subject<TaskResult>();
  private terminate$ = new Subject();

  private taskResultObs$ = this.taskResult$.asObservable().pipe(share());

  private id = 1;
  private finalizing = false;
  private terminated = false;

  private constructor(workerDef: WorkerDefinition) {
    this.name = workerDef.name;
    this.worker = workerDef.factory(workerDef.name);

    this.ready = new Promise((resolve, reject) => {
      this.worker.onmessage = (message) => this.runOnce(resolve, reject, message);
    });

    this.setupMessageHandlers();
    this.orchestrate();
  }

  static create<T extends UnknownWorker = UnknownWorker>(
    workerDef: WorkerDefinition,
  ): Promise<AsyncWorker<T>> {
    const instance = new WebWorker(workerDef);
    return instance.ready as Promise<AsyncWorker<T>>;
  }

  private runOnce(
    resolve: (value: AsyncWorker | PromiseLike<AsyncWorker>) => void,
    reject: (reason: string) => void,
    { data }: MessageEvent<WorkerMessage>,
  ) {
    if (data.type === WorkerMessageType.Init) {
      const message = data as WorkerInitMessage;
      const asyncWorker =
        message.workerType === 'function'
          ? this.createAsyncWorkerFunction()
          : this.createAsyncWorkerLiteral(message.functions);

      resolve(asyncWorker);
    } else {
      reject(`Worker initialization failed: ${this.name}`);
    }
  }

  private createAsyncWorkerFunction(): AsyncWorkerFunction {
    const workerFunction: AsyncWorkerFunction = {
      name: this.name,
      isTerminated: this.isTerminated.bind(this),
      terminate: this.terminate.bind(this),
      taskResult$: this.taskResultObs$,
      postMessage: this.createWorkerFunction(),
      result$: this.taskResultObs$.pipe(
        filter((x) => !x.func),
        map((x) => x.value),
      ),
    };
    return workerFunction;
  }

  private createAsyncWorkerLiteral(keys: string[]): AsyncWorkerLiteral {
    const workerLiteral: Record<string, unknown> = {
      name: this.name,
      isTerminated: this.isTerminated.bind(this),
      terminate: this.terminate.bind(this),
      taskResult$: this.taskResultObs$,
    };

    keys.forEach((key) => {
      workerLiteral[key] = this.createWorkerFunction(key);
      workerLiteral[`${key}$`] = this.taskResultObs$.pipe(
        filter((x) => x.func === key),
        map((x) => x.value),
      );
    });

    return workerLiteral as AsyncWorkerLiteral;
  }

  private createWorkerFunction(func?: string): WorkerFunction {
    const workerFn = (...parameters: unknown[]) => {
      const task = new WorkerTask(this.nextId(), func, ...parameters);
      this.workerTask$.next(task);
      return task.promise as Promise<unknown>;
    };
    return workerFn as WorkerFunction;
  }

  private nextId() {
    return `${this.name}:${this.id++}`;
  }

  private setupMessageHandlers() {
    this.ready.then(() => {
      this.worker.onmessage = (message) => this.onmessage(message);
    });

    this.worker.onmessageerror = (message) => this.onmessageerror(message);
    this.worker.onerror = (error) => this.onerror(error);
  }

  private onmessage({ data }: MessageEvent<WorkerMessage>) {
    if (data.type === WorkerMessageType.Result) {
      const { payload } = data as WorkerResultMessage;
      this.taskResult$.next(payload);
      this.current.resolve(payload.value);
    } else if (data.type === WorkerMessageType.Error) {
      const message = data as WorkerErrorMessage;
      this.current.reject(`Task: ${this.current.id}, message: ${message.error}`);
    } else {
      this.current.reject(`Task: ${this.current.id}, message: unknown`);
    }
  }

  private onmessageerror(message: MessageEvent) {
    this.current.reject(`Task: ${this.current.id}, message: ${message.data}`);
  }

  private onerror(error: ErrorEvent) {
    this.current.reject(`Task: ${this.current.id}, message: ${error.message}`);
  }

  private orchestrate() {
    from(this.ready)
      .pipe(
        switchMap(() => this.workerTask$),
        concatMap((task) => {
          this.current = task; // TODO: Error handling
          this.worker.postMessage(task.params, task.transferables);
          return from(task.promise);
        }),
        takeUntil(this.terminate$),
      )
      .subscribe({
        error: (error) => this.taskResult$.error(error),
      });
  }

  private isTerminated(): boolean {
    return this.terminated;
  }

  private async terminate(force = false) {
    this.terminate$.complete();

    if (this.current && !force) {
      return this.current.promise.then(() => {
        this.finalize();
      });
    } else {
      return Promise.resolve().then(() => this.finalize());
    }
  }

  private finalize() {
    if (this.finalizing) return;

    this.finalizing = true;
    this.taskResult$.complete();

    this.worker.onmessage = null;
    this.worker.onmessageerror = null;
    this.worker.onerror = null;

    this.worker.terminate();
    this.terminated = true;
  }
}
