import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { isNil } from '@trimble-gcs/common';
import { SetLicenses } from './license.actions';
import { License } from './license.models';

export interface LicenseStateModel {
  licenses?: License[];
}

@State<LicenseStateModel>({
  name: 'licenseState',
})
@Injectable()
export class LicenseState {
  @Selector() static hasActiveLicense(state: LicenseStateModel): boolean {
    if (isNil(state.licenses)) return false;

    return state.licenses.some((license) => {
      return (
        new Date(license.startDate) <= new Date() &&
        (isNil(license.endDate) || new Date(license.endDate) > new Date())
      );
    });
  }

  @Selector() static licenses(state: LicenseStateModel) {
    return state.licenses;
  }

  @Action(SetLicenses) setLicenses(ctx: StateContext<LicenseStateModel>, action: SetLicenses) {
    ctx.patchState({ licenses: action.licenses });
  }
}
