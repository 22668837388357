<div class="modus-menu-item-content" (click)="onItemClick($event)">
  <ng-content></ng-content>
</div>

<!-- No support for sub-menu's at this time. -->
<!-- <svg
  *ngIf="false"
  class="modus-menu-item-submenu-icon"
  viewBox="0 0 5 10"
  focusable="false"
  aria-hidden="true"
>
  <polygon points="0,0 5,5 0,10" />
</svg> -->
