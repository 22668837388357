/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { WorkerDefinition } from './worker-models';
import { WorkerPool } from './worker-pool';
import { AsyncWorker, UnknownWorker } from './worker-types';

@Injectable({ providedIn: 'root' })
export class WorkerFarm {
  private concurrency: number;
  private workerPools: AsyncWorker<UnknownWorker>[] = [];

  constructor() {
    this.concurrency = 4;
    // this.concurrency = navigator.hardwareConcurrency || 4;
  }

  async getWorkerPool<T extends UnknownWorker>(
    workerDef: WorkerDefinition,
  ): Promise<AsyncWorker<T>> {
    const index = this.workerPools.findIndex((pool) => pool.name === workerDef.name);
    const workerPool =
      index >= 0 ? this.workerPools[index] : await this.createWorkerPool(workerDef);
    return workerPool as AsyncWorker<T>;
  }

  async terminateWorkerPool<T extends UnknownWorker>(workerPool: AsyncWorker<T>) {
    await workerPool.terminate();
    const index = this.workerPools.findIndex((pool) => pool.name === workerPool.name);
    if (index > -1) {
      this.workerPools.splice(index, 1);
    }
  }

  private async createWorkerPool<T extends UnknownWorker>(
    workerDef: WorkerDefinition,
  ): Promise<AsyncWorker<T>> {
    const workerPool = await WorkerPool.create<T>(workerDef, this.concurrency);
    this.workerPools.push(workerPool as AsyncWorker<T>);
    return workerPool;
  }
}
