import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pointCount',
  standalone: true,
})
export class PointCountPipe implements PipeTransform {
  transform(num: number, significantFigures = 3): string {
    // const suffixes = ['', 'Thousand', 'Million', 'Billion', 'Trillion', 'Quadrillion', 'Quintillion', 'Sextillion']; //Leaving here for now in case Tim or Andy want to play around with this.
    const suffixes = ['', 'K', 'M', 'B', 'T', 'Q4', 'Q5', 'Q6'];

    if (isNaN(num) || num <= 0) {
      return '';
    }

    const numLength = Math.floor(Math.log10(num)) + 1;
    const magnitude = Math.floor((numLength - 1) / 3);

    const roundedNum = this.roundToSignificantFigures(num, significantFigures);
    const integerResult = roundedNum / Math.pow(10, magnitude * 3);
    const result = `${integerResult} ${suffixes[magnitude]}`.trim();

    return result;
  }

  private roundToSignificantFigures(num: number, significantFigures: number): number {
    const multiplier = Math.pow(10, significantFigures - Math.floor(Math.log10(num)) - 1);
    return Math.round(Math.round(num * multiplier) / multiplier);
  }
}
