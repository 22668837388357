import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { buildUrl, isDefined } from '@trimble-gcs/common';
import { combineLatestWith, filter, map, Observable, switchMap, take } from 'rxjs';
import { ConnectProject } from 'trimble-connect-workspace-api';
import { AppState } from '../app-state/app.state';
import { AuthState } from '../auth/auth.state';
import { GlobalRegion } from '../tenancy/tenancy.models';
import { TenancyService } from '../tenancy/tenancy.service';
import { SetLicenses } from './license.actions';
import { License } from './license.models';

@Injectable({
  providedIn: 'root',
})
export class LicenseService {
  constructor(
    private http: HttpClient,
    private store: Store,
    private tenancyService: TenancyService,
  ) {}

  loadLicenses() {
    return this.getLicenses().pipe(
      switchMap((licenses) => {
        return this.store.dispatch(new SetLicenses(licenses));
      }),
    );
  }

  getLicenses(): Observable<License[]> {
    return this.store.select(AppState.project).pipe(
      filter(isDefined),
      switchMap((connectProject) =>
        this.tenancyService.getGlobalRegion(connectProject).pipe(
          map((globalRegion) => {
            return [connectProject, globalRegion] as [ConnectProject, GlobalRegion];
          }),
        ),
      ),
      map(([connectProject, globalRegion]) =>
        buildUrl(
          globalRegion.sdsBaseUrl,
          `/licenses?projectId=${connectProject.id}&projectLocation=${globalRegion.connectRegion}`,
        ),
      ),
      combineLatestWith(this.store.select(AuthState.accessToken)),
      filter(([_, token]) => {
        return isDefined(token);
      }),
      take(1),
      switchMap(([url, token]) => {
        return this.http.get<License[] | null>(url, {
          headers: { Authorization: `Bearer ${token}` },
        });
      }),
      map((licenses) => licenses ?? []),
    );
  }
}
