import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModusCheckboxModule } from '../modus-checkbox';
import { ModusFormFieldModule } from '../modus-form-field';
import { ModusIconModule } from '../modus-icon';
import { ModusSwitchModule } from '../modus-switch';
import { ModusList } from './list-component/modus-list.component';
import { ModusListItem } from './list-item/modus-list-item.component';

@NgModule({
  imports: [
    CommonModule,
    ModusFormFieldModule,
    ModusIconModule,
    ModusCheckboxModule,
    ModusSwitchModule,
  ],
  declarations: [ModusList, ModusListItem],
  exports: [
    CommonModule,
    ModusFormFieldModule,
    ModusIconModule,
    ModusCheckboxModule,
    ModusSwitchModule,
    ModusList,
    ModusListItem,
  ],
})
export class ModusListModule {}
