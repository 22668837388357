export enum Role {
  Admin = 'ADMIN',
  User = 'USER',
  None = 'NONE',
}

export interface User {
  /** Trimble Identity - TID. */
  id: string;

  /** Connect user id. Not the same as TID. */
  connectId: string;

  title?: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  language?: string;
  role: Role;
  roleClaims?: string[];
}
