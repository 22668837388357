import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Subject, filter, fromEvent, map, takeUntil } from 'rxjs';
import { isDate, isDefined, isString, toIsoDateString } from '../utils/utils';

/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */

export type DatePickerValue = {
  inputString: string;
  type: 'single' | 'start' | 'end';
  value: string | null;
};

@Directive({
  selector: 'modus-date-input',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ModusDatePickerDirective,
      multi: true,
    },
  ],
})
export class ModusDatePickerDirective
  implements ControlValueAccessor, OnInit, OnChanges, OnDestroy, AfterViewInit
{
  private readonly element = this.elementRef.nativeElement;
  private readonly onDestroy = new Subject<void>();

  private _writeValue = false;

  @Input() formControl?: FormControl;

  constructor(
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    /**
     * HACK:
     *
     * ensure formatter is initialised before setting element value,
     * by modifying the format value
     */
    const format = this.element.format;
    this.element.format = '';
    this.element.format = format;
  }

  ngOnChanges(changes: SimpleChanges) {
    const formControlChange = changes['formControl'];
    if (formControlChange && formControlChange.currentValue) {
      const formControl = <FormControl>formControlChange.currentValue;
      formControl.addValidators(HasErrorTextValidator(this.element));
      formControl.updateValueAndValidity();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngAfterViewInit(): void {
    this.subscribeToEvents();
    this.subscribeToValueChanges();

    /**
     * HACK:
     *
     * When using modus datepicker the component reserves space in the layout
     * for two date inputs. This is fine when wanting to select a date range.
     * Not so much if you want to use this component to select a single date
     * and expect it to fill 100% width.
     *
     * The hack below is to insert a css override to the shadow dom of the
     * component responsible for the layout so the input will fill 100% width.
     *
     * Note this hack is only applied if the date input type is set to 'single' .
     */
    if (this.element.type !== 'single') return;

    const hostElement = <HTMLElement>(<HTMLElement>this.element).parentElement;
    const hostShadowRoot = hostElement.shadowRoot;

    const css = new CSSStyleSheet();
    css.insertRule('.modus-date-picker .date-inputs { display: block !important; }');

    hostShadowRoot?.adoptedStyleSheets.push(css);
  }

  onChange = (_: unknown) => {};
  onTouched = () => {};

  /**
   * @param value
   * A string representing the date entered in the input.
   * The date is formatted according to ISO8601 'yyyy-mm-dd'.
   * The underlying web component is responsible for validation.
   */
  writeValue(value: string | Date): void {
    /**
     * Modus fires a valueChange event when we set the element value,
     * and also when they set the value.
     *
     * We are interested in their valueChanges, so that we can call onChange(),
     * to propagate the value to our FormControl, which also marks it as dirty.
     *
     * However, when we set a FormControl value we don't want to
     * handle the valueChange and call onChange(),
     * because our FormControl state should not change.
     * e.g. If we reset() the FormControl, we don't want onChange()
     * to mark it as dirty again.
     *
     * To handle this, we set _writeValue to true when we set the value,
     * and then filter by _writeValue in the valueChanges subscription.
     * We also reset _writeValue in the subscription, so we can handle
     * their valueChange when it emits.
     *
     * To complicate matters further, if we set their value to the same value,
     * in succession, they only emit the first change, so we don't get the
     * opportunity to reset _writeValue if we set the same value more than
     * once in succession. Should they then emit a valueChange, we filter
     * it out instead of handling it.
     *
     * The sequence could happend like this:
     *
     * - Instantiate FormControl with an initial value
     *      set _writeValue = true
     * - valueChange emits
     *      _writeValue = true, do not call onChange()
     *      set _writeValue = false
     *
     * - Form loads data and the FormControl is assigned the same value
     *      set _writeValue = true
     * - Modus does not emit valueChange
     *
     * - User picks a date
     * - valueChange emits
     *      _writeValue = true, do not call onChange()
     * - Whoops, or FormControl value and state is out of sync
     *
     * To handle all of the above, we only want to set the element value
     * if there is a new FormControl value.
     *
     * As a further complication, when the picker is in an error state,
     * setting its value does not clear the error state, so we want to
     * handle that ourselves.
     *
     */

    const date = this.getIsoDateString(value);
    if (this.element.value === date) {
      return;
    }

    this._writeValue = true;
    this.element.value = date;
  }

  registerOnChange(fn: never): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: never): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.element.disabled = isDisabled;
  }

  private subscribeToEvents() {
    if (!this.formControl) return;

    fromEvent(this.element, 'blur')
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.onTouched();
        this.changeDetectorRef.markForCheck();
      });

    fromEvent(this.element, 'keyup')
      .pipe(takeUntil(this.onDestroy))
      .subscribe(async () => {
        this.formControl?.markAsDirty();

        // We also need to check the underlying element's validity here,
        // because Modus does not fire a valueChange when the element is invalid.
        // We cannot do it on blur because it fires after the formControl's
        // valueChange and modus-date-picker dateInputBlur events.
        await this.validate();

        this.changeDetectorRef.markForCheck();
      });
  }

  /**
   * DatePickerValue.value is a string with format yyyy-mm-dd
   * We construct a Date object from the value, for the onChange() callback.
   * Note: Value changed will not fire when you change from an invalid date to an empty date.
   */
  private subscribeToValueChanges() {
    if (!this.formControl) return;

    fromEvent<CustomEvent>(this.element, 'valueChange')
      .pipe(
        filter(() => {
          return this._writeValue ? (this._writeValue = false) : true;
        }),
        map((event: CustomEvent) => event.detail as DatePickerValue),
        takeUntil(this.onDestroy),
      )
      .subscribe(async (pickerValue: DatePickerValue) => {
        await this.validate();

        const value = pickerValue.value;
        const date = isString(value) ? new Date(value) : null;
        this.onChange(date);
      });
  }

  private getIsoDateString(value: string | Date): string {
    let date = '';

    if (isString(value)) {
      date = value;
    } else if (isDate(value)) {
      date = toIsoDateString(value);
    }
    return date;
  }

  private async validate() {
    await this.element.validate();
    this.formControl?.updateValueAndValidity();
  }
}

function HasErrorTextValidator(element: unknown): ValidatorFn {
  return (): ValidationErrors | null => {
    // When the Modus element is invalid, the value is null and the only
    // indication of the error condition is that the 'errorText' property
    // has a value.
    const hasErrorTextProperty =
      typeof element === 'object' && isDefined(element) && 'errorText' in element;

    if (!hasErrorTextProperty) return null;

    const error = element.errorText;

    if (error) return { invalidDate: true };

    return null;
  };
}
