import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { filter, map, Observable, shareReplay, switchMap, take } from 'rxjs';
import { AppContext } from './app-state/app.models';
import { AppService } from './app-state/app.service';
import { AppState } from './app-state/app.state';
import { DialogService } from './dialog/dialog.service';
import { AppHostComponent } from './hosts/app-host/app-host.component';
import { ExtHostComponent } from './hosts/ext-host/ext-host.component';
import { LicenseState } from './license/license.state';
import { OutOfQuotaDialogComponent } from './quota/out-of-quota-dialog.component';
import { ProjectQuotaService } from './quota/project-quota.service';

@Component({
  selector: 'sd-root',
  standalone: true,
  imports: [CommonModule, AppHostComponent, ExtHostComponent],
  template: `
    @if ((extensionHost$ | async) === true) {
      <sd-ext-host></sd-ext-host>
    } @else {
      <sd-app-host></sd-app-host>
    }
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 100%;
        max-height: 100%;
      }
    `,
  ],
})
export class AppComponent {
  @Select(AppState.context) private context$!: Observable<AppContext>;
  @Select(LicenseState.hasActiveLicense) private hasActiveLicense$!: Observable<boolean>;

  extensionHost$: Observable<boolean>;

  constructor(
    appService: AppService,
    private projectQuotaService: ProjectQuotaService,
    private dialogService: DialogService,
  ) {
    this.extensionHost$ = this.context$.pipe(
      filter((ctx) => ctx !== AppContext.Unknown),
      map((ctx) => ctx === AppContext.ConnectExtension),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );

    this.hasActiveLicense$
      .pipe(
        filter((hasActiveLicense) => hasActiveLicense),
        take(1),
        switchMap(() => appService.loadAppData()),
        switchMap(() => this.checkProjectQuota()),
      )
      .subscribe();
  }

  private checkProjectQuota() {
    return this.projectQuotaService.getProjectQuota().pipe(
      filter((quota) => quota.quotaUsedByAccount >= quota.quota),
      switchMap(() => {
        return this.dialogService.showComponent(OutOfQuotaDialogComponent, {
          disableClose: true,
        });
      }),
    );
  }
}
