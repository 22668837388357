import { ClassificationScheme } from './classification-scheme.model';

export class SetClassificationSchemes {
  static readonly type = '[ClassificationScheme] SetClassificationSchemes';
  constructor(public classificationSchemes: ClassificationScheme[]) {}
}

export class ClearClassificationSchemes {
  static readonly type = '[ClassificationScheme] ClearClassificationSchemes';
}

export class SetClassificationForEdit {
  static readonly type = '[ClassificationScheme] SetClassificationForEdit';
  constructor(public classificationScheme: ClassificationScheme) {}
}
