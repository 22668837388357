import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { isDefined, isNil } from '@trimble-gcs/common';
import {
  ModusButtonModule,
  ModusIconModule,
  ModusSwitchModule,
  ModusTooltipModule,
} from '@trimble-gcs/modus';
import { filter, map, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { CANCEL_BUTTON, DialogData } from '../dialog/dialog.model';
import { DialogService } from '../dialog/dialog.service';
import { ImportFileListComponent } from './import-file-list/import-file-list.component';
import { ImportFile, ImportFileStatus, ImportScan } from './import.models';
import { ImportService } from './import.service';
import { LocalFilePickerComponent } from './local-file-picker/local-file-picker.component';

export const importDialogDefaultConfig: MatDialogConfig = {
  disableClose: true,
  height: '70%',
  minHeight: '300px',
  width: '70%',
  minWidth: '500px',
  maxWidth: '650px',
};

export type ImportDialogResult = {
  reloadScans: boolean;
};

enum ImportStatus {
  NotStarted = 'NotStarted',
  Busy = 'Busy',
  Completed = 'Completed',
  Error = 'Error',
}

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModusTooltipModule,
    ModusIconModule,
    ModusButtonModule,
    ModusSwitchModule,
    LocalFilePickerComponent,
    ImportFileListComponent,
  ],
  templateUrl: './import-dialog.component.html',
  styles: [
    `
      :host {
        display: block;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportDialogComponent {
  private importStatus = signal<ImportStatus>(ImportStatus.NotStarted);
  private selectedFiles = signal<ImportFile[]>([]);
  private importScans = signal<ImportScan[]>([]);

  importFiles = computed(() => {
    const selectedFiles = this.selectedFiles();
    const importFiles = this.importScans().flatMap((importScan) => importScan.files);

    return selectedFiles.map((selectedFile) => {
      return importFiles.find((importFile) => importFile.id === selectedFile.id) ?? selectedFile;
    });
  });

  importStarted = computed(() => this.importStatus() !== ImportStatus.NotStarted);
  disableImport = computed(() => this.selectedFiles().length === 0 || this.importStarted());

  private cancelImport = new Subject<void>();

  cancelButtonText = computed(() =>
    this.importStatus() === ImportStatus.Error ? 'Close' : 'Cancel',
  );

  combineToSingleScanControl = new FormControl<boolean>(false, { nonNullable: true });

  private combineToSingleScan = toSignal(this.combineToSingleScanControl.valueChanges, {
    initialValue: this.combineToSingleScanControl.value,
  });

  constructor(
    private dialogRef: MatDialogRef<ImportDialogComponent, ImportDialogResult>,
    private dialogService: DialogService,
    private importService: ImportService,
  ) {
    this.createCombineInputEffect();
  }

  addFiles(addFiles: ImportFile[]) {
    // TODO: add limit of 1000 files (imposed by backend)
    const selectedFiles = this.selectedFiles();
    this.selectedFiles.set([...selectedFiles, ...addFiles]);
  }

  removeFiles(removeFiles: ImportFile[]) {
    const selectedFiles = this.selectedFiles();
    const filteredFiles = selectedFiles.filter((file) =>
      removeFiles.find((removeFile) => file.id !== removeFile.id),
    );
    this.selectedFiles.set(filteredFiles);
  }

  cancelClick() {
    const importStatus = this.importStatus();
    const showPrompt =
      (this.selectedFiles().length > 0 && importStatus === ImportStatus.NotStarted) ||
      importStatus === ImportStatus.Busy;

    if (!showPrompt) {
      this.dialogRef.close({ reloadScans: importStatus !== ImportStatus.NotStarted });
      return;
    }

    this.confirmCancel()
      .pipe(
        filter((cancel) => cancel),
        switchMap(() => this.cancelAndDeleteIncompleteImports()),
      )
      .subscribe(() => {
        const hasCompletedScans = this.importScans().some(
          (importScan) => this.getImportStatus([importScan]) === ImportStatus.Completed,
        );
        this.dialogRef.close({ reloadScans: hasCompletedScans });
      });
  }

  importClick() {
    this.importStatus.set(ImportStatus.Busy);

    const combineToSingleScan = this.combineToSingleScan();
    const importScans = this.getImportScans(this.selectedFiles(), combineToSingleScan);
    this.importScans.set(importScans);

    this.importService
      .import(importScans)
      .pipe(
        tap((importScans) => this.importScans.set(importScans)),
        map((importScans) => this.getImportStatus(importScans)),
        filter((importStatus) => importStatus !== ImportStatus.Busy),
        takeUntil(this.cancelImport),
      )
      .subscribe((importStatus) => {
        if (importStatus === ImportStatus.Completed) {
          this.dialogRef.close({ reloadScans: true });
          return;
        }

        this.importStatus.set(importStatus);
      });
  }

  private getImportScans(selectedFiles: ImportFile[], combineToSingleScan: boolean): ImportScan[] {
    return combineToSingleScan
      ? [this.getImportScan(selectedFiles)]
      : selectedFiles.map((selectedFile) => this.getImportScan([selectedFile]));
  }

  private getImportScan(selectedFiles: ImportFile[]): ImportScan {
    const name =
      selectedFiles.length === 1
        ? selectedFiles[0].name
        : selectedFiles.length > 1
          ? `${selectedFiles[0].name} (${selectedFiles.length} combined files)`
          : '';
    return {
      name,
      files: selectedFiles.map((selectedFile) => ({
        ...selectedFile,
        status: ImportFileStatus.Pending,
      })),
    };
  }

  private getImportStatus(importScans: ImportScan[]): ImportStatus {
    const importFiles = importScans.flatMap((importScan) => importScan.files);

    const busy = importFiles.some(
      (file) =>
        isNil(file.status) ||
        file.status === ImportFileStatus.Pending ||
        file.status === ImportFileStatus.Busy,
    );
    if (busy) return ImportStatus.Busy;

    const error = importFiles.some((file) => file.status === ImportFileStatus.Error);
    if (error) return ImportStatus.Error;

    return ImportStatus.Completed;
  }

  private confirmCancel() {
    const dialogTitle = this.importStatus() === ImportStatus.Busy ? 'Upload In Progress' : 'Upload';
    const dialogData = new DialogData(
      dialogTitle,
      'Are you sure you want to cancel this upload?',
      { text: 'Yes', color: 'danger' },
      CANCEL_BUTTON,
    );

    return this.dialogService.show(dialogData);
  }

  private cancelAndDeleteIncompleteImports() {
    this.cancelImport.next();

    const cancelImports = this.importScans().filter(
      (importScan) =>
        isDefined(importScan.scan) && this.getImportStatus([importScan]) === ImportStatus.Busy,
    );

    return cancelImports.length > 0 ? this.importService.cancelImport(cancelImports) : of(null);
  }

  private createCombineInputEffect() {
    effect(() => {
      if (this.importStatus() !== ImportStatus.NotStarted) {
        this.combineToSingleScanControl.disable();
        return;
      }

      const allowCombine = this.allowCombine(this.selectedFiles());
      if (!allowCombine) {
        this.combineToSingleScanControl.setValue(false);
        this.combineToSingleScanControl.disable();
        return;
      }

      this.combineToSingleScanControl.enable();
    });
  }

  private allowCombine(importFiles: ImportFile[]) {
    return (
      importFiles.length > 1 &&
      importFiles.every((file) => {
        return file.name.toLowerCase().endsWith('.las') || file.name.toLowerCase().endsWith('.laz');
      })
    );
  }
}
