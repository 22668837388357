import { signal } from '@angular/core';
import { ClassificationScheme } from '../../classification/classification-scheme.model';

export class ClassificationFormState {
  private validSignal = signal(true);
  private dirtySignal = signal(false);
  private valueSignal = signal<ClassificationScheme[]>([]);

  readonly valid = this.validSignal.asReadonly();
  readonly dirty = this.dirtySignal.asReadonly();
  readonly value = this.valueSignal.asReadonly();

  setValid(value: boolean) {
    this.validSignal.set(value);
  }

  setDirty(value: boolean) {
    this.dirtySignal.set(value);
  }

  setValue(value: ClassificationScheme[]) {
    this.valueSignal.set(value);
  }
}
