import { ConnectProject } from 'trimble-connect-workspace-api';

import { ConnectRegion } from '../connect/connect.models';
import { ProjectRegion } from '../project-region/project-region.models';
import { AppContext, AppSettings } from './app.models';

export class SetSettings {
  static readonly type = '[AppState] SetSettings';
  constructor(public readonly appSettings: AppSettings) {}
}

export class SetContext {
  static readonly type = '[AppState] SetContext';
  constructor(public readonly context: AppContext) {}
}

export class SetProject {
  static readonly type = '[AppState] SetProject';
  constructor(public readonly project: ConnectProject) {}
}

export class SetProjectRegion {
  static readonly type = '[AppState] SetProjectRegion';
  constructor(public readonly projectRegion: ProjectRegion) {}
}

export class SetConnectRegion {
  static readonly type = '[AppState] SetConnectRegion';
  constructor(public readonly connectRegion: ConnectRegion) {}
}

export class SetConnectRegions {
  static readonly type = '[AppState] SetConnectRegions';
  constructor(public readonly connectRegions: ConnectRegion[]) {}
}

export class SetUrlQuery {
  static readonly type = '[AppState] SetUrlQuery';
  constructor(public readonly urlQuery: string) {}
}
