<ng-template>
  <div class="modus-option-list" [ngStyle]="{ maxHeight: dropdownMaxHeight }">
    @if ((hasOptions$ | async) === true) {
      <ng-content select="modus-option"></ng-content>
    }

    @if ((hasOptions$ | async) === false) {
      <div class="text-sm px-4 pt-1">{{ noResultsFoundText }}</div>
      <div class="text-xs px-4 pb-1">{{ noResultsFoundSubtext }}</div>
    }
  </div>
</ng-template>
