import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModusButtonModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { PercentagePipe } from '../../../pipes/percentage.pipe';

export interface UsageValue {
  percentage: number;
  label: string;
}

@UntilDestroy()
@Component({
  selector: 'sd-usage-bar',
  standalone: true,
  imports: [ModusButtonModule, ModusIconModule, ModusTooltipModule, PercentagePipe],
  templateUrl: './usage-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsageBarComponent {
  @Input() usageValueBackground!: UsageValue;
  @Input() usageValueForeground!: UsageValue;
}
