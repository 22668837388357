import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import { EMPTY, filter, map, Observable, switchMap, take } from 'rxjs';
import { ConnectProject } from 'trimble-connect-workspace-api';
import { SetConnectRegion, SetProject } from '../../../app-state/app.actions';
import { AppState } from '../../../app-state/app.state';
import { ConnectRegionService } from '../../../connect/connect-region.service';
import { DialogService } from '../../../dialog/dialog.service';
import {
  ProjectSelectDialogComponent,
  projectSelectDialogDefaultConfig,
  ProjectSelectDialogResult,
} from './project-select-dialog.component';
import { ProjectSelectState } from './project-select.state';

@UntilDestroy()
@Component({
  selector: 'sd-project-select',
  standalone: true,

  imports: [CommonModule],
  templateUrl: './project-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSelectComponent {
  @Select(AppState.project) connectProject$!: Observable<ConnectProject | undefined>;

  projectName$: Observable<string>;

  constructor(
    private store: Store,
    private connectRegionService: ConnectRegionService,
    private dialogService: DialogService,
  ) {
    this.projectName$ = this.connectProject$.pipe(
      filter(isDefined),
      map((project) => project.name ?? ''),
    );

    this.setProjectFromCacheOrDefault();
  }

  showProjectPicker() {
    this.dialogService
      .showComponent<ProjectSelectDialogComponent, any, ProjectSelectDialogResult>(
        ProjectSelectDialogComponent,
        projectSelectDialogDefaultConfig,
      )
      .pipe(
        filter(isDefined),
        switchMap(({ project, region }) =>
          this.store.dispatch([new SetProject(project), new SetConnectRegion(region)]),
        ),
      )
      .subscribe(() => {
        window.location.reload();
      });
  }

  private setProjectFromCacheOrDefault() {
    this.getProjects()
      .pipe(
        map((projects) => {
          const cachedProject = this.store.selectSnapshot(ProjectSelectState.cachedProject);
          const project = projects.find((project) => project.id === cachedProject?.id);
          if (isDefined(project)) return project;

          return projects.at(0);
        }),
        switchMap((project) => {
          if (isNil(project)) return EMPTY;

          return this.store.dispatch(new SetProject(project));
        }),
        take(1),
      )
      .subscribe();
  }

  private getProjects() {
    return this.store.selectOnce(ProjectSelectState.cachedConnectRegion).pipe(
      switchMap((connectRegion) => {
        if (isNil(connectRegion)) return this.connectRegionService.getProjects();

        return this.connectRegionService.getProjectsForRegion(connectRegion);
      }),
    );
  }
}
