export enum RxCollectionChangeType {
  pop = 'pop',
  push = 'push',
  reverse = 'reverse',
  shift = 'shift',
  sort = 'sort',
  splice = 'splice',
  unshift = 'unshift',

  fill = 'fill',
  copyWithin = 'copyWithin',

  set = 'set',
  pull = 'pull',
}

export class RxCollectionChange {
  constructor(public readonly type: RxCollectionChangeType) {}

  static reverse() {
    return new RxCollectionChange(RxCollectionChangeType.reverse);
  }

  static sort(): RxCollectionChange | undefined {
    return new RxCollectionChange(RxCollectionChangeType.sort);
  }
}

export class RxCollectionPop<T> extends RxCollectionChange {
  constructor(public value: T) {
    super(RxCollectionChangeType.pop);
  }
}

export class RxCollectionPush<T> extends RxCollectionChange {
  constructor(
    public length: number,
    public items: T[],
  ) {
    super(RxCollectionChangeType.push);
  }
}

export class RxCollectionShift<T> extends RxCollectionChange {
  constructor(public value: T) {
    super(RxCollectionChangeType.shift);
  }
}

export class RxCollectionSplice<T> extends RxCollectionChange {
  constructor(
    public start: number,
    public deleteCount: number | undefined,
    public insertItems: T[],
    public deletedItems: T[],
  ) {
    super(RxCollectionChangeType.splice);
  }
}

export class RxCollectionUnshift<T> extends RxCollectionChange {
  constructor(
    public length: number,
    public items: T[],
  ) {
    super(RxCollectionChangeType.unshift);
  }
}

export class RxCollectionFill<T> extends RxCollectionChange {
  constructor(
    public value: T,
    public start?: number,
    public end?: number,
  ) {
    super(RxCollectionChangeType.fill);
  }
}

export class RxCollectionCopyWithin extends RxCollectionChange {
  constructor(
    public target: number,
    public start: number,
    public end?: number,
  ) {
    super(RxCollectionChangeType.copyWithin);
  }
}

export class RxCollectionSet<T> extends RxCollectionChange {
  constructor(
    public index: number,
    public oldValue: T,
    public newValue: T,
  ) {
    super(RxCollectionChangeType.set);
  }
}

export class RxCollectionPull<T> extends RxCollectionChange {
  constructor(
    public index: number,
    public item: T,
  ) {
    super(RxCollectionChangeType.pull);
  }
}
