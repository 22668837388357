/**
 * Copied source from https://www.npmjs.com/package/leaflet.repeatedmarkers/v/0.1.0,
 * because the leaflet plugin does not have typescript bindings.
 */

import L from 'leaflet';

const markerLayerOptions: L.GridLayerOptions = {
  maxNativeZoom: 0,
  noWrap: true,
  keepBuffer: 0,
};

export class MarkerLayer extends L.GridLayer {
  // A plain array to hold the original markers.
  private _masterMarkers: L.Marker[] = [];

  // A map of tile keys to arrays of markers.
  private _markersByTile: { [key: string]: L.Marker[] } = {};

  // A map of tile keys to longitude offsets
  private _offsetsByTile: { [key: string]: number } = {};

  constructor() {
    super(markerLayerOptions);
  }

  override createTile(coords: L.Coords): HTMLElement {
    const key = this._tileCoordsToKey(coords);
    const longitudeOffset = coords.x * 360;

    this._markersByTile[key] = [];
    this._offsetsByTile[key] = longitudeOffset;

    for (let i = 0, l = this._masterMarkers.length; i < l; i++) {
      this._markersByTile[key].push(this._addOffsetMarker(this._masterMarkers[i], longitudeOffset));
    }

    return L.DomUtil.create('div');
  }

  addMarker(marker: L.Marker) {
    if (!(marker instanceof L.Marker)) {
      throw new Error('L.GridLayer.RepeatedMarkers can only hold instances of L.Marker.');
    }

    // Adds the marker to the reference group, **and** to **each** of
    // the this._markersByTile arrays (and each time, add its copy to the map)

    this._masterMarkers.push(marker);

    for (const key in this._markersByTile) {
      this._markersByTile[key][L.stamp(marker)] = this._addOffsetMarker(
        marker,
        this._offsetsByTile[key],
      );
    }
  }

  removeMarker(marker: L.Marker) {
    const i = this._masterMarkers.indexOf(marker);
    if (i === -1) return;

    const markerId = L.stamp(marker);
    for (const key in this._markersByTile) {
      if (this._map) {
        this._markersByTile[key][markerId].remove();
      }
      delete this._markersByTile[key][markerId];
    }
  }

  private _addOffsetMarker(marker: L.Marker, longitudeOffset: number) {
    const originalLatLng = marker.getLatLng();
    const offsetLatLng = L.latLng(originalLatLng.lat, originalLatLng.lng + longitudeOffset);
    const copyMarker = L.marker(offsetLatLng, marker.options);

    // Copy the event handlers from the original marker.
    // This is a hard hack, going deep into the implementation of L.Evented
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const events = (marker as any)._events;
    for (const evName in events) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      events[evName].forEach(function (i: any) {
        copyMarker.on(evName, i.fn, i.ctx || copyMarker);
      });
    }
    // Copy a reference to the bound popup, if any
    // copyMarker._popup = marker._popup;

    if (this._map) {
      this._map.addLayer(copyMarker);
    }
    return copyMarker;
  }

  // This method is not exposed by leaflet type definition, but exists on GridLayer
  private _removeTile(key: string) {
    const markersToRemove = this._markersByTile[key];
    for (let i = 0, l = markersToRemove.length; i < l; i++) {
      if (this._map && markersToRemove[i]) {
        markersToRemove[i].removeFrom(this._map);
      }
    }
    delete this._markersByTile[key];
    delete this._offsetsByTile[key];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (L.GridLayer.prototype as any)._removeTile.call(this, key);
  }
}
