import { BaseLayer } from './base-layer.models';

export class SetActiveBaseLayer {
  static readonly type = '[BaseLayer] SetActiveBaseLayer';
  constructor(public activeBaseLayer: BaseLayer) {}
}

export class SetBaseLayers {
  static readonly type = '[BaseLayer] SetBaseLayers';
  constructor(public baseLayers: BaseLayer[]) {}
}
