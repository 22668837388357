import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetInfoView, SetView, TogglePreviewOpen } from './options-panel.actions';

export enum OptionsPanelView {
  None = 'None',
  DownloadStatus = 'DownloadStatus',
  ListFilters = 'ListFilters',
  SelectedDetails = 'SelectedDetails',
  ClassificationEdit = 'ClassificationEdit',
}

export enum OptionsPanelInfoView {
  Properties = 'Properties',
  Tagging = 'Tagging',
  Classification = 'Classification',
}

export interface OptionsPanelStateModel {
  view: OptionsPanelView;
  previewOpen: boolean;
  infoView: OptionsPanelInfoView;
}

const defaultState: OptionsPanelStateModel = {
  view: OptionsPanelView.None,
  previewOpen: true,
  infoView: OptionsPanelInfoView.Properties,
};

@State<OptionsPanelStateModel>({
  name: 'optionsPanelState',
  defaults: defaultState,
})
@Injectable()
export class OptionsPanelState {
  @Selector() static view(state: OptionsPanelStateModel) {
    return state.view ?? OptionsPanelView.None;
  }

  @Selector() static previewOpen(state: OptionsPanelStateModel) {
    return state.previewOpen ?? true;
  }

  @Selector() static infoView(state: OptionsPanelStateModel) {
    return state.infoView ?? OptionsPanelInfoView.Properties;
  }

  @Action(SetView) setView(ctx: StateContext<OptionsPanelStateModel>, action: SetView) {
    let setView = action.view;
    if (setView === ctx.getState().view && action.withToggle) {
      setView = OptionsPanelView.None;
    }

    ctx.patchState({ view: setView });
  }

  @Action(TogglePreviewOpen) togglePreviewOpen(ctx: StateContext<OptionsPanelStateModel>) {
    ctx.patchState({ previewOpen: !(ctx.getState().previewOpen ?? true) });
  }

  @Action(SetInfoView) setInfoPreview(
    ctx: StateContext<OptionsPanelStateModel>,
    action: SetInfoView,
  ) {
    ctx.patchState({ infoView: action.infoView });
  }
}
