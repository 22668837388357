import { Scan3dStyle } from './models/scan-3d-style';

export class SetGlobalStyle {
  static readonly type = '[Scan3d] SetGlobalStyle';
  constructor(public readonly style: Scan3dStyle) {}
}

export class CloseTreeMenu {
  static readonly type = '[Scan3d] CloseTreeMenu';
}

export class CloseSortMenu {
  static readonly type = '[Scan3d] CloseSortMenu';
}
