export interface EasterEgg {
  title: string;
  path: string;
}

export const easterEggs: EasterEgg[] = [
  {
    title: 'The first rule of Seurat',
    path: 'the-first-rule-of-seurat',
  },
  {
    title: 'One does not simply',
    path: 'one-does-not-simply',
  },
  {
    title: 'What if I told you',
    path: 'what-if-i-told-you',
  },
  {
    title: 'There is no spoon',
    path: 'there-is-no-spoon',
  },
  {
    title: 'Whose spec is it?',
    path: 'whos-spec-is-it-anyway',
  },
  {
    title: 'Yoda',
    path: 'yoda',
  },
  {
    title: 'Her and Him',
    path: 'her-and-him',
  },
  {
    title: 'Chuck Norris',
    path: 'chuck-norris',
  },
  {
    title: 'Why?',
    path: 'connect-extension-why',
  },
  {
    title: 'South Park',
    path: 'south-park',
  },
  {
    title: 'Go on... Say it',
    path: 'go-on-say-it',
  },
  {
    title: 'Disaster Girl',
    path: 'disaster-girl',
  },
];
