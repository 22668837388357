import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { Observable, filter, map, switchMap } from 'rxjs';
import { ScandataState } from '../scandata/scandata.state';
import { SetScannerTypes } from './scanner-type.actions';
import { ScannerTypeState } from './scanner-type.state';

@Injectable({
  providedIn: 'root',
})
export class ScannerTypeService {
  constructor(private store: Store) {}

  getScannerTypes(): Observable<string[]> {
    return this.getScandataScannerTypes().pipe(
      map((scandataScannerTypes) => {
        const scannerTypes = this.store.selectSnapshot(ScannerTypeState.scannerTypes);
        return [...scandataScannerTypes, ...scannerTypes];
      }),
      map((scannerTypes) => {
        const uniqueScannerTypes = [...new Set(scannerTypes)];
        return uniqueScannerTypes.sort((a, b) => a.localeCompare(b));
      }),
      switchMap((scannerTypes) => {
        return this.store.dispatch(new SetScannerTypes(scannerTypes)).pipe(map(() => scannerTypes));
      }),
    );
  }

  private getScandataScannerTypes(): Observable<string[]> {
    return this.store.select(ScandataState.scandata).pipe(
      filter((scandata) => scandata.length > 0),
      map((scandata) => {
        return scandata
          .filter((item) => isDefined(item.scannerType) && item.scannerType.trim().length > 0)
          .map((item) => item.scannerType as string);
      }),
    );
  }
}
