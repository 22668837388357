import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, switchMap } from 'rxjs';
import { GET_SCAN_PROJECT_URL } from '../utils/get-scan-project-url';
import { Role, User } from './user.models';
import { SetUser } from './user.state';

interface UserResponse {
  /** Connect user id. Not the same as TID. */
  id: string;

  /** Trimble Identity - TID. */
  tidUuid: string;

  title?: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  language?: string;
  role: Role;
  roleClaims?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly getScanProjectUrl$ = inject(GET_SCAN_PROJECT_URL);

  constructor(
    private http: HttpClient,
    private store: Store,
  ) {}

  getUsers() {
    return this.getScanProjectUrl$('/users').pipe(
      switchMap((url) => {
        return this.http.get<UserResponse[]>(url);
      }),
      map((users) => users.map((user) => this.mapUser(user))),
    );
  }

  loadUser() {
    return this.getCurrentUser().pipe(switchMap((user) => this.store.dispatch(new SetUser(user))));
  }

  private getCurrentUser() {
    return this.getScanProjectUrl$('/users/me').pipe(
      switchMap((url) => this.http.get<UserResponse>(url)),
      map((user) => this.mapUser(user)),
    );
  }

  private mapUser(user: UserResponse): User {
    return {
      id: user.tidUuid,
      connectId: user.id,
      title: user.title,
      firstName: user.firstName,
      lastName: user.lastName,
      fullName: user.fullName,
      email: user.email,
      language: user.language,
      role: user.role,
      roleClaims: user.roleClaims,
    };
  }
}
