import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, Observable, of, switchMap } from 'rxjs';
import { PageData } from '../shared/page-data.model';
import { GET_SCAN_PROJECT_URL } from '../utils/get-scan-project-url';
import { ScandataFile } from './scandata-file.model';
import { PatchScandataModel } from './scandata.actions';
import { ScandataModel } from './scandata.models';

@Injectable({
  providedIn: 'root',
})
export class ScandataFileService {
  private readonly getScanProjectUrl$ = inject(GET_SCAN_PROJECT_URL);

  constructor(
    private store: Store,
    private http: HttpClient,
  ) {}

  loadScandataFiles(scan: ScandataModel): Observable<ScandataModel> {
    return this.getScanProjectUrl$(`/pointclouds/${scan.id}/files`).pipe(
      switchMap((url) => {
        return this.http.get<PageData<ScandataFile>>(url).pipe(
          map((pageData) => {
            return { pageData, url };
          }),
        );
      }),
      switchMap(({ pageData, url }) => {
        if (pageData.items.length === pageData.totalItems) return of(pageData);

        const pageing = `pageSize=${pageData.totalItems}&pageIndex=0`;
        return this.http.get<PageData<ScandataFile>>(`${url}?${pageing}`);
      }),
      map((pageData) => pageData.items),
      switchMap((files) => {
        return this.store
          .dispatch(new PatchScandataModel({ ...scan, files }))
          .pipe(map(() => scan));
      }),
    );
  }
}
