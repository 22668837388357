import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { Observable, catchError, filter, map, of, switchMap } from 'rxjs';
import { ConnectFile } from 'trimble-connect-workspace-api';
import { AppState } from '../app-state/app.state';
import { ScandataModel } from '../scandata/scandata.models';
import { ScandataService } from '../scandata/scandata.service';
import {
  GetClientIdentificationHeaders,
  IngestionSource,
} from '../utils/client-identification-headers';
import { GET_SCAN_PROJECT_URL } from '../utils/get-scan-project-url';
import { AddTilingWorkflow, RemoveTilingWorkflow } from '../workflow/workflow.actions';
import { getExternalFileId } from './external-file-id-utils';
import { ConnectIngestion, ImportFile, ImportStatus } from './models/connect-ingestion';

@Injectable({
  providedIn: 'root',
})
export class ConnectIngestionService {
  private readonly getScanProjectUrl$ = inject(GET_SCAN_PROJECT_URL);

  constructor(
    private http: HttpClient,
    private scandataService: ScandataService,
    private store: Store,
  ) {}

  public createIngestion(file: ConnectFile, downloadUrl: string, ingestionSource: IngestionSource) {
    const externalFileId = getExternalFileId(file) as string;

    return this.store.dispatch(AddTilingWorkflow).pipe(
      switchMap(() => this.getScanByExternalFileId(file)),
      switchMap((scan) =>
        isDefined(scan)
          ? of(scan)
          : this.createPointcloud(file.name, externalFileId, ingestionSource),
      ),
      switchMap((scan) => this.createImport(scan.id, scan.name, downloadUrl).pipe(map(() => scan))),
      switchMap(() => this.updateScanInStore(file)),
      switchMap(() => this.store.dispatch(RemoveTilingWorkflow)),
      catchError((err) => {
        this.store.dispatch(RemoveTilingWorkflow);
        throw err;
      }),
    );
  }

  public getIngestion(file: ConnectFile): Observable<ConnectIngestion> {
    return this.getScanByExternalFileId(file).pipe(
      filter((scan): scan is ScandataModel => isDefined(scan)),
      map((scan) => ({ file, scan })),
    );
  }

  public getScan(file: ConnectFile) {
    // Cater for scans with externalFileId = file.id
    return this.getScanByExternalFileId(file).pipe(
      switchMap((scan) =>
        isDefined(scan) ? of(scan) : this.scandataService.getScanByExternalFileId(file.id),
      ),
    );
  }

  private createImport(pointcloudId: string, name: string, sourceUrl: string) {
    const importFile: ImportFile = {
      sourceUrl,
      targetFileName: name,
    };

    return this.getScanProjectUrl$(`/pointclouds/${pointcloudId}/imports`).pipe(
      switchMap((url) =>
        this.http.post<ImportStatus>(url, {
          files: [importFile],
        }),
      ),
    );
  }

  private createPointcloud(name: string, externalFileId: string, ingestionSource: IngestionSource) {
    const appSettings = this.store.selectSnapshot(AppState.settings);

    return this.getScanProjectUrl$('/pointclouds').pipe(
      switchMap((url) =>
        this.http.post<ScandataModel>(
          url,
          {
            name,
            externalFileId,
          },
          { headers: GetClientIdentificationHeaders(appSettings, ingestionSource) },
        ),
      ),
    );
  }

  private getScanByExternalFileId(file: ConnectFile) {
    const externalFileId = getExternalFileId(file) as string;
    return this.scandataService.getScanByExternalFileId(externalFileId);
  }

  private updateScanInStore(file: ConnectFile) {
    // scandataService.getScanByExternalFileId updates scan with
    // necessary properties and upserts into the store
    return this.getScanByExternalFileId(file);
  }
}
