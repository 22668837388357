import { Coordinate } from './coordinate';

export class Vector3 {
  constructor(
    public x: number,
    public y: number,
    public z: number,
  ) {}

  public add(other: Vector3): Vector3 {
    return new Vector3(this.x + other.x, this.y + other.y, this.z + other.z);
  }

  public subtract(other: Vector3): Vector3 {
    return new Vector3(this.x - other.x, this.y - other.y, this.z - other.z);
  }

  public multiplyScalar(scalar: number): Vector3 {
    return new Vector3(this.x * scalar, this.y * scalar, this.z * scalar);
  }

  public magnitude(): number {
    return Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z);
  }

  public normalize(): Vector3 {
    const length = this.magnitude();
    return this.multiplyScalar(1 / length);
  }

  public dot(other: Vector3): number {
    return this.x * other.x + this.y * other.y + this.z * other.z;
  }

  public match(other: Vector3, threshold: number): boolean {
    const distance = this.subtract(other).magnitude();
    const isMatch = distance <= threshold;
    return isMatch;
  }

  static fromCoordinate(coordinate: Coordinate): Vector3 {
    return new Vector3(coordinate.x, coordinate.y, coordinate.z);
  }
}
