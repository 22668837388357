import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  OnDestroy,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Observable, Subject, map, merge, startWith, switchMap, takeUntil } from 'rxjs';
import { ModusMenuItem } from '../modus-menu-item/modus-menu-item.component';

/* eslint-disable @angular-eslint/component-class-suffix */

@Component({
  selector: 'md-menu',
  exportAs: 'modusMenu',
  templateUrl: './modus-menu.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModusMenu implements AfterContentInit, OnDestroy {
  private readonly _destroy$ = new Subject<void>();
  private _isOpen = false;

  @ViewChild(TemplateRef, { static: true }) template!: TemplateRef<unknown>;
  @ContentChildren(ModusMenuItem) items!: QueryList<ModusMenuItem>;

  hasItems$!: Observable<boolean>;

  get isOpen(): boolean {
    return this._isOpen;
  }

  @Output() itemClick = new EventEmitter<void>();

  ngAfterContentInit(): void {
    this.hasItems$ = this.items.changes.pipe(
      startWith(this.items),
      map((queryList: QueryList<ModusMenuItem>) => queryList.length > 0),
      takeUntil(this._destroy$),
    );

    this.items.changes
      .pipe(
        startWith(this.items),
        switchMap((list: QueryList<ModusMenuItem>) =>
          merge(...list.map((item: ModusMenuItem) => item.itemClick)),
        ),
        takeUntil(this._destroy$),
      )
      .subscribe(() => {
        this.itemClick.emit();
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this.itemClick.complete();
  }

  open() {
    this._isOpen = true;
  }

  close() {
    this._isOpen = false;
  }
}
