import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  computed,
  effect,
  inject,
  input,
  signal,
} from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import '@google/model-viewer';
import { ModelViewerElement } from '@google/model-viewer';
import { UntilDestroy } from '@ngneat/until-destroy';
import { isNil } from '@trimble-gcs/common';

@UntilDestroy()
@Component({
  selector: 'sd-viewer-glb',
  standalone: true,
  imports: [CommonModule, MatProgressBarModule],
  templateUrl: './viewer-glb.component.html',
  styles: [
    `
      :host {
        display: flex;
        flex: 1 1 0%;
        height: 100%;

        ::ng-deep model-viewer::part(default-progress-bar) {
          display: none;
        }
      }
    `,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ViewerGlbComponent {
  private element: HTMLElement = inject(ElementRef).nativeElement;

  sourceUri = input<string>();
  loadProgress = signal(0);
  showLoader = computed(() => this.loadProgress() < 100);

  private get container() {
    return this.element.getElementsByClassName('viewer-container').item(0);
  }

  constructor() {
    this.monitorSourceUri();
  }

  updateProgress(event: unknown) {
    const progressEvent = event as {
      detail: { totalProgress: number };
    };

    const progress = progressEvent.detail.totalProgress * 100;
    this.loadProgress.set(progress);
  }

  private monitorSourceUri() {
    /**
     * The model-viewer has a bug, where if you set a new src while a src
     * is busy loading, the viewer will ignore the new src and continue to
     * display the src it is busy loading.
     *
     * Currently the model-viewer has no way to cancel loading a src either.
     *
     * So the fix here is to remove the model-viewer from the DOM and add
     * a new instance.
     */

    return effect(
      () => {
        const modelUri = this.sourceUri();
        this.destroyModelViewer();

        if (isNil(modelUri)) return;

        this.loadProgress.set(0);
        this.createModelViewer(modelUri);
      },
      { allowSignalWrites: true },
    );
  }

  private createModelViewer(modelUri: string) {
    if (isNil(this.container)) return;

    const modelViewer = new ModelViewerElement();

    modelViewer.classList.add('flex-1', 'h-full', 'w-full');
    modelViewer.addEventListener('progress', this.updateProgress.bind(this));

    modelViewer.shadowIntensity = 1;
    modelViewer.cameraControls = true;
    modelViewer.autoRotate = true;
    modelViewer.src = modelUri;

    this.container.appendChild(modelViewer);
  }

  private destroyModelViewer() {
    const modelViewer = this.container?.firstElementChild as ModelViewerElement;

    if (isNil(modelViewer)) return;

    modelViewer.removeEventListener('progress', this.updateProgress);
    modelViewer.remove();
  }
}
