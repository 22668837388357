import { InjectionToken, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { MonoTypeOperatorFunction, pipe, tap } from 'rxjs';
import { ErrorType } from './error-type';
import { ClearError, SetError } from './error.actions';
import { ErrorState } from './error.state';

export const HANDLE_ERROR = new InjectionToken('RxJs error handling operator', {
  factory: () => {
    const store = inject(Store);
    return <T>(type: ErrorType): MonoTypeOperatorFunction<T> => {
      return handleError(store, type);
    };
  },
});

function handleError<T>(store: Store, type: ErrorType): MonoTypeOperatorFunction<T> {
  return pipe(
    tap({
      next: () => {
        const hasError = store.selectSnapshot(ErrorState.hasError(type));
        if (hasError) store.dispatch(new ClearError(type));
      },
      error: (err) => {
        store.dispatch(new SetError(type));
        throw err;
      },
    }),
  );
}
