import bbox from '@turf/bbox';
import booleanIntersects from '@turf/boolean-intersects';
import centroid from '@turf/centroid';
import { AllGeoJSON } from '@turf/helpers';

import { BBox, Feature, FeatureCollection, Geometry, GeometryCollection } from 'geojson';
import { LatLngBounds, geoJSON, latLngBounds } from 'leaflet';

// turf.js implementation (3x to 5x faster than leaflet implementation)
export function calculateBounds(geoJson: FeatureCollection | Feature): BBox {
  return bbox(geoJson);
}

// leaflet implementation (if we don't want to include turf.js)
export function calculateBoundsAlt(featureCollection: FeatureCollection): BBox {
  const layer = geoJSON(featureCollection);
  return convertLeafletBoundsToBBox(layer.getBounds());
}

export function bboxEquals(bbox1: BBox, bbox2: BBox): boolean {
  return bbox1.length === bbox2.length && bbox1.every((element, index) => element === bbox2[index]);
}

export function convertLeafletBoundsToBBox(latLng: LatLngBounds): BBox {
  return [
    latLng.getSouthWest().lng,
    latLng.getSouthWest().lat,
    latLng.getNorthEast().lng,
    latLng.getNorthEast().lat,
  ];
}

export function convertBBoxToLeafletBounds(bbox: BBox): LatLngBounds {
  const [west, south, east, north] = bbox;
  return latLngBounds([south, west], [north, east]);
}

export function calculateCentroid(
  geoJson: Feature | FeatureCollection | Geometry | GeometryCollection,
) {
  return centroid(geoJson as AllGeoJSON);
}

export function getBBoxWidthAndHeight(bbox: BBox): { width: number; height: number } {
  const width = Math.abs(bbox[0] - bbox[2]);
  const height = Math.abs(bbox[1] - bbox[3]);
  return { width, height };
}

export function offsetPolygon(
  polygon: GeoJSON.Polygon,
  lngOffset = 0,
  latOffset = 0,
): GeoJSON.Polygon {
  const offsetCoordinates = polygon.coordinates.map((polygons) => {
    return polygons.map((position) => {
      return [position[0] + lngOffset, position[1] + latOffset];
    });
  });

  const offsetPolygon: GeoJSON.Polygon = {
    type: 'Polygon',
    coordinates: offsetCoordinates,
  };

  return offsetPolygon;
}

export function intersects(poly1: GeoJSON.Polygon, poly2: GeoJSON.Polygon) {
  return booleanIntersects(poly1, poly2);
}
