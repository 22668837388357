import { CommonModule } from '@angular/common';
import { Component, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map } from 'rxjs';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [CommonModule],
  template: `<div class="flex flex-colborder border-solid border-gray-200 m-2 shadow-md">
    <img class="object-contain h-[calc(100vh-136px)]" [src]="meme()" />
  </div>`,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    `,
  ],
})
export class EasterEggComponent {
  meme: Signal<string>;

  constructor(router: Router) {
    const image$ = router.events.pipe(
      filter((evt) => evt instanceof NavigationEnd),
      map((evt) => {
        const param = (evt as NavigationEnd).url.slice(12);
        const image = `/assets/memes/${param}.jpg`;
        return image;
      }),
      untilDestroyed(this),
    );

    this.meme = toSignal(image$, { initialValue: '/assets/memes/the-first-rule-of-seurat.jpg' });
  }
}
