import { hasModifierKey } from '@angular/cdk/keycodes';
import {
  ConnectionPositionPair,
  HorizontalConnectionPos,
  Overlay,
  OverlayConfig,
  OverlayRef,
  PositionStrategy,
  ScrollDispatcher,
  VerticalConnectionPos,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { Subject, Subscription, filter, fromEvent, map, takeUntil } from 'rxjs';
import {
  IntersectionObserverEntryEx,
  createIntersectionObserver,
} from '../utils/intersection-observer';
import { isDefined, isNil, last } from '../utils/utils';
import { ModusMenu } from './modus-menu-component/modus-menu.component';

/* eslint-disable @angular-eslint/directive-class-suffix */

type MenuPositionX = 'before' | 'after';
type MenuPositionY = 'above' | 'below';

@Directive({
  selector: `[modus-menu-trigger-for], [modusMenuTriggerFor]`,
  exportAs: 'modusMenuTrigger',
})
export class ModusMenuTrigger implements AfterViewInit, OnDestroy {
  private readonly _document = inject(DOCUMENT);
  private readonly _element = inject(ElementRef).nativeElement;
  private readonly _overlay = inject(Overlay);
  private readonly _viewContainerRef = inject(ViewContainerRef);
  private readonly _scrollDispatcher = inject(ScrollDispatcher);

  private readonly _destroy$ = new Subject<void>();
  private readonly _panelClass = 'modus-menu-panel';
  private readonly _topIntersectionThreshold = 0.5;
  private readonly _bottomIntersectionThreshold = 0.5;

  private _closeSubscription = Subscription.EMPTY;

  private _menu?: ModusMenu;
  private _overlayRef?: OverlayRef;

  @Input('modusMenuTriggerFor') set menu(value: ModusMenu) {
    if (value === this._menu) return;
    this._menu = value;
  }

  @Input() xPosition: MenuPositionX = 'before';
  @Input() yPosition: MenuPositionY = 'below';

  ngAfterViewInit(): void {
    this.subscribeToTriggerClick();
    this.subscribeToIntersectionObserver();
  }

  ngOnDestroy(): void {
    this.closeMenu();
    this._destroy$.next();
    this._destroy$.complete();
    this._closeSubscription.unsubscribe();
  }

  toggleMenu() {
    if (isNil(this._menu)) return;

    !this._menu.isOpen ? this.openMenu() : this.closeMenu();
  }

  openMenu() {
    if (isNil(this._menu) || this._menu.isOpen) return;

    this._menu.open();
    this._overlayRef = this.createOverlayRef();
    this._overlayRef.attach(new TemplatePortal(this._menu.template, this._viewContainerRef));

    this._closeSubscription.unsubscribe();
    this._closeSubscription = this._menu.itemClick
      .pipe(
        filter(() => this._menu?.isOpen ?? false),
        takeUntil(this._destroy$),
      )
      .subscribe(() => this.closeMenu());
  }

  closeMenu() {
    if (isNil(this._menu) || !this._menu.isOpen) return;

    this._menu.close();
    this._overlayRef?.dispose();
    this._overlayRef = undefined;
    this._closeSubscription.unsubscribe();
  }

  private subscribeToTriggerClick() {
    fromEvent<PointerEvent>(this._element, 'click')
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.toggleMenu());
  }

  private subscribeToIntersectionObserver(): void {
    const options: IntersectionObserverInit = {
      threshold: [this._topIntersectionThreshold, this._bottomIntersectionThreshold],
    };

    const isIntersecting = (entry: IntersectionObserverEntryEx) =>
      entry.intersection.top
        ? entry.intersectionRatio >= this._topIntersectionThreshold
        : entry.intersection.bottom
          ? entry.intersectionRatio >= this._bottomIntersectionThreshold
          : true;

    createIntersectionObserver(this._element, options)
      .pipe(
        map(last),
        filter(isDefined),
        filter((entry) => !isIntersecting(entry)),
        filter(() => this._menu?.isOpen ?? false),
        takeUntil(this._destroy$),
      )
      .subscribe(() => this.closeMenu());
  }

  private createOverlayRef(): OverlayRef {
    const overlayRef = this._overlay.create(this.getOverlayConfig());

    overlayRef
      .outsidePointerEvents()
      .pipe(takeUntil(this._destroy$))
      .subscribe((event) => {
        this.closeMenu();

        if (this.isPointerEventOnElement(event)) {
          event.stopPropagation();
        }
      });

    overlayRef
      .keydownEvents()
      .pipe(
        filter(({ key }) => /Escape|Tab/.test(key)),
        filter((event) => !hasModifierKey(event)),
        takeUntil(this._destroy$),
      )
      .subscribe(() => this.closeMenu());

    return overlayRef;
  }

  private getOverlayConfig(): OverlayConfig {
    const overlayConfig = new OverlayConfig({
      panelClass: this._panelClass,
      positionStrategy: this.getPositionStrategy(),
      scrollStrategy: this._overlay.scrollStrategies.reposition(),
      width: this._element.getBoundingClientRect().width,
    });
    return overlayConfig;
  }

  private getPositionStrategy(): PositionStrategy {
    const scrollableAncestors = this._scrollDispatcher.getAncestorScrollContainers(this._element);

    const positionStrategy = this._overlay
      .position()
      .flexibleConnectedTo(this._element)
      .withPositions(this.getPositions())
      .withScrollableContainers(scrollableAncestors)
      .withPush(false);

    return positionStrategy;
  }

  private getPositions(): ConnectionPositionPair[] {
    const [originX, originX2]: HorizontalConnectionPos[] =
      this.xPosition === 'before' ? ['end', 'start'] : ['start', 'end'];

    const [originY, originY2]: VerticalConnectionPos[] =
      this.yPosition === 'above' ? ['top', 'bottom'] : ['bottom', 'top'];

    const [overlayX, overlayX2] = [originX, originX2];
    const [overlayY, overlayY2] = [originY2, originY];

    return [
      { originX: originX, originY: originY, overlayX: overlayX, overlayY: overlayY },
      { originX: originX2, originY: originY, overlayX: overlayX2, overlayY: overlayY },
      { originX: originX, originY: originY2, overlayX: overlayX, overlayY: overlayY2 },
      { originX: originX2, originY: originY2, overlayX: overlayX2, overlayY: overlayY2 },
    ];
  }

  private isPointerEventOnElement(event: MouseEvent) {
    const elementFromPoint = this._document.elementFromPoint(event.clientX, event.clientY);
    return elementFromPoint === this._element;
  }
}
