<!-- header -->
<div mat-dialog-title class="flex flex-row p-4 items-center dark:text-white dark:bg-gray-9">
  <div class="flex flex-1 flex-wrap items-center font-bold">
    {{ data.title }}
  </div>
  <button modus-icon-button (click)="cancelClick()">
    <md-icon>close</md-icon>
  </button>
</div>

<!-- content -->
<div
  mat-dialog-content
  class="flex-auto p-4 text-sm border-t border-b border-gray-0 dark:border-gray-6 dark:text-white dark:bg-gray-9"
>
  <span class="whitespace-pre-wrap">{{ data.message }}</span>
</div>

<!-- actions -->
<div mat-dialog-actions class="flex h-16 p-4 justify-end items-center dark:bg-gray-9">
  @if (data.cancelButton) {
    <button
      modus-stroked-button
      [color]="data.cancelButton.color"
      class="mr-2"
      (click)="cancelClick()"
    >
      {{ data.cancelButton.text }}
    </button>
  }
  <button modus-flat-button [color]="data.okButton.color" (click)="okClick()">
    {{ data.okButton.text }}
  </button>
</div>
