<div class="h-full flex flex-col mx-4 items-center justify-center">
  <div class="text-center text-sm">
    <div class="text-lg font-semibold mt-8">Extension Permission Denied</div>

    <p class="mt-2">This extension requires permission.</p>

    @switch (currentView()) {
      @case (view.Extension3D) {
        <p class="mt-2">You can 'Reset permission' for this extension from the Settings menu.</p>
      }
      @default {
        <p class="mt-2">
          You can 'Reset authorization' for this extension from the Settings > Extensions page.
        </p>

        <button id="view-extensions" class="mt-4" modus-flat-button (click)="viewExtensions()">
          Go To Settings > Extensions
        </button>
      }
    }
  </div>
</div>
