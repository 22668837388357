<!-- error alert -->
@if (scanDetailsSaveError$ | async) {
  <div class="w-full mb-4">
    <modus-alert
      class="w-full"
      message="Error saving tag(s). Please try again later."
      type="error"
    ></modus-alert>
  </div>
}

<div class="flex flex-col">
  <!-- tags -->
  <div class="flex justify-start flex-wrap">
    @for (tag of selectedTags$ | async; track tag) {
      <modus-chip
        class="m-1"
        show-close
        [value]="tag"
        (closeClick)="remove(tag)"
        [disabled]="(isSaving$ | async)!"
      >
      </modus-chip>
    }
  </div>

  <!-- autocomplete-->

  <modus-autocomplete-input
    #autocomplete
    placeholder="Begin typing to search..."
    [clearable]="true"
    [errorText]="(errorText$ | async) ?? ''"
    [options]="tagOptionList"
    [formControl]="tagSelector"
    (optionSelected)="selected($event)"
  >
  </modus-autocomplete-input>
  <modus-option-list #tagOptionList="modusOptionList">
    @for (tag of filteredTags$ | async; track tag) {
      <modus-option [value]="tag">{{ tag }}</modus-option>
    }
  </modus-option-list>
</div>
