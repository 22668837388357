import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'area',
  standalone: true,
})
export class AreaPipe implements PipeTransform {
  transform(sizeInSquareMetres: number, decimalPlaces = 2): string {
    if (sizeInSquareMetres === null || isNaN(sizeInSquareMetres) || sizeInSquareMetres <= 0) {
      return '';
    }

    if (sizeInSquareMetres < 1000000) {
      return `${sizeInSquareMetres.toFixed(0)} m²`;
    } else {
      return `${(sizeInSquareMetres * 0.000001).toFixed(decimalPlaces)} km²`;
    }
  }
}
