<modus-form-field [hideRequiredMarker]="!hasLabel">
  @if (hasLabel) {
    <modus-label>{{ label }}</modus-label>
  }

  @if (includeSearchIcon) {
    <md-icon modus-icon-prefix>search</md-icon>
  }

  <input
    #autocompleteInput
    modus-input
    type="text"
    [id]="formFieldControl.id"
    [required]="formFieldControl.required"
    [readonly]="formFieldControl.readonly"
    [attr.readonly]="formFieldControl.readonly ? true : null"
    [placeholder]="placeholder ?? ''"
    [formControl]="formControl"
    [size]="size"
  />

  @if (clearable) {
    <button
      #autocompleteClearButton
      modus-icon-button
      modus-icon-suffix
      [attr.disabled]="formFieldControl.disabled || formFieldControl.readonly ? true : null"
      (click)="clear($event)"
    >
      <md-icon>close</md-icon>
    </button>
  }

  @if (hasErrorText) {
    <modus-error #errorLabel>{{ errorText }}</modus-error>
  }
</modus-form-field>
