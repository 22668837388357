import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { Observable, filter, map, switchMap } from 'rxjs';
import manifest from '../../../manifest.json';
import { AppState } from '../../app-state/app.state';
import { AppRoute } from '../../app.routes';
import { AuthState } from '../../auth/auth.state';
import { Connect3dPanelService } from '../../connect/connect-3d-panel.service';
import { ConnectPanelService } from '../../connect/connect-panel.service';
import { ConnectCommand, ConnectWorkspace } from '../../connect/connect.models';
import { ConnectService } from '../../connect/connect.service';
import { ClearError } from '../../error-handling/error.actions';
import { noopErrorObserver } from '../../logging/noop-error-observer';
import { FeatureLayerService } from '../../map/feature-layer/feature-layer.service';
import { ScandataService } from '../../scandata/scandata.service';
import { ScandataState } from '../../scandata/scandata.state';
import { createGeospatialMenu } from './ext-host.menu';

@UntilDestroy()
@Component({
  selector: 'sd-ext-host',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './ext-host.component.html',
  styleUrls: ['./ext-host.component.scss'],
})
export class ExtHostComponent implements OnInit {
  private workspace!: ConnectWorkspace;

  readonly activeLink$: Observable<string>;

  constructor(
    private router: Router,
    private store: Store,
    private connectService: ConnectService,
    private connectPanelService: ConnectPanelService,
    private connect3dPanelService: Connect3dPanelService,
    private scandataService: ScandataService,
    private featureLayerService: FeatureLayerService,
  ) {
    this.activeLink$ = this.router.events.pipe(
      filter((evt) => evt instanceof NavigationEnd),
      map((evt) => {
        const url = (evt as NavigationEnd).url;
        return url.startsWith('/') ? url.slice(1) : url;
      }),
    );
  }

  async ngOnInit(): Promise<void> {
    this.workspace = await this.connectService.getWorkspace();
    this.setExtensionMenu();
    this.subscribeToNavigationCommands();
    this.connectPanelService.subscribeToConnectEvents();
    await this.connect3dPanelService.subscribeToConnectEvents();
  }

  navigate(path: string) {
    this.router.navigate([path]);
  }

  private setExtensionMenu() {
    // LM: Connect Workspace API is the same for file view and 3d view.
    // setMenu throws an error if called in Connect 3d view
    this.workspace.api.ui
      .setMenu(createGeospatialMenu(this.store.selectSnapshot(AppState.settings)))
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  }

  private subscribeToNavigationCommands() {
    this.workspace.command$.pipe(untilDestroyed(this)).subscribe({
      next: (event) => {
        switch (event.data) {
          case manifest.configCommand:
            this.router.navigate([AppRoute.Config]);
            break;

          case ConnectCommand.ScandataBrowser:
            this.router.navigate([''], { onSameUrlNavigation: 'reload' });
            this.reloadScansAndFeatures();
            break;

          default:
            break;
        }
      },
    });
  }

  private reloadScansAndFeatures() {
    if (!this.store.selectSnapshot(AuthState.loggedIn)) return;
    if (this.store.selectSnapshot(ScandataState.isLoading)) return;

    this.store
      .dispatch(new ClearError('scanLoadError'))
      .pipe(
        switchMap(() => this.scandataService.refreshScandata()),
        switchMap(() => this.featureLayerService.loadFeatures()),
      )
      .subscribe(noopErrorObserver);
  }
}
