import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { buildUrl, isDefined } from '@trimble-gcs/common';
import { catchError, filter, map, switchMap } from 'rxjs';
import { ConnectProject } from 'trimble-connect-workspace-api';
import { AppState } from '../app-state/app.state';
import { AuthState } from '../auth/auth.state';
import { Logger, injectLogger } from '../logging/logger';
import { GlobalRegion, Project } from './tenancy.models';

@Injectable({
  providedIn: 'root',
})
export class TenancyService {
  private logger = injectLogger(Logger, 'TenancyService');

  constructor(
    private http: HttpClient,
    private store: Store,
  ) {}

  createTenancy() {
    const connectProject = this.store.selectSnapshot(AppState.project);
    const token = this.store.selectSnapshot(AuthState.accessToken);

    return this.getGlobalRegion(connectProject).pipe(
      map((region) => buildUrl(region.sdsBaseUrl, `/projects`)),
      switchMap((url) =>
        this.http.post<Project>(
          url,
          {
            projectId: connectProject.id,
            projectLocation: connectProject.location,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        ),
      ),
      catchError((err) => {
        this.logger.error(`Creation of tenancy for project ${connectProject.id} failed`, {}, err);
        throw err;
      }),
    );
  }

  getGlobalRegion(connectProject: ConnectProject) {
    const regionsUrl = this.store.selectSnapshot(AppState.settings).endpoints.regions.url;

    return this.http.get<GlobalRegion[]>(regionsUrl).pipe(
      map((regions) => regions.find((x) => x.connectRegion === connectProject.location)),
      filter((region): region is GlobalRegion => isDefined(region)),
    );
  }
}
