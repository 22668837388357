import { InjectionToken } from '@angular/core';

export type LoggerType = 'consoleLogger' | 'dataDogLogger';

export enum LogLevel {
  None = 'none',
  Debug = 'debug',
  Info = 'info',
  Warn = 'warn',
  Error = 'error',
}

export interface ILogger {
  name: string;
  createLogger(name: string): ILogger;
  log(message: string, context?: object, level?: LogLevel, error?: Error): void;
  debug(message: string, context?: object, error?: Error): void;
  info(message: string, context?: object, error?: Error): void;
  warn(message: string, context?: object, error?: Error): void;
  error(message: string, context?: object, error?: Error): void;
}

export const LOGGERS = new InjectionToken<ILogger[]>('Logger');

export const logLevels = new Map<LogLevel, number>([
  [LogLevel.None, 0],
  [LogLevel.Debug, 1],
  [LogLevel.Info, 2],
  [LogLevel.Warn, 3],
  [LogLevel.Error, 1],
]);
