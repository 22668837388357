import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  BehaviorSubject,
  Subject,
  Subscription,
  distinctUntilChanged,
  filter,
  map,
  merge,
  startWith,
  takeUntil,
} from 'rxjs';
import { ModusOption, SelectionChangeSource } from '../modus-option/modus-option.component';

/* eslint-disable @angular-eslint/component-class-suffix */

@Component({
  selector: 'modus-option-list',
  exportAs: 'modusOptionList',
  templateUrl: './modus-option-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModusOptionList<T> implements AfterContentInit, OnDestroy {
  private readonly onDestroy = new Subject<void>();

  private _isOpen = false;
  private _activeIndex = -1;
  private _activeOption: ModusOption<T> | null = null;

  private selectionSubscription = new Subscription();

  @ViewChild(TemplateRef, { static: true }) template!: TemplateRef<unknown>;
  @ContentChildren(ModusOption<T>, { descendants: true }) options!: QueryList<ModusOption<T>>;

  @Input() dropdownMaxHeight = '260px'; // 8 * 32px + 4px
  @Input() showNoResultsFoundMessage = true;
  @Input() noResultsFoundText = 'No results found';
  @Input() noResultsFoundSubtext = 'Try a different search';

  @Output() readonly selectionChange = new EventEmitter<T>();

  get isOpen(): boolean {
    return this._isOpen;
  }

  get hasOptions(): boolean {
    return this.options?.length > 0;
  }

  hasOptions$ = new BehaviorSubject(false);

  ngAfterContentInit(): void {
    this.subscribeToSelectionChanges();

    this.options.changes.subscribe((queryList: QueryList<ModusOption<T>>) => {
      const activeOption = queryList.find((item) => item.active);
      if (activeOption) activeOption.deactivate();
      this._activeIndex = -1;
      this.subscribeToSelectionChanges();
    });

    this.options.changes
      .pipe(
        map((queryList: QueryList<ModusOption<T>>) => {
          return this.showNoResultsFoundMessage && queryList.length > 0;
        }),
        startWith(this.showNoResultsFoundMessage && this.options.length > 0),
        distinctUntilChanged(),
        takeUntil(this.onDestroy),
      )
      .subscribe({
        next: (value) => {
          /**
           * HACK: If the change is emitted on this turn of the VM,
           * the *ngIf binding to hasOptions$ is not refreshed.
           * I suspect it is due to the template being displayed in an overlay.
           * The promise is used to force the emission at the next turn.
           */
          Promise.resolve().then(() => this.hasOptions$.next(value));
        },
        error: (err) => this.hasOptions$.error(err),
        complete: () => this.hasOptions$.complete(),
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.selectionSubscription.unsubscribe();
  }

  open() {
    this._isOpen = true;
  }

  close() {
    this.options.forEach((opt) => opt.deactivate());
    this._activeIndex = -1;
    this._activeOption = null;
    this._isOpen = false;
  }

  activatePrevious() {
    if (!this.canMove()) return;
    const index = this._activeIndex > 0 ? this._activeIndex - 1 : this.options.length - 1;
    this.activateOption(index);
  }

  activateNext() {
    if (!this.canMove()) return;
    const index = this._activeIndex < this.options.length - 1 ? this._activeIndex + 1 : 0;
    this.activateOption(index);
  }

  selectActiveOption() {
    this.clearSelection();
    this._activeOption?.select(SelectionChangeSource.userInput);
  }

  clearSelection() {
    this.options.forEach((opt) => opt.deselect());
  }

  private subscribeToSelectionChanges() {
    this.selectionSubscription.unsubscribe();

    this.selectionSubscription = merge(...this.options.map((opt) => opt.selectionChange))
      .pipe(
        filter((change) => {
          return this.isOpen && change.source === SelectionChangeSource.userInput;
        }),
        takeUntil(this.onDestroy),
      )
      .subscribe(({ option }) => {
        const other = this.options.filter((opt) => {
          return opt !== option;
        });

        other.forEach((opt) => {
          return option.selected ? opt.deselect() : opt.select();
        });

        // this._selectedIndex = this.options.indexOf(option)
        this.selectionChange.emit(option.value);
      });
  }

  private canMove(): boolean {
    return this.isOpen && this.hasOptions;
  }

  private activateOption(index: number) {
    const option = this.options.get(index);

    if (option) {
      this.options.forEach((opt) => opt.deactivate());
      option.activate();
      this._activeIndex = index;
      this._activeOption = option;
    }
  }
}
