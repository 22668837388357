<div class="flex flex-col grow border border-solid border-sky-700 m-2 mt-0">
  <div
    class="flex flex-col basis-12 justify-center items-start border-b border-solid border-sky-700"
  >
    <span class="ml-2 text-2xl text-sky-700">Loading indicators demo</span>
  </div>
  <div
    class="flex grow"
    loadingBar
    [loading]="loadingPrimary()"
    [color]="'primary'"
    [panelClass]="['bg-gray-400', 'bg-opacity-25']"
  ></div>
</div>

<div class="flex grow m-2 mt-0">
  <div class="flex flex-col basis-1/2 border border-solid border-yellow-400 mr-1">
    <div class="flex basis-12 items-center border-b border-solid border-yellow-400">
      <button class="ml-2" modus-flat-button color="secondary" (click)="toggleAccent()">
        {{ (loadingAccent$ | async) === false ? 'Load Accent' : 'Stop' }}
      </button>
    </div>
    <div
      class="flex grow"
      loadingSpinner
      [loading]="(loadingAccent$ | async) === true"
      [color]="'accent'"
      [panelClass]="['bg-gray-400', 'bg-opacity-25']"
    ></div>
  </div>

  <div class="flex flex-col basis-1/2 border border-solid border-orange-500 ml-1">
    <div class="flex basis-12 items-center border-b border-solid border-orange-500">
      <button class="ml-2" modus-flat-button color="danger" (click)="toggleWarn()">
        {{ (loadingWarn$ | async) === false ? 'Load Warn' : 'Stop' }}
      </button>
    </div>
    <div
      class="flex grow"
      loadingSpinner
      [loading]="(loadingWarn$ | async) === true"
      [color]="'warn'"
      [panelClass]="['bg-gray-400', 'bg-opacity-25']"
    ></div>
  </div>
</div>
