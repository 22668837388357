import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';

/* eslint-disable @angular-eslint/component-class-suffix */

@Component({
  selector: 'md-menu-item',
  templateUrl: './modus-menu-item.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModusMenuItem {
  private _disabled = false;

  @Input()
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }

  @HostBinding('class.modus-menu-item-disabled')
  get isDisabled(): boolean {
    return this._disabled;
  }

  @HostBinding('class.modus-menu-item') itemClass = true;

  @Output() itemClick = new EventEmitter<void>();

  onItemClick(event: MouseEvent) {
    if (this.isDisabled) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      this.itemClick.emit();
    }
  }
}
