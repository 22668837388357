import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  OnInit,
  computed,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import {
  ModusButtonModule,
  ModusFormFieldModule,
  ModusIconModule,
  ModusInputModule,
  ModusTooltipModule,
} from '@trimble-gcs/modus';
import {
  Observable,
  debounce,
  defaultIfEmpty,
  filter,
  map,
  startWith,
  switchMap,
  take,
  timer,
} from 'rxjs';
import { FeatureFlagService } from '../../../../../../libs/feature-flags/src';
import { AppState } from '../../app-state/app.state';
import { AppRoute } from '../../app.routes';
import { ClearClassificationSchemes } from '../../classification/classification-scheme.actions';
import { ConnectService } from '../../connect/connect.service';
import { DialogService } from '../../dialog/dialog.service';
import { ClearError } from '../../error-handling/error.actions';
import { FeatureFlagKey } from '../../feature-flags/feature-flag-key';
import {
  ImportDialogComponent,
  ImportDialogResult,
  importDialogDefaultConfig,
} from '../../import/import-dialog.component';
import { noopErrorObserver } from '../../logging/noop-error-observer';
import { FeatureLayerService } from '../../map/feature-layer/feature-layer.service';
import { DownloadState } from '../../options-panel/download/download.state';
import { OptionsPanelComponent } from '../../options-panel/options-panel.component';
import { OptionsPanelService } from '../../options-panel/options-panel.service';
import { OptionsPanelView } from '../../options-panel/options-panel.state';
import { SetLastVisitedRoute } from '../../route/route.state';
import { ScandataFilterService } from '../../scandata/scandata-filter.service';
import { SelectOnly, SetFilters, SetTextFilter } from '../../scandata/scandata.actions';
import { ScandataModel } from '../../scandata/scandata.models';
import { ScandataService } from '../../scandata/scandata.service';
import { ScandataState } from '../../scandata/scandata.state';
import { Role } from '../../user/user.models';
import { UserState } from '../../user/user.state';
import { tabLinks } from './tab-links';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    ModusButtonModule,
    ModusIconModule,
    ModusTooltipModule,
    ModusFormFieldModule,
    ModusInputModule,
    MatToolbarModule,
    MatSidenavModule,
    MatTabsModule,
    OptionsPanelComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './tab-host.component.html',
  styleUrls: ['./tab-host.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabHostComponent implements OnInit {
  @Select(ScandataState.filterCount) filterCount$!: Observable<number>;
  @Select(ScandataState.textFilter) textFilter$!: Observable<string | undefined>;
  @Select(ScandataState.scandata) scans$!: Observable<ScandataModel[]>;
  @Select(DownloadState.activeDownloadCount) downloadCount$!: Observable<number>;

  links = tabLinks;
  activeLink$: Observable<string>;

  panelView = OptionsPanelView;
  showOptionsPanel$!: Observable<boolean>;
  filterPanelActive$!: Observable<boolean>;
  downloadStatusPanelActive$!: Observable<boolean>;
  showTextFilterClear$!: Observable<boolean>;

  textFilterControl = new FormControl<string | null>(null);

  private userRole = toSignal(this.store.select(UserState.userRole), { requireSync: true });
  private settingsFeatureActive = toSignal(this.featureFlagService.hasFeature$('tools_settings'), {
    requireSync: true,
  });
  private importFeatureActive = toSignal(this.featureFlagService.hasFeature$('tools_import'), {
    requireSync: true,
  });

  // TODO: disable the feature if not admin role in the featureFlagsConfigService
  showSettings = computed(() => this.userRole() === Role.Admin && this.settingsFeatureActive());
  showImport = computed(() => this.userRole() === Role.Admin && this.importFeatureActive());

  constructor(
    private router: Router,
    route: ActivatedRoute,
    private optionsPanelService: OptionsPanelService,
    private scandataService: ScandataService,
    private scandataFilterService: ScandataFilterService,
    private featureLayerService: FeatureLayerService,
    private connectService: ConnectService,
    private store: Store,
    private dialogService: DialogService,
    private featureFlagService: FeatureFlagService<FeatureFlagKey>,
  ) {
    let snapshot = route.snapshot;

    while (snapshot.firstChild) {
      snapshot = snapshot.firstChild;
    }

    this.activeLink$ = this.router.events.pipe(
      filter((evt) => evt instanceof NavigationEnd),
      map((evt) => {
        const url = (evt as NavigationEnd).urlAfterRedirects;
        return url.startsWith('/') ? url.slice(1) : url;
      }),
      startWith(snapshot.url[0].path),
      defaultIfEmpty(snapshot.params['path']),
    );

    this.activeLink$.pipe(untilDestroyed(this)).subscribe((path) => {
      const rememberRoutes = [AppRoute.MapView, AppRoute.ListView];
      if (!rememberRoutes.map((route) => route.toString()).includes(path)) return;

      this.store.dispatch(new SetLastVisitedRoute(path as AppRoute));
    });

    this.selectScandataFromQueryParams();
    this.setFiltersFromQueryParams();
  }

  async ngOnInit() {
    this.showOptionsPanel$ = this.optionsPanelService.view$.pipe(
      map((view) => view !== OptionsPanelView.None),
    );

    this.filterPanelActive$ = this.optionsPanelService.view$.pipe(
      map((view) => view === OptionsPanelView.ListFilters),
    );

    this.downloadStatusPanelActive$ = this.optionsPanelService.view$.pipe(
      map((view) => view === OptionsPanelView.DownloadStatus),
    );

    this.showTextFilterClear$ = this.textFilter$.pipe(map(isDefined));

    this.setInitialTextFilterValue();
    this.subscribeToTextFilterChange();
  }

  navigateClick(route: AppRoute) {
    this.router.navigate([route]);
  }

  toggleOptionsPanelViewClick(view: OptionsPanelView) {
    this.optionsPanelService.setView(view, true);
  }

  refreshClick() {
    this.refreshScansAndFeatures().subscribe(noopErrorObserver);
  }

  view3DClick() {
    this.connectService.goTo3dExtension();
  }

  viewSettingsClick() {
    this.router.navigate([AppRoute.Config], { queryParams: { showBackNavigation: true } });
  }

  clearTextFilterClick() {
    this.textFilterControl.reset();
  }

  importClick() {
    this.dialogService
      .showComponent<ImportDialogComponent, any, ImportDialogResult>(
        ImportDialogComponent,
        importDialogDefaultConfig,
      )
      .pipe(
        filter((result) => result?.reloadScans === true),
        switchMap(() => this.refreshScansAndFeatures()),
      )
      .subscribe();
  }

  private refreshScansAndFeatures() {
    return this.store.dispatch([new ClearError('scanLoadError'), ClearClassificationSchemes]).pipe(
      switchMap(() => this.scandataService.refreshScandata()),
      switchMap(() => this.featureLayerService.loadFeatures()),
    );
  }

  private setInitialTextFilterValue() {
    this.textFilter$.pipe(take(1)).subscribe((value) => {
      this.textFilterControl.reset(value);
    });
  }

  private subscribeToTextFilterChange() {
    this.textFilterControl.valueChanges
      .pipe(
        debounce(() => timer(250)),
        untilDestroyed(this),
      )
      .subscribe((value) => {
        this.store.dispatch(new SetTextFilter(value ?? undefined));
      });
  }

  private selectScandataFromQueryParams() {
    this.scans$
      .pipe(
        filter((scans) => scans.length > 0),
        take(1),
        switchMap(() => this.store.selectOnce(AppState.urlQueryScandataIds)),
        filter((scandataIds) => scandataIds.length > 0),
        switchMap((scandataIds) => this.store.dispatch(new SelectOnly(scandataIds))),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private setFiltersFromQueryParams() {
    const scandataQuery = this.store.selectSnapshot(AppState.urlQueryScandataQuery);
    if (isNil(scandataQuery)) return;

    const filters = this.scandataFilterService.getFiltersFromQueryParameter(scandataQuery);
    this.store.dispatch(new SetFilters(filters));
  }
}
