<div
  style=" 
    --bg-width:{{ usageValueBackground.percentage }}%; 
    --fg-width:{{ usageValueForeground.percentage }}%"
  class="flex flex-1 flex-col"
>
  <div class="w-full relative h-5 bg-gray-5 rounded-2xl">
    <div
      class="w-[--bg-width] max-w-full min-w-4 absolute z-0 h-full bg-trimble-blue rounded-2xl"
    ></div>
    <div
      class="w-[--fg-width] max-w-full min-w-4 absolute z-10 h-full bg-blue-dark rounded-2xl"
    ></div>
  </div>
  <div class="w-full min-h-8 relative text-sm whitespace-nowrap">
    <div class="w-[--bg-width] max-w-full min-w-4 flex justify-end h-6 mt-1 border-r-2"></div>
    <div class="w-[--bg-width] max-w-full flex-col min-w-fit z-0 flex justify-end">
      <div class="flex w-full justify-end">
        {{ usageValueBackground.label }}: {{ usageValueBackground.percentage | percentage: 0 }}
      </div>
    </div>
    <div
      class="w-[--fg-width] max-w-full min-w-4 flex absolute justify-end h-2 top-1 z-20 border-r-2"
    ></div>
    <div
      class="w-[--fg-width] max-w-full flex min-w-fit absolute z-10justify-end top-0 pt-3 bg-white"
    >
      <div class="flex w-full justify-end">
        {{ usageValueForeground.label }}: {{ usageValueForeground.percentage | percentage: 0 }}
      </div>
    </div>
  </div>
</div>
