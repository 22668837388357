import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, combineLatest, map } from 'rxjs';
import { ScandataState } from '../scandata/scandata.state';
import { FeatureLayerState } from './feature-layer/feature-layer.state';
import { MapFilterOption, MapScandataModel } from './map.models';
import { MapState } from './map.state';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  constructor(private store: Store) {}

  getMapScandata(): Observable<MapScandataModel[]> {
    return combineLatest([
      this.store.select(FeatureLayerState.featureCollection),
      this.store.select(ScandataState.textFilteredScandata),
      this.store.select(MapState.filterOption),
    ]).pipe(
      map(([featureCollection, scandata, filterOption]) => {
        const featureIds =
          featureCollection?.features.map((feature) => (feature.id as string) ?? '') ?? [];

        return scandata
          .map((model) => {
            return {
              ...model,
              hasFeature: featureIds.findIndex((id) => id === model.id) > -1,
            };
          })
          .filter((model) => filterOption === MapFilterOption.All || model.hasFeature)
          .sort((a, b) => a.name.localeCompare(b.name));
      }),
    );
  }
}
