import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'percentage',
  standalone: true,
})
export class PercentagePipe implements PipeTransform {
  transform(percentage: number, decimalPlaces = 2): string {
    if (percentage === null || isNaN(percentage) || percentage < 0) {
      return '';
    }

    if (percentage > 0 && percentage < 1 && decimalPlaces === 0) {
      return '<1%';
    }

    return `${percentage.toFixed(decimalPlaces)}%`;
  }
}
