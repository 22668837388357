import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { ModusButtonModule } from '@trimble-gcs/modus';
import { Observable, filter, switchMap } from 'rxjs';
import { AuthService } from '../auth.service';
import { AuthState } from '../auth.state';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [CommonModule, ModusButtonModule, MatProgressSpinnerModule],
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutComponent implements OnInit {
  busy$: Observable<boolean>;

  @Select(AuthState.loggedIn) loggedIn$!: Observable<boolean>;

  constructor(private authService: AuthService) {
    this.busy$ = this.loggedIn$;
  }

  ngOnInit() {
    this.loggedIn$
      .pipe(
        filter((loggedIn) => loggedIn),
        switchMap(() => this.authService.signOut()),
        untilDestroyed(this),
      )
      .subscribe();
  }

  login() {
    this.authService.signIn().subscribe();
  }
}
