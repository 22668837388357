<div class="flex flex-col h-full">
  @if (showLoading()) {
    <div class="w-full absolute">
      <mat-progress-bar class="mds-x-small" [mode]="'indeterminate'"></mat-progress-bar>
    </div>
  }

  <div class="flex flex-row p-4 pr-6 border-b border-gray-1">
    <div class="h-8 flex flex-1 flex-wrap content-center font-semibold">Download Package</div>
    <button
      id="options-panel-download-close"
      class="text-gray-8"
      modus-icon-button
      modus-tooltip="Close"
      (click)="closeClicked.emit()"
    >
      <md-icon>close</md-icon>
    </button>
  </div>

  <sd-download-select-list
    class="flex flex-1 overflow-hidden"
    (cancelClicked)="closeClicked.emit()"
    (loading)="setLoading($event)"
  ></sd-download-select-list>
</div>
