import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { ModusButtonModule } from '@trimble-gcs/modus';
import { catchError, EMPTY, filter, Observable, repeat, Subject, switchMap } from 'rxjs';
import { isApiErrorType } from '../../error-handling/api-error-type';
import { LoadingService } from '../../loading/loading.service';
import { LicenseService } from '../license.service';
import { LicenseState } from '../license.state';

enum View {
  LoadLicense,
  NoLicense,
  AccountNotAllowed,
  UnknownError,
}

@UntilDestroy()
@Component({
  standalone: true,
  imports: [CommonModule, MatProgressBarModule, ModusButtonModule],
  templateUrl: './license-check.component.html',
  styles: [
    `
      :host {
        height: 100%;
        background-color: white;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LicenseCheckComponent {
  @Select(LicenseState.hasActiveLicense) private hasActiveLicense$!: Observable<boolean>;

  view = View;

  isLoading = toSignal(this.loadingService.isLoading$(this), { initialValue: false });
  currentView = signal(View.LoadLicense);

  private readonly reloadLicenses$ = new Subject<void>();

  constructor(
    private loadingService: LoadingService,
    private licenseService: LicenseService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.subscribeToHasActiveLicense();
    this.loadLicenses();
  }

  reloadLicense() {
    this.reloadLicenses$.next();
  }

  private subscribeToHasActiveLicense() {
    this.hasActiveLicense$
      .pipe(
        filter((hasActiveLicense) => hasActiveLicense),
        untilDestroyed(this),
      )
      .subscribe(() => {
        const returnPath = this.activatedRoute.snapshot.queryParamMap.get('returnPath') ?? '';
        this.router.navigate([returnPath]);
      });
  }

  private loadLicenses() {
    this.loadingService
      .loadFrom(this.licenseService.loadLicenses(), this)
      .pipe(
        switchMap(() => this.hasActiveLicense$),
        catchError((err: HttpErrorResponse) => {
          const view = this.getErrorView(err);
          this.currentView.set(view);
          this.loadingService.stopLoading(this);
          return EMPTY;
        }),
        repeat({ delay: () => this.reloadLicenses$ }),
        untilDestroyed(this),
      )
      .subscribe((hasActiveLicense) => {
        if (hasActiveLicense) return;

        this.currentView.set(View.NoLicense);
        this.loadingService.stopLoading(this);
      });
  }

  private getErrorView(err: HttpErrorResponse) {
    if (isApiErrorType(err, 'ACCOUNT_NOT_ALLOWED')) {
      return View.AccountNotAllowed;
    }

    return View.UnknownError;
  }
}
