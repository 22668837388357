import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Scan3dStyle } from './models/scan-3d-style';
import { SetGlobalStyle } from './scan-3d.actions';

export interface Scan3dStateModel {
  globalStyle?: Scan3dStyle;
}

@State<Scan3dStateModel>({
  name: 'scan3dState',
})
@Injectable()
export class Scan3dState {
  @Selector() static globalStyle(state: Scan3dStateModel): Scan3dStyle | undefined {
    return state.globalStyle;
  }

  @Action(SetGlobalStyle) setGlobalStyle(
    ctx: StateContext<Scan3dStateModel>,
    { style }: SetGlobalStyle,
  ) {
    ctx.patchState({ globalStyle: style });
  }
}
