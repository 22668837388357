/** Convert hex string to rgb/rgba array
 * @param hex Hex rgb/rgba color format e.g. 7ff, 7ffa, 7f7f7f, 7f7f7f7f
 * @returns Number array in order rgb/rgba with range 0 - 1
 */
export function colorHexToArray(hex: string) {
  if (/^#?([A-Fa-f\d]{3,4}|[A-Fa-f\d]{6}|[A-Fa-f\d]{8})$/.test(hex)) {
    const noHash = hex.replace('#', '');
    const hexArray =
      noHash.length === 3 || noHash.length === 4
        ? noHash.split('').map((x) => x + x)
        : (noHash.match(/.{1,2}/g) as string[]);

    return hexArray.map((x) => parseInt(x, 16) / 255);
  } else {
    return undefined;
  }
}

/** Strip alpha channel from rgba string
 * @param hex Hex rgb/rgba color format e.g. #7ff, #7ffa, #7f7f7f, #7f7f7f7f
 * @returns Rgb string
 */
export function colorHexStripAlpha(hex: string) {
  const colors = colorHexToArray(hex);

  if (!colors) return undefined;

  return (
    '#' +
    colors
      .slice(0, 3)
      .map((c) => (c * 255).toString(16).padStart(2, '0'))
      .join('')
  );
}
