import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { switchMap } from 'rxjs';
import { GET_SCAN_PROJECT_URL } from '../utils/get-scan-project-url';
import { Setting } from './setting.model';

@Injectable({
  providedIn: 'root',
})
export class SettingService {
  private readonly getScanProjectUrl$ = inject(GET_SCAN_PROJECT_URL);

  constructor(private http: HttpClient) {}

  getSetting<T>(key: string) {
    return this.getScanProjectUrl$(`/settings/${key}`).pipe(
      switchMap((url) => {
        return this.http.get<Setting<T>>(url);
      }),
    );
  }

  patchSetting<T>(key: string, data: T) {
    return this.getScanProjectUrl$(`/settings/${key}`).pipe(
      switchMap((url) => {
        return this.http.patch<Setting<T>>(url, { data });
      }),
    );
  }

  putSetting<T>(key: string, data: T) {
    return this.getScanProjectUrl$(`/settings/${key}`).pipe(
      switchMap((url) => {
        return this.http.put<Setting<T>>(url, { data });
      }),
    );
  }

  deleteSetting(key: string) {
    return this.getScanProjectUrl$(`/settings/${key}`).pipe(
      switchMap((url) => {
        return this.http.delete<void>(url);
      }),
    );
  }
}
