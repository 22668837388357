import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { ScandataSelectedState } from '../../scandata/scandata-selected.state';
import { UnselectAllScandataModels, UnselectScandataModel } from '../../scandata/scandata.actions';
import { ScandataModel } from '../../scandata/scandata.models';
import { Role } from '../../user/user.models';
import { UserState } from '../../user/user.state';
import { DownloadSelectComponent } from '../download/download-select/download-select.component';
import { MultiSelectedComponent } from './multi-selected/multi-selected.component';
import { SingleSelectedComponent } from './single-selected/single-selected.component';

@UntilDestroy()
@Component({
  selector: 'sd-selected-details',
  standalone: true,
  imports: [CommonModule, MultiSelectedComponent, SingleSelectedComponent, DownloadSelectComponent],
  templateUrl: './selected-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectedDetailsComponent {
  private userRole = toSignal(this.store.select(UserState.userRole), { requireSync: true });
  readonly = computed(() => this.userRole() !== Role.Admin);

  selected = toSignal(this.store.select(ScandataSelectedState.chronologicalSelected), {
    requireSync: true,
  });

  showDownload = signal(false);

  showSingleSelected = computed(() => this.selected().length === 1 && !this.showDownload());
  singleSelected = computed(() => {
    const selected = this.selected();
    return selected.length === 1 ? selected[0] : null;
  });

  showMultiSelected = computed(() => this.selected().length > 1 && !this.showDownload());

  constructor(private store: Store) {}

  unselectScan(scan: ScandataModel) {
    this.store.dispatch(new UnselectScandataModel(scan.id));
  }

  unselectAllScans() {
    this.store.dispatch(new UnselectAllScandataModels());
  }
}
