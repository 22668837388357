<div class="h-full flex flex-col">
  <!-- header -->
  <div mat-dialog-title class="flex h-12 flex-row p-4 items-center border-b border-gray-1">
    <div class="flex flex-1 flex-wrap items-center font-bold">Select Connect Project</div>
    <button modus-icon-button (click)="cancelClick()">
      <md-icon>close</md-icon>
    </button>
  </div>

  <!-- content -->
  <div mat-dialog-content class="flex flex-1 flex-col text-sm overflow-hidden">
    @if (isLoading$ | async) {
      <div class="w-full relative">
        <div class="w-full absolute">
          <mat-progress-bar class="mds-x-small" [mode]="'indeterminate'"></mat-progress-bar>
        </div>
      </div>
    }
    <div class="flex flex-row p-4 bg-gray-light border-b border-gray-1">
      <mat-select
        id="connect-region-select"
        class="modus-dropdown !w-[140px] !px-2 hover:bg-gray-0"
        panelClass="modus-dropdown-panel"
        [formControl]="regionFormControl"
        disableRipple
      >
        @for (region of regions$ | async; track region.location) {
          <mat-option [value]="region">
            {{ region.location }}
          </mat-option>
        }
      </mat-select>
    </div>

    <div class="flex flex-col flex-1 pb-4 overflow-y-auto">
      @for (project of projects; track project.id) {
        <div
          class="p-4 font-medium cursor-pointer hover:bg-blue-pale"
          (click)="projectClick(project)"
        >
          {{ project.name }}
        </div>
      }
    </div>
  </div>

  <!-- actions -->
  <div mat-dialog-actions class="flex p-4 justify-end items-center border-t border-gray-1">
    <button modus-button color="secondary" (click)="cancelClick()">Cancel</button>
  </div>
</div>
