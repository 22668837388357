import { InjectionToken, inject } from '@angular/core';
import { buildUrl } from '@trimble-gcs/common';
import { Observable, map } from 'rxjs';
import { ConnectRegionService } from './connect-region.service';

export const GET_CONNECT_REGION_URL = new InjectionToken<(path: string) => Observable<string>>(
  'GetConnectRegionUrl',
  {
    factory: () => {
      const connectRegionService = inject(ConnectRegionService);

      return (path: string) => {
        return connectRegionService.getConnectRegionEndpoint().pipe(
          map((endpoint) => {
            return buildUrl(endpoint.url, path);
          }),
        );
      };
    },
  },
);
