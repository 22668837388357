import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SetView } from './options-panel.actions';
import { OptionsPanelState, OptionsPanelView } from './options-panel.state';

@Injectable({ providedIn: 'root' })
export class OptionsPanelService {
  @Select(OptionsPanelState.view) view$!: Observable<OptionsPanelView>;

  constructor(private store: Store) {}

  setView(view: OptionsPanelView, withToggle: boolean = false) {
    this.store.dispatch(new SetView(view, withToggle));
  }
}
