import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Role, User } from './user.models';

export interface UserStateModel {
  user?: User;
}

export class SetUser {
  static readonly type = '[UserState] SetUser';
  constructor(public readonly user: User) {}
}

@State<UserStateModel>({
  name: 'userState',
})
@Injectable()
export class UserState {
  @Selector() static user(state: UserStateModel) {
    return state.user;
  }

  @Selector() static userRole(state: UserStateModel): Role | undefined {
    return state.user?.role ?? Role.None;
  }

  @Action(SetUser) setUser(ctx: StateContext<UserStateModel>, action: SetUser) {
    ctx.patchState({ user: action.user });
  }
}
