import { RxCollection, RxCollectionChange } from '@trimble-gcs/reactive';
import { BehaviorSubject, Observable, Subject, Subscription, merge } from 'rxjs';
import { FeatureFlag, isFeatureFlag } from './feature-flag';
import { FeatureFlagOwner } from './feature-flag-owner';

export class FeatureFlagStore<TKey extends string = string> implements FeatureFlagOwner {
  private readonly _features = new RxCollection<FeatureFlag<TKey>>();
  private readonly _changes$ = new Subject<RxCollectionChange>();
  private readonly _subscriptions = new Map<FeatureFlag<TKey>, Subscription>();

  // top-level FeatureFlagOwner is always active
  readonly active: boolean = true;
  readonly active$: Observable<boolean> = new BehaviorSubject(true).asObservable();

  get features(): ReadonlyArray<FeatureFlag<TKey>> {
    return [...this._features];
  }

  get changes$(): Observable<RxCollectionChange> {
    const merged = merge(this._features.pipe(), this._changes$);
    return merged;
  }

  constructor(config: Record<TKey, boolean>) {
    let root: FeatureFlag<TKey> | undefined;
    let current: FeatureFlag<TKey> | undefined;

    Object.entries(config)
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(([key, value]) => [key.split('.'), value] as [TKey[], boolean])
      .forEach(([segments, value]) => {
        if (segments.length === 1) {
          root = new FeatureFlag<TKey>(segments[0], value);
          this.pushFeature(root);
        }

        for (let depth = 1; depth < segments.length; depth++) {
          const key = segments.slice(0, depth + 1).join('.') as TKey;
          const path = segments.slice(0, depth).join('.') as TKey;
          const leaf = depth === segments.length - 1;

          current = root?.find(path) ?? root;

          if (!current) {
            throw new Error(`Cannot find FeatureFlag with '${path}', to add key: '${key}'`);
          }

          if (leaf) {
            current.add(key, value);
          }
        }
      });
  }

  add(feature: FeatureFlag<TKey>): FeatureFlag<TKey>;
  add(key: TKey, enabled?: boolean): FeatureFlag<TKey>;
  add(keyOrFeature: TKey | FeatureFlag<TKey>, enabled = true): FeatureFlag<TKey> {
    return isFeatureFlag(keyOrFeature)
      ? this.addFeature(keyOrFeature)
      : this.addFeature(new FeatureFlag<TKey>(keyOrFeature, enabled));
  }

  remove(keyOrFeature: TKey | FeatureFlag<TKey>): void {
    const key = isFeatureFlag(keyOrFeature) ? keyOrFeature.key : keyOrFeature;
    const segments = key.split('.') as TKey[];

    return segments.length === 1
      ? this.removeFeatureFromSelf(key)
      : this.removeFeatureFromTree(key, segments);
  }

  find(key: TKey) {
    const segments = key.split('.');
    const root = this._features.find((f) => f.key === segments[0]);
    return segments.length === 1 ? root : root?.find(key);
  }

  private addFeature(feature: FeatureFlag<TKey>): FeatureFlag<TKey> {
    const segments = feature.key.split('.');

    return segments.length === 1
      ? this.addFeatureToSelf(feature)
      : this.addFeatureToTree(feature, segments);
  }

  private addFeatureToSelf(feature: FeatureFlag<TKey>): FeatureFlag<TKey> {
    const index = this._features.findIndex((f) => f.key === feature.key);
    const existing = this._features.get(index);

    if (!existing) {
      this.pushFeature(feature);
    } else {
      this.replaceFeature(existing, feature, index);
    }

    return feature;
  }

  private addFeatureToTree(feature: FeatureFlag<TKey>, segments: string[]) {
    const path = segments.filter((_, i) => i < segments.length - 1).join('.') as TKey;
    const next = this.find(path);
    if (!next) throw new Error(`No parent FeatureFlag for key: ${feature.key}`);
    return next.add(feature);
  }

  private pushFeature(feature: FeatureFlag<TKey>) {
    this.subscribeToFeatureChanges(feature);
    feature['owner'] = this;
    this._features.push(feature);
  }

  private replaceFeature(existing: FeatureFlag<TKey>, feature: FeatureFlag<TKey>, index: number) {
    if (existing === feature) return;

    existing['owner'] = undefined;
    this.unsubscribeFromFeatureChanges(existing);

    feature['owner'] = this;
    this._features.set(index, feature);
    this.subscribeToFeatureChanges(feature);
  }

  private removeFeatureFromSelf(key: string) {
    const index = this._features.findIndex((feature) => feature.key === key);
    const feature = this._features.get(index);

    if (index >= 0 && feature) {
      feature['owner'] = undefined;
      this._features.splice(index, 1);
      this.unsubscribeFromFeatureChanges(feature);
    }
  }

  private removeFeatureFromTree(key: TKey, segments: TKey[]) {
    const path = segments[0];
    const next = this._features.find((f) => f.key === path);
    next?.remove(key);
  }

  private subscribeToFeatureChanges(feature: FeatureFlag<TKey>) {
    this._subscriptions.set(
      feature,
      feature.changes$.subscribe((change: RxCollectionChange) => {
        this._changes$.next(change);
      }),
    );
  }

  private unsubscribeFromFeatureChanges(feature: FeatureFlag<TKey>) {
    const subscription = this._subscriptions.get(feature);
    subscription?.unsubscribe();
  }
}
