import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Observable, combineLatestWith, filter, map, switchMap, tap } from 'rxjs';
import { ScandataModel } from '../scandata/scandata.models';
import { ScandataState } from '../scandata/scandata.state';
import { ClassificationEditComponent } from './classification-edit/classification-edit.component';
import { DownloadStatusComponent } from './download/download-status/download-status.component';
import { ListFiltersComponent } from './list-filters/list-filters.component';
import { SetView } from './options-panel.actions';
import { OptionsPanelService } from './options-panel.service';
import { OptionsPanelView } from './options-panel.state';
import { SelectedDetailsComponent } from './selected-details/selected-details.component';

@UntilDestroy()
@Component({
  selector: 'sd-options-panel',
  standalone: true,
  imports: [
    CommonModule,
    ListFiltersComponent,
    DownloadStatusComponent,
    SelectedDetailsComponent,
    ClassificationEditComponent,
  ],
  templateUrl: './options-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsPanelComponent implements OnInit {
  @Select(ScandataState.selected) private selected$!: Observable<ScandataModel[]>;

  view$!: Observable<OptionsPanelView>;
  view = OptionsPanelView;

  constructor(
    private optionsPanelService: OptionsPanelService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.view$ = this.getView$();

    //monitor selection change
    this.selected$
      .pipe(
        filter((selected) => selected.length > 0),
        switchMap(() => this.store.dispatch(new SetView(OptionsPanelView.SelectedDetails))),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private getView$() {
    //monitor view change
    return this.optionsPanelService.view$.pipe(
      combineLatestWith(this.selected$),
      tap(([view, selected]) => {
        const selectedCount = selected.length ?? 0;

        if (selectedCount > 0 && view === OptionsPanelView.None)
          this.store.dispatch(new SetView(OptionsPanelView.SelectedDetails));

        if (selectedCount === 0 && view === OptionsPanelView.SelectedDetails)
          this.store.dispatch(new SetView(OptionsPanelView.None));
      }),
      map(([view]) => view),
    );
  }
}
