import { NgModule } from '@angular/core';
import { ModusButton } from './basic-button/basic-button.component';
import { ModusFlatButton } from './flat-button/flat-button.component';
import { ModusIconButton } from './icon-button/icon-button.component';
import { ModusStrokedButton } from './stroked-button/stroked-button.component';

@NgModule({
  declarations: [ModusButton, ModusFlatButton, ModusIconButton, ModusStrokedButton],
  exports: [ModusButton, ModusFlatButton, ModusIconButton, ModusStrokedButton],
})
export class ModusButtonModule {}
