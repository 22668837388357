import { Subject, filter, share } from 'rxjs';
import { EventId, WorkspaceAPI } from 'trimble-connect-workspace-api';
import { Endpoint } from '../app-state/app.models';

export enum ConnectCommand {
  ScandataBrowser = 'scandata_browser_command',
}

export type WorkspaceEvent<TData> = {
  id: EventId;
  data: TData;
  action?: string;
};

export class ConnectWorkspace {
  private _event$ = new Subject<WorkspaceEvent<unknown>>();

  event$ = this._event$.pipe(share());

  command$ = this._event$.pipe(
    filter((event) => (event.id as string) === 'extension.command'),
    share(),
  );

  constructor(public api: WorkspaceAPI) {}
}

export interface ConnectRegionDto {
  location: string;
  'tc-api': string;
}

export interface ConnectRegion {
  location: string;
  endpoint: Endpoint;
}
