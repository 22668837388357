import { TaskResult } from './worker-models';

export enum WorkerMessageType {
  Init = 'WorkerInit',
  Result = 'WorkerResult',
  Error = 'WorkerError',
}

export interface WorkerMessage {
  readonly type: WorkerMessageType;
}

export class WorkerInitMessage implements WorkerMessage {
  readonly type = WorkerMessageType.Init;
  constructor(
    readonly workerType: 'function' | 'object',
    readonly functions: string[] = [],
  ) {}
}

export class WorkerResultMessage implements WorkerMessage {
  readonly type = WorkerMessageType.Result;
  constructor(readonly payload: TaskResult) {}
}

export class WorkerErrorMessage implements WorkerMessage {
  readonly type = WorkerMessageType.Error;
  constructor(readonly error: string | Error) {}
}
