import { ProviderToken, inject } from '@angular/core';
import { Select } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { Observable, filter } from 'rxjs';
import { AppSettings } from '../app-state/app.models';
import { AppState } from '../app-state/app.state';
import { ILogger, LOGGERS, LogLevel, LoggerType, logLevels } from './logger-types';

const loggerMap = new Map<string, ILogger>();

export function injectLogger<T extends ILogger>(token: ProviderToken<T>, name: string): T {
  if (!name) return inject(token) as T;
  if (loggerMap.has(name)) return loggerMap.get(name) as T;

  const logger = inject(token).createLogger(name);
  loggerMap.set(name, logger);
  return logger as T;
}

export function compareLevel(left: LogLevel, right: LogLevel): number {
  const a = logLevels.get(left) as number;
  const b = logLevels.get(right) as number;
  return a - b;
}

export abstract class LoggerBase implements ILogger {
  @Select(AppState.settings)
  protected settings$!: Observable<AppSettings>;
  protected level: LogLevel = LogLevel.Debug;

  abstract readonly type: LoggerType;

  constructor(public readonly name: string) {
    this.settings$.pipe(filter(isDefined)).subscribe((settings) => {
      const logSettings = settings.logging.loggers[this.type];
      this.level = logSettings?.logLevel ?? LogLevel.None;
    });
  }

  abstract createLogger(name: string): ILogger;
  abstract log(message: string, context?: object, level?: LogLevel, error?: Error): void;
  abstract debug(message: string, context?: object, error?: Error): void;
  abstract info(message: string, context?: object, error?: Error): void;
  abstract warn(message: string, context?: object, error?: Error): void;
  abstract error(message: string, context?: object, error?: Error): void;
}

export class Logger implements ILogger {
  private loggers = inject(LOGGERS);

  public readonly name: string;

  constructor(name = 'Logger', loggers?: ILogger[]) {
    this.name = name;

    if (loggers && loggers.length > 0) {
      this.loggers = loggers;
    }
  }

  createLogger(name: string): ILogger {
    const childLoggers = this.loggers.map((logger) => logger.createLogger(name));
    return new Logger(name, childLoggers);
  }

  log(message: string, context?: object, level?: LogLevel, error?: Error): void {
    this.loggers.forEach((logger) => logger.log(message, context, level, error));
  }

  debug(message: string, context?: object, error?: Error): void {
    this.loggers.forEach((logger) => logger.debug(message, context, error));
  }

  info(message: string, context?: object, error?: Error): void {
    this.loggers.forEach((logger) => logger.info(message, context, error));
  }

  warn(message: string, context?: object, error?: Error): void {
    this.loggers.forEach((logger) => logger.warn(message, context, error));
  }

  error(message: string, context?: object, error?: Error): void {
    this.loggers.forEach((logger) => logger.error(message, context, error));
  }
}
