import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { Logger, injectLogger } from './logger';

export const loggingInterceptor: HttpInterceptorFn = (req, next) => {
  const logger = injectLogger(Logger, 'HttpErrorInterceptor');

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      logger.error(error.message, {}, error);
      return throwError(() => error);
    }),
  );
};
