import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Renderer2, effect, signal } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModusButtonModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { Observable } from 'rxjs';
import { CANCEL_BUTTON, DialogData, OK_BUTTON } from '../dialog.model';
import { DialogService } from '../dialog.service';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [CommonModule, ModusButtonModule, ModusIconModule, ModusTooltipModule],
  templateUrl: './dialog-demo.component.html',
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 100%;
        max-height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogDemoComponent {
  darkThemeOn = signal(false);

  confirmResult$!: Observable<boolean>;
  confirmAltResult$!: Observable<boolean>;

  constructor(
    private renderer: Renderer2,
    private dialogService: DialogService,
  ) {
    this.subscribeThemeChange();
  }

  toggleDarkMode() {
    this.darkThemeOn.update((value) => !value);
  }

  showDialog() {
    const dialogData = new DialogData('Title', 'Content...');
    this.dialogService.show(dialogData).subscribe();
  }

  showConfirm() {
    const dialogData = new DialogData('Title', 'Content...', OK_BUTTON, CANCEL_BUTTON);
    this.confirmResult$ = this.dialogService.show(dialogData);

    this.confirmResult$.subscribe({
      next: (confirmed) => {
        console.log(`Confirm: ${confirmed}`);
      },
    });
  }

  showConfirmAlt() {
    const dialogData = new DialogData(
      'Title',
      'Content...',
      { text: 'Yes', color: 'danger' },
      { text: 'No', color: 'primary' },
    );
    this.confirmAltResult$ = this.dialogService.show(dialogData);

    this.confirmAltResult$.subscribe({
      next: (confirmed) => {
        console.log(`Confirm Alt: ${confirmed}`);
      },
    });
  }

  private subscribeThemeChange() {
    effect(() => {
      const darkThemeOn = this.darkThemeOn();
      if (darkThemeOn) {
        this.renderer.removeClass(document.documentElement, 'light');
        this.renderer.addClass(document.documentElement, 'dark');
      } else {
        this.renderer.removeClass(document.documentElement, 'dark');
        this.renderer.addClass(document.documentElement, 'light');
      }
    });
  }
}
