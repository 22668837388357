import { InjectionToken, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { buildUrl, isDefined } from '@trimble-gcs/common';
import { Observable, combineLatest, filter, map, take } from 'rxjs';
import { AppState } from '../app-state/app.state';

export const GET_SCAN_PROJECT_URL = new InjectionToken<(path: string) => Observable<string>>(
  'GetScanProjectUrl',
  {
    factory: () => {
      const store = inject(Store);

      return (path: string) => {
        return combineLatest([
          store.select(AppState.projectRegion),
          store.select(AppState.project),
        ]).pipe(
          filter(([region, project]) => isDefined(region) && isDefined(project)),
          map(([region, project]) => buildUrl(region.scandata.url, `/projects/${project.id}`)),
          map((baseUrl) => buildUrl(baseUrl, path)),
          take(1),
        );
      };
    },
  },
);
