import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { buildUrl, isDefined } from '@trimble-gcs/common';
import { FeatureCollection } from 'geojson';
import { Observable, combineLatest, filter, map, of, switchMap, take, tap } from 'rxjs';
import { AppState } from '../../app-state/app.state';
import { LoadingService } from '../../loading/loading.service';
import { ScandataModel } from '../../scandata/scandata.models';
import { ScandataState } from '../../scandata/scandata.state';
import { SetBounds } from '../map.actions';
import { DEFAULT_BBOX, DEFAULT_BOUNDS_PADDING, MapBounds } from '../map.models';
import { MapState } from '../map.state';
import { bboxEquals, calculateBounds } from '../map.util';
import { SetFeatureCollection, SetIsLoading } from './feature-layer.actions';
import { FeatureLayer } from './feature-layer.models';
import { FeatureLayerState } from './feature-layer.state';

@Injectable({
  providedIn: 'root',
})
export class FeatureLayerService {
  private get featuresUrl$(): Observable<string> {
    return combineLatest([
      this.store.select(AppState.projectRegion),
      this.store.select(AppState.project),
    ]).pipe(
      filter(([region, project]) => isDefined(region) && isDefined(project)),
      map(([region, project]) => `${region.features.url}projects/${project.id}`),
      take(1),
    );
  }

  constructor(
    private store: Store,
    private http: HttpClient,
    private loadingService: LoadingService,
  ) {
    this.loadingService
      .isLoading$(this)
      .pipe(tap((loading) => this.store.dispatch(new SetIsLoading(loading))))
      .subscribe();
  }

  loadFeatures() {
    return this.fetchAndCacheFeatures();
  }

  getFeatureLayer() {
    return combineLatest([
      this.getFeatures(),
      this.store.select(ScandataState.textFilteredScandata),
    ]).pipe(map(([featureCollection, scandata]) => this.combineData(featureCollection, scandata)));
  }

  getFeatureCollectionOrDefaultBBox(featureCollection: FeatureCollection | null) {
    const visibleFeatures =
      isDefined(featureCollection) && featureCollection.features.length > 0
        ? this.filterFeaturesByVisibilityOnMap(featureCollection)
        : null;

    return isDefined(visibleFeatures) && visibleFeatures.features.length > 0
      ? calculateBounds(visibleFeatures)
      : DEFAULT_BBOX;
  }

  private getFeatures() {
    return this.store.select(FeatureLayerState.featureCollection).pipe(
      switchMap((featureCollection) => {
        return isDefined(featureCollection) ? of(featureCollection) : this.fetchAndCacheFeatures();
      }),
    );
  }

  private fetchAndCacheFeatures() {
    return this.featuresUrl$.pipe(
      tap(() => this.loadingService.startLoading(this)),
      switchMap((baseUrl) => {
        /**
         * The operand in the path below will always be "QQrLmjOjRoY",
         * it is hard-coded by the Seurat backend.
         */
        const pathAndQuery =
          '/features?coordinateType=Global&filter=[{"field":"metadata.common_layerId","operand":"QQrLmjOjRoY","operator":"EQ"}]';
        const url = buildUrl(baseUrl, pathAndQuery);

        return this.http.get<FeatureCollection | null>(url);
      }),
      map((featureCollection) => {
        return (
          featureCollection ?? ({ type: 'FeatureCollection', features: [] } as FeatureCollection)
        );
      }),
      map((featureCollection) => this.filterFeaturesWithGeometry(featureCollection)),
      map((featureCollection) => this.mapFeatures(featureCollection)),
      switchMap((featureCollection) => this.filterFeaturesByScandata(featureCollection)),
      switchMap((featureCollection) => {
        const newBounds = this.getNewBounds(featureCollection);
        const actions = isDefined(newBounds)
          ? [new SetFeatureCollection(featureCollection), new SetBounds(newBounds)]
          : [new SetFeatureCollection(featureCollection)];

        return this.store.dispatch(actions).pipe(map(() => featureCollection));
      }),
      tap(() => this.loadingService.stopLoading(this)),
    );
  }

  /**
   * Return the feature collection bounds if the current bounds in state is the default
   */
  private getNewBounds(featureCollection: FeatureCollection) {
    const currentBBox = this.store.selectSnapshot(MapState.bounds).bbox;

    if (featureCollection.features.length > 0 && bboxEquals(currentBBox, DEFAULT_BBOX)) {
      return {
        bbox: this.getFeatureCollectionOrDefaultBBox(featureCollection),
        padding: DEFAULT_BOUNDS_PADDING,
      } as MapBounds;
    }

    return null;
  }

  private filterFeaturesWithGeometry(featureCollection: FeatureCollection) {
    const featuresWithGeometry = featureCollection.features.filter((feature) => {
      return isDefined(feature.geometry);
    });

    return {
      type: 'FeatureCollection',
      features: featuresWithGeometry,
    } as FeatureCollection;
  }

  private mapFeatures(featureCollection: FeatureCollection) {
    featureCollection.features.forEach((feature) => {
      /**
       * The cast below exists because the Trimble Feature Service adds
       * a non-standard metadata property (similar to properties property)
       * which holds the pointcloudId. For downstream convenience, feature.id
       * is replaced with the pointcloudId.
       */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      feature.id = (<any>feature).metadata?.['pointcloudId'];
    });
    return featureCollection;
  }

  private filterFeaturesByScandata(featureCollection: FeatureCollection) {
    return this.store.selectOnce(ScandataState.scandata).pipe(
      map((scandata) => {
        const features = featureCollection.features.filter((feature) => {
          return isDefined(scandata.find((scan) => scan.id === feature.id));
        });

        return {
          type: 'FeatureCollection',
          features,
        } as FeatureCollection;
      }),
    );
  }

  private filterFeaturesByVisibilityOnMap(featureCollection: FeatureCollection) {
    const featuresVisibleOnMap = featureCollection.features.filter((feature) => {
      return feature.properties?.['visible'] ?? true;
    });

    return {
      type: 'FeatureCollection',
      features: featuresVisibleOnMap,
    } as FeatureCollection;
  }

  private combineData(
    featureCollection: FeatureCollection | null,
    scandata: ScandataModel[],
  ): FeatureLayer {
    if (featureCollection?.features) {
      for (let i = 0; i < featureCollection.features.length; i++) {
        const feature = featureCollection.features[i];
        if (feature) {
          let visible = false;
          let selected = false;

          const model = scandata.find((sd) => sd.id === feature.id);

          visible = model ? !model.hiddenOnMap : false;
          selected = model ? model.selected : false;

          feature.properties = { ...feature.properties, visible, selected };
        }
      }
    }

    return { featureCollection } as FeatureLayer;
  }
}
