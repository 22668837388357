<div class="flex flex-1 flex-col w-full">
  <div class="flex-1 overflow-y-scroll p-4 select-none">
    @if (displayScans$ | async; as scans) {
      <div class="flex flex-col max-w-full text-sm">
        <div class="h-7 flex items-center pb-1 font-bold">Package Contents</div>
        <div>
          @if (downloadAllSelect$ | async; as downloadAll) {
            @if (downloadAll.visible) {
              <div
                class="flex flex-row items-center"
                [ngClass]="{ 'hover:!bg-blue-pale cursor-pointer': !downloadAll.disabled }"
                (click)="!downloadAll.disabled && selectAllClicked()"
              >
                <modus-checkbox
                  id="download-list-toggle-check"
                  [checked]="downloadAll.selected !== 'indeterminate' ? downloadAll.selected : null"
                  [indeterminate]="downloadAll.selected === 'indeterminate'"
                  [disabled]="downloadAll.disabled"
                ></modus-checkbox>
                <div class="w-full leading-8 ml-2">All Files</div>
              </div>
            }
          }
          <!-- list scandata model -->
          @for (scan of scans; track $index) {
            <div>
              <div
                class="flex flex-row items-center"
                [ngClass]="{ 'hover:!bg-blue-pale cursor-pointer': !scan.disabled }"
                (click)="!scan.disabled && selectScanClicked(scan)"
              >
                <button
                  modus-icon-button
                  (click)="toggleExpanded(scan); $event.stopPropagation()"
                  [disabled]="loading$ | async"
                >
                  @if (scan.expanded) {
                    <md-icon class="text-lg/5">caret_down</md-icon>
                  } @else {
                    <md-icon class="text-lg/5">caret_right</md-icon>
                  }
                </button>

                <modus-checkbox
                  id="download-list-toggle-check"
                  [checked]="scan.selected !== 'indeterminate' ? scan.selected : null"
                  [indeterminate]="scan.selected === 'indeterminate'"
                  [disabled]="scan.disabled"
                ></modus-checkbox>

                <div
                  modus-tooltip="{{ scan.scandataModel.name }}"
                  class="w-full overflow-hidden text-ellipsis whitespace-nowrap leading-8 ml-1"
                >
                  {{ scan.scandataModel.name }}
                </div>
              </div>
              <!-- list scandata model files -->
              @if (scan.expanded) {
                <div class="pl-12 pb-2">
                  @if (scan.files.length > 0) {
                    @for (file of scan.files; track $index) {
                      <div
                        class="flex flex-row items-center"
                        [ngClass]="{ 'hover:!bg-blue-pale cursor-pointer': !file.disabled }"
                        (click)="!file.disabled && selectFileClicked($event, file)"
                      >
                        <modus-checkbox
                          id="download-list-toggle-check"
                          [checked]="file.selected"
                          [disabled]="file.disabled"
                        ></modus-checkbox>

                        <md-icon>{{ file.icon }}</md-icon>
                        <div
                          modus-tooltip="{{ file.scandataFile.name }}"
                          class="w-full overflow-hidden text-ellipsis whitespace-nowrap leading-8 ml-1"
                        >
                          {{ file.scandataFile.name }}
                        </div>

                        <!-- file download progress -->
                        @if (file.downloadProgress) {
                          <div class="min-w-[26px] max-w-[26px] ml-1 flex justify-center">
                            <sd-download-progress
                              [downloadProgress]="file.downloadProgress"
                              [progressTooltipPrefix]="'File Progress'"
                            ></sd-download-progress>
                          </div>
                        }
                      </div>
                    }
                  } @else if (scan.scandataModel.status !== pointcloudAPIStatus.Ready) {
                    <div class="flex flex-row items-center">
                      <md-icon class="-mt-1">alert_outlined</md-icon>
                      <div class="leading-8 ml-2">Scan not ready</div>
                    </div>
                  } @else {
                    <div class="flex flex-row items-center">
                      <md-icon class="-mt-1">alert_outlined</md-icon>
                      <div class="leading-8 ml-2">No files available</div>
                    </div>
                  }
                </div>
              }
            </div>
          }
        </div>
      </div>
    }
  </div>

  <!-- button group -->
  <div class="flex p-4 justify-end border-t border-gray-1">
    <button
      id="download-list-cancel"
      modus-button
      class="mr-4"
      color="secondary"
      (click)="cancelClicked.emit()"
    >
      Cancel
    </button>

    <button
      id="download-list-download"
      modus-flat-button
      color="secondary"
      [disabled]="downloadDisabled$ | async"
      (click)="download()"
    >
      Download
    </button>
  </div>
</div>
