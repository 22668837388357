import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { map } from 'rxjs';
import {
  Filters,
  NO_SCANNER_TYPE_FILTER,
  NO_TAGS_FILTER,
  Paging,
  Sorting,
} from './scandata-query.models';
import { PointcloudStatus, PointcloudStatusMap } from './scandata.models';
import { ScandataState } from './scandata.state';

@Injectable({
  providedIn: 'root',
})
export class ScandataFilterService {
  constructor(private store: Store) {}

  getQuerystring() {
    return this.store.selectOnce(ScandataState.query).pipe(
      map((query) => {
        const parts: string[] = [];

        parts.push(this.getSortingQueryString(query.sorting));
        parts.push(this.getPagingQueryString(query.paging));
        parts.push(this.getFiltersQuerystring(query.filters));

        return parts.filter((part) => part.length > 0).join('&');
      }),
    );
  }

  getFiltersFromQueryParameter(queryParameter: string): Filters {
    const decoded = decodeURIComponent(queryParameter);
    const filterPairs = decoded
      .trim()
      .split(',')
      .map((x) => this.removeOuterParenthesis(x))
      .map((x) => x.trim());

    const name = filterPairs.find((x) => x.includes('name='))?.replace('name=', '');

    const captureFromDateValue = filterPairs
      .find((x) => x.includes('captureDate>='))
      ?.replace('captureDate>=', '');
    const captureFromDate = isDefined(captureFromDateValue)
      ? new Date(captureFromDateValue)
      : undefined;

    const captureToDateValue = filterPairs
      .find((x) => x.includes('captureDate<='))
      ?.replace('captureDate<=', '');
    const captureToDate = isDefined(captureToDateValue) ? new Date(captureToDateValue) : undefined;

    // Will be using PointcloudStatus as input from query parameter.  Not PointcloudAPIStatus
    const statusValues = filterPairs.find((x) => x.includes('status='))?.replaceAll('status=', '');
    const status = isDefined(statusValues)
      ? statusValues
          .split('|')
          .map((x) => PointcloudStatus[x as keyof typeof PointcloudStatus])
          .filter((x): x is PointcloudStatus => isDefined(x))
      : undefined;

    const noScannerType =
      filterPairs.find((x) => x.includes('noScannerType='))?.replace('noScannerType=', '') ===
      'true';

    const scannerType = noScannerType
      ? NO_SCANNER_TYPE_FILTER
      : filterPairs.find((x) => x.includes('scannerType='))?.replace('scannerType=', '');

    const tagsMatchAll = filterPairs.filter((x) => x.includes('tags=')).length > 1;

    const tagsValues = tagsMatchAll
      ? filterPairs.filter((x) => x.includes('tags=')).map((x) => x.replace('tags=', ''))
      : (filterPairs
          .find((x) => x.includes('tags='))
          ?.replaceAll('tags=', '')
          .split('|') ?? []);

    const noTags =
      filterPairs.find((x) => x.includes('noTags='))?.replace('noTags=', '') === 'true';
    if (noTags) tagsValues.push(NO_TAGS_FILTER);

    const tags = tagsValues.length > 0 ? tagsValues : undefined;

    const isClassified =
      filterPairs.find((x) => x.includes('isClassified='))?.split('=')[1] === 'true';

    const containsStations =
      filterPairs.find((x) => x.includes('containsStations='))?.split('=')[1] === 'true';

    return {
      name,
      status,
      captureFromDate,
      captureToDate,
      scannerType,
      tags,
      tagsMatchAll: noTags ? false : tagsMatchAll,
      uploadedBy: undefined,
      isClassified: isClassified || undefined,
      containsStations: containsStations || undefined,
    };
  }

  private getSortingQueryString(sorting: Sorting) {
    const sortingQuery =
      sorting.sortBy?.trim().length > 0 ? `sortBy=${sorting.sortBy} ${sorting.sortDirection}` : '';
    return sortingQuery;
  }

  private getPagingQueryString(paging: Paging) {
    const pagingQuery = `pageSize=${paging.pageSize}&pageIndex=${paging.pageIndex}`;
    return pagingQuery;
  }

  private getFiltersQuerystring(filters: Filters) {
    const filterPairs: string[] = [];

    if (isDefined(filters.name) && filters.name.length > 0)
      filterPairs.push(`name=*${this.escapeFilterSpecialChars(filters.name)}/i`);

    if (isDefined(filters.captureFromDate)) {
      const dateValue = new Date(filters.captureFromDate).toISOString();
      filterPairs.push(`captureDate>=${this.escapeFilterSpecialChars(dateValue)}`);
    }

    if (isDefined(filters.captureToDate)) {
      const dateValue = new Date(filters.captureToDate).toISOString();
      filterPairs.push(`captureDate<=${this.escapeFilterSpecialChars(dateValue)}`);
    }

    if (isDefined(filters.status) && filters.status.length > 0) {
      const statusFilter = [...PointcloudStatusMap]
        .filter(([, status]) => filters.status?.some((item) => item === status))
        .map(([apiStatus]) => `status=${this.escapeFilterSpecialChars(apiStatus)}`)
        .join('|');
      filterPairs.push(`(${statusFilter})`);
    }

    if (isDefined(filters.scannerType) && filters.scannerType.length > 0) {
      if (filters.scannerType === NO_SCANNER_TYPE_FILTER) {
        filterPairs.push('noScannerType=true');
      } else {
        filterPairs.push(`scannerType=*${this.escapeFilterSpecialChars(filters.scannerType)}/i`);
      }
    }

    if (isDefined(filters.tags) && filters.tags.length > 0) {
      const tagsFilter = filters.tags.map((tag) => {
        if (tag === NO_TAGS_FILTER) {
          return 'noTags=true';
        } else {
          return `tags=${this.escapeFilterSpecialChars(tag)}`;
        }
      });

      if (filters.tagsMatchAll) {
        filterPairs.push(...tagsFilter);
      } else {
        filterPairs.push(`(${tagsFilter.join('|')})`);
      }
    }

    if (isDefined(filters.uploadedBy) && filters.uploadedBy.length > 0) {
      const uploadedByFilter = filters.uploadedBy
        .map((item) => `uploadedByTIDUuid=${this.escapeFilterSpecialChars(item)}`)
        .join('|');
      filterPairs.push(`(${uploadedByFilter})`);
    }

    if (isDefined(filters.isClassified)) filterPairs.push(`isClassified=${filters.isClassified}`);

    if (isDefined(filters.containsStations)) filterPairs.push(`numberOfStations>0`);

    return filterPairs.length > 0 ? `filterBy=${encodeURIComponent(filterPairs.join(','))}` : '';
  }

  /**
   * Escape special characters used in filter values.
   * More information: https://alirezanet.github.io/Gridify/guide/filtering#escaping
   */
  private escapeFilterSpecialChars(value: string) {
    return value.replace(/([(),|\\]|\/i)/g, '\\$1');
  }

  private removeOuterParenthesis(value: string) {
    return value.slice(
      value.charAt(0) === '(' ? 1 : 0,
      value.charAt(value.length - 1) === ')' ? -1 : undefined,
    );
  }
}
