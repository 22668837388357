import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, fromEvent, takeUntil } from 'rxjs';

export enum SelectionChangeSource {
  userInput = 'userInput',
  system = 'system',
}

/* eslint-disable @angular-eslint/component-class-suffix */

export class ModusOptionSelectionChange<T = unknown> {
  constructor(
    public readonly option: ModusOption<T>,
    public readonly source: SelectionChangeSource = SelectionChangeSource.system,
  ) {}
}

@Component({
  selector: 'modus-option',
  templateUrl: './modus-option.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModusOption<T = unknown> implements OnInit, OnDestroy {
  private readonly element = this.elementRef.nativeElement;
  private readonly onDestroy = new Subject<void>();

  private _active = false;
  private _selected = false;

  @Input() value!: T;

  @HostBinding('class.modus-option-active')
  get active(): boolean {
    return this._active;
  }

  @HostBinding('class.modus-option-selected')
  get selected(): boolean {
    return this._selected;
  }

  @Output() readonly selectionChange = new EventEmitter<ModusOptionSelectionChange<T>>();

  constructor(
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    elementRef.nativeElement.classList.add('modus-option');
  }

  ngOnInit(): void {
    fromEvent<MouseEvent>(this.element, 'click')
      .pipe(takeUntil(this.onDestroy))
      .subscribe({
        next: () => this.select(SelectionChangeSource.userInput),
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  activate() {
    this._active = true;
    this.element.scrollIntoView();
    this.changeDetectorRef.markForCheck();
  }

  deactivate() {
    this._active = false;
    this.changeDetectorRef.markForCheck();
  }

  select(source: SelectionChangeSource = SelectionChangeSource.system) {
    this._selected = true;
    this.changeDetectorRef.markForCheck();
    this.selectionChange.emit(new ModusOptionSelectionChange(this, source));
  }

  deselect(source: SelectionChangeSource = SelectionChangeSource.system) {
    this._selected = false;
    this.changeDetectorRef.markForCheck();
    this.selectionChange.emit(new ModusOptionSelectionChange(this, source));
  }
}
