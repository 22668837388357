import { Injectable } from '@angular/core';

// https://support.gainsight.com/PX/API_for_Developers/01About/Work_with_Gainsight_PX_Web_SDK

@Injectable({
  providedIn: 'root',
})
export class AptrinsicSdk {
  identify(user: aptrinsic.user, account?: aptrinsic.account) {
    aptrinsic('identify', user, account);
  }

  track(eventName: string, data?: aptrinsic.eventData) {
    aptrinsic('track', eventName, data);
  }

  set(data: aptrinsic.contextData) {
    aptrinsic('set', 'globalContext', data);
  }

  setOnce(data: aptrinsic.contextData) {
    aptrinsic('setOnce', 'globalContext', data);
  }

  remove(props: string[]) {
    aptrinsic('remove', 'globalContext', props);
  }
}
