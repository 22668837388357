import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { FeatureFlagService } from '@trimble-gcs/feature-flags';
import { tap } from 'rxjs';
import { AppState } from '../app-state/app.state';
import { FeatureFlagKey } from './feature-flag-key';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsConfigService {
  constructor(
    private featureFlagService: FeatureFlagService<FeatureFlagKey>,
    private store: Store,
  ) {}

  loadFeatureFlags() {
    // Eventually the feature config should come from an endpoint
    return this.store.selectOnce(AppState.settings).pipe(
      tap((settings) => {
        this.featureFlagService.load(settings.featureFlags);
      }),
    );
  }
}
