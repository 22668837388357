import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { SetTags } from './tag.actions';

export interface TagStateModel {
  tags: string[];
}

@State<TagStateModel>({
  name: 'TagState',
  defaults: {
    tags: [],
  },
})
@Injectable()
export class TagState {
  @Selector() static tags(state: TagStateModel): string[] {
    return state.tags;
  }

  @Action(SetTags)
  setTags(ctx: StateContext<TagStateModel>, { tags }: SetTags) {
    ctx.setState(patch({ tags }));
  }
}
