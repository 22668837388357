import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { SetScannerTypes } from './scanner-type.actions';

export interface ScannerTypeStateModel {
  scannerTypes: string[];
}

@State<ScannerTypeStateModel>({
  name: 'ScannerTypeState',
  defaults: {
    scannerTypes: [],
  },
})
@Injectable()
export class ScannerTypeState {
  @Selector() static scannerTypes(state: ScannerTypeStateModel): string[] {
    return state.scannerTypes;
  }

  @Action(SetScannerTypes)
  setScannerTypes(ctx: StateContext<ScannerTypeStateModel>, { scannerTypes }: SetScannerTypes) {
    ctx.setState(patch({ scannerTypes }));
  }
}
