import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
  standalone: true,
})
export class FileSizePipe implements PipeTransform {
  transform(sizeInBytes: number, decimalPlaces = 2): string {
    if (Number.isFinite(sizeInBytes) && sizeInBytes >= 0 && decimalPlaces >= 0) {
      const suffixes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

      for (let i = 0; i < suffixes.length; i++) {
        if (sizeInBytes < 1024 ** (i + 1) || i === suffixes.length - 1) {
          return (sizeInBytes / 1024 ** i).toFixed(i === 0 ? 0 : decimalPlaces) + ' ' + suffixes[i];
        }
      }
    }
    return '';
  }
}
