import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ModusError } from './directives/error.directive';
import { ModusHint } from './directives/hint.directive';
import { ModusLabel } from './directives/label.directive';
import { ModusPrefix } from './directives/prefix.directive';
import { ModusSuffix } from './directives/suffix.directive';
import { ModusValid } from './directives/valid.directive';
import { ModusFormField } from './form-field-component/form-field.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  declarations: [
    ModusFormField,
    ModusLabel,
    ModusPrefix,
    ModusSuffix,
    ModusHint,
    ModusValid,
    ModusError,
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    ModusFormField,
    ModusLabel,
    ModusPrefix,
    ModusSuffix,
    ModusHint,
    ModusValid,
    ModusError,
  ],
})
export class ModusFormFieldModule {}
