import { inject } from '@angular/core';
import {
  LogsEvent,
  LogsInitConfiguration,
  Logger as dataDogLogger,
  datadogLogs,
} from '@datadog/browser-logs';
import { Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { filter, take } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { AppState } from '../app-state/app.state';
import { dataDogConfig } from './data-dog-config';
import { LoggerBase, compareLevel } from './logger';
import { ILogger, LogLevel, LoggerType } from './logger-types';

export class DataDogLogger extends LoggerBase implements ILogger {
  private store = inject(Store);
  private logger!: dataDogLogger;

  override get type(): LoggerType {
    return 'dataDogLogger';
  }

  static isInitialized = false;

  constructor(name = 'DataDogLogger', logger?: dataDogLogger) {
    super(name);

    if (!DataDogLogger.isInitialized) {
      /**
       * Initialize with static settings to make the logger work with Angular DI.
       * This also makes the logger available early, and can be used in the APP_INITIALIZER.
       * Then subscribe to appSettings for runtime configuration.
       */
      this.initialize(dataDogConfig);
      DataDogLogger.isInitialized = true;
    }

    this.logger = logger || datadogLogs.createLogger(name);
  }

  createLogger(name: string): ILogger {
    return new DataDogLogger(name);
  }

  log(message: string, context?: object, level?: LogLevel, error?: Error): void {
    if (level === LogLevel.None) return;

    const logLevel = level ?? LogLevel.Debug;

    if (compareLevel(logLevel, this.level) >= 0) {
      this.logger.log(message, context, logLevel, error);
    }
  }

  debug(message: string, context?: object, error?: Error): void {
    if (compareLevel(this.level, LogLevel.Debug) >= 0) {
      this.logger.debug(message, context, error);
    }
  }

  info(message: string, context?: object, error?: Error): void {
    if (compareLevel(this.level, LogLevel.Info) >= 0) {
      this.logger.info(message, context, error);
    }
  }

  warn(message: string, context?: object, error?: Error): void {
    if (compareLevel(this.level, LogLevel.Warn) >= 0) {
      this.logger.warn(message, context, error);
    }
  }

  error(message: string, context?: object, error?: Error): void {
    if (compareLevel(this.level, LogLevel.Error) >= 0) {
      this.logger.error(message, context, error);
    }
  }

  private initialize(config: LogsInitConfiguration & { logLevel: LogLevel }) {
    datadogLogs.init({
      ...config,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: ['error'],
      forwardReports: 'all',
      sessionSampleRate: 100,
      storeContextsAcrossPages: true,
      beforeSend: (log: LogsEvent) => {
        if (log.message?.includes('[webpack-dev-server]')) {
          return false;
        } else return true;
      },
    });

    datadogLogs.setGlobalContextProperty('session', uuidv4());

    this.level = config.logLevel;

    this.store
      .select(AppState.settings)
      .pipe(filter(isDefined), take(1))
      .subscribe((appSettings) => {
        const logSettings = appSettings.logging;
        datadogLogs.setGlobalContextProperty('env', logSettings.dataDog.env);
      });

    this.store
      .select(AppState.project)
      .pipe(filter(isDefined))
      .subscribe((project) => {
        datadogLogs.setGlobalContextProperty('project', { id: project.id, name: project.name });
      });
  }
}
