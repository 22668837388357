import { DownloadFile, DownloadScan } from './download.model';

export class AddToDownload {
  static readonly type = '[Downloads] AddToDownload';
  constructor(
    public downloadScan: DownloadScan,
    public downloadFile: DownloadFile,
  ) {}
}

export class PatchDownloadFile {
  static readonly type = '[Downloads] PatchDownloadFile';
  constructor(public downloadFile: DownloadFile) {}
}

export class ClearAllDownloads {
  static readonly type = '[Downloads] ClearAllDownloads';
}

export class ClearCompletedDownloads {
  static readonly type = '[Downloads] ClearCompletedDownloads';
}
