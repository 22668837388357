// index.ts
import { featureCollection, lineString, multiLineString } from "@turf/helpers";
import { getGeom } from "@turf/invariant";
function polygonToLine(poly, options = {}) {
  const geom = getGeom(poly);
  if (!options.properties && poly.type === "Feature") {
    options.properties = poly.properties;
  }
  switch (geom.type) {
    case "Polygon":
      return singlePolygonToLine(geom, options);
    case "MultiPolygon":
      return multiPolygonToLine(geom, options);
    default:
      throw new Error("invalid poly");
  }
}
function singlePolygonToLine(poly, options = {}) {
  const geom = getGeom(poly);
  const coords = geom.coordinates;
  const properties = options.properties ? options.properties : poly.type === "Feature" ? poly.properties : {};
  return coordsToLine(coords, properties);
}
function multiPolygonToLine(multiPoly, options = {}) {
  const geom = getGeom(multiPoly);
  const coords = geom.coordinates;
  const properties = options.properties ? options.properties : multiPoly.type === "Feature" ? multiPoly.properties : {};
  const lines = [];
  coords.forEach(coord => {
    lines.push(coordsToLine(coord, properties));
  });
  return featureCollection(lines);
}
function coordsToLine(coords, properties) {
  if (coords.length > 1) {
    return multiLineString(coords, properties);
  }
  return lineString(coords[0], properties);
}
var turf_polygon_to_line_default = polygonToLine;
export { coordsToLine, turf_polygon_to_line_default as default, multiPolygonToLine, polygonToLine, singlePolygonToLine };
