<mat-toolbar class="!bg-blue-dark !text-white">
  <button modus-icon-button class="mr-4" [disabled]="!loggedIn" (click)="menuClick.emit($event)">
    <md-icon class="text-white">menu</md-icon>
  </button>

  <span class="h-1/2 border-r border-gray-1"></span>

  <span class="ml-4 mr-4">Reality Capture Browser</span>

  <span class="h-1/2 border-r border-gray-1"></span>

  @if (loggedIn) {
    <sd-project-select class="ml-4 mr-4"></sd-project-select>
  }

  <div class="flex grow justify-end">
    <button
      modus-icon-button
      class="flex flex-col justify-center mr-4"
      [ngClass]="{ invisible: !loggedIn }"
      [matMenuTriggerFor]="userMenu"
    >
      <md-icon class="text-white">person</md-icon>
    </button>
  </div>

  <mat-menu #userMenu="matMenu">
    <button mat-menu-item (click)="profileClick.emit()">Profile</button>
    <button mat-menu-item (click)="licenceClick.emit()">Licence</button>
    <button mat-menu-item (click)="logoutClick.emit()">Logout</button>
  </mat-menu>
</mat-toolbar>
