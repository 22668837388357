import { Directive } from '@angular/core';

/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @angular-eslint/directive-class-suffix */

/** Valid feedback message to be displayed for a modus-form-field. */
@Directive({
  selector: 'modus-valid',
})
export class ModusValid {}
