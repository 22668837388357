import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { ButtonBase } from '../modus-button-base';

/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable @angular-eslint/component-selector */

export type FlatButtonColor = 'primary' | 'secondary' | 'tertiary' | 'danger';

@Component({
  selector: 'button[modus-flat-button]',
  template: `<ng-content></ng-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModusFlatButton extends ButtonBase {
  private _color: FlatButtonColor = 'primary';

  private colorClasses = {
    primary: 'modus-flat-button-primary',
    secondary: 'modus-flat-button-secondary',
    tertiary: 'modus-flat-button-tertiary',
    danger: 'modus-flat-button-danger',
  };

  @Input() set color(value: FlatButtonColor) {
    const oldColor = this.colorClasses[this._color];
    const newColor = this.colorClasses[value];

    if (oldColor) this.element.classList.remove(oldColor);
    this.element.classList.add(newColor);

    this._color = value;
  }

  constructor(elementRef: ElementRef) {
    super(elementRef);
    this.element.classList.add('modus-flat-button-primary');
  }
}
