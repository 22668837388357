import { Component } from '@angular/core';

@Component({
  standalone: true,
  imports: [],
  template: `<div class="m-4">You don't have access to this project.</div>`,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    `,
  ],
})
export class NoProjectAccessComponent {}
