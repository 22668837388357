import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { isNil } from '@trimble-gcs/common';
import { ModusButtonModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { Observable, combineLatest, map, shareReplay } from 'rxjs';
import { SetClassificationForEdit } from '../../../../classification/classification-scheme.actions';
import { ClassificationScheme } from '../../../../classification/classification-scheme.model';
import { ClassificationSchemeState } from '../../../../classification/classification-scheme.state';
import { Classification } from '../../../../classification/classification.model';
import { ClassificationService } from '../../../../classification/classification.service';
import { PercentagePipe } from '../../../../pipes/percentage.pipe';
import { ScandataModel } from '../../../../scandata/scandata.models';
import { SetView } from '../../../options-panel.actions';
import { OptionsPanelView } from '../../../options-panel.state';
import { ClassificationGraphComponent } from './classification-graph/classification-graph.component';

@UntilDestroy()
@Component({
  selector: 'sd-classification',
  standalone: true,
  imports: [
    CommonModule,
    ClassificationGraphComponent,
    PercentagePipe,
    ModusTooltipModule,
    ModusButtonModule,
    ModusIconModule,
  ],
  templateUrl: './classification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClassificationComponent implements OnChanges {
  @Select(ClassificationSchemeState.classifications)
  classificationScheme$!: Observable<ClassificationScheme[] | undefined>;

  @Input() scandataModel!: ScandataModel;
  @Input() readonly = false;

  hasClassifications$!: Observable<boolean>;
  classifications$!: Observable<Classification[]>;
  activeClassification!: Classification | null;
  unallocatedClassificationCount$!: Observable<number>;

  constructor(
    private classificationService: ClassificationService,
    private store: Store,
  ) {}

  ngOnChanges() {
    this.classifications$ = this.getScanClassifications();

    this.hasClassifications$ = this.classifications$.pipe(
      map((classifications) => classifications.length > 0),
    );

    this.unallocatedClassificationCount$ = combineLatest([
      this.classificationScheme$,
      this.classifications$,
    ]).pipe(
      map(([scheme, classifications]) => {
        if (isNil(scheme) || scheme.length < classifications.length) return 0;
        return scheme.length - classifications.length;
      }),
    );
  }

  setActiveClassification(classification: Classification | null = null) {
    this.activeClassification = classification;
  }

  isActiveClassification(classification: Classification) {
    return classification === this.activeClassification;
  }

  editClassification(classificationScheme: ClassificationScheme) {
    this.store.dispatch([
      new SetClassificationForEdit(classificationScheme),
      new SetView(OptionsPanelView.ClassificationEdit, false),
    ]);
  }

  private getScanClassifications() {
    return this.classificationService.getClassificationSchemes().pipe(
      map((schemes) =>
        this.classificationService.getScanClassifications(this.scandataModel, schemes),
      ),
      shareReplay({ refCount: true, bufferSize: 1 }),
      untilDestroyed(this),
    );
  }
}
