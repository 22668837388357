import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';

/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable @angular-eslint/component-selector */

@Component({
  selector: 'button[modus-icon-button]',
  template: `<ng-content></ng-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModusIconButton {
  protected element: HTMLButtonElement;

  constructor(elementRef: ElementRef) {
    this.element = elementRef.nativeElement as HTMLButtonElement;
    this.element.classList.add('modus-button', 'modus-icon-button');
  }
}
