import { Directive, Input } from '@angular/core';

/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @angular-eslint/directive-class-suffix */

/** Prefix to be placed at the start of a modus-form-field. */
@Directive({
  selector: '[modus-prefix], [modus-icon-prefix], [modus-text-prefix]',
})
export class ModusPrefix {
  isText = false;

  get isIcon(): boolean {
    return !this.isText;
  }

  @Input('modus-text-prefix')
  set textPrefix(value: '') {
    this.isText = true;
  }
}
