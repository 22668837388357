<div class="h-full py-2 flex flex-col">
  <!-- header -->
  <div mat-dialog-title class="flex h-12 flex-row p-4 items-center">
    <div class="flex flex-1 flex-wrap items-center font-bold">Upload Scan File</div>
    <button
      id="import-dialog-close"
      modus-icon-button
      modus-tooltip="Close"
      (click)="cancelClick()"
    >
      <md-icon>close</md-icon>
    </button>
  </div>

  <!-- content -->
  <div mat-dialog-content class="flex flex-1 flex-col p-4 pt-2 text-sm overflow-hidden">
    <sd-local-file-picker
      class="flex"
      [disabled]="importStarted()"
      (filesPicked)="addFiles($event)"
    ></sd-local-file-picker>

    <sd-import-file-list
      #uploadFilesControl
      class="flex"
      [importFiles]="importFiles()"
      [disableRemove]="importStarted()"
      [showStatus]="importStarted()"
      (removeFiles)="removeFiles($event)"
    ></sd-import-file-list>
  </div>

  <!-- actions -->
  <div mat-dialog-actions class="flex p-4 items-center">
    <modus-switch
      class="ml-4 select-none"
      label="Combine files into a single scan package."
      [formControl]="combineToSingleScanControl"
    ></modus-switch>

    <div class="flex grow justify-end">
      <button id="import-dialog-cancel" modus-button color="secondary" (click)="cancelClick()">
        {{ cancelButtonText() }}
      </button>
      <button
        id="import-dialog-import"
        modus-flat-button
        color="primary"
        class="ml-4"
        (click)="importClick()"
        [disabled]="disableImport()"
      >
        Upload
      </button>
    </div>
  </div>
</div>
