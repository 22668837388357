<div class="flex flex-col border-b border-gray-1 min-h-[76.5px]">
  @if (isLoading()) {
    <div class="w-full relative">
      <div class="w-full absolute">
        <mat-progress-bar class="mds-x-small" [mode]="'indeterminate'"></mat-progress-bar>
      </div>
    </div>
  }

  @if (showTextFilter()) {
    <div class="pt-1 px-3">
      <modus-form-field>
        <md-icon modus-icon-prefix>search</md-icon>
        <button modus-icon-suffix (click)="hideTextFilterClick()">
          <md-icon>close</md-icon>
        </button>
        <input
          auto-focus
          #textFilter
          modus-input
          [formControl]="textFilterControl"
          placeholder="Find"
          size="large"
        />
      </modus-form-field>
    </div>
  } @else {
    <div class="flex">
      <div class="flex flex-col flex-grow pl-3 pt-3 pr-2 text-xl font-medium">
        Reality Capture
        <div class="w-[154px]">
          <mat-select
            id="3d-list-filter"
            class="modus-dropdown"
            panelClass="modus-dropdown-panel"
            [formControl]="filterOptionControl"
            disableRipple
          >
            <mat-option [value]="scan3dListFilterOption.ShowAll">All project data</mat-option>
            <mat-option [value]="scan3dListFilterOption.ShowSelected">Selected only</mat-option>
          </mat-select>
        </div>
      </div>

      <div class="flex items-center mr-1">
        <sd-scan-3d-list-sort-menu
          [menuItems]="sortMenuItems"
          (sortClick)="sortClick($event)"
        ></sd-scan-3d-list-sort-menu>
        <button
          id="3d-search"
          class="text-gray-8"
          modus-icon-button
          (click)="showTextFilterClick()"
        >
          <md-icon>search</md-icon>
        </button>
        <button
          id="3d-global-settings"
          class="text-gray-8"
          modus-icon-button
          (click)="onSettingClick()"
        >
          <md-icon>settings</md-icon>
        </button>
      </div>
    </div>
  }
</div>

@if (hasCurrentStation()) {
  <div class="flex flex-row items-center pl-3 border-b border-gray-1 bg-blue-light text-white">
    <div
      modus-tooltip="Station: {{ currentStationName() }}"
      class="w-full overflow-hidden text-ellipsis whitespace-nowrap mr-1"
    >
      Station: {{ currentStationName() }}
    </div>
    <div class="flex justify-end mr-2">
      <button
        id="3d-exit-station"
        modus-tooltip="Exit Station"
        class="bg-blue-light text-white enabled:hover:text-white"
        modus-icon-button
        (click)="onExitStationClick()"
      >
        <md-icon>close</md-icon>
      </button>
    </div>
  </div>
}

@if (scanLoadError()) {
  <div class="p-4">
    <modus-alert
      class="w-full"
      message="Error loading data. Please try again later."
      type="error"
    ></modus-alert>
  </div>
}

<div class="pb-3 overflow-y-auto pt-1" cdkScrollable>
  <sd-scandata-tree-view
    [scandata]="data()"
    (modelDetailClick)="onModelDetailClick($event)"
  ></sd-scandata-tree-view>

  @if (showScandataEmpty()) {
    <sd-scandata-empty [reason]="scandataEmptyReason()"></sd-scandata-empty>
  }
</div>
