<div class="flex flex-col h-full">
  @if (showProgressBar$ | async) {
    <div class="w-full absolute">
      <mat-progress-bar class="mds-x-small" [mode]="'indeterminate'"></mat-progress-bar>
    </div>
  }

  <div class="flex flex-row p-4 pr-6 border-b border-gray-1">
    <div class="h-8 flex flex-1 flex-wrap content-center font-semibold">Details</div>
    <button
      id="options-panel_close"
      class="text-gray-8"
      modus-icon-button
      modus-tooltip="Close"
      (click)="unselect()"
    >
      <md-icon>close</md-icon>
    </button>
  </div>

  <div class="flex-1 overflow-y-scroll p-4" cdkScrollable>
    @if (scandataModel; as model) {
      <div class="flex flex-col max-w-full">
        <!--model name-->
        <div class="flex flex-row -mt-3">
          <div
            class="w-full overflow-hidden text-ellipsis whitespace-nowrap leading-8 text-primary font-semibold"
            modus-tooltip="{{ model.name }}"
          >
            {{ model.name }}
          </div>
          <button
            id="options-panel_toggle_preview"
            class="text-gray-8"
            modus-icon-button
            [modus-tooltip]="(previewTooltip$ | async)!"
            [disabled]="disablePreview$ | async"
            (click)="togglePreview()"
          >
            @if (showPreview$ | async) {
              <md-icon>visibility_on</md-icon>
            } @else {
              <md-icon>visibility_off</md-icon>
            }
          </button>
        </div>
        <!--preview-->
        @if (showPreview$ | async) {
          <div class="w-full">
            <div
              class="flex border border-gray-200 mt-1 mb-1 w-full overflow-hidden items-center h-[200px]"
            >
              <sd-viewer-glb [sourceUri]="model.previewUrl"></sd-viewer-glb>
            </div>
          </div>
        } @else {
          <div class="-ml-4 -mr-4 mt-1 mb-2 border-b border-gray-1"></div>
        }
        <!--options-->
        <div class="flex flex-row items-center mt-1">
          <button
            id="options-panel_view_in_3d"
            modus-flat-button
            color="secondary"
            class="w-[115px]"
            (click)="viewIn3d()"
          >
            View in 3D
          </button>
          <div class="flex flex-1 justify-end">
            <button
              id="options-panel_download"
              class="text-gray-8"
              modus-icon-button
              modus-tooltip="Download"
              (click)="downloadClicked.emit()"
            >
              <md-icon>download</md-icon>
            </button>
            @if (!readonly) {
              <button
                id="options-panel_delete"
                class="text-gray-8"
                modus-icon-button
                modus-tooltip="Delete"
                (click)="deleteScandata()"
              >
                <md-icon>delete</md-icon>
              </button>
            }
          </div>
        </div>
        <!--details-->
        <div class="-ml-4 -mr-4 mt-2 border-t border-gray-1">
          <mat-tab-group
            class="mds-small"
            preserveContent="true"
            (selectedTabChange)="infoTabChange($event)"
            [selectedIndex]="selectedInfoTabIndex$ | async"
            disableRipple
          >
            <mat-tab label="Properties" labelClass="!p-0">
              <div class="border-t border-gray-1 p-4">
                <sd-properties
                  [scandataModel]="model"
                  [readonly]="readonly"
                  (saving)="handleSave($event)"
                ></sd-properties>
              </div>
            </mat-tab>
            <mat-tab label="Tagging" labelClass="!p-0">
              <div class="border-t border-gray-1 p-4">
                <sd-tagging [scandataModel]="model" (saving)="handleSave($event)"></sd-tagging>
              </div>
            </mat-tab>
            <mat-tab label="Classification" labelClass="!p-0">
              <div class="border-t border-gray-1 p-4">
                <sd-classification
                  [scandataModel]="model"
                  [readonly]="readonly"
                ></sd-classification>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    }
  </div>
</div>
