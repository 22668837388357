import { OverlayModule } from '@angular/cdk/overlay';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCommonModule } from '@angular/material/core';
import { ModusTooltip } from './modus-tooltip.directive';
import { TooltipComponent } from './tooltip.component';

@NgModule({
  imports: [CommonModule, OverlayModule, MatCommonModule],
  exports: [ModusTooltip, TooltipComponent, MatCommonModule, CdkScrollableModule],
  declarations: [ModusTooltip, TooltipComponent],
})
export class ModusTooltipModule {}
