import { WorkerFactory } from './worker-types';

export interface WorkerDefinition {
  readonly name: string;
  readonly factory: WorkerFactory;
}

export class WorkerTask {
  readonly params: TaskParams;
  readonly transferables: Transferable[];

  readonly promise: Promise<unknown>;
  resolve!: (value: unknown | PromiseLike<unknown>) => void;
  reject!: (reason?: unknown) => void;

  constructor(
    readonly id: string,
    func = '',
    ...parameters: unknown[]
  ) {
    const { args, transferables } = packParams(...parameters);

    this.params = new TaskParams(id, func, args);
    this.transferables = transferables;

    this.promise = new Promise<unknown>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}

export class TaskParams {
  constructor(
    readonly id: string,
    readonly func: string,
    readonly args: unknown[],
  ) {}
}

export class TaskResult {
  constructor(
    readonly id: string,
    readonly func: string,
    readonly value: unknown,
  ) {}
}

export class Transfer<T = never> {
  readonly type = 'Transfer';
  constructor(
    readonly arg: T,
    readonly transferables: Transferable[],
  ) {}
}

export function transfer<T>(arg: T, transferables?: Transferable[]): Transfer<T> {
  if (!transferables) {
    if (!isTransferable(arg)) throw new Error('Argument is not Transferable.');
    return new Transfer<T>(arg, [arg]);
  }
  return new Transfer<T>(arg, transferables);
}

export function packParams(...parameters: unknown[]) {
  const args: unknown[] = [];
  const transferables: Transferable[] = [];
  parameters.forEach((param) => {
    if (isTransfer(param)) {
      args.push(param.arg);
      transferables.push(...param.transferables);
    } else {
      args.push(param);
    }
  });
  return { args, transferables };
}

export function packResult(value: unknown) {
  const transferables: Transferable[] = [];
  if (isTransfer(value)) {
    transferables.push(...value.transferables);
    return { value: value.arg, transferables };
  }
  return { value, transferables };
}

// https://developer.mozilla.org/en-US/docs/Glossary/Transferable_objects#supported_objects
const transferables = [
  'ArrayBuffer',
  'MessagePort',
  'ReadableStream',
  'WritableStream',
  'TransformStream',
  'AudioData',
  'ImageBitmap',
  'VideoFrame',
  'OffscreenCanvas',
];

function isTransferable(arg: unknown): arg is Transferable {
  const candidate = arg as Transferable;
  return candidate && transferables.indexOf(candidate.constructor.name) >= 0;
}

function isTransfer(arg: unknown): arg is Transfer {
  const candidate = arg as Transfer;
  const isTransfer = candidate && candidate.type && candidate.type === 'Transfer';
  return isTransfer;
}
