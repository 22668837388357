import { Component } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { ModusButtonModule } from '@trimble-gcs/modus';
import { filter, map } from 'rxjs';
import { AppRoute } from '../../app.routes';
import { ConnectService } from '../../connect/connect.service';
import { AuthState } from '../auth.state';

enum View {
  Extension3D,
  ExtensionBrowser,
  ExtensionBrowserConfig,
}

@UntilDestroy()
@Component({
  standalone: true,
  imports: [ModusButtonModule],
  templateUrl: './connect-permission-denied.component.html',
})
export class ConnectPermissionDeniedComponent {
  currentView = toSignal(this.getCurrentView(), { initialValue: View.ExtensionBrowser });
  view = View;

  constructor(
    store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private connectService: ConnectService,
  ) {
    store
      .select(AuthState.loggedIn)
      .pipe(
        filter((loggedIn) => loggedIn),
        untilDestroyed(this),
      )
      .subscribe(() => {
        const returnPath = this.activatedRoute.snapshot.queryParamMap.get('returnPath') ?? '/';
        return router.navigate([returnPath]);
      });
  }

  async viewExtensions() {
    /**
     * HACK
     * Connect does not go to the project extensions screen when you are in the
     * extensions config. To work around this connect bug, we first goto the
     * project details and then to the config screen.
     */
    if (this.currentView() === View.ExtensionBrowserConfig) {
      await this.connectService.goToProjectSettings();
    }

    await this.connectService.goToProjectExtensions();
  }

  private getCurrentView() {
    return this.router.events.pipe(
      filter((data) => data instanceof NavigationEnd),
      map((event) => {
        const url = event.url;

        if (url.includes(AppRoute.Connect3d)) return View.Extension3D;
        if (url.includes(AppRoute.Config)) return View.ExtensionBrowserConfig;

        return View.ExtensionBrowser;
      }),
      untilDestroyed(this),
    );
  }
}
