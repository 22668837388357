import { FeatureCollection } from 'geojson';

export class SetFeatureCollection {
  static readonly type = '[FeatureLayer] SetFeatureCollection';
  constructor(public featureCollection: FeatureCollection | null) {}
}

export class SetIsLoading {
  static readonly type = '[FeatureLayer] SetIsLoading';
  constructor(public readonly isLoading: boolean) {}
}
