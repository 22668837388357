import { Injectable } from '@angular/core';
import { Action, State, StateContext, createSelector } from '@ngxs/store';
import { ErrorType } from './error-type';
import { ClearError, SetError } from './error.actions';

type ErrorStateModel = { [K in ErrorType]: boolean };

const defaultState: ErrorStateModel = {
  scanLoadError: false,
  scanDetailsLoadError: false,
  scanDetailsSaveError: false,
  classificationLoadError: false,
  classificationSaveError: false,
  classificationResetError: false,
  classificationImportError: false,
};

@State<ErrorStateModel>({
  name: 'errorState',
  defaults: defaultState,
})
@Injectable()
export class ErrorState {
  static hasError(type: ErrorType) {
    return createSelector([ErrorState], (state: ErrorStateModel) => {
      return state[type];
    });
  }

  @Action(SetError) setError(ctx: StateContext<ErrorStateModel>, { errorType }: SetError) {
    const state = ctx.getState();
    state[errorType] = true;
    ctx.setState({ ...state });
  }

  @Action(ClearError) clearError(ctx: StateContext<ErrorStateModel>, { errorType }: SetError) {
    const state = ctx.getState();
    state[errorType] = false;
    ctx.setState({ ...state });
  }
}
