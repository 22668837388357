import { Injectable } from '@angular/core';
import { NgxsAfterBootstrap, Select, Selector, State, StateContext } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import { map, Observable } from 'rxjs';
import { ScandataModel } from './scandata.models';
import { ScandataState } from './scandata.state';

export interface ScandataSelectedStateModel {
  scans: ScandataModel[];
}

const defaultState: ScandataSelectedStateModel = {
  scans: [],
};

@State<ScandataSelectedStateModel>({
  name: 'scandataSelectedState',
  defaults: defaultState,
})
@Injectable()
export class ScandataSelectedState implements NgxsAfterBootstrap {
  @Select(ScandataState.scandata) scans$!: Observable<ScandataModel[]>;

  @Selector() static ids(state: ScandataSelectedStateModel): string[] {
    return state.scans.map((scan) => scan.id);
  }

  @Selector() static chronologicalSelected(state: ScandataSelectedStateModel): ScandataModel[] {
    return state.scans;
  }

  ngxsAfterBootstrap(ctx: StateContext<ScandataSelectedStateModel>): void {
    this.scans$
      .pipe(map((scans) => scans.filter((scan) => scan.selected)))
      .subscribe((selectedScans) => {
        const currentScans = ctx.getState().scans;

        //remove scans's no longer selected
        const keepScans = currentScans
          .map((currentScan) =>
            selectedScans.find((selectedScan) => selectedScan.id === currentScan.id),
          )
          .filter(isDefined);

        //add new scans's selected
        const addScans = selectedScans.filter((selectedScan) =>
          isNil(keepScans.find((keepScan) => keepScan.id === selectedScan.id)),
        );

        ctx.patchState({ scans: [...keepScans, ...addScans] });
      });
  }
}
