import { InjectionToken } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

export interface LoadingBarOptions {
  color: ThemePalette;
}

export const LOADING_BAR_DEFAULT_OPTIONS: LoadingBarOptions = {
  color: 'primary',
};

export const LOADING_BAR_OPTIONS = new InjectionToken<LoadingBarOptions>('LoadingBarOptions');
