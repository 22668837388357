import { Injectable } from '@angular/core';
import { isNil } from '@trimble-gcs/common';
import { AsyncWorkerLiteral, WebWorker, WorkerDefinition } from '@trimble-gcs/web-workers';
import { from, Observable, switchMap } from 'rxjs';
import { Md5Compute } from './md5.worker';

const md5ComputeDefinition: WorkerDefinition = {
  name: 'Md5Compute',
  factory: () =>
    new Worker(new URL('./md5.worker.ts', import.meta.url), {
      name: 'md5Compute',
      type: 'module',
    }),
};

@Injectable({
  providedIn: 'root',
})
export class Md5WorkerService {
  private md5Compute!: AsyncWorkerLiteral<Md5Compute>;

  public async destroy() {
    await this.md5Compute?.terminate();
  }

  public getMd5Hash(blob: Blob): Observable<string> {
    return from(this.initWorker()).pipe(switchMap((worker) => from(worker.compute(blob))));
  }

  private async initWorker(): Promise<AsyncWorkerLiteral<Md5Compute>> {
    if (isNil(this.md5Compute)) {
      this.md5Compute = await WebWorker.create<Md5Compute>(md5ComputeDefinition);
    }

    return this.md5Compute;
  }
}
