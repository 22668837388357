import { Directive, HostListener, input, output } from '@angular/core';
import { isDefined } from '@trimble-gcs/common';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[file-drag-drop]',
  standalone: true,
})
export class FileDragDropDirective {
  disabled = input(false);
  filesDropped = output<File[]>();
  dragOver = output<boolean>();

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (this.disabled()) return;
    this.dragOver.emit(true);
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (this.disabled()) return;
    this.dragOver.emit(false);
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragOver.emit(false);

    if (!this.disabled() && isDefined(event.dataTransfer)) {
      const files = Array.from(event.dataTransfer.files);
      if (files.length > 0) {
        this.filesDropped.emit(files);
      }
    }
  }
}
