import {
  ConnectionPositionPair,
  Overlay,
  OverlayConfig,
  OverlayRef,
  PositionStrategy,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, ElementRef, Injector, Input, OnDestroy } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import {
  LOADING_SPINNER_DEFAULT_OPTIONS,
  LOADING_SPINNER_OPTIONS,
  LoadingSpinnerOptions,
} from './loading-spinner-options';
import { LoadingSpinnerComponent } from './loading-spinner.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[loadingSpinner]',
  standalone: true,
})
export class LoadingSpinnerDirective implements OnDestroy {
  private overlayRef!: OverlayRef;

  @Input() color?: ThemePalette;
  @Input() diameter?: number;
  @Input() strokeWidth?: number;

  @Input() hasBackdrop = false;
  @Input() panelClass: string | string[] = [];

  @Input() set loading(value: boolean) {
    if (value) this.attachLoadingIndicator();
    else this.detachLoadingIndicator();
  }

  constructor(
    private elementRef: ElementRef,
    private overlay: Overlay,
    private injector: Injector,
  ) {}

  ngOnDestroy(): void {
    this.overlayRef?.dispose();
  }

  private attachLoadingIndicator() {
    this.overlayRef?.dispose();

    const options = this.getSpinnerOptions();
    const injector = Injector.create({
      parent: this.injector,
      providers: [{ provide: LOADING_SPINNER_OPTIONS, useValue: options }],
    });

    this.overlayRef = this.overlay.create(this.getOverlayConfig());
    this.overlayRef.attach(new ComponentPortal(LoadingSpinnerComponent, null, injector));
  }

  private getOverlayConfig(): OverlayConfig {
    const overlayConfig = new OverlayConfig({
      height: this.elementRef.nativeElement.clientHeight,
      width: this.elementRef.nativeElement.clientWidth,
      hasBackdrop: this.hasBackdrop,
      panelClass: ['gcs-loading-spinner-panel', ...this.panelClass],
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.getPositionStrategy(),
    });
    return overlayConfig;
  }

  private getPositionStrategy(): PositionStrategy {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions(this.getPositions())
      .withPush(false);
    return positionStrategy;
  }

  private getPositions(): ConnectionPositionPair[] {
    return [
      {
        originX: 'center',
        originY: 'center',
        overlayX: 'center',
        overlayY: 'center',
      },
    ];
  }

  private detachLoadingIndicator() {
    this.overlayRef?.detach();
  }

  private getSpinnerOptions(): LoadingSpinnerOptions {
    const options = Object.entries(LOADING_SPINNER_DEFAULT_OPTIONS).reduce((acc, [key, value]) => {
      (acc as never)[key] = (this as never)[key] ?? value;
      return acc;
    }, {} as LoadingSpinnerOptions);
    return options;
  }
}
