export var Filter;
(function (Filter) {
  Filter[Filter["Nearest"] = 9728] = "Nearest";
  Filter[Filter["Linear"] = 9729] = "Linear";
  Filter[Filter["NearestMipmapNearest"] = 9984] = "NearestMipmapNearest";
  Filter[Filter["LinearMipmapNearest"] = 9985] = "LinearMipmapNearest";
  Filter[Filter["NearestMipmapLinear"] = 9986] = "NearestMipmapLinear";
  Filter[Filter["LinearMipmapLinear"] = 9987] = "LinearMipmapLinear";
})(Filter || (Filter = {}));
export var Wrap;
(function (Wrap) {
  Wrap[Wrap["ClampToEdge"] = 33071] = "ClampToEdge";
  Wrap[Wrap["MirroredRepeat"] = 33648] = "MirroredRepeat";
  Wrap[Wrap["Repeat"] = 10497] = "Repeat";
})(Wrap || (Wrap = {}));
