import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { ButtonBase } from '../modus-button-base';

/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/component-class-suffix */

@Component({
  selector: 'button[modus-button]',
  template: `<ng-content></ng-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModusButton extends ButtonBase {
  constructor(elementRef: ElementRef) {
    super(elementRef);
    this.element.classList.add('modus-basic-button');
  }
}
