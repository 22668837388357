@if (hasClassifications$ | async) {
  <sd-classification-graph
    [classifications]="(classifications$ | async)!"
    [activeClassification]="activeClassification"
    (activeClassificationChanged)="setActiveClassification($event)"
    class="flex h-[225px]"
  ></sd-classification-graph>
  <ol class="mt-4">
    @for (item of classifications$ | async; track $index) {
      <li
        (mouseover)="setActiveClassification(item)"
        (mouseout)="setActiveClassification()"
        [ngClass]="{
          'bg-gray-0': isActiveClassification(item),
          italic: item.classificationScheme.isNew
        }"
        class="group flex items-center text-xs px-2 h-6 hover:bg-gray-0"
      >
        <span class="w-6">{{ item.classificationScheme.id }}.</span>
        <span class="flex-1 overflow-hidden text-ellipsis whitespace-nowrap pr-4">{{
          item.classificationScheme.name
        }}</span>
        @if (!readonly) {
          <span class="flex items-center invisible group-hover:visible">
            @if (item.classificationScheme.isNew) {
              <button
                id="options-panel-classification-add"
                class="text-gray-8 h-6 w-6"
                modus-icon-button
                modus-tooltip="Add Classification"
                (click)="editClassification(item.classificationScheme)"
              >
                <md-icon class="!text-xl">add_circle</md-icon>
              </button>
            } @else {
              <button
                id="options-panel-classification-edit"
                class="text-gray-8 h-6 w-6"
                modus-icon-button
                modus-tooltip="Edit Classification"
                (click)="editClassification(item.classificationScheme)"
              >
                <md-icon class="!text-xl">pencil</md-icon>
              </button>
            }
          </span>
        }
        <span class="ml-2 w-6 text-right" modus-tooltip="{{ item.percentage | percentage: 2 }}">{{
          item.percentage | percentage: 0
        }}</span>
      </li>
    }
  </ol>
  @if (unallocatedClassificationCount$ | async; as count) {
    @if (count > 0) {
      <label class="mt-2 text-xs flex grow justify-center italic"
        >{{ count }} other {{ count === 1 ? 'class' : 'classes' }} with zero value not shown
      </label>
    }
  }
} @else {
  No classification data.
}
