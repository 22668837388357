import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetActiveBaseLayer, SetBaseLayers } from './base-layer.actions';
import { BaseLayer } from './base-layer.models';

export interface BaseLayerStateModel {
  baseLayers: BaseLayer[] | null;
  activeBaseLayer: BaseLayer | null;
}

const defaultState: BaseLayerStateModel = {
  baseLayers: null,
  activeBaseLayer: null,
};

@State<BaseLayerStateModel>({
  name: 'baseLayerState',
  defaults: defaultState,
})
@Injectable()
export class BaseLayerState {
  @Selector()
  static activeBaseLayer(state: BaseLayerStateModel) {
    return state.activeBaseLayer;
  }

  @Selector()
  static baseLayers(state: BaseLayerStateModel) {
    return state.baseLayers;
  }

  @Action(SetActiveBaseLayer)
  setActiveBaseLayer(
    ctx: StateContext<BaseLayerStateModel>,
    { activeBaseLayer }: SetActiveBaseLayer,
  ) {
    ctx.patchState({ activeBaseLayer: activeBaseLayer });
  }

  @Action(SetBaseLayers)
  setBaseLayers(ctx: StateContext<BaseLayerStateModel>, { baseLayers }: SetBaseLayers) {
    ctx.patchState({ baseLayers: baseLayers });
  }
}
