import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ModusButtonModule } from '@trimble-gcs/modus';

export enum TreeIcon {
  On,
  Off,
  Error,
  Loading,
  Unavailable,
}

@Component({
  selector: 'sd-scandata-tree-icon',
  standalone: true,
  imports: [CommonModule, ModusButtonModule, MatProgressSpinnerModule],
  templateUrl: './scandata-tree-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScandataTreeIconComponent {
  treeIcon = input.required<TreeIcon>();
  disabled = input.required<boolean>();
  clicked = output<void>();
  treeIconType = TreeIcon;

  onTreeIconClick() {
    if (this.disabled()) return;

    this.clicked.emit();
  }
}
