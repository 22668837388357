import { RxCollection, RxCollectionChange } from '@trimble-gcs/reactive';
import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  of,
  Subject,
  Subscription,
  switchMap,
} from 'rxjs';
import { FeatureFlagOwner } from './feature-flag-owner';

export function isFeatureFlag(obj: unknown): obj is FeatureFlag {
  return (obj as FeatureFlag).key !== undefined;
}

export function getRegexForKeyDescendants(key: string) {
  return new RegExp(`^${key}(\\.\\w+)+$`);
}

export class FeatureFlag<TKey extends string = string> implements FeatureFlagOwner {
  private readonly _key: TKey;
  private readonly _enabled$: BehaviorSubject<boolean>;

  private readonly _owner$ = new BehaviorSubject<FeatureFlagOwner | undefined>(undefined);
  private readonly _features = new RxCollection<FeatureFlag<TKey>>();
  private readonly _changes$ = new Subject<RxCollectionChange>();
  private readonly _subscriptions = new Map<FeatureFlag<TKey>, Subscription>();

  get key(): TKey {
    return this._key;
  }

  get enabled(): boolean {
    return this._enabled$.value;
  }

  private set owner(value: FeatureFlagOwner | undefined) {
    this._owner$.next(value);
  }

  get owner(): Readonly<FeatureFlagOwner> | undefined {
    return this._owner$.value;
  }

  get active(): boolean {
    const ownerActive = this.owner?.active || false;
    return ownerActive && this._enabled$.value;
  }

  get active$(): Observable<boolean> {
    const ownerActive$ = this._owner$.pipe(
      switchMap((owner) => {
        return owner?.active$ ?? of(false);
      }),
    );

    return combineLatest([ownerActive$, this._enabled$]).pipe(
      map(([owner, self]) => owner && self),
    );
  }

  get features(): ReadonlyArray<FeatureFlag<TKey>> {
    return [...this._features];
  }

  get changes$(): Observable<RxCollectionChange> {
    return this._changes$.asObservable();
  }

  constructor(key: TKey, enabled = true) {
    const validKey = /^(?!.*\.{2,})\w(?:\w*\.*\w)*$/.test(key);
    if (!validKey) {
      throw new Error('Key does not match valid pattern.');
    }

    this._key = key;
    this._enabled$ = new BehaviorSubject(enabled);

    this._subscriptions.set(
      this,
      this._features.subscribe((change: RxCollectionChange) => {
        this._changes$.next(change);
      }),
    );
  }

  add(feature: FeatureFlag<TKey>): FeatureFlag<TKey>;
  add(key: TKey, enabled?: boolean): FeatureFlag<TKey>;
  add(keyOrFeature: TKey | FeatureFlag<TKey>, enabled = true): FeatureFlag<TKey> {
    const newLocal = isFeatureFlag(keyOrFeature);
    return newLocal
      ? this.addFeature(keyOrFeature)
      : this.addFeature(new FeatureFlag(keyOrFeature, enabled));
  }

  remove(keyOrFeature: TKey | FeatureFlag<TKey>): void {
    const key = isFeatureFlag(keyOrFeature) ? keyOrFeature.key : keyOrFeature;

    const ownSegments = this.key.split('.');
    const keySegments = key.split('.');

    return keySegments.length === ownSegments.length + 1
      ? this.removeFeatureFromSelf(key)
      : this.removeFeatureFromTree(key, ownSegments, keySegments);
  }

  find(key: TKey): FeatureFlag<TKey> | undefined {
    const segments = key.split('.');
    let depth = this.key.split('.').length + 1;
    let search = segments.slice(0, depth).join('.');
    let current = this._features.find((f) => f.key === search);

    for (depth; depth < segments.length; depth++) {
      search = segments.slice(0, depth + 1).join('.');
      current = current?.features.find((f) => f.key === search);
    }

    return current;
  }

  enable(propagate = false): void {
    if (this._enabled$.value) return;

    this._enabled$.next(true);

    if (propagate) {
      this._features.forEachItem((f) => f.enable(true));
    }
  }

  disable(propagate = false): void {
    if (!this._enabled$.value) return;

    this._enabled$.next(false);

    if (propagate) {
      this._features.forEachItem((f) => f.disable(true));
    }
  }

  private addFeature(feature: FeatureFlag<TKey>): FeatureFlag<TKey> {
    const regex = getRegexForKeyDescendants(this.key);

    if (!regex.test(feature.key)) {
      throw new Error('Key does not extend parent key.');
    }

    const ownSegments = this.key.split('.');
    const keySegments = feature.key.split('.');

    return keySegments.length === ownSegments.length + 1
      ? this.addFeatureToSelf(feature)
      : this.addFeatureToTree(feature, ownSegments, keySegments);
  }

  private addFeatureToSelf(feature: FeatureFlag<TKey>) {
    const index = this._features.findIndex((f) => f.key === feature.key);
    const existing = this._features.get(index);

    if (!existing) {
      this.pushFeature(feature);
    } else {
      this.replaceFeature(existing, feature, index);
    }

    return feature;
  }

  private addFeatureToTree(
    feature: FeatureFlag<TKey>,
    ownSegments: string[],
    keySegments: string[],
  ) {
    const path = keySegments.slice(0, ownSegments.length + 1).join('.');
    const next = this._features.find((f) => f.key === path);
    if (!next) throw new Error(`No parent FeatureFlag for key: ${feature.key}`);
    return next.add(feature);
  }

  private pushFeature(feature: FeatureFlag<TKey>) {
    this.subscribeToFeatureChanges(feature);
    feature.owner = this;
    this._features.push(feature);
  }

  private replaceFeature(existing: FeatureFlag<TKey>, feature: FeatureFlag<TKey>, index: number) {
    if (existing === feature) return;

    existing.owner = undefined;
    this.unsubscribeFromFeatureChanges(existing);

    feature.owner = this;
    this._features.set(index, feature);
    this.subscribeToFeatureChanges(feature);
  }

  private removeFeatureFromSelf(key: TKey) {
    const index = this._features.findIndex((feature) => feature.key === key);
    const feature = this._features.get(index);

    if (index >= 0 && feature) {
      feature.owner = undefined;
      this._features.splice(index, 1);
      this.unsubscribeFromFeatureChanges(feature);
    }
  }

  private removeFeatureFromTree(key: TKey, ownSegments: string[], keySegments: string[]) {
    const path = keySegments.slice(0, ownSegments.length + 1).join('.');
    const next = this._features.find((f) => f.key === path);
    next?.remove(key);
  }

  private subscribeToFeatureChanges(feature: FeatureFlag<TKey>) {
    this._subscriptions.set(
      feature,
      feature.changes$.subscribe((change: RxCollectionChange) => {
        this._changes$.next(change);
      }),
    );
  }

  private unsubscribeFromFeatureChanges(feature: FeatureFlag<TKey>) {
    const subscription = this._subscriptions.get(feature);
    subscription?.unsubscribe();
  }
}
