@if (isSaving()) {
  <div class="w-full absolute">
    <mat-progress-bar class="mds-x-small" [mode]="'indeterminate'"></mat-progress-bar>
  </div>
}

<div class="flex flex-row p-4 pr-6 border-b border-gray-1">
  <div class="h-8 flex flex-1 flex-wrap content-center font-semibold">Edit Tags</div>
  <button
    id="options-panel-multi_add_tags_close"
    class="text-gray-8"
    modus-icon-button
    modus-tooltip="Close"
    (click)="closeClicked.emit()"
    [disabled]="isSaving()"
  >
    <md-icon>close</md-icon>
  </button>
</div>

<div class="flex-1 overflow-y-auto p-4 text-sm" cdkScrollable>
  <!-- remove tags -->
  @if (hasExistingTags()) {
    <div class="flex flex-col pb-4">
      <label class="block text-small text-gray-8">Remove Existing Tags</label>
      <div class="flex justify-start flex-wrap">
        @for (tag of existingTags(); track tag) {
          <modus-chip
            class="m-1"
            show-close
            [value]="tag"
            (closeClick)="removeExisting(tag)"
            [disabled]="isSaving()"
          >
          </modus-chip>
        }
      </div>
    </div>
  }

  <!-- add tags -->
  <div class="flex flex-col">
    <label class="block text-small text-gray-8">Add Tags</label>

    <div class="flex justify-start flex-wrap">
      @for (tag of addedTags(); track tag) {
        <modus-chip
          class="m-1"
          show-close
          [value]="tag"
          (closeClick)="removeAdded(tag)"
          [disabled]="isSaving()"
        >
        </modus-chip>
      }
    </div>

    <!-- autocomplete-->
    <modus-autocomplete-input
      #autocomplete
      placeholder="Begin typing to search..."
      [clearable]="true"
      [errorText]="errorText()"
      [options]="tagOptionList"
      [formControl]="tagSelector"
      (optionSelected)="addTag($event)"
    >
    </modus-autocomplete-input>
    <modus-option-list #tagOptionList="modusOptionList">
      @for (tag of filteredTags(); track tag) {
        <modus-option [value]="tag">{{ tag }}</modus-option>
      }
    </modus-option-list>
  </div>

  <!-- buttons -->
  <div class="flex py-4 justify-end">
    <button
      id="options-panel-multi_add_tags_cancel"
      modus-flat-button
      (click)="closeClicked.emit()"
      [disabled]="isSaving()"
      class="mr-4"
      color="secondary"
    >
      Cancel
    </button>

    <button
      id="options-panel-multi_add_tags_save"
      modus-flat-button
      (click)="save()"
      [disabled]="saveDisabled()"
    >
      Save
    </button>
  </div>

  <!-- selected list -->
  <div class="flex flex-col">
    <div class="-ml-4 -mr-4 mt-1 mb-2 border-b border-gray-1"></div>
    <div class="font-medium mt-2 mb-4">Selected Items</div>
    @for (scan of scandataModels(); track scan.id) {
      <div class="flex flex-row mb-4">
        <md-icon>file</md-icon>
        <div
          modus-tooltip="{{ scan.name }}"
          class="w-full overflow-hidden text-ellipsis whitespace-nowrap leading-8 ml-2"
        >
          {{ scan.name }}
        </div>
      </div>
    }
  </div>
</div>
