import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { isNil } from '../../../../../libs/common/src';
import { PatchScandataModel } from '../scandata/scandata.actions';
import { ScandataModel } from '../scandata/scandata.models';
import { ClearCurrentStation } from '../station/station.actions';
import { Scan3dStyle } from './models/scan-3d-style';
import { Scan3dDetailComponent } from './scan-3d-detail/scan-3d-detail.component';
import { Scan3dListComponent } from './scan-3d-list/scan-3d-list.component';
import { Scan3dSettingsComponent } from './scan-3d-settings/scan-3d-settings.component';
import { Scan3dService } from './scan-3d.service';

enum Scan3dView {
  List = 'List',
  Details = 'Details',
  Settings = 'Settings',
}

@UntilDestroy()
@Component({
  selector: 'sd-scan-3d-panel',
  standalone: true,
  imports: [CommonModule, Scan3dListComponent, Scan3dDetailComponent, Scan3dSettingsComponent],
  templateUrl: './scan-3d-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Scan3dPanelComponent {
  currentView = signal<Scan3dView>(Scan3dView.List);
  currentScan = signal<ScandataModel | null>(null);

  private globalStyle = toSignal(this.getCopyOfGlobalStyle());
  scan3dStyle = signal<Scan3dStyle | undefined>(undefined);

  listHidden = computed(() => this.currentView() !== this.scan3dView.List);

  scan3dView = Scan3dView;

  constructor(
    private store: Store,
    private scan3dService: Scan3dService,
  ) {}

  onExitStationClick() {
    this.store.dispatch(new ClearCurrentStation());
  }

  onSettingsClick() {
    this.currentView.set(Scan3dView.Settings);
  }

  onExitSettingsClick() {
    this.currentView.set(Scan3dView.List);
  }

  onModelDetailClick(model: ScandataModel) {
    this.currentScan.set(model);
    this.scan3dStyle.set(model.scan3dStyle ?? this.globalStyle());
    this.currentView.set(Scan3dView.Details);
  }

  onExitDetailClick() {
    this.currentScan.set(null);
    this.currentView.set(Scan3dView.List);
  }

  onStyleChange(style: Scan3dStyle) {
    const scan = this.currentScan();
    const scan3dStyle = this.scan3dStyle();
    if (isNil(scan) || isNil(scan3dStyle)) return;

    // Turn eye dome lighting on when classification color is switched on
    const classificationShowing = scan3dStyle.showClassification === true;

    if (
      classificationShowing === false &&
      style.showClassification === true &&
      style.showEyeDomeLighting === false
    ) {
      style.showEyeDomeLighting = true;
    }

    this.scan3dStyle.set(style);
    scan.scan3dStyle = style;
    this.store.dispatch(new PatchScandataModel(scan));
  }

  private getCopyOfGlobalStyle(): Observable<Scan3dStyle> {
    return this.scan3dService.getGlobalStyle().pipe(
      map((globalStyle) => ({
        ...globalStyle,
        classificationSchemes: [...globalStyle.classificationSchemes],
      })),
    );
  }
}
