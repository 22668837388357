import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LOADING_SPINNER_OPTIONS, LoadingSpinnerOptions } from './loading-spinner-options';

@Component({
  standalone: true,
  imports: [MatProgressSpinnerModule],
  template: `
    <ng-container>
      <mat-progress-spinner
        [mode]="'indeterminate'"
        [color]="color"
        [diameter]="diameter"
        [strokeWidth]="strokeWidth"
      >
      </mat-progress-spinner>
    </ng-container>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex: 1 0 auto;
        justify-content: center;
        align-items: center;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingSpinnerComponent {
  color: ThemePalette;
  diameter: number;
  strokeWidth: number;

  constructor(@Inject(LOADING_SPINNER_OPTIONS) options: LoadingSpinnerOptions) {
    this.color = options.color;
    this.diameter = options.diameter;
    this.strokeWidth = options.strokeWidth;
  }
}
