import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { map } from 'rxjs';
import { AppRoute } from '../app.routes';
import { LicenseState } from './license.state';

export const licenseGuard: CanActivateFn = (
  _: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const store = inject(Store);
  const router = inject(Router);

  return store.select(LicenseState.hasActiveLicense).pipe(
    map((hasActiveLicense) => {
      if (hasActiveLicense) return true;

      return router.createUrlTree([AppRoute.LicenseCheck], {
        queryParams: { returnPath: state.url },
      });
    }),
  );
};
