import { Directive, ElementRef, inject } from '@angular/core';
import { ModusControlSize } from '../directives/control-size.directive';
import { FORM_FIELD_ID_PROVIDER, FormFieldControl, InputIdProvider } from '../modus-form-field';

/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @angular-eslint/directive-class-suffix */

@Directive({
  selector: 'input[modus-input], textarea[modus-input]',
  exportAs: 'modusInput',
  hostDirectives: [
    { directive: ModusControlSize, inputs: ['size'] },
    { directive: FormFieldControl, inputs: ['id', 'required', 'disabled', 'readonly'] },
  ],
  providers: [
    {
      provide: FORM_FIELD_ID_PROVIDER,
      useExisting: InputIdProvider,
    },
  ],
})
export class ModusInput {
  private _formFieldControl: FormFieldControl = inject(FormFieldControl);

  constructor() {
    const elementRef = inject(ElementRef);
    elementRef.nativeElement.classList.add(this._formFieldControl.controlType);
  }
}
