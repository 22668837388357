import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isDefined } from '@trimble-gcs/common';
import { ModusButtonModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { filter, map, switchMap } from 'rxjs';
import { ConnectService } from '../../../connect/connect.service';
import { CANCEL_BUTTON, DialogData } from '../../../dialog/dialog.model';
import { DialogService } from '../../../dialog/dialog.service';
import { LoadingService } from '../../../loading/loading.service';
import { ScandataModel } from '../../../scandata/scandata.models';
import { ScandataService } from '../../../scandata/scandata.service';
import { OptionsComponent } from './options/options.component';
import { TaggingComponent } from './tagging/tagging.component';

@UntilDestroy()
@Component({
  selector: 'sd-multi-selected',
  standalone: true,
  imports: [
    CommonModule,
    ModusButtonModule,
    ModusIconModule,
    ModusTooltipModule,
    MatProgressBarModule,
    OptionsComponent,
    TaggingComponent,
  ],
  templateUrl: './multi-selected.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectedComponent {
  scandataModels = input.required<ScandataModel[]>();
  readonly = input<boolean>(false);

  downloadClicked = output();
  unselectClicked = output<ScandataModel>();
  unselectAllClicked = output();

  showProgressBar = toSignal(this.loadingService.isLoading$(this), { initialValue: false });
  disabled = computed(() => this.showProgressBar());

  showTagging = signal(false);
  showOptions = computed(() => !this.showTagging());

  constructor(
    private loadingService: LoadingService,
    private scandataService: ScandataService,
    private connectService: ConnectService,
    private dialogService: DialogService,
  ) {}

  viewIn3d() {
    this.connectService.goTo3dExtension();
  }

  deleteScandata() {
    this.showDeleteConfirmation(this.scandataModels())
      .pipe(
        switchMap((scandataModels) => {
          return this.loadingService.loadFrom(
            this.scandataService.deleteScandataModels(scandataModels),
            this,
          );
        }),
        map((result) => {
          const errors = result
            .filter((item) => isDefined(item.error))
            .map((item) => item.scandataModel);

          if (errors.length === 0) return;

          this.showDeleteErrors(errors);
        }),
      )
      .subscribe();
  }

  private showDeleteConfirmation(scandataModels: ScandataModel[]) {
    const dialogData = new DialogData(
      'Delete Reality Capture Data',
      `Are you sure you want to delete these ${scandataModels.length} items?`,
      { text: 'Delete', color: 'danger' },
      CANCEL_BUTTON,
    );

    return this.dialogService.show(dialogData).pipe(
      filter((confirmed) => confirmed),
      map(() => scandataModels),
      untilDestroyed(this),
    );
  }

  private showDeleteErrors(failedModels: ScandataModel[]) {
    const message =
      `The following scan${failedModels.length > 1 ? 's' : ''} failed to delete:\n\n` +
      failedModels.map((model) => model.name).join('\n');

    const dialogData = new DialogData('Delete Errors', message, {
      text: 'Dismiss',
      color: 'danger',
    });

    this.dialogService.show(dialogData).pipe(untilDestroyed(this)).subscribe();
  }
}
