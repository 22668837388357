import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, DoCheck, ElementRef, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
import { Subject, Subscription, fromEvent, map, merge, tap } from 'rxjs';
import { AbstractFormFieldControl } from './abstract-form-field-control';
import { FORM_FIELD_ID_PROVIDER } from './form-field-id-provider';

export type StateChange = 'id' | 'required' | 'disabled' | 'readonly' | 'focused' | 'doCheck';

@Directive({
  standalone: true,
})
export class FormFieldControl implements AbstractFormFieldControl, OnInit, DoCheck, OnDestroy {
  private readonly elementRef = inject(ElementRef);
  private _id!: string;
  private _uid: string;

  private _required?: boolean;
  private _disabled?: boolean;
  private _readonly = false;
  private _focused = false;

  private readonly _subscription = new Subscription();

  readonly controlType: string;

  @Input()
  get id(): string {
    return this._id || this._uid;
  }
  set id(value: string) {
    this._id = value || this._uid;
    this.stateChanges.next('id');
  }

  @Input()
  get required(): boolean {
    return this._required ?? this.ngControl?.control?.hasValidator(Validators.required) ?? false;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next('required');
  }

  @Input()
  get disabled(): boolean {
    return this._disabled ?? this.ngControl?.control?.disabled ?? false;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next('disabled');
  }

  @Input()
  get readonly(): boolean {
    return this._readonly;
  }
  set readonly(value: BooleanInput) {
    this._readonly = coerceBooleanProperty(value);
    this.stateChanges.next('readonly');
  }

  get focused(): boolean {
    return this._focused;
  }

  readonly ngControl = inject(NgControl, { optional: true });
  readonly stateChanges: Subject<StateChange> = new Subject<StateChange>();

  constructor() {
    const idProvider = inject(FORM_FIELD_ID_PROVIDER, { self: true });
    this._uid = idProvider.nextId();
    this.controlType = idProvider.controlType;
  }

  ngDoCheck(): void {
    this.stateChanges.next('doCheck');
  }

  ngOnInit(): void {
    const focusSubscription = merge(
      fromEvent<FocusEvent>(this.elementRef.nativeElement, 'focus'),
      fromEvent<FocusEvent>(this.elementRef.nativeElement, 'blur'),
    )
      .pipe(
        tap((event: FocusEvent) => (this._focused = event.type === 'focus')),
        map(() => 'focused' as StateChange),
      )
      .subscribe(this.stateChanges);

    this._subscription.add(focusSubscription);
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
    this.stateChanges.complete();
  }
}
