import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModusButtonModule, ModusIconModule } from '@trimble-gcs/modus';
import { DialogData } from './dialog.model';

@Component({
  standalone: true,
  imports: [ModusIconModule, ModusButtonModule],
  templateUrl: './dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  cancelClick(): void {
    this.dialogRef.close(false);
  }

  okClick(): void {
    this.dialogRef.close(true);
  }
}
