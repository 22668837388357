import { Observable } from 'rxjs';

export type Intersection = { top: boolean; right: boolean; bottom: boolean; left: boolean };
export type IntersectionObserverEntryEx = IntersectionObserverEntry & {
  intersection: Intersection;
};

export function createIntersectionObserver(
  target: HTMLElement,
  options: IntersectionObserverInit,
): Observable<IntersectionObserverEntryEx[]> {
  return new Observable(function subscribe(subscriber) {
    const callback: IntersectionObserverCallback = (entries: IntersectionObserverEntry[]) => {
      const entriesEx = entries.map((entry: IntersectionObserverEntry) => {
        const { boundingClientRect, intersectionRect } = entry;
        const intersection: Intersection = {
          top: boundingClientRect.top < intersectionRect.top,
          right: boundingClientRect.right > intersectionRect.right,
          bottom: boundingClientRect.bottom > intersectionRect.bottom,
          left: boundingClientRect.left < intersectionRect.left,
        };
        (entry as IntersectionObserverEntryEx)['intersection'] = intersection;
        return entry as IntersectionObserverEntryEx;
      });
      subscriber.next(entriesEx);
    };

    const observer = new IntersectionObserver(callback, options);
    observer.observe(target);
  });
}
