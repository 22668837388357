import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetCameraProjection } from './camera.action';
import { CameraProjection } from './camera.models';

export interface CameraStateModel {
  projection?: CameraProjection;
}

const defaultState: CameraStateModel = {};

@State<CameraStateModel>({
  name: 'cameraState',
  defaults: defaultState,
})
@Injectable()
export class CameraState {
  @Selector() static projection(state: CameraStateModel): CameraProjection | undefined {
    return state.projection;
  }

  @Action(SetCameraProjection) setCameraProjection(
    ctx: StateContext<CameraStateModel>,
    { projection }: SetCameraProjection,
  ) {
    ctx.patchState({ projection });
  }
}
