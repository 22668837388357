import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  ClearClassificationSchemes,
  SetClassificationForEdit,
  SetClassificationSchemes,
} from './classification-scheme.actions';
import { ClassificationScheme } from './classification-scheme.model';

export interface ClassificationSchemeStateModel {
  classificationSchemes?: ClassificationScheme[];
  classificationSchemeForEdit?: ClassificationScheme;
}

@State<ClassificationSchemeStateModel>({
  name: 'classificationSchemeState',
  defaults: {},
})
@Injectable()
export class ClassificationSchemeState {
  @Selector() static classifications(
    state: ClassificationSchemeStateModel,
  ): ClassificationScheme[] | undefined {
    return state.classificationSchemes;
  }

  @Selector() static classificationForEdit(
    state: ClassificationSchemeStateModel,
  ): ClassificationScheme | undefined {
    return state.classificationSchemeForEdit;
  }

  @Action(SetClassificationForEdit) setClassificationForEdit(
    ctx: StateContext<ClassificationSchemeStateModel>,
    { classificationScheme }: SetClassificationForEdit,
  ) {
    ctx.patchState({ classificationSchemeForEdit: classificationScheme });
  }

  @Action(SetClassificationSchemes)
  setClassificationSchemes(
    ctx: StateContext<ClassificationSchemeStateModel>,
    { classificationSchemes }: SetClassificationSchemes,
  ) {
    ctx.patchState({ classificationSchemes });
  }

  @Action(ClearClassificationSchemes)
  clearClassificationSchemes(ctx: StateContext<ClassificationSchemeStateModel>) {
    ctx.patchState({ classificationSchemes: undefined });
  }
}
