import { BehaviorSubject, Observable, combineLatestAll, map, of } from 'rxjs';

export type LoadingContext = object;
export type LoadingKey = string | number;

export class LoadingState {
  private _loading$: BehaviorSubject<boolean>;

  get loading(): boolean {
    return this._loading$.value;
  }

  get loading$(): Observable<boolean> {
    return this._loading$.asObservable();
  }

  constructor(initialState = false) {
    this._loading$ = new BehaviorSubject(initialState);
  }

  set(state: boolean): void {
    this._loading$.next(state);
  }

  complete(): void {
    this._loading$.complete();
  }
}

export class LoadingMap implements Map<LoadingKey, LoadingState> {
  private readonly map = new Map<LoadingKey, LoadingState>();
  private readonly sub = new BehaviorSubject<Observable<boolean>>(of(false));

  get loading$(): Observable<boolean> {
    return this.sub.pipe(
      combineLatestAll(),
      map((values) => values.some((loading) => loading)),
    );
  }

  get size(): number {
    return this.map.size;
  }

  [Symbol.toStringTag] = 'LoadingMap';

  *[Symbol.iterator](): IterableIterator<[LoadingKey, LoadingState]> {
    for (const iterator of this.map) {
      yield iterator;
    }
  }

  clear(): void {
    this.map.clear();
  }

  delete(key: LoadingKey): boolean {
    return this.map.delete(key);
  }

  forEach(
    callbackfn: (value: LoadingState, key: LoadingKey, map: Map<LoadingKey, LoadingState>) => void,
    thisArg?: unknown,
  ): void {
    this.map.forEach(callbackfn, thisArg);
  }

  get(key: LoadingKey): LoadingState | undefined {
    return this.map.get(key);
  }

  has(key: LoadingKey): boolean {
    return this.map.has(key);
  }

  set(key: LoadingKey, value: LoadingState): this {
    this.map.set(key, value);
    this.sub.next(value.loading$);
    return this;
  }

  entries(): IterableIterator<[LoadingKey, LoadingState]> {
    return this.map.entries();
  }

  keys(): IterableIterator<LoadingKey> {
    return this.map.keys();
  }

  values(): IterableIterator<LoadingState> {
    return this.map.values();
  }
}
