/* eslint-disable @typescript-eslint/no-explicit-any */
import { Observable } from 'rxjs';
import { TaskResult, Transfer } from './worker-models';

type AnyFunction = (...args: any) => any;
type WorkerLiteral = Record<string, AnyFunction>;

type Trans<T> = T extends Transferable ? T | Transfer<T> : T;

// prettier-ignore
type WorkerParameters<T extends AnyFunction> = T extends (...args: infer P) => unknown
  ? P extends [infer P0]
    ? [Trans<P0>]
    : P extends [infer P0, infer P1]
    ? [Trans<P0>, Trans<P1>]
    : P extends [infer P0, infer P1, infer P2]
    ? [Trans<P0>, Trans<P1>, Trans<P2>]
    : P extends [infer P0, infer P1, infer P2, infer P3]
    ? [Trans<P0>, Trans<P1>, Trans<P2>, Trans<P3>]
    : P extends [infer P0, infer P1, infer P2, infer P3, infer P4]
    ? [Trans<P0>, Trans<P1>, Trans<P2>, Trans<P3>, Trans<P4>]
    : P extends [infer P0, infer P1, infer P2, infer P3, infer P4, infer P5]
    ? [Trans<P0>, Trans<P1>, Trans<P2>, Trans<P3>, Trans<P4>, Trans<P5>]
    : P extends [infer P0, infer P1, infer P2, infer P3, infer P4, infer P5, infer P6]
    ? [Trans<P0>, Trans<P1>, Trans<P2>, Trans<P3>, Trans<P4>, Trans<P5>, Trans<P6>]
    : P extends [infer P0, infer P1, infer P2, infer P3, infer P4, infer P5, infer P6, infer P7]
    ? [Trans<P0>, Trans<P1>, Trans<P2>, Trans<P3>, Trans<P4>, Trans<P5>, Trans<P6>, Trans<P7>]
    : P extends [infer P0, infer P1, infer P2, infer P3, infer P4, infer P5, infer P6, infer P7, infer P8]
    ? [Trans<P0>, Trans<P1>, Trans<P2>, Trans<P3>, Trans<P4>, Trans<P5>, Trans<P6>, Trans<P7>, Trans<P8>]
    : P extends [infer P0, infer P1, infer P2, infer P3, infer P4, infer P5, infer P6, infer P7, infer P8, infer P9]
    ? [Trans<P0>, Trans<P1>, Trans<P2>, Trans<P3>, Trans<P4>, Trans<P5>, Trans<P6>, Trans<P7>, Trans<P8>, Trans<P9>]
    : P
  : never;

type WorkerReturnType<T extends WorkerFunction> = T extends (...args: any) => infer R
  ? R extends Promise<infer U>
    ? U extends Transfer<infer V>
      ? V
      : U
    : R extends Transfer<infer V>
      ? V
      : R
  : any;

type WorkerProps = {
  readonly name: string;
  isTerminated(): boolean;
  terminate(force?: boolean): Promise<void>;
  taskResult$: Observable<TaskResult>;
};

export type WorkerFactory = (name: string) => Worker;

export type UnknownWorker = AnyFunction | WorkerLiteral;
export type GeneralWorker = AnyFunction & WorkerLiteral;

export type WorkerFunction<T extends AnyFunction = AnyFunction> = (
  ...args: WorkerParameters<T>
) => Promise<WorkerReturnType<T>>;

export type AsyncWorkerFunction<T extends AnyFunction = AnyFunction> = WorkerProps & {
  postMessage: WorkerFunction<T>;
  result$: Observable<WorkerReturnType<T>>;
};

export type AsyncWorkerLiteral<T extends WorkerLiteral = WorkerLiteral> = WorkerProps & {
  [K in keyof T]: T[K] extends AnyFunction ? WorkerFunction<T[K]> : never;
} & {
  [K in keyof T as `${K & string}$`]: Observable<WorkerReturnType<T[K]>>;
};

export type AsyncWorker<T = UnknownWorker> = T extends GeneralWorker
  ? GeneralWorker
  : T extends AnyFunction
    ? AsyncWorkerFunction<T>
    : T extends WorkerLiteral
      ? AsyncWorkerLiteral<T>
      : never;

export function isAsyncWorkerFunction(arg: unknown): arg is AsyncWorkerFunction {
  const candidate = arg as AsyncWorkerFunction;
  const isFunc = candidate && candidate.postMessage && typeof candidate.postMessage === 'function';
  return isFunc;
}

export function isAsyncWorkerLiteral(arg: unknown): arg is AsyncWorkerLiteral {
  const candidate = arg as AsyncWorkerLiteral;
  const isLiteral = candidate && !candidate['postMessage'] && !candidate['result$'];
  return isLiteral;
}
