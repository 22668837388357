@if (downloadProgress(); as progress) {
  <div>
    @switch (progress.status) {
      @case (downloadStatus.Pending) {
        <ng-container [ngTemplateOutlet]="progressIndicator"></ng-container>
      }
      @case (downloadStatus.Busy) {
        <ng-container [ngTemplateOutlet]="progressIndicator"></ng-container>
      }
      @case (downloadStatus.Complete) {
        <div class="flex">
          <md-icon modus-tooltip="Download Completed">check</md-icon>
        </div>
      }
      @case (downloadStatus.Failed) {
        <div class="flex">
          <md-icon modus-tooltip="Download Failed">alert_outlined</md-icon>
        </div>
      }
    }
    <ng-template #progressIndicator>
      <div class="relative" [modus-tooltip]="progressTooltip()">
        <div class="w-5 h-5 absolute border-2 border-gray-1 rounded-full"></div>
        <mat-progress-spinner
          class="!w-5 !h-5 relative"
          mode="determinate"
          [value]="progress.percentageComplete"
        >
        </mat-progress-spinner>
      </div>
    </ng-template>
  </div>
}
