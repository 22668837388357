import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { isNil } from '@trimble-gcs/common';
import { catchError, concatMap, from, map, Observable, of, switchMap, takeWhile } from 'rxjs';
import { AppState } from '../app-state/app.state';
import { injectLogger, Logger } from '../logging/logger';
import { ProjectQuotaService } from '../quota/project-quota.service';
import { ScandataModel } from '../scandata/scandata.models';
import { GetClientIdentificationHeaders } from '../utils/client-identification-headers';
import { GET_SCAN_PROJECT_URL } from '../utils/get-scan-project-url';
import { ImportFile, ImportFileStatus, ImportScan } from './import.models';
import { UploadFile } from './upload.models';
import { UploadService } from './upload.service';

@Injectable({
  providedIn: 'root',
})
export class ImportService {
  private readonly getScanProjectUrl$ = inject(GET_SCAN_PROJECT_URL);
  private readonly logger = injectLogger(Logger, 'ImportService');

  constructor(
    private projectQuotaService: ProjectQuotaService,
    private uploadService: UploadService,
    private store: Store,
    private http: HttpClient,
  ) {}

  cancelImport(importScans: ImportScan[]) {
    return from(importScans).pipe(switchMap((importScan) => this.deleteScan(importScan)));
  }

  import(importScans: ImportScan[]): Observable<ImportScan[]> {
    return from(importScans).pipe(
      concatMap((importScan) => {
        return this.importScan(importScan).pipe(
          takeWhile((importScan) => !this.hasError(importScan), true),
        );
      }),
      map(() => [...importScans]),
    );
  }

  private importScan(importScan: ImportScan) {
    return this.checkAvailableQuota(importScan).pipe(
      switchMap((importScan) => this.createPointcloud(importScan)),
      switchMap((importScan) => this.createFiles(importScan)),
      switchMap((importScan) => this.uploadFiles(importScan)),
      switchMap((importScan) => {
        return this.isCompleted(importScan) ? this.createIngestion(importScan) : of(importScan);
      }),
      catchError(() => {
        this.markPendingFilesAsSkipped(importScan);
        return this.deleteScan(importScan);
      }),
    );
  }

  private isCompleted(importScan: ImportScan) {
    return (
      importScan.files.length > 0 &&
      importScan.files.every((importFile) => importFile.status === ImportFileStatus.Completed)
    );
  }

  private hasError(importScan: ImportScan) {
    return importScan.files.some((importFile) => importFile.status === ImportFileStatus.Error);
  }

  private setImportFilesError(importFiles: ImportFile[], errorMessage: string) {
    importFiles.forEach((file) => {
      file.errorMessage = errorMessage;
      file.status = ImportFileStatus.Error;
    });
  }

  private markPendingFilesAsSkipped(importScan: ImportScan) {
    importScan.files
      .filter((importFile) => importFile.status === ImportFileStatus.Pending)
      .forEach((importFile) => {
        importFile.status = ImportFileStatus.Skipped;
      });
  }

  private checkAvailableQuota(importScan: ImportScan): Observable<ImportScan> {
    const importSize = importScan.files.reduce((acc, cur) => acc + cur.size, 0);

    return this.projectQuotaService.quotaExceeded(importSize).pipe(
      catchError((err) => {
        this.logger.error(`Import error checking quota`, {}, err);
        this.setImportFilesError(importScan.files, 'Error checking quota');
        throw err;
      }),
      map((quotaExceeded) => {
        if (quotaExceeded) {
          this.setImportFilesError(importScan.files, 'Quota will be exceeded');
          throw new Error('Quota will be exceeded');
        }

        return importScan;
      }),
    );
  }

  private createPointcloud(importScan: ImportScan): Observable<ImportScan> {
    const appSettings = this.store.selectSnapshot(AppState.settings);

    return this.getScanProjectUrl$(`/pointclouds`).pipe(
      switchMap((url) => {
        return this.http.post<ScandataModel>(
          url,
          {
            name: importScan.name,
          },
          {
            headers: GetClientIdentificationHeaders(appSettings),
          },
        );
      }),
      map((scan) => {
        importScan.scan = scan;
        return importScan;
      }),
      catchError((err) => {
        this.logger.error(`Import error creating pointcloud`, {}, err);
        this.setImportFilesError(importScan.files, 'Error creating pointcloud');
        throw err;
      }),
    );
  }

  private createFiles(importScan: ImportScan): Observable<ImportScan> {
    return this.getScanProjectUrl$(`/pointclouds/${importScan.scan!.id}/files`).pipe(
      switchMap((url) => {
        return this.http.post<UploadFile[]>(url, {
          files: importScan.files.map((importFile) => ({
            name: importFile.name,
            size: importFile.size,
          })),
        });
      }),
      map((uploadFiles) => ({
        ...importScan,
        importFiles: this.mapImportFileUrl(importScan.files, uploadFiles),
      })),
      catchError((err) => {
        this.logger.error(`Import error creating files`, {}, err);
        this.setImportFilesError(importScan.files, 'Error creating files');
        throw err;
      }),
    );
  }

  private mapImportFileUrl(importFiles: ImportFile[], uploadFiles: UploadFile[]) {
    return uploadFiles.map((uploadFile) => {
      const importFile = importFiles.find((importFile) => {
        return (
          importFile.name === uploadFile.name &&
          importFile.size === uploadFile.size &&
          isNil(importFile.fileUpload?.uploadUrl)
        );
      })!;

      importFile.fileUpload!.uploadUrl = uploadFile.uploadLink;

      return importFile;
    });
  }

  private uploadFiles(importScan: ImportScan): Observable<ImportScan> {
    return this.uploadService.uploadFiles(importScan.files).pipe(
      map((importFiles) => {
        return {
          ...importScan,
          importFiles,
        };
      }),
    );
  }

  private createIngestion(importScan: ImportScan) {
    return this.getScanProjectUrl$(`/pointclouds/${importScan.scan!.id}/ingestions`).pipe(
      switchMap((url) => this.http.post(url, null)),
      map(() => importScan),
      catchError((err) => {
        this.logger.error(`Import error creating ingestion`, {}, err);
        this.setImportFilesError(importScan.files, 'Error starting ingestion');
        throw err;
      }),
    );
  }

  private deleteScan(importScan: ImportScan) {
    if (isNil(importScan.scan)) return of(importScan);

    return this.getScanProjectUrl$(`/pointclouds/${importScan.scan.id}`).pipe(
      switchMap((url) => {
        return this.http.delete<void>(url);
      }),
      catchError((err) => {
        this.logger.error(`Import error deleting pointcloud`, {}, err);
        return of(null);
      }),
      map(() => importScan),
    );
  }
}
