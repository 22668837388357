import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModusButtonModule, ModusIconModule } from '@trimble-gcs/modus';

@Component({
  standalone: true,
  imports: [ModusIconModule, ModusButtonModule],
  templateUrl: './out-of-quota-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutOfQuotaDialogComponent {
  constructor(public dialogRef: MatDialogRef<OutOfQuotaDialogComponent>) {}

  closeClick(): void {
    this.dialogRef.close();
  }
}
