import { Directive } from '@angular/core';

/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @angular-eslint/directive-class-suffix */

/** The label for a modus-form-field */
@Directive({
  selector: 'modus-label',
})
export class ModusLabel {}
