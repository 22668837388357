import { Directive, HostBinding, Input } from '@angular/core';

/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @angular-eslint/directive-class-suffix */

export type ControlSize = 'default' | 'large';

@Directive({
  standalone: true,
})
export class ModusControlSize {
  private _size: ControlSize = 'default';

  @Input()
  set size(value: ControlSize) {
    this._size = value;
  }

  @HostBinding('class')
  get sizeClass() {
    return `modus-control-size-${this._size}`;
  }
}
